import React from "react";
import { BrandDetails } from "@pose-party/types";

export const BrandContext = React.createContext<{
  brandId: string;
  brandDetails: BrandDetails;
  hasLoadedBrandDetails: boolean;
  setCurrentUid: (uid: string | undefined) => void;
  setGameDetails: (
    details: { gameId: string; hostId: string } | undefined
  ) => void;
}>({
  brandId: "pose_party",
  // @ts-ignore This will never actually be used
  brandDetails: undefined,
  hasLoadedBrandDetails: false,
  setCurrentUid: () => {},
  setGameDetails: () => {},
});
