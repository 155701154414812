import React from "react";
import _ from "lodash";
import { hostActions } from "../data/game";

export const useHostActions = ({
  brandId,
  gameId,
  uid,
  groupSize,
  hostPlaying,
  isHost,
}: {
  brandId: string;
  gameId: string;
  uid: string | undefined;
  groupSize: number | undefined;
  hostPlaying: boolean;
  isHost: boolean;
}): [boolean, { [key in keyof typeof hostActions]: () => Promise<void> }] => {
  const [performingHostNext, setPerformingHostNext] = React.useState(false);
  const onHostNextInUseRef = React.useRef(false);

  const mappedActions = React.useMemo(
    () =>
      _.mapValues(hostActions, (action, key) => async () => {
        if (!uid || !isHost || onHostNextInUseRef.current) {
          return;
        }

        onHostNextInUseRef.current = true;
        const handle = setTimeout(() => setPerformingHostNext(true), 150);
        try {
          await action({ brandId, gameId, groupSize, hostPlaying, uid });
        } catch (e) {
          alert(`Error while performing host action ${key}, ${e.toString()}`);
        }
        clearTimeout(handle);
        setPerformingHostNext(false);
        setTimeout(() => {
          onHostNextInUseRef.current = false;
        }, 1000);
      }),
    [brandId, gameId, groupSize, hostPlaying, isHost, uid]
  );

  return [performingHostNext, mappedActions];
};
