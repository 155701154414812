import {
  SNAPSHOT_HEIGHT,
  SNAPSHOT_WIDTH,
  WebcamSnapshot,
} from "@pose-party/types";
import React from "react";
import styled from "styled-components/macro";
import uploadBlack from "../../assets/uploadBlack.svg";

const Container = styled.div`
  flex: 1;
`;

const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;

const Icon = styled.img`
  width: 25% !important;
  height: 25% !important;
`;

export interface SnapshotImagePickerButtonProps {
  onSnapshotPicked: (snapshot: WebcamSnapshot) => void;
}

export const SnapshotImagePickerButton: React.FC<SnapshotImagePickerButtonProps> = React.memo(
  ({
    onSnapshotPicked,
  }: React.PropsWithChildren<SnapshotImagePickerButtonProps>) => {
    const snapshotCanvasRef = React.createRef<HTMLCanvasElement>();

    const handleFileChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (event.target.files?.length !== 1) {
          return;
        }

        const eventFile = event.target.files[0];
        if (!eventFile.type.startsWith("image/")) {
          alert("Please only choose images");
          // eslint-disable-next-line no-param-reassign
          event.target.value = "";
          return;
        }

        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          if (typeof e.target?.result !== "string") {
            return;
          }

          const image = new Image();
          image.onload = () => {
            if (!snapshotCanvasRef.current) {
              return;
            }

            const ctx = snapshotCanvasRef.current.getContext("2d");
            if (!ctx) {
              return;
            }

            const inputWidth = image.naturalWidth;
            const inputHeight = image.naturalHeight;

            // scale to the target width
            const scaleX1 = SNAPSHOT_WIDTH;
            const scaleY1 = (inputHeight * SNAPSHOT_WIDTH) / inputWidth;

            // scale to the target height
            const scaleX2 = (inputWidth * SNAPSHOT_HEIGHT) / inputHeight;
            const scaleY2 = SNAPSHOT_HEIGHT;

            // now figure out which one we should use
            const scaleOnWidth = scaleX2 <= SNAPSHOT_WIDTH;

            const width = scaleOnWidth
              ? Math.floor(scaleX1)
              : Math.floor(scaleX2);
            const height = scaleOnWidth
              ? Math.floor(scaleY1)
              : Math.floor(scaleY2);

            const left = Math.floor((SNAPSHOT_WIDTH - width) / 2);
            const top = Math.floor((SNAPSHOT_HEIGHT - height) / 2);

            ctx.save();
            ctx.drawImage(image, left, top, width, height);
            ctx.restore();
            onSnapshotPicked([
              snapshotCanvasRef.current.toDataURL("image/jpeg", 0.9),
            ]);
          };
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      [onSnapshotPicked, snapshotCanvasRef]
    );

    return (
      <Container>
        <canvas
          ref={snapshotCanvasRef}
          className="is-hidden"
          width={SNAPSHOT_WIDTH}
          height={SNAPSHOT_HEIGHT}
        />
        <HiddenInput
          type="file"
          id="snapshotUpload"
          name="snapshotUpload"
          onChange={handleFileChange}
        />
        <Label htmlFor="snapshotUpload">
          <Icon src={uploadBlack} alt="Upload" />
        </Label>
      </Container>
    );
  }
);
