import React from "react";

export const useGalleryPinStorage = (
  gameId: string
): [() => string | null, (newPin: string) => void, () => void] => {
  const storageKey = React.useMemo(() => `poseParty-galleryPin-${gameId}`, [
    gameId,
  ]);

  const get = React.useCallback(() => window.localStorage.getItem(storageKey), [
    storageKey,
  ]);
  const set = React.useCallback(
    (newPin: string) => window.localStorage.setItem(storageKey, newPin),
    [storageKey]
  );
  const clear = React.useCallback(
    () => window.localStorage.removeItem(storageKey),
    [storageKey]
  );

  return [get, set, clear];
};
