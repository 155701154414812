import React from "react";
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GameIndex } from "./Game";
import { GameInvite } from "./GameInvite";
import { GameSlideshow } from "./GameSlideshow";
import { GalleryIndex } from "./Gallery";
import { Tournament } from "./Tournament";
import { TournamentInviteHost } from "./TournamentInviteHost";
import { EditGame } from "./User/EditGame";
import { EditGameAdvanced } from "./User/EditGameAdvanced";
import { HostGameUI } from "./User/HostGameUI";
import { HostGameScreenshareUI } from "./User/HostGameScreenshareUI";
import { NewGame } from "./User/NewGame";
import { NewTournament } from "./User/NewTournament";
import { GameDetails } from "./User/GameDetails";
import { TournamentDetails } from "./User/TournamentDetails";
import { Home } from "./Home";
import { InPersonStart } from "./InPersonStart";
import { ForgottenPassword } from "./Auth/ForgottenPassword";
import { Login } from "./Auth/Login";
import { SignUp } from "./Auth/SignUp";
import { DemoStart } from "./DemoStart";
import { NotFound } from "./NotFound";
import { TermsModal } from "./components/TermsModal";
import { UnsupportedModal } from "./components/UnsupportedModal";
import { useBrand } from "../data/brand";
import { useAuth } from "../data/auth";
import { useBrowserSupported } from "../data/browserSupported";

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const { isSignedIn } = useAuth();
  const {
    brandDetails: { allowSignUp },
  } = useBrand();

  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          isSignedIn ? (
            children
          ) : (
            <Redirect
              to={`/${allowSignUp ? "signUp" : "login"}?continue=${
                location.pathname
              }`}
            />
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
};

export const Navigation = () => {
  const location = useLocation();
  const {
    isBrowserSupported,
    ignoreBrowserUnsupported,
  } = useBrowserSupported();

  const { brandDetails } = useBrand();
  const theme = React.useMemo(
    () => ({
      colors: brandDetails.colors,
      settings: {
        headerShadow: brandDetails.headerShadow,
        footerShadow: brandDetails.footerShadow,
      },
    }),
    [brandDetails.colors, brandDetails.footerShadow, brandDetails.headerShadow]
  );

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signUp">
          <SignUp />
        </Route>
        <Route path="/forgottenPassword">
          <ForgottenPassword />
        </Route>

        <PrivateRoute path="/user/create">
          <NewGame />
        </PrivateRoute>
        <PrivateRoute path="/user/game/:gameId/edit">
          <EditGame />
        </PrivateRoute>
        <PrivateRoute path="/user/game/:gameId/advanced">
          <EditGameAdvanced />
        </PrivateRoute>
        <PrivateRoute path="/user/game/:gameId">
          <GameDetails />
        </PrivateRoute>
        <PrivateRoute path="/user/tournament/create">
          <NewTournament />
        </PrivateRoute>
        <PrivateRoute path="/user/tournament/:tournamentId">
          <TournamentDetails />
        </PrivateRoute>

        <Route path={["/demo/:alertHostUid", "/demo"]}>
          <DemoStart />
        </Route>
        <Route path="/inPerson">
          <InPersonStart />
        </Route>
        <Route path="/gallery/:gameId">
          <GalleryIndex />
        </Route>
        <Route path="/slideshow/:gameId">
          <GameSlideshow />
        </Route>
        <Route path="/:tournamentId([0-9]{5})/inviteHost/:additionalHostCode">
          <TournamentInviteHost />
        </Route>
        <Route path="/:tournamentId([0-9]{5})">
          <Tournament />
        </Route>
        <Route path="/:gameId/host/screenshare">
          <HostGameScreenshareUI />
        </Route>
        <Route path="/:gameId/host">
          <HostGameUI />
        </Route>
        <Route path="/:gameId/invitePlayer/:playerInviteCode">
          <GameInvite />
        </Route>
        <Route path="/:gameId">
          <GameIndex />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>

      <Route path="*/privacy" exact>
        <TermsModal />
      </Route>

      {isBrowserSupported === false &&
        !location.pathname.startsWith("/gallery") && (
          <UnsupportedModal onIgnore={ignoreBrowserUnsupported} />
        )}
    </ThemeProvider>
  );
};
