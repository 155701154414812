/* eslint-disable react/jsx-wrap-multilines */

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Markdown } from "./Markdown";
import { PlatformTabs } from "./PlatformTabs";
import { Text } from "./Text";
import { useBrand } from "../../data/brand";
import { CopyContainer } from "./CopyContainer";

export interface UnsupportedModalProps {
  onIgnore: () => void;
}

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.backgroundOverlay};
  border-radius: 8px;
  width: 100%;
  max-width: min(calc(100vw - 40px), 400px);
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const URLCopyContainer = styled(CopyContainer)`
  margin-bottom: 16px;
`;

const MarkdownContent = styled(Markdown)``;

const IgnoreButton = styled.button`
  text-decoration: underline;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  margin-top: 26px;

  &:hover {
    text-decoration: none;
  }
`;

const BottomMarkdownContent = styled(MarkdownContent)`
  margin-top: 24px;
`;

export const UnsupportedModal: React.FunctionComponent<UnsupportedModalProps> = React.memo(
  ({ onIgnore }) => {
    const { t } = useTranslation();
    const {
      brandDetails: { landingPageContent },
    } = useBrand();

    const fullURL = window.location.href;
    const shortURL = React.useMemo(() => {
      const noProtocol = fullURL.replace("https://", "");
      if (noProtocol.endsWith("/")) {
        return noProtocol.substring(0, noProtocol.length - 1);
      } else {
        return noProtocol;
      }
    }, [fullURL]);

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <Container className="modal-content">
          {!!landingPageContent?.gameUnsupported?.top && (
            <MarkdownContent source={landingPageContent.gameUnsupported.top} />
          )}

          <MarkdownContent source={t("browserUnsupported.hint")} />

          <URLCopyContainer title="URL" text={fullURL} displayText={shortURL} />

          <PlatformTabs
            iOS={<MarkdownContent source={t("browserUnsupported.ios")} />}
            android={
              <MarkdownContent source={t("browserUnsupported.android")} />
            }
            desktop={
              <MarkdownContent source={t("browserUnsupported.desktop")} />
            }
          />

          <IgnoreButton onClick={onIgnore}>
            <Text>{t("browserUnsupported.ignore")}</Text>
          </IgnoreButton>

          {!!landingPageContent?.gameUnsupported?.bottom && (
            <BottomMarkdownContent
              source={landingPageContent.gameUnsupported.bottom}
            />
          )}
        </Container>
      </div>
    );
  }
);
