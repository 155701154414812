const select = <T>(inputs: { staging: T; prod: T; local?: T }): T => {
  if (process.env.REACT_APP_LOCAL === "true" && inputs.local) {
    return inputs.local;
  } else if (process.env.REACT_APP_PROD === "true") {
    return inputs.prod;
  } else {
    return inputs.staging;
  }
};

export const environment = {
  apiBaseURL: select({
    local: "http://localhost:8080/callable/",
    staging: "https://server-niv7a3mmxq-ew.a.run.app/callable/",
    prod: "https://server-oa53dvoazq-ew.a.run.app/callable/",
  }),
  firebase: select({
    staging: {
      apiKey: "AIzaSyD8VmlW1xYvpJvk4GM1iH-r89L6Jz56d-w",
      authDomain: "pose-party-staging.firebaseapp.com",
      databaseURL: "https://pose-party-staging.firebaseio.com",
      projectId: "pose-party-staging",
      storageBucket: "pose-party-staging.appspot.com",
      messagingSenderId: "548209471666",
      appId: "1:548209471666:web:c2edc9cb36e874acce19ab",
    },
    prod: {
      apiKey: "AIzaSyAlaSFn_94k60HxLLWaA60Bw5Li8xaIVaY",
      authDomain: "pose-party.firebaseapp.com",
      databaseURL: "https://pose-party.firebaseio.com",
      projectId: "pose-party",
      storageBucket: "pose-party.appspot.com",
      messagingSenderId: "729511470314",
      appId: "1:729511470314:web:acbe1d284c734e87a86393",
    },
  }),
  sentry: {
    environment: select({
      staging: "staging",
      prod: "production",
    }),
    release: process.env.REACT_APP_SENTRY_RELEASE,
  },
  googleTagManager: {
    enabled:
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_PROD === "true",
    gtmId: "GTM-TVSG2S3",
  },
};
