import React from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GameScreen } from "../components/GameScreen";
import { Markdown } from "../components/Markdown";
import { MenuToggle } from "../components/MenuToggle";
import { ShadowBottom } from "../components/ShadowBottom";
import { RegularTitle } from "../components/Title";
import { useTournamentData } from "../../data/tournament";
import { useGameList } from "../../data/game";
import { Text } from "../components/Text";
import { TournamentGameRow } from "./TournamentGameRow";
import { useBrand } from "../../data/brand";
import { BrandLogo } from "../components/BrandLogo";

const Content = styled.div`
  overflow-y: auto;
`;
const TopCard = styled(ShadowBottom)`
  padding: 24px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.headerBackground};
`;
const Logo = styled(BrandLogo)`
  margin-bottom: 16px;
  max-width: 300px;
  max-height: 150px;
`;
const TournamentName = styled(RegularTitle)`
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
`;
const TournamentCode = styled(Text)`
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
  opacity: 0.5;
`;

const GamesSection = styled.section`
  margin: 32px 0;
  color: ${(props) => props.theme.colors.backgroundOverlay};
`;
const GamesSectionTitle = styled(RegularTitle)`
  text-align: center;
  margin: 0 16px;
`;
const GamesSectionText = styled(Markdown)`
  margin: 4px 16px 16px 16px;
`;
const GamesSectionHint = styled(Markdown)`
  text-align: center;
`;

export const Tournament = React.memo(() => {
  const { t } = useTranslation();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { brandId, brandDetails } = useBrand();
  const { landingPageContent, showHomepageJoinGameForm } = brandDetails;
  const tournamentPath = `${tournamentId}`;
  const tournamentURL = `https://${brandDetails.domain}/${tournamentPath}`;

  const tournament = useTournamentData({ brandId, tournamentId });
  const heatGames = useGameList({ brandId, gameIds: tournament?.heatGameIds });
  const finalGameId = React.useMemo(
    () => (tournament ? [tournament.finalGameId] : undefined),
    [tournament]
  );
  const finalGame = useGameList({ brandId, gameIds: finalGameId });

  const gameLogoView = React.useMemo(
    () => <Logo overrideSrc={tournament?.logo} />,
    [tournament]
  );

  const renderBody = React.useCallback(
    ({ toggleMenu }) => {
      const content =
        !!tournament && !!heatGames ? (
          <>
            <TopCard>
              {tournament.logoUrl ? (
                <a href={tournament.logoUrl}>{gameLogoView}</a>
              ) : (
                gameLogoView
              )}
              <TournamentName>{tournament.name}</TournamentName>
              <a href={tournamentURL}>
                <TournamentCode weight="bold">{tournamentPath}</TournamentCode>
              </a>
            </TopCard>

            {!!landingPageContent?.tournament?.top && (
              <GamesSection>
                <GamesSectionText source={landingPageContent.tournament.top} />
              </GamesSection>
            )}

            {heatGames.length > 0 && (
              <GamesSection>
                <GamesSectionTitle>
                  {t("tournament.heatsTitle")}
                </GamesSectionTitle>
                {!!landingPageContent?.tournament?.heats && (
                  <GamesSectionHint
                    source={landingPageContent.tournament.heats}
                  />
                )}
                {heatGames.map((game) => (
                  <TournamentGameRow
                    key={game.id}
                    path={
                      game.status === "finished"
                        ? `/gallery/${game.id}`
                        : `/${game.id}`
                    }
                    game={game}
                    showCode={showHomepageJoinGameForm}
                  />
                ))}
              </GamesSection>
            )}

            {!!finalGame && finalGame.length > 0 && (
              <GamesSection>
                <GamesSectionTitle>
                  {t("tournament.finalTitle")}
                </GamesSectionTitle>
                {!!landingPageContent?.tournament?.final && (
                  <GamesSectionHint
                    source={landingPageContent.tournament.final}
                  />
                )}
                <TournamentGameRow
                  key={finalGame[0].id}
                  path={
                    finalGame[0].status === "finished"
                      ? `/gallery/${finalGame[0].id}`
                      : `/${finalGame[0].id}`
                  }
                  game={finalGame[0]}
                  showCode={showHomepageJoinGameForm}
                />
              </GamesSection>
            )}

            {!!landingPageContent?.tournament?.bottom && (
              <GamesSection>
                <GamesSectionText
                  source={landingPageContent.tournament.bottom}
                />
              </GamesSection>
            )}
          </>
        ) : null;

      return (
        <Content>
          <MenuToggle onClick={toggleMenu} />

          {content}
        </Content>
      );
    },
    [
      finalGame,
      gameLogoView,
      heatGames,
      landingPageContent,
      showHomepageJoinGameForm,
      t,
      tournament,
      tournamentPath,
      tournamentURL,
    ]
  );

  return <GameScreen renderBody={renderBody} />;
});
