import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { GameListGame, GameListGameStatus } from "@pose-party/types";
import { Text } from "../components/Text";

const Row = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.background};
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.backgroundOverlay};
`;
const GameName = styled(Text)`
  flex: 2;
`;
const GameCode = styled(Text)`
  flex: 0;
  padding: 0 6px;
`;
const GameStatus = styled(Text)`
  flex: 1;
  padding: 0 6px;
  text-align: right;
`;

export interface TournamentGameRowProps {
  game: GameListGame;
  path: string;
  showCode: boolean;
}

export const TournamentGameRow = ({
  game,
  path,
  showCode,
}: TournamentGameRowProps) => {
  const { t } = useTranslation();

  let status: string;
  switch (game.status) {
    case GameListGameStatus.Setup:
      status = t("tournament.gameStatus.setup");
      break;
    case GameListGameStatus.Active:
      status = t("tournament.gameStatus.active");
      break;
    case GameListGameStatus.Finished:
      status = t("tournament.gameStatus.finished");
      break;
  }
  return (
    <Link to={path}>
      <Row>
        <GameName>{game.name}</GameName>
        {showCode && <GameCode>{game.id}</GameCode>}
        <GameStatus subtle>{status}</GameStatus>
      </Row>
    </Link>
  );
};
