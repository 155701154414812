/* eslint-disable no-nested-ternary, global-require */
import React from "react";
import { GameSetupState, GameStatus } from "@pose-party/types";
import { useDebounce } from "use-debounce";
import { Redirect } from "react-router-dom";
import { HostGameContext } from "./HostGameContext";
import {
  useGameState,
  usePrimaryGameHost,
  useGameSetupStateCounters,
  useIsAdditionalGameHosts,
  useGameLogo,
  useGameName,
  useGamePose,
  useGamePoses,
  useGamePoseStateCounter,
  useGameScoreStateCounter,
  useReportedSnapshots,
  useGameSpectatorCount,
  usePoseSpectatorScoredCount,
} from "../../data/game";
import { logger } from "../../logger";
import { HostGameData, GameProviderProps, RawHostGameData } from "./types";
import { useAuth } from "../../data/auth";
import { useBrand } from "../../data/brand";
import { useGameIdParam } from "../useGameIdParam";
import { FullScreenLoading } from "../../ui/components/FullScreenLoading";
import { useFinalGameData } from "../useFinalGameData";

const useValidHostGameData = (
  input: RawHostGameData
): HostGameData | undefined => {
  return React.useMemo((): HostGameData | undefined => {
    const { state, poses, reportedSnapshots, ...rest } = input;
    if (state) {
      return {
        state,
        poses: poses || [],
        reportedSnapshots: reportedSnapshots || [],
        ...rest,
      };
    } else {
      return undefined;
    }
  }, [input]);
};

export const HostGameProvider: React.FunctionComponent<GameProviderProps> = React.memo(
  ({ children }) => {
    const { uid, isAdmin } = useAuth();
    const { brandId, setGameDetails } = useBrand();
    const gameId = useGameIdParam();

    /**
     * Game state
     */
    const gameState = useGameState({ brandId, gameId });
    const setupStatusCounters = useGameSetupStateCounters({
      enabled: true,
      brandId,
      gameId,
    });
    const playerCount = setupStatusCounters
      ? setupStatusCounters[GameSetupState.Done]
      : undefined;
    const poseCompletedCount = useGamePoseStateCounter({
      enabled: gameState?.status === GameStatus.Pose,
      brandId,
      gameId,
      poseId: gameState?.currentPoseId,
    });
    const scoreCompletedCount = useGameScoreStateCounter({
      enabled: gameState?.status === GameStatus.Score,
      brandId,
      gameId,
      poseId: gameState?.currentPoseId,
    });

    /**
     * Is host
     */
    const primaryGameHostId = usePrimaryGameHost({ brandId, gameId });
    const isAdditionalHostUser = useIsAdditionalGameHosts({
      brandId,
      gameId,
      uid,
    });
    const isHost = React.useMemo(() => {
      const isHostUser =
        primaryGameHostId === uid || isAdditionalHostUser || isAdmin;
      if (
        isHostUser &&
        gameState &&
        gameState.status !== GameStatus.Setup &&
        gameState?.mainHost !== "hostUI"
      ) {
        return uid === gameState.mainHost;
      }

      return isHostUser;
    }, [gameState, isAdditionalHostUser, isAdmin, primaryGameHostId, uid]);
    const hostingWithHostUI = gameState?.mainHost === "hostUI";

    // Set the game host id when it changes. This will ensure the game brand details match the host's
    // overriden brand details
    React.useEffect(() => {
      if (primaryGameHostId) {
        setGameDetails({ gameId, hostId: primaryGameHostId });
      } else {
        setGameDetails(undefined);
      }

      return () => setGameDetails(undefined);
    }, [gameId, primaryGameHostId, setGameDetails]);

    /**
     * Game details
     */
    const logo = useGameLogo({ brandId, gameId });
    const name = useGameName({ brandId, gameId });

    /**
     * Poses
     */
    const poses = useGamePoses({ brandId, gameId });
    const currentPose = useGamePose({
      brandId,
      gameId: gameState ? gameId : undefined,
      poseId: gameState?.currentPoseId,
    });
    React.useEffect(() => {
      logger.info("Current pose updated", currentPose);
    }, [currentPose]);

    /**
     * Round details
     */
    const roundDetails = React.useMemo(
      () => ({
        roundNumber: currentPose ? `${currentPose.order + 1}` : "?",
        roundCount: poses.length > 0 ? poses.length.toString() : "?",
      }),
      [currentPose, poses.length]
    );

    /**
     * Reported snapshots
     */
    const reportedSnapshots = useReportedSnapshots({
      brandId,
      gameId,
    });

    /**
     * Sepctators
     */
    const spectatorCount = useGameSpectatorCount({ brandId, gameId });
    const poseSpectatorScoredCount = usePoseSpectatorScoredCount({
      brandId,
      gameId,
      poseId: currentPose?.id,
    });

    /**
     * Game data
     */
    const rawHostGameData: RawHostGameData = React.useMemo(
      () => ({
        poses,
        currentPose,
        isHost,
        hostingWithHostUI,
        logo,
        name,
        roundDetails,
        setupStatusCounters,
        playerCount,
        poseCompletedCount,
        scoreCompletedCount,
        state: gameState,
        reportedSnapshots,
        spectatorCount,
        poseSpectatorScoredCount,
      }),
      [
        currentPose,
        gameState,
        isHost,
        hostingWithHostUI,
        logo,
        name,
        playerCount,
        poseCompletedCount,
        poseSpectatorScoredCount,
        poses,
        reportedSnapshots,
        roundDetails,
        scoreCompletedCount,
        setupStatusCounters,
        spectatorCount,
      ]
    );
    const [debouncedRawHostGameData] = useDebounce(rawHostGameData, 250, {
      maxWait: 250,
    });
    const data = useValidHostGameData(debouncedRawHostGameData);
    // If we're going to delete the game data when it's finished, we want to hold onto a copy
    // locally so we can continue to use the screenshare UI
    const finalData = useFinalGameData(data);
    const dataToSend = data || finalData;

    /**
     * Render
     */
    return (
      // @ts-ignore We know the data won't be undefined whenever anyone is using it
      <HostGameContext.Provider value={dataToSend}>
        {!!data && !isHost && !finalData && <Redirect to="/" />}
        {dataToSend && children}
        {!dataToSend && <FullScreenLoading />}
      </HostGameContext.Provider>
    );
  }
);
