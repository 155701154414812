import React from "react";
import { GameStatus, GameSetupState } from "@pose-party/types";
import { GameSetup } from "./Setup";
import { Pose } from "./Pose";
import { Results } from "./Results";
import { FinalResults } from "./FinalResults";
import { Finished } from "./Finished";
import { Score } from "./Score";
import { StartedWithout } from "./StartedWithout";
import { Kicked } from "./Kicked";
import { HostActions } from "../../../data/game";
import { useWebcamStream } from "../../../data/webcam";
import { useGame } from "../../../hooks/useGame";
import { useBrand } from "../../../data/brand";

export interface GameStateViewProps {
  hostActions: HostActions;
  kickPlayer: (playerId: string) => void;
}

export const GameStateView = React.memo(
  ({ hostActions, kickPlayer }: GameStateViewProps) => {
    const {
      data: { state, currentPlayer, isSpectator, isLate },
    } = useGame();
    const {
      brandDetails: { allowJoinLate, allowSnapshotWebcam },
    } = useBrand();

    /**
     * Handle starting and stopping the stream
     */
    const { startStream, stopStream } = useWebcamStream();
    React.useEffect(() => {
      if (
        state.status === GameStatus.FinalResults ||
        state.status === GameStatus.Finished
      ) {
        stopStream();
      } else if (
        currentPlayer?.setupState === GameSetupState.Done &&
        allowSnapshotWebcam
      ) {
        startStream();
      }
    }, [
      allowSnapshotWebcam,
      currentPlayer,
      startStream,
      state.status,
      stopStream,
    ]);

    /**
     * Render the correct state
     */
    if (
      state.status === GameStatus.Finished ||
      (state.status === GameStatus.FinalResults &&
        !isSpectator &&
        currentPlayer?.setupState !== GameSetupState.Done)
    ) {
      // Redirect to the finished game screen
      return <Finished gameId={state.id} />;
    } else if (
      (allowJoinLate && isLate && !isSpectator) ||
      state.status === GameStatus.Setup
    ) {
      // The game is waiting to be setup
      return (
        <GameSetup
          onHostStartPress={hostActions.startGame}
          kickPlayer={kickPlayer}
        />
      );
    } else if (
      currentPlayer?.setupState !== GameSetupState.Done &&
      !isSpectator
    ) {
      // The game has started, but the user was not ready
      return <StartedWithout />;
    } else if (currentPlayer?.hidden) {
      // The game has started, but the user was kicked
      return <Kicked />;
    } else if (state.status === GameStatus.Pose) {
      // Show the pose screen
      return (
        <Pose
          onHostStartScoringPress={hostActions.startScoring}
          kickPlayer={kickPlayer}
        />
      );
    } else if (state.status === GameStatus.Score) {
      // Show the score screen
      return (
        <Score
          onHostShowResultsPress={hostActions.showResults}
          kickPlayer={kickPlayer}
        />
      );
    } else if (state.status === GameStatus.Results) {
      // Show the results screen
      return (
        <Results
          onHostNextPosePress={hostActions.startNextPose}
          onHostFinalResultsPress={hostActions.showFinalResults}
          kickPlayer={kickPlayer}
        />
      );
    } else if (state.status === GameStatus.FinalResults) {
      // Show the results screen
      return <FinalResults />;
    } else {
      // TODO: Handle this unknown state
      return null;
    }
  }
);
