import { api } from "../api";

/**
 * API call to create a new game
 */
export const newDemoGame = async (params: {
  brandId: string;
  hostEmail: string;
  alertHostUid?: string;
}) => {
  return api.post("newDemoGame", params);
};
