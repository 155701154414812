import React from "react";
import styled from "styled-components/macro";
import {
  motion,
  useMotionValue,
  useTransform,
  MotionValue,
  PanInfo,
  useAnimation,
} from "framer-motion";
import { useTranslation } from "react-i18next";
import {
  WebcamSnapshot,
  DISLIKE_SCORE_STRING,
  SUPERLIKE_SCORE_STRING,
  LIKE_SCORE_STRING,
  GameSound,
} from "@pose-party/types";
import { AspectCard } from "../../../components/AspectCard";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import { RegularTitle } from "../../../components/Title";
import { useGameSounds } from "../../../../hooks/useGameSounds";

export enum SnapshotCardDirection {
  Up,
  Left,
  Right,
}

export interface SnapshotCardProps {
  snapshot: WebcamSnapshot;
  playerName: string | undefined;
  onCardGesture: (direction: SnapshotCardDirection) => void;
}

export interface SnapshotCardStatic {
  animateInDirection(direction: SnapshotCardDirection): void;
}

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SnapshotContainer = styled(AspectCard)`
  margin-bottom: 16px;
  pointer-events: none;
`;

const PlayerName = styled(RegularTitle)`
  text-align: center;
`;

const ScoreHint = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
const ScoreHintTitle = styled(RegularTitle)`
  font-size: 32px;
  line-height: 44px;
  text-align: center;
`;
const ScoreHintPoints = styled(RegularTitle)`
  font-size: 48px;
  line-height: 66px;
  text-align: center;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const useMinMotionValue = (
  mv1: MotionValue<number>,
  mv2: MotionValue<number>
): MotionValue<number> => {
  const minValue = useMotionValue(1);

  React.useEffect(() => {
    const updateValue = () => {
      minValue.set(Math.min(mv1.get(), mv2.get()));
    };

    const unsubscribeX = mv1.onChange(updateValue);
    const unsubscribeY = mv2.onChange(updateValue);

    return () => {
      unsubscribeX();
      unsubscribeY();
    };
  }, [minValue, mv1, mv2]);

  return minValue;
};

export const SnapshotCard = React.forwardRef<
  SnapshotCardStatic,
  SnapshotCardProps
>(({ snapshot, playerName, onCardGesture }, ownRef) => {
  const { t } = useTranslation();
  const { playGameSound } = useGameSounds();

  // Keep track of the gesture and animations
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const animation = useAnimation();

  // Transform the x and y into the styles we want
  const xOpacity = useTransform(x, [-130, -100, 100, 130], [0, 1, 1, 0]);
  const yOpacity = useTransform(y, [-100, -80, 0], [0, 1, 1]);
  const opacity = useMinMotionValue(xOpacity, yOpacity);
  const rotateZ = useTransform(x, [-130, 0, 130], [-5, 0, 5]);

  // Animation values for the scoring hints
  const dislikeOpacity = useTransform(x, [-130, -100], [1, 0]);
  const likeOpacity = useTransform(x, [100, 130], [0, 1]);
  const superlikeOpacity = useTransform(y, [-100, -80], [1, 0]);

  // Handle the end of the drag
  const [pendingDirection, setPendingDirection] = React.useState<
    SnapshotCardDirection
  >();
  React.useEffect(() => {
    let playSound = true;
    switch (pendingDirection) {
      case SnapshotCardDirection.Left:
        animation.start("left");
        break;
      case SnapshotCardDirection.Right:
        animation.start("right");
        break;
      case SnapshotCardDirection.Up:
        animation.start("up");
        break;
      default:
        animation.start("default");
        playSound = false;
        break;
    }

    if (playSound) {
      playGameSound(GameSound.Swipe);
    }
  }, [animation, pendingDirection, playGameSound]);
  const onDragEnd = React.useCallback(
    (_event: MouseEvent | TouchEvent | PointerEvent, panInfo: PanInfo) => {
      const { offset } = panInfo;
      if (offset.x < -50) {
        // Swiped left
        setPendingDirection(SnapshotCardDirection.Left);
      } else if (offset.x > 50) {
        // Swiped right
        setPendingDirection(SnapshotCardDirection.Right);
      } else if (offset.y < -50) {
        // Swiped up
        setPendingDirection(SnapshotCardDirection.Up);
      } else {
        // Didn't swipe
        setPendingDirection(undefined);
        animation.start("default");
      }
    },
    [animation]
  );

  // Wait for the animation to complete
  const onAnimationComplete = React.useCallback(() => {
    if (pendingDirection === undefined) {
      return;
    }

    onCardGesture(pendingDirection);
  }, [onCardGesture, pendingDirection]);

  // Imperative method for animating in a given direction
  React.useImperativeHandle(ownRef, () => ({
    animateInDirection: (direction: SnapshotCardDirection) => {
      setPendingDirection(direction);
    },
  }));

  // Render the view
  return (
    <OuterContainer>
      <ScoreHint style={{ opacity: dislikeOpacity }}>
        <ScoreHintTitle>{t("gameScore.scoring.dislike")}</ScoreHintTitle>
        <ScoreHintPoints>
          {t("gameScore.scoring.points", {
            score: DISLIKE_SCORE_STRING,
          })}
        </ScoreHintPoints>
      </ScoreHint>

      <ScoreHint style={{ opacity: likeOpacity }}>
        <ScoreHintTitle>{t("gameScore.scoring.like")}</ScoreHintTitle>
        <ScoreHintPoints>
          {t("gameScore.scoring.points", {
            score: LIKE_SCORE_STRING,
          })}
        </ScoreHintPoints>
      </ScoreHint>

      <ScoreHint style={{ opacity: superlikeOpacity }}>
        <ScoreHintTitle>{t("gameScore.scoring.superlike")}</ScoreHintTitle>
        <ScoreHintPoints>
          {t("gameScore.scoring.points", {
            score: SUPERLIKE_SCORE_STRING,
          })}
        </ScoreHintPoints>
      </ScoreHint>

      <motion.div
        // Drag controls
        drag={!pendingDirection}
        dragConstraints={{
          top: -100,
          left: -130,
          right: 130,
          bottom: 0,
        }}
        dragDirectionLock
        onDragEnd={onDragEnd}
        style={{ x, y, opacity, rotateZ }}
        // Animation for after dragging
        animate={animation}
        transition={{ type: "spring", damping: 60, stiffness: 600 }}
        variants={{
          default: { x: 0, y: 0 },
          left: { x: -130, y: 0 },
          right: { x: 130, y: 0 },
          up: { x: 0, y: -100 },
        }}
        initial="default"
        onAnimationComplete={onAnimationComplete}
      >
        <SnapshotContainer>
          <SnapshotDisplay snapshot={snapshot} />
        </SnapshotContainer>
        <PlayerName>{playerName}</PlayerName>
      </motion.div>
    </OuterContainer>
  );
});
