import React from "react";
import { GameContextState } from "./types";

export const GameContext = React.createContext<GameContextState>({
  /// @ts-ignore
  data: undefined,

  // Methods
  setPlayerName: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setPlayerProfilePicture: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setPlayerSetupState: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setPlayerSnapshot: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setSnapshotVote: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setScordingState: () => {
    throw new Error("Must be used inside a GameContext");
  },
  setAsSpectator: () => {
    throw new Error("Must be used inside a GameContext");
  },
});
