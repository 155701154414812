import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  GamePlayer,
  GamePose,
  WebcamSnapshot,
  calculatePosition,
} from "@pose-party/types";
import { headerBaseCss } from "../../components/Title";
import { AspectCard } from "../../components/AspectCard";
import { SnapshotDisplay } from "../../Game/SnapshotDisplay";
import {
  useGamePoseSnapshots,
  hideGameSnapshot,
  useGamePoseScores,
} from "../../../data/game";
import { positionWithSuffix } from "../../Game/GameStateView/Results/utils";
import { GoogleStorageImage } from "../../components/GoogleStorageImage";
import { useBrand } from "../../../data/brand";

export interface GameRoundResultsProps {
  gameId: string;
  groupId: string | undefined;
  pose: GamePose;
  gamePlayers: GamePlayer[];
  canDelete: boolean;
  hideNames: boolean;
}

const Container = styled.div`
  padding: 24px 16px 32px 16px;
`;

const PosePrompt = styled.h2`
  ${headerBaseCss};
  font-size: 25px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 16px;
`;

const Grid = styled.div``;

const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SnapshotCard = styled(AspectCard)`
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;
const PlayerName = styled.h3`
  ${headerBaseCss};
  font-size: 20px;
  line-height: 27px;
  margin-top: 16px;
  font-weight: 400;
  text-align: center;
`;
const Position = styled.span`
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const DeleteContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s transform, 0.25s box-shadow;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
`;
const DeleteImage = styled.img`
  width: 12px;
  height: 12px;
`;

const SanpshotView = ({
  snapshot,
}: {
  snapshot: string | WebcamSnapshot | null | undefined;
}) => {
  if (typeof snapshot === "string") {
    return <GoogleStorageImage src={snapshot} />;
  } else if (Array.isArray(snapshot)) {
    return <SnapshotDisplay snapshot={snapshot} />;
  } else {
    return null;
  }
};

export const GameRoundResults: React.FunctionComponent<GameRoundResultsProps> = ({
  gameId,
  groupId,
  pose,
  gamePlayers,
  canDelete,
  hideNames,
}) => {
  const { t } = useTranslation();
  const { brandId } = useBrand();

  const poseSnapshots = useGamePoseSnapshots({
    brandId,
    gameId,
    poseId: pose.id,
    groupId,
  });
  const scores = useGamePoseScores({
    brandId,
    gameId,
    poseId: pose.id,
    groupId,
  });
  const history = useHistory();

  const makeHandleView = React.useCallback(
    (uid: string) => (e: React.MouseEvent) => {
      e.preventDefault();
      history.push(`/gallery/${gameId}/${pose.id}/${uid}`);
    },
    [gameId, history, pose.id]
  );

  const makeHandleDelete = React.useCallback(
    (snapshotId: string) => (e: React.MouseEvent) => {
      if (!groupId) {
        return;
      }

      e.preventDefault();

      const sure = window.confirm(t("gallery.deleteConfirm"));
      if (!sure) {
        return;
      }

      hideGameSnapshot({
        brandId,
        gameId,
        groupId,
        poseId: pose.id,
        snapshotId,
      });
    },
    [brandId, gameId, groupId, pose.id, t]
  );

  const playerList = React.useMemo(() => {
    if (!gamePlayers || !poseSnapshots) {
      return [];
    }

    return _.chain(gamePlayers)
      .map((p) => {
        return {
          ...p,
          score: scores?.find((s) => s.playerId === p.id)?.score,
        };
      })
      .filter((p) => {
        const snapshot = poseSnapshots.find((s) => s.uid === p.id);
        return p.score !== undefined && !!snapshot && !snapshot.hidden;
      })
      .sortBy((p) => p.score)
      .reverse()
      .map((p, index, values) => {
        if (!p.score) {
          return null;
        }

        const results = calculatePosition(
          p.score,
          index,
          _.map(values, (v) => v.score || 0)
        );

        return {
          id: p.id,
          name: p.name,
          snapshot: poseSnapshots.find((s) => s.uid === p.id),
          ...results,
        };
      })
      .compact()
      .value();
  }, [gamePlayers, poseSnapshots, scores]);

  return (
    <Container>
      <PosePrompt>{pose.prompt}</PosePrompt>

      <Grid className="columns is-multiline is-mobile is-centered">
        {playerList.map((player) => (
          <GridItem
            key={player.id}
            className="column is-one-third-tablet is-half-mobile"
          >
            <SnapshotCard
              aspectRatio={1}
              style={{ borderRadius: "5%" }}
              onClick={makeHandleView(player.id)}
            >
              <SanpshotView
                snapshot={player.snapshot?.gif || player.snapshot?.snapshot}
              />
            </SnapshotCard>

            <PlayerName>
              <Position>
                {player.equalPosition ? "=" : ""}
                {positionWithSuffix(player.position)}
              </Position>
              {!hideNames ? ` ${player.name}` : ""}
            </PlayerName>

            {canDelete && player.snapshot && (
              <DeleteContainer onClick={makeHandleDelete(player.snapshot.id)}>
                <DeleteImage
                  src="/assets/crossBlack.svg"
                  alt={t("common.delete")}
                />
              </DeleteContainer>
            )}
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};
