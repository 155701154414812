import React from "react";
import styled, { css } from "styled-components/macro";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disableFullWidth?: boolean;
  loading?: boolean;
}

const InnerButton: React.FunctionComponent<ButtonProps> = ({
  className,
  disableFullWidth,
  loading,
  ...props
}) => {
  return (
    <button
      type="button"
      className={`button ${!disableFullWidth ? "is-fullwidth" : ""} ${
        loading ? "is-loading" : ""
      } ${className}`}
      {...props}
    />
  );
};

const shared = css`
  border-color: transparent;
  font-family: "Nunito Sans";
  font-weight: 700;
  border-radius: 10px;
  height: 48px;
  min-height: 48px;
`;

export const Button = styled(InnerButton)`
  ${shared}
  background-color: ${(props) => props.theme.colors.secondaryBrand};
  color: ${(props) => props.theme.colors.secondaryBrandOverlay};

  &:hover,
  &:focus {
    ${shared}
    opacity: 0.7;
  }

  &:active {
    border-color: transparent;
  }

  &[disabled] {
    opacity: 0.25;
  }
`;

export const AccentButton = styled(InnerButton)`
  ${shared}
  background-color: ${(props) => props.theme.colors.primaryBrand};
  color: ${(props) => props.theme.colors.primaryBrandOverlay};

  &:hover,
  &:focus {
    ${shared}
    opacity: 0.7;
  }

  &:active {
    border-color: transparent;
  }

  &[disabled] {
    opacity: 0.25;
  }
`;
