import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AccentButton } from "./Button";
import { RegularTitle } from "./Title";
import { TextInput } from "./TextInput";
import { PoseInput } from "./PoseInput";
import { Text } from "./Text";
import { useBrand } from "../../data/brand";
import { CheckboxInput } from "./CheckboxInput";
import {
  GameAdvancedSettingsForm,
  GameAdvancedSettingsFormProps,
} from "./GameAdvancedSettingsForm";

export interface GameDetailsFormProps {
  customGameId?: string;
  onCustomGameIdChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  gameName: string;
  onGameNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowLargeGames: boolean;
  isLargeGame: boolean;
  onIsLargeGameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  largeGameGroupSize: string;
  onLargeGameGroupSizeChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  poses: string[];
  poseTimings: number[];
  poseOptions: string[];
  makeUpdatePose: (index: number) => (newValue: string | undefined) => void;
  makeUpdatePoseTiming: (
    index: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  makeDeletePose: (index: number) => () => void;
  handleAddPose: () => void;
  advancedSettings?:
    | Omit<GameAdvancedSettingsFormProps, "isLargeGame">
    | undefined;
}

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 32px 16px;
`;
const CheckboxCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;
const CheckboxText = styled(Text)`
  margin-left: 16px;
`;
const Title = styled(RegularTitle)`
  text-align: center;
  margin: 32px 0 8px 0;
`;
const Hint = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
`;
const PoseContainer = styled.div`
  margin-bottom: 16px;
`;
const LargeGroupSizeHint = styled(Hint)`
  margin-top: 16px;
  margin-bottom: 8px;
`;
const PoseTimingContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;
const PoseTimingTextInput = styled(TextInput)`
  flex: 1;
`;
const PoseTimingLabel = styled(Text)`
  flex: 0;
  margin-left: 8px;
`;

export const GameDetailsForm: React.FunctionComponent<GameDetailsFormProps> = ({
  customGameId,
  onCustomGameIdChange,
  gameName,
  onGameNameChange,
  allowLargeGames,
  isLargeGame,
  onIsLargeGameChange,
  largeGameGroupSize,
  onLargeGameGroupSizeChange,
  poses,
  poseTimings,
  poseOptions,
  makeUpdatePose,
  makeUpdatePoseTiming,
  makeDeletePose,
  handleAddPose,
  advancedSettings,
}) => {
  const { t } = useTranslation();
  const {
    brandDetails: {
      allowCustomGameId,
      allowPerPoseTiming,
      gamePoseLimit,
      gamesHaveRandomPoses,
      allowHostPerGameSettings,
    },
  } = useBrand();

  /**
   * Figure out the default poses
   */
  const poseSelectOptions = React.useMemo(
    () =>
      poseOptions.map((pose) => ({
        label: pose,
        value: pose,
      })),
    [poseOptions]
  );

  /**
   * Render the views
   */

  return (
    <Content>
      <TextInput
        placeholder={t("createGame.namePlaceholder")}
        value={gameName}
        onChange={onGameNameChange}
      />

      {allowCustomGameId && onCustomGameIdChange && (
        <>
          <Title>{t("createGame.customGameIdTitle")}</Title>
          <TextInput
            placeholder={t("createGame.customGameIdPlaceholder")}
            value={customGameId}
            onChange={onCustomGameIdChange}
          />
        </>
      )}

      {allowLargeGames && (
        <>
          <Title>{t("createGame.largeGameTitle")}</Title>
          <Hint>{t("createGame.largeGameHint")}</Hint>
          <CheckboxCheckboxContainer>
            <CheckboxInput
              checked={isLargeGame}
              onChange={onIsLargeGameChange}
            />
            <CheckboxText>{t("createGame.largeGameCheckbox")}</CheckboxText>
          </CheckboxCheckboxContainer>

          {isLargeGame && (
            <>
              <LargeGroupSizeHint>
                {t("createGame.largeGroupSizeHint")}
              </LargeGroupSizeHint>
              <TextInput
                placeholder={t("createGame.largeGroupSizePlaceholder")}
                value={largeGameGroupSize}
                onChange={onLargeGameGroupSizeChange}
              />
            </>
          )}
        </>
      )}

      {allowHostPerGameSettings && advancedSettings && (
        <>
          <Title>{t("createGame.advancedGameSettingsTitle")}</Title>
          <GameAdvancedSettingsForm
            {...advancedSettings}
            isLargeGame={isLargeGame}
          />
        </>
      )}

      {gamesHaveRandomPoses ? (
        <>
          <Title>{t("createGame.randomPoseTitle")}</Title>
          <Hint>{t("createGame.randomPoseHint")}</Hint>
        </>
      ) : (
        <>
          <Title>{t("createGame.poseTitle")}</Title>
          <Hint>{t("createGame.poseHint")}</Hint>

          {poses.map((pose, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PoseContainer key={index}>
              <PoseInput
                roundNumber={index + 1}
                value={pose}
                poseOptions={poseSelectOptions}
                onValueChange={makeUpdatePose(index)}
                onDeletePress={makeDeletePose(index)}
              />
              {allowPerPoseTiming && (
                <PoseTimingContainer>
                  <PoseTimingTextInput
                    value={poseTimings[index].toString()}
                    onChange={makeUpdatePoseTiming(index)}
                  />
                  <PoseTimingLabel>
                    {t("createGame.poseTimingSeconds")}
                  </PoseTimingLabel>
                </PoseTimingContainer>
              )}
            </PoseContainer>
          ))}

          {poses.length < gamePoseLimit && (
            <AccentButton onClick={handleAddPose}>
              {t("poseInput.addPose")}
            </AccentButton>
          )}
        </>
      )}
    </Content>
  );
};
