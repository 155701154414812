import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GameSnapshot, GamePlayer } from "@pose-party/types";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { RegularTitle } from "../../../components/Title";
import { AspectCard } from "../../../components/AspectCard";
import { Text } from "../../../components/Text";
import { PlayerWaitingList } from "../../PlayerWaitingList";
import { GameFooter } from "../../../components/GameFooter";
import { Button } from "../../../components/Button";
import { SuperlikeLoadingIndicator } from "../../../components/SuperlikeLoadingIndicator";

export interface PoseProps {
  isLargeGame: boolean;
  isDemoGame: boolean;
  gameLogo: string | null | undefined;
  currentPlayerPoseSnapshot: GameSnapshot | undefined;
  gamePlayers: GamePlayer[];
  otherPlayerPoseSnapshots: GameSnapshot[];
  isSpectator: boolean;
  renderHeader: (props: ScreenAreaRenderProps) => React.ReactNode;
  kickPlayer: ((playerId: string) => void) | undefined;
  onHostNext: (() => void) | undefined;
}

const Container = styled.div`
  overflow-y: auto;
`;

const SnapshotContainer = styled.div`
  width: 136px;
  margin: 32px auto;
`;

const Spacer = styled.div`
  height: 32px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
`;

export const PoseWaiting: React.FunctionComponent<PoseProps> = React.memo(
  ({
    isLargeGame,
    isDemoGame,
    gameLogo,
    currentPlayerPoseSnapshot,
    gamePlayers,
    otherPlayerPoseSnapshots,
    isSpectator,
    renderHeader,
    kickPlayer,
    onHostNext,
  }) => {
    const { t } = useTranslation();

    /**
     * Add a slight delay for the host in the demo game to avoid a flash of this waiting screen
     * before moving to the next step
     */
    const [showContent, setShowContent] = React.useState(
      !onHostNext || !isDemoGame
    );
    React.useEffect(() => {
      const handle = setTimeout(() => setShowContent(true), 750);
      return () => clearTimeout(handle);
    }, []);

    /**
     * Work out the current list of players
     */
    const playerList = gamePlayers.map((player) => {
      const { id, profilePicture, name, fake } = player;
      // Finished if it's the current player or we have a snapshot for the given player
      const finished =
        player.id === currentPlayerPoseSnapshot?.uid ||
        !!otherPlayerPoseSnapshots.find(
          (snapshot) => snapshot.uid === player.id
        );

      return {
        id,
        profilePicture,
        name,
        finished,
        fake,
      };
    });
    const count = React.useMemo(
      () =>
        playerList.reduce(
          (prev, player) => (player.finished ? prev + 1 : prev),
          0
        ),
      [playerList]
    );
    const of = playerList.length;

    /**
     * Render the views
     */
    const renderBody = React.useCallback(() => {
      if (!showContent) {
        return null;
      }

      return (
        <Container>
          {currentPlayerPoseSnapshot ? (
            <SnapshotContainer>
              <AspectCard aspectRatio={1}>
                <SnapshotDisplay
                  snapshot={currentPlayerPoseSnapshot.snapshot}
                />
              </AspectCard>
            </SnapshotContainer>
          ) : (
            <Spacer />
          )}

          <TextContainer>
            <RegularTitle>
              {isSpectator
                ? t("gamePose.waiting.spectatorTitle")
                : t("gamePose.waiting.title")}
            </RegularTitle>
            {!isLargeGame && (
              <Text>{t("gamePose.waiting.count", { count, of })}</Text>
            )}
          </TextContainer>

          {!isLargeGame && count > 0 ? (
            <PlayerWaitingList
              players={playerList}
              uid={currentPlayerPoseSnapshot?.uid}
              kickPlayer={kickPlayer}
            />
          ) : (
            <SuperlikeLoadingIndicator />
          )}
        </Container>
      );
    }, [
      showContent,
      currentPlayerPoseSnapshot,
      isSpectator,
      t,
      isLargeGame,
      count,
      of,
      playerList,
      kickPlayer,
    ]);

    const renderFooter = React.useCallback(() => {
      if (!onHostNext || !showContent) {
        return null;
      }

      return (
        <GameFooter>
          <Button onClick={onHostNext}>{t("gamePose.waiting.hostNext")}</Button>
        </GameFooter>
      );
    }, [onHostNext, showContent, t]);

    return (
      <GameScreen
        id="gamePose"
        logo={gameLogo}
        renderHeader={showContent ? renderHeader : undefined}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
