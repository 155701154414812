import React from "react";
import { CountdownDetails } from "@pose-party/types";

/**
 * Keep track of when we think the round ends
 *
 * FIXME: This is local to the player and will be slightly different between each of them. We need
 * to do this to support the "smooth" progress bar between them. Only the host actually uses this
 * flag to decide when to start the next stage.
 */
export const useRoundTimer = ({
  roundLength,
  started,
}: {
  roundLength: number;
  started: boolean;
}) => {
  // Manage the countdown details
  const [countdownDetails, setCountdownDetails] = React.useState<
    CountdownDetails
  >();
  React.useEffect(() => {
    if (started) {
      setCountdownDetails({
        start: Date.now(),
        end: Date.now() + roundLength,
      });
    } else {
      setCountdownDetails(undefined);
    }
  }, [roundLength, started]);

  // Mamage a flag to say when the round has eneded
  const [roundEnded, setRoundEnded] = React.useState(false);
  React.useEffect(() => {
    if (!countdownDetails) {
      return;
    }

    const roundTimeLeft = countdownDetails?.end - Date.now();
    if (roundTimeLeft <= 0) {
      setRoundEnded(true);
      return;
    }
    const handle = setTimeout(() => {
      setRoundEnded(true);
    }, roundTimeLeft);
    return () => clearTimeout(handle);
  }, [countdownDetails]);

  // Return the data
  return {
    roundEnded,
    countdownDetails,
  };
};
