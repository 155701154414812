import React from "react";
import { UserRole, WebcamSnapshot } from "@pose-party/types";

export const AuthContext = React.createContext<{
  uid: string | undefined;
  isAnonymous: boolean;
  isSignedIn: boolean;
  isHost: boolean;
  isAdmin: boolean;
  userDetails:
    | {
        name: string | undefined;
        email: string | undefined;
        profilePicture: string[] | undefined;
        role: UserRole;
        acceptedTerms: boolean;
      }
    | undefined;
  performSignIn: (params: {
    email: string;
    password: string;
  }) => Promise<boolean>;
  performSignUp: (params: {
    name: string;
    email: string;
    password: string;
  }) => Promise<boolean>;
  updateName: (name: string) => void;
  updateProfilePicture: (profilePicture: WebcamSnapshot) => void;
  acceptTerms: () => void;
  updateEmail: (email: string) => void;
  performPasswordReset: (params: {
    email: string;
    continueUrl?: string;
  }) => Promise<void>;
  performSignOut: () => void;
}>({
  uid: undefined,
  isAnonymous: true,
  isSignedIn: false,
  isHost: false,
  isAdmin: false,
  userDetails: undefined,
  performSignIn: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  performSignUp: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  updateName: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  updateProfilePicture: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  acceptTerms: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  updateEmail: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  performPasswordReset: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  performSignOut: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
});
