import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useGameName, useGameLogo, useGameLogoUrl } from "../../data/game";
import { headerBaseCss } from "../components/Title";
import { BrandLogo } from "../components/BrandLogo";

export interface GalleryHeaderProps {
  brandId: string;
  gameId: string;
  hideGameName: boolean;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 32px 8px 32px;
  align-items: center;
`;

const Logo = styled(BrandLogo)`
  margin-bottom: 16px;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
`;

const GameName = styled.h1`
  ${headerBaseCss};
  font-size: 30px;
  line-height: 40px;
  text-align: center;
`;

export const GalleryHeader: React.FunctionComponent<GalleryHeaderProps> = ({
  brandId,
  gameId,
  hideGameName,
}) => {
  const { t } = useTranslation();
  const gameName = useGameName({ brandId, gameId });
  const gameLogo = useGameLogo({ brandId, gameId });
  const gameLogoUrl = useGameLogoUrl({ brandId, gameId });

  const gameLogoView = <Logo overrideSrc={gameLogo} alt={t("gallery.title")} />;

  return (
    <Header>
      {gameLogoUrl ? (
        <a href={gameLogoUrl}>{gameLogoView}</a>
      ) : (
        <Link to="/">{gameLogoView}</Link>
      )}

      {!hideGameName && <GameName>{gameName}</GameName>}
    </Header>
  );
};
