import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { GameDetailsForm } from "../../components/GameDetailsForm";
import { GameScreen } from "../../components/GameScreen";
import { GameHeader } from "../../components/GameHeader";
import { GameFooter } from "../../components/GameFooter";
import { Button } from "../../components/Button";
import { useFormInput } from "../../../hooks/useFormInput";
import { useGamePoseOptions } from "../../../hooks/useGamePoseOptions";
import { LoadingModal } from "../../components/LoadingModal";
import {
  editGame,
  useGameName,
  useGamePoses,
  useIsLargeGame,
} from "../../../data/game";
import { useBrand } from "../../../data/brand";
import { useFormCheckbox } from "../../../hooks/useFormCheckbox";

export const EditGame: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    brandId,
    brandDetails: { allowLargeGames },
  } = useBrand();
  const { gameId } = useParams<{ gameId: string }>();

  /**
   * Handle the form data
   */
  const [gameName, onGameNameChange, setGameName] = useFormInput();
  const [isLargeGame, onIsLargeGameChange, setIsLargeGame] = useFormCheckbox(
    allowLargeGames
  );
  const [
    largeGameGroupSize,
    onLargeGameGroupSizeChange,
    setLargeGameGroupSize,
  ] = useFormInput({
    defaultValue: "20",
  });
  React.useEffect(() => {
    if (!allowLargeGames) {
      setIsLargeGame(false);
    }
  }, [allowLargeGames, setIsLargeGame]);
  const [
    poses,
    poseTimings,
    poseOptions,
    makeUpdatePose,
    makeUpdatePoseTiming,
    makeDeletePose,
    handleAddPose,
    setInitialPoses,
  ] = useGamePoseOptions();

  /**
   * Loading the existing game details
   */
  const initialGameName = useGameName({ brandId, gameId });
  const initialLargeGame = useIsLargeGame({ brandId, gameId });
  const initialGamePoses = useGamePoses({ brandId, gameId });

  React.useEffect(() => {
    if (initialGameName) {
      setGameName(initialGameName);
    }
  }, [initialGameName, setGameName]);
  React.useEffect(() => {
    if (typeof initialLargeGame.isLargeGame === "boolean") {
      setIsLargeGame(initialLargeGame.isLargeGame);
    }
    if (typeof initialLargeGame.largeGroupSize === "number") {
      setLargeGameGroupSize(initialLargeGame.largeGroupSize.toString());
    }
  }, [
    initialGameName,
    initialLargeGame.isLargeGame,
    initialLargeGame.largeGroupSize,
    setGameName,
    setIsLargeGame,
    setLargeGameGroupSize,
  ]);
  React.useEffect(() => {
    if (initialGamePoses) {
      setInitialPoses(initialGamePoses);
    }
  }, [initialGamePoses, setInitialPoses]);

  /**
   * Handle submitting the form data
   */
  const [isEditing, setIsEditing] = React.useState(false);
  const handleEdit = React.useCallback(async () => {
    if (!gameName || poses.length === 0) {
      alert(t("editGame.invalidData"));
      return;
    }

    if (poseTimings.find((time) => time < 10) !== undefined) {
      alert(t("createGame.invalidPoseTimings"));
      return;
    }

    setIsEditing(true);

    try {
      await editGame({
        brandId,
        gameId,
        name: gameName,
        largeGroupSize:
          allowLargeGames && isLargeGame
            ? parseInt(largeGameGroupSize, 10)
            : undefined,
        poses,
        poseTimings: poseTimings.map((timing) => timing * 1000),
      });

      // Navigate to the game screen
      history.replace(`/user/game/${gameId}`);

      setIsEditing(false);
    } catch (e) {
      alert(t("editGame.editError", { details: e.toString() }));
      setIsEditing(false);
    }
  }, [
    allowLargeGames,
    brandId,
    gameId,
    gameName,
    history,
    isLargeGame,
    largeGameGroupSize,
    poseTimings,
    poses,
    t,
  ]);

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return <GameHeader goBack={history.goBack} title={t("editGame.title")} />;
  }, [history.goBack, t]);

  const renderBody = React.useCallback(() => {
    return (
      <GameDetailsForm
        gameName={gameName}
        onGameNameChange={onGameNameChange}
        allowLargeGames={allowLargeGames}
        isLargeGame={isLargeGame}
        onIsLargeGameChange={onIsLargeGameChange}
        largeGameGroupSize={largeGameGroupSize}
        onLargeGameGroupSizeChange={onLargeGameGroupSizeChange}
        poses={poses}
        poseTimings={poseTimings}
        poseOptions={poseOptions}
        handleAddPose={handleAddPose}
        makeUpdatePose={makeUpdatePose}
        makeUpdatePoseTiming={makeUpdatePoseTiming}
        makeDeletePose={makeDeletePose}
      />
    );
  }, [
    gameName,
    onGameNameChange,
    allowLargeGames,
    isLargeGame,
    onIsLargeGameChange,
    largeGameGroupSize,
    onLargeGameGroupSizeChange,
    poses,
    poseTimings,
    poseOptions,
    handleAddPose,
    makeUpdatePose,
    makeUpdatePoseTiming,
    makeDeletePose,
  ]);

  const renderFooter = React.useCallback(() => {
    return (
      <GameFooter>
        <Button onClick={handleEdit}>{t("editGame.submit")}</Button>
      </GameFooter>
    );
  }, [handleEdit, t]);

  return (
    <>
      <GameScreen
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
      {isEditing && <LoadingModal text={t("editGame.editing")} />}
    </>
  );
};
