import React from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  useTournamentData,
  acceptTournamentHostInvite,
} from "../../data/tournament";
import { GameScreen } from "../components/GameScreen";
import { ShadowBottom } from "../components/ShadowBottom";
import { RegularTitle } from "../components/Title";
import { Text } from "../components/Text";
import { BackButton } from "../components/BackButton";
import { Button } from "../components/Button";
import { useAuth } from "../../data/auth";
import { useBrand } from "../../data/brand";
import { BrandLogo } from "../components/BrandLogo";

const Content = styled.div`
  overflow-y: auto;
`;
const TopCard = styled(ShadowBottom)`
  padding: 24px;
  align-items: center;
`;
const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;
const Logo = styled(BrandLogo)`
  margin: 40px 0;
  max-width: 300px;
  max-height: 200px;
`;
const TournamentName = styled(RegularTitle)``;

const Instructions = styled.div`
  margin: 32px 16px;
`;
const Hint = styled(Text)`
  margin-bottom: 16px;
`;

export const TournamentInviteHost = () => {
  const { t } = useTranslation();
  const { tournamentId, additionalHostCode } = useParams<{
    tournamentId: string;
    additionalHostCode: string;
  }>();
  const history = useHistory();
  const { uid } = useAuth();
  const { brandId } = useBrand();

  const tournament = useTournamentData({ brandId, tournamentId });
  const isAdditionalHost = !!uid && !!tournament?.additionalHosts.includes(uid);

  const gameLogoView = React.useMemo(
    () => <Logo overrideSrc={tournament?.logo} />,
    [tournament]
  );

  const [isAccepting, setIsAccepting] = React.useState(false);
  const onAcceptPress = React.useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();

      if (isAccepting) {
        return;
      }

      setIsAccepting(true);

      try {
        await acceptTournamentHostInvite({
          brandId,
          tournamentId,
          additionalHostCode,
        });

        setIsAccepting(false);
      } catch (e) {
        alert(t("tournamentInviteHost.acceptError", { details: e.toString() }));
        setIsAccepting(false);
      }
    },
    [brandId, additionalHostCode, isAccepting, t, tournamentId]
  );

  const renderBody = React.useCallback(() => {
    return (
      <Content>
        <TopCard>
          <BackButtonContainer>
            <BackButton onClick={() => history.replace(`/${tournamentId}`)} />
          </BackButtonContainer>
          {tournament?.logoUrl ? (
            <a href={tournament.logoUrl}>{gameLogoView}</a>
          ) : (
            gameLogoView
          )}
          {!!tournament && <TournamentName>{tournament.name}</TournamentName>}
        </TopCard>

        <Instructions>
          <Hint>{t("tournamentInviteHost.hint")}</Hint>
          <Button loading={isAccepting} onClick={onAcceptPress}>
            {t("tournamentInviteHost.button")}
          </Button>
        </Instructions>
      </Content>
    );
  }, [
    tournamentId,
    history,
    gameLogoView,
    tournament,
    t,
    onAcceptPress,
    isAccepting,
  ]);

  if (isAdditionalHost) {
    return <Redirect to={`/${tournamentId}`} />;
  }

  return <GameScreen renderBody={renderBody} />;
};
