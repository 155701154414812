import React from "react";
import styled from "styled-components";
import { detect } from "detect-browser";
import { Text } from "./Text";

const Container = styled.div``;

const TabBar = styled.div`
  display: flex;
  overflow: auto;
`;

const Tab = styled.div<{ selected?: boolean }>`
  padding: 8px 6px;
  margin: 0 6px;
  border-bottom: 1px solid ${(props) => props.theme.colors.backgroundOverlay};
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1 : 0.4)};

  &:hover {
    opacity: 0.75;
  }
`;

const TabText = styled(Text)``;

const Content = styled.div``;

export interface PlatformTabsProps {
  iOS: React.ReactNode;
  android: React.ReactNode;
  desktop: React.ReactNode;
}

enum PlatformTab {
  iOS,
  Android,
  Desktop,
}

export const PlatformTabs: React.FunctionComponent<PlatformTabsProps> = ({
  iOS,
  android,
  desktop,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<PlatformTab>(() => {
    const browser = detect();
    if (browser?.os === "iOS") {
      return PlatformTab.iOS;
    } else if (browser?.os === "android") {
      return PlatformTab.Android;
    } else {
      return PlatformTab.Desktop;
    }
  });
  const makeTabClick = React.useCallback(
    (tab: PlatformTab) => () => {
      setSelectedTab(tab);
    },
    []
  );

  const content = React.useMemo(() => {
    switch (selectedTab) {
      case PlatformTab.iOS:
        return iOS;
      case PlatformTab.Android:
        return android;
      case PlatformTab.Desktop:
        return desktop;
    }
  }, [android, desktop, iOS, selectedTab]);

  return (
    <Container>
      <TabBar>
        <Tab
          selected={selectedTab === PlatformTab.iOS}
          onClick={makeTabClick(PlatformTab.iOS)}
        >
          <TabText>iPhone</TabText>
        </Tab>
        <Tab
          selected={selectedTab === PlatformTab.Android}
          onClick={makeTabClick(PlatformTab.Android)}
        >
          <TabText>Android</TabText>
        </Tab>
        <Tab
          selected={selectedTab === PlatformTab.Desktop}
          onClick={makeTabClick(PlatformTab.Desktop)}
        >
          <TabText>Desktop</TabText>
        </Tab>
      </TabBar>
      <Content>{content}</Content>
    </Container>
  );
};
