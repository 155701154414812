import React from "react";
import styled from "styled-components";
import { WebcamSnapshot } from "@pose-party/types";
import { AspectCard } from "../components/AspectCard";
import { SnapshotDisplay } from "./SnapshotDisplay";
import { SmallDeleteIcon } from "../components/SmallDeleteIcon";
import { Text } from "../components/Text";

export interface PlayerWaitingListProps {
  players: {
    id: string;
    name: string;
    profilePicture: WebcamSnapshot | undefined;
    finished: boolean;
    fake: boolean;
  }[];
  uid: string | undefined;
  kickPlayer: ((playerId: string) => void) | undefined;
}

const Container = styled.div`
  flex: 1;
  padding: 16px 32px;
`;

const NotFinishedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PlayerNameText = styled(Text)`
  text-align: center;
  margin-top: 8px;
`;

export const PlayerWaitingList = React.memo(
  ({ players, uid, kickPlayer }: PlayerWaitingListProps) => {
    return (
      <Container>
        <div className="columns is-multiline is-mobile">
          {players.map((player) => (
            <div
              key={player.id}
              className="column is-one-third"
              style={{ position: "relative" }}
            >
              <AspectCard
                aspectRatio={1}
                style={{ borderRadius: "5%", position: "relative" }}
              >
                <SnapshotDisplay
                  snapshot={player.profilePicture}
                  animated={false}
                />
                {!player.finished && <NotFinishedOverlay />}
              </AspectCard>
              <PlayerNameText weight="bold">{player.name}</PlayerNameText>

              {!!kickPlayer && player.id !== uid && !player.fake && (
                <SmallDeleteIcon onClick={() => kickPlayer(player.id)} />
              )}
            </div>
          ))}
        </div>
      </Container>
    );
  }
);
