import React, { ReactNode } from "react";
import styled from "styled-components";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

export interface ResultsSliderProps {
  header: ReactNode;
  results: ReactNode[];
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BaseHeaderContent = styled(motion.div)``;
const HeaderStartContent = styled(BaseHeaderContent)`
  margin-top: 32px;
`;
const HeaderResultsContent = styled(BaseHeaderContent)`
  position: absolute;
  top: 0;
`;

const ResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
`;
const Results = styled.div`
  width: 600px;
  height: 600px;
  position: relative;
`;
const Result = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Arrow = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primaryBrand};
  color: ${(props) => props.theme.colors.primaryBrandOverlay};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 16pt;
  cursor: pointer;
  z-index: 1000;
`;
const PrevArrow = styled(Arrow)`
  left: 64px;
`;
const NextArrow = styled(Arrow)`
  right: 64px;
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export const ResultsSlider: React.FunctionComponent<ResultsSliderProps> = ({
  header,
  results,
}) => {
  const [[page, direction], setPage] = React.useState([0, 0]);

  const paginate = (newDirection: number) => {
    const newIndex = page + newDirection;
    if (newIndex >= 0 && newIndex < results.length + 1)
      setPage([page + newDirection, newDirection]);
  };

  const HeaderContent = page === 0 ? HeaderStartContent : HeaderResultsContent;

  return (
    <AnimateSharedLayout>
      <Content>
        <ResultsContainer>
          <Results>
            {page > 0 && (
              <AnimatePresence custom={direction}>
                <Result
                  key={page}
                  custom={direction}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                  }}
                >
                  {results[page - 1]}
                </Result>
              </AnimatePresence>
            )}
          </Results>
          {page !== 0 && <PrevArrow onClick={() => paginate(-1)}>◀</PrevArrow>}
          {page !== results.length && (
            <NextArrow onClick={() => paginate(1)}>▶</NextArrow>
          )}
        </ResultsContainer>

        <HeaderContainer>
          <HeaderContent
            layoutId="header"
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            {header}
          </HeaderContent>
        </HeaderContainer>
      </Content>
    </AnimateSharedLayout>
  );
};
