import React from "react";
import { ReportedSnapshot } from "@pose-party/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";
import { RegularTitle } from "../../components/Title";
import { AspectCard } from "../../components/AspectCard";
import { Button } from "../../components/Button";
import { SnapshotDisplay } from "../../Game/SnapshotDisplay";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { useBrand } from "../../../data/brand";
import { Text } from "../../components/Text";
import { hideGamePlayer } from "../../../data/game";
import {
  hideGameSnapshot,
  removeSnapshotReport,
} from "../../../data/game/snapshots";

const Content = styled.section`
  margin: 32px 0;
`;
const Title = styled(RegularTitle)`
  margin-bottom: 16px;
`;
const ReportedSnapshots = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ReportedSnapshotContent = styled.div`
  width: 33%;
  padding: 16px;
`;
const Snapshot = styled(AspectCard)``;
const PlayerName = styled(Text)`
  margin-top: 8px;
  text-align: center;
`;
const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
`;

const ReportedSnapshotItem: React.FunctionComponent<{
  reportedSnapshot: ReportedSnapshot;
}> = React.memo(
  ({
    reportedSnapshot: {
      snapshot,
      name,
      groupId,
      poseId,
      snapshotId,
      uid: reportedPlayerUid,
    },
  }) => {
    const { brandId } = useBrand();
    const gameId = useGameIdParam();
    const { t } = useTranslation();

    const [performingAction, setPerformingAction] = React.useState(false);

    const ignore = React.useCallback(async () => {
      const sure = window.confirm(
        `Are you sure you want to ignore this report?`
      );
      if (!sure) {
        return;
      }

      setPerformingAction(true);
      try {
        await removeSnapshotReport({
          brandId,
          gameId,
          playerId: reportedPlayerUid,
          poseId,
        });
      } catch (e) {
        window.alert(
          `There was a problem when attempting to kick the player. ${e}`
        );
      }
      setPerformingAction(false);
    }, [brandId, gameId, poseId, reportedPlayerUid]);

    const hide = React.useCallback(async () => {
      const sure = window.confirm(
        `Are you sure you want to hide this snapshot?`
      );
      if (!sure) {
        return;
      }

      setPerformingAction(true);
      try {
        await hideGameSnapshot({
          brandId,
          gameId,
          groupId,
          poseId,
          snapshotId,
        });
        await removeSnapshotReport({
          brandId,
          gameId,
          playerId: reportedPlayerUid,
          poseId,
        });
      } catch (e) {
        window.alert(
          `There was a problem when attempting to kick the player. ${e}`
        );
      }
      setPerformingAction(false);
    }, [brandId, gameId, groupId, poseId, reportedPlayerUid, snapshotId]);

    const kick = React.useCallback(async () => {
      const sure = window.confirm(
        `Are you sure you want to kick the player "${name}"?`
      );
      if (!sure) {
        return;
      }

      setPerformingAction(true);
      try {
        await hideGamePlayer({
          brandId,
          gameId,
          playerId: reportedPlayerUid,
          groupId,
        });
        await removeSnapshotReport({
          brandId,
          gameId,
          playerId: reportedPlayerUid,
          poseId,
        });
      } catch (e) {
        window.alert(
          `There was a problem when attempting to kick the player. ${e}`
        );
      }
      setPerformingAction(false);
    }, [brandId, gameId, groupId, name, poseId, reportedPlayerUid]);

    return (
      <ReportedSnapshotContent>
        <Snapshot aspectRatio={1}>
          <SnapshotDisplay snapshot={snapshot} />
        </Snapshot>
        <PlayerName>{name}</PlayerName>
        <ActionButton onClick={ignore} loading={performingAction}>
          {t("hostGameUI.reportedSnapshots.ignore")}
        </ActionButton>
        <ActionButton onClick={hide} loading={performingAction}>
          {t("hostGameUI.reportedSnapshots.hide")}
        </ActionButton>
        <ActionButton onClick={kick} loading={performingAction}>
          {t("hostGameUI.reportedSnapshots.kick")}
        </ActionButton>
      </ReportedSnapshotContent>
    );
  }
);

export const HostGameReportedSnapshots: React.FunctionComponent = React.memo(
  () => {
    const { reportedSnapshots } = useHostGame();
    const { t } = useTranslation();

    return (
      <Content>
        <Title>{t("hostGameUI.reportedSnapshots.title")}</Title>

        {reportedSnapshots.length === 0 && (
          <Text>{t("hostGameUI.reportedSnapshots.noneReported")}</Text>
        )}

        {reportedSnapshots.length > 0 && (
          <ReportedSnapshots>
            {reportedSnapshots.map((reportedSnapshot) => (
              <ReportedSnapshotItem
                key={reportedSnapshot.id}
                reportedSnapshot={reportedSnapshot}
              />
            ))}
          </ReportedSnapshots>
        )}
      </Content>
    );
  }
);
