/* eslint-disable global-require */
import React from "react";
import { Howl, Howler } from "howler";
import { GameSound } from "@pose-party/types";
import { GameSoundsContext } from "./GameSoundsContext";
/// @ts-ignore
import countdown from "../../assets/sounds/countdown.mp3";
/// @ts-ignore
import swipe from "../../assets/sounds/swipe.mp3";
/// @ts-ignore
import roundWinner from "../../assets/sounds/round_winner.mp3";
/// @ts-ignore
import drumRoll from "../../assets/sounds/drum_roll.mp3";
/// @ts-ignore
import finalResults from "../../assets/sounds/final_results.mp3";

Howler.autoSuspend = false;

const SOUNDS: Record<GameSound, Howl> = {
  [GameSound.Countdown]: new Howl({
    src: [countdown],
    loop: true,
  }),

  [GameSound.Swipe]: new Howl({
    src: [swipe],
  }),

  [GameSound.RoundWinner]: new Howl({
    src: [roundWinner],
  }),

  [GameSound.DrumRoll]: new Howl({
    src: [drumRoll],
  }),

  [GameSound.FinalResults]: new Howl({
    src: [finalResults],
  }),
};

export const GameSoundsProvider: React.FunctionComponent = React.memo(
  ({ children }) => {
    const playGameSound = React.useCallback((sound: GameSound) => {
      SOUNDS[sound].play();

      return () => SOUNDS[sound].stop();
    }, []);

    return (
      <GameSoundsContext.Provider value={{ playGameSound }}>
        {children}
      </GameSoundsContext.Provider>
    );
  }
);
