import React from "react";
import _ from "lodash";
import { Tournament } from "@pose-party/types";
import { mapTournament, mapQueryTournament } from "./utils";
import firebase from "../firebase";

export const useTournamentData = ({
  brandId,
  tournamentId,
}: {
  brandId: string;
  tournamentId: string | undefined;
}) => {
  const [tournament, setTournament] = React.useState<Tournament>();

  React.useEffect(() => {
    if (!tournamentId) {
      return;
    }

    const documentRef = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("tournaments")
      .doc(tournamentId);

    return documentRef.onSnapshot((data) => {
      const values = data.data();
      if (values) {
        setTournament(mapTournament(data.id, values));
      } else {
        setTournament(undefined);
      }
    });
  }, [brandId, tournamentId]);

  return tournament;
};

export const useTounramentsByHost = ({
  brandId,
  uid,
}: {
  brandId: string;
  uid: string | undefined;
}) => {
  const [gamesList, setGamesList] = React.useState<Tournament[]>();

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const gamesRef = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("tournaments")
      .where("hostId", "==", uid);

    return gamesRef.onSnapshot((data) => {
      const raw = data.docs.map(mapQueryTournament);
      setGamesList(_.sortBy(raw, (g) => g.createdAt).reverse());
    });
  }, [brandId, uid]);

  return gamesList;
};
