import _ from "lodash";

export const groupPlayers = <T>(playerIds: T[], groupSize: number): T[][] => {
  const results = _.chunk(playerIds, groupSize);

  // Check if we have a group on the end with a small number of player ids
  // If so, we are going to redistribute those players into the other groups
  let lastGroup = _.last(results);
  let takeIndex = 0;
  while (results.length > 1 && lastGroup && lastGroup.length < groupSize - 1) {
    // Remove a player from the `takeIndex` and add it to the last group
    const item = _.pullAt(results[takeIndex], 0)[0];
    results[results.length - 1].push(item);

    // Update the last group and take index
    lastGroup = _.last(results);
    takeIndex = (takeIndex + 1) % (results.length - 1);
  }

  return results;
};
