import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { GameDetailsForm } from "../../components/GameDetailsForm";
import { GameScreen } from "../../components/GameScreen";
import { GameHeader } from "../../components/GameHeader";
import { GameFooter } from "../../components/GameFooter";
import { Button } from "../../components/Button";
import { useFormInput } from "../../../hooks/useFormInput";
import { useGamePoseOptions } from "../../../hooks/useGamePoseOptions";
import { LoadingModal } from "../../components/LoadingModal";
import { newGame } from "../../../data/game";
import { useBrand } from "../../../data/brand";
import { useFormCheckbox } from "../../../hooks/useFormCheckbox";

export const NewGame: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { brandId, brandDetails } = useBrand();
  const { allowLargeGames, allowHostPerGameSettings } = brandDetails;
  const location = useLocation();
  const hostId = React.useMemo(
    () => new URLSearchParams(location.search).get("hostId") || undefined,
    [location.search]
  );

  /**
   * Handle the form data
   */
  const [customGameId, onCustomGameIdChange] = useFormInput();
  const [gameName, onGameNameChange] = useFormInput();
  const [isLargeGame, onIsLargeGameChange, setIsLargeGame] = useFormCheckbox(
    false
  );
  const [largeGameGroupSize, onLargeGameGroupSizeChange] = useFormInput({
    defaultValue: "20",
  });
  React.useEffect(() => {
    if (!allowLargeGames) {
      setIsLargeGame(false);
    }
  }, [allowLargeGames, setIsLargeGame]);
  const [
    poses,
    poseTimings,
    poseOptions,
    makeUpdatePose,
    makeUpdatePoseTiming,
    makeDeletePose,
    handleAddPose,
  ] = useGamePoseOptions();

  /**
   * Handle advanced per game options
   */
  const [stillSnapshots, onStillSnapshotsChange] = useFormCheckbox(
    brandDetails.stillSnapshots
  );
  const [
    defaultToUserFacingCamera,
    onDefaultToUserFacingCameraChange,
  ] = useFormCheckbox(brandDetails.defaultToUserFacingCamera);
  const [allowSwapCamera, onAllowSwapCameraChange] = useFormCheckbox(
    brandDetails.allowSwapCamera
  );
  const [allowSnapshotWebcam, onAllowSnapshotWebcamChange] = useFormCheckbox(
    brandDetails.allowSnapshotWebcam
  );
  const [allowSnapshotUpload, onAllowSnapshotUploadChange] = useFormCheckbox(
    brandDetails.allowSnapshotUpload
  );
  const [enableCameraTimer, onEnableCameraTimerChange] = useFormCheckbox(
    brandDetails.enableCameraTimer
  );
  const [hideRoundCountdown, onHideRoundCountdownChange] = useFormCheckbox(
    brandDetails.hideRoundCountdown
  );
  const [allowJoinLate, onAllowJoinLateChange] = useFormCheckbox(
    brandDetails.allowJoinLate
  );
  const [allowSpectators, onAllowSpectatorsChange] = useFormCheckbox(
    brandDetails.allowSpectators
  );
  const advancedSettings = React.useMemo(
    () => ({
      stillSnapshots,
      onStillSnapshotsChange,
      defaultToUserFacingCamera,
      onDefaultToUserFacingCameraChange,
      allowSwapCamera,
      onAllowSwapCameraChange,
      allowSnapshotWebcam,
      onAllowSnapshotWebcamChange,
      allowSnapshotUpload,
      onAllowSnapshotUploadChange,
      enableCameraTimer,
      onEnableCameraTimerChange,
      hideRoundCountdown,
      onHideRoundCountdownChange,
      allowJoinLate,
      onAllowJoinLateChange,
      allowSpectators,
      onAllowSpectatorsChange,
    }),
    [
      allowJoinLate,
      allowSnapshotUpload,
      allowSnapshotWebcam,
      allowSpectators,
      allowSwapCamera,
      defaultToUserFacingCamera,
      enableCameraTimer,
      hideRoundCountdown,
      onAllowJoinLateChange,
      onAllowSnapshotUploadChange,
      onAllowSnapshotWebcamChange,
      onAllowSpectatorsChange,
      onAllowSwapCameraChange,
      onDefaultToUserFacingCameraChange,
      onEnableCameraTimerChange,
      onHideRoundCountdownChange,
      onStillSnapshotsChange,
      stillSnapshots,
    ]
  );

  /**
   * Handle submitting the form data
   */
  const [isCreating, setIsCreating] = React.useState(false);
  const handleCreate = React.useCallback(async () => {
    const trimmedGameId = customGameId.trim();
    if (trimmedGameId.length > 0 && !trimmedGameId.match(/^[a-z0-9-]+$/i)) {
      alert(t("createGame.invalidGameId"));
      return;
    }

    if (!gameName || poses.length === 0) {
      alert(t("createGame.invalidData"));
      return;
    }

    if (poseTimings.find((time) => time < 10) !== undefined) {
      alert(t("createGame.invalidPoseTimings"));
      return;
    }

    setIsCreating(true);

    try {
      const { data: newGameId } = await newGame({
        brandId,
        hostId,
        gameId: trimmedGameId || undefined,
        name: gameName,
        largeGroupSize:
          allowLargeGames && isLargeGame
            ? parseInt(largeGameGroupSize, 10)
            : undefined,
        poses,
        poseTimings: poseTimings.map((timing) => timing * 1000),
        gameBrandOverrides: allowHostPerGameSettings
          ? {
              stillSnapshots,
              defaultToUserFacingCamera,
              allowSwapCamera,
              allowSnapshotUpload,
              allowSnapshotWebcam,
              enableCameraTimer,
              hideRoundCountdown,
              allowJoinLate,
              allowSpectators,
            }
          : undefined,
      });

      // Navigate to the game screen
      history.replace(`/user/game/${newGameId}`);

      setIsCreating(false);
    } catch (e) {
      alert(t("createGame.createError", { details: e.toString() }));
      setIsCreating(false);
    }
  }, [
    allowHostPerGameSettings,
    allowJoinLate,
    allowLargeGames,
    allowSnapshotUpload,
    allowSnapshotWebcam,
    allowSpectators,
    allowSwapCamera,
    brandId,
    customGameId,
    defaultToUserFacingCamera,
    enableCameraTimer,
    gameName,
    hideRoundCountdown,
    history,
    hostId,
    isLargeGame,
    largeGameGroupSize,
    poseTimings,
    poses,
    stillSnapshots,
    t,
  ]);

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return <GameHeader goBack={history.goBack} title={t("createGame.title")} />;
  }, [history.goBack, t]);

  const renderBody = React.useCallback(() => {
    return (
      <GameDetailsForm
        customGameId={customGameId}
        onCustomGameIdChange={onCustomGameIdChange}
        gameName={gameName}
        onGameNameChange={onGameNameChange}
        allowLargeGames={allowLargeGames}
        isLargeGame={isLargeGame}
        onIsLargeGameChange={onIsLargeGameChange}
        largeGameGroupSize={largeGameGroupSize}
        onLargeGameGroupSizeChange={onLargeGameGroupSizeChange}
        poses={poses}
        poseTimings={poseTimings}
        poseOptions={poseOptions}
        handleAddPose={handleAddPose}
        makeUpdatePose={makeUpdatePose}
        makeUpdatePoseTiming={makeUpdatePoseTiming}
        makeDeletePose={makeDeletePose}
        advancedSettings={advancedSettings}
      />
    );
  }, [
    customGameId,
    onCustomGameIdChange,
    gameName,
    onGameNameChange,
    allowLargeGames,
    isLargeGame,
    onIsLargeGameChange,
    largeGameGroupSize,
    onLargeGameGroupSizeChange,
    poses,
    poseTimings,
    poseOptions,
    handleAddPose,
    makeUpdatePose,
    makeUpdatePoseTiming,
    makeDeletePose,
    advancedSettings,
  ]);

  const renderFooter = React.useCallback(() => {
    return (
      <GameFooter>
        <Button onClick={handleCreate}>{t("createGame.submit")}</Button>
      </GameFooter>
    );
  }, [handleCreate, t]);

  return (
    <>
      <GameScreen
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
      {isCreating && <LoadingModal text={t("createGame.creating")} />}
    </>
  );
};
