import React from "react";
import styled from "styled-components/macro";

export interface RoundButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  large?: boolean;
  buttonColor?: "default" | "neutral" | "accent" | "error";
}

export const RoundButton = styled.button<RoundButtonProps>`
  width: ${(props) => (props.large ? "82px" : "64px")};
  height: ${(props) => (props.large ? "82px" : "64px")};
  border-radius: 100px;
  background-color: ${(props) => {
    switch (props.buttonColor) {
      case "accent":
        return "#4AE4DB";
      case "error":
        return "#DD2E4E";
      case "neutral":
        return "#FFFFFF";
      default:
        return "#0a0a0a";
    }
  }};
  border-width: 2px;
  border-color: ${(props) => {
    switch (props.buttonColor) {
      case "accent":
        return "black";
      case "error":
        return "black";
      case "neutral":
        return "black";
      default:
        return "transparent";
    }
  }};
  color: ${(props) => {
    switch (props.buttonColor) {
      case "accent":
        return "black";
      case "error":
        return "white";
      case "neutral":
        return "#D6D6D6";
      default:
        return "white";
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.29);

  &:active {
    box-shadow: none;
  }

  &:focus {
    outline: 0;
  }
`;
