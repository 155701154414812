import React from "react";
import styled from "styled-components";

import { BounceDiv } from "./BounceDiv";
import { GoogleStorageImage } from "./GoogleStorageImage";
import { useBrand } from "../../data/brand";
import superlikeBlack from "../../assets/superlikeBlack.svg";

const WaitingIndicatorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const WaitingIndicator = styled(BounceDiv)`
  & img {
    width: 150px;
    height: 150px;
  }
`;

export const SuperlikeLoadingIndicator = () => {
  const {
    brandDetails: { gameButtonSuperLike },
  } = useBrand();

  return (
    <WaitingIndicatorContainer>
      <WaitingIndicator>
        {gameButtonSuperLike ? (
          <GoogleStorageImage src={gameButtonSuperLike} />
        ) : (
          <img src={superlikeBlack} alt="" />
        )}
      </WaitingIndicator>
    </WaitingIndicatorContainer>
  );
};
