import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";

const CounterTable = styled.table`
  width: 100%;
  max-width: 500px;
  margin: 16px 0;
`;
const StatusRow = styled.tr`
  height: 30px;
  padding-bottom: 4px;
`;
const Count = styled.td`
  width: 50%;
  text-align: right;
`;

export const HostGameScoreCounter: React.FunctionComponent = React.memo(() => {
  const {
    playerCount,
    scoreCompletedCount,
    spectatorCount,
    poseSpectatorScoredCount,
  } = useHostGame();
  const { t } = useTranslation();

  const playerCompleted = scoreCompletedCount || 0;
  const playerTotal = playerCount || 0;

  return (
    <>
      <CounterTable>
        <StatusRow>
          <td>{t("hostGameUI.playerCount")}</td>
          <Count>{playerCount || 0}</Count>
        </StatusRow>
        <StatusRow>
          <td>{t("hostGameUI.playersCompleted")}</td>
          <Count>{playerCompleted}</Count>
        </StatusRow>
        <StatusRow>
          <td>{t("hostGameUI.playersRemaining")}</td>
          <Count>{playerTotal - playerCompleted}</Count>
        </StatusRow>
        <StatusRow>
          <td>{t("hostGameUI.playersPercentage")}</td>
          <Count>
            {playerTotal === 0
              ? 0
              : Math.ceil((playerCompleted / playerTotal) * 100)}
            %
          </Count>
        </StatusRow>
      </CounterTable>

      {spectatorCount > 0 && (
        <CounterTable>
          <StatusRow>
            <td>{t("hostGameUI.spectatorCount")}</td>
            <Count>{spectatorCount}</Count>
          </StatusRow>
          <StatusRow>
            <td>{t("hostGameUI.spectatorsCompleted")}</td>
            <Count>{poseSpectatorScoredCount}</Count>
          </StatusRow>
          <StatusRow>
            <td>{t("hostGameUI.spectatorsRemaining")}</td>
            <Count>{spectatorCount - poseSpectatorScoredCount}</Count>
          </StatusRow>
          <StatusRow>
            <td>{t("hostGameUI.spectatorsPercentage")}</td>
            <Count>
              {Math.ceil((poseSpectatorScoredCount / spectatorCount) * 100)}%
            </Count>
          </StatusRow>
        </CounterTable>
      )}
    </>
  );
});
