import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { Text } from "./Text";
import { useBrand } from "../../data/brand";

export interface CopyContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  text: string;
  displayText?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: transparent;
  font-family: "Nunito Sans";
  font-weight: 700;
  border-radius: 10px;
  padding: 0 16px;
  height: 48px;
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};
`;

const CopyTitle = styled(Text)`
  margin-right: 8px;
`;
const CopyText = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CopyConfirmText = styled(Text)`
  flex: 1;
  font-style: italic;
`;
const CopyImage = styled.svg`
  pointer-events: all;
  cursor: pointer;
  margin-left: 16px;

  &:active {
    opacity: 0.5;
  }
`;

export const CopyContainer: React.FunctionComponent<CopyContainerProps> = ({
  title,
  displayText,
  text,
  ...rest
}) => {
  const { t } = useTranslation();
  const { brandDetails } = useBrand();

  const [displayCopyMessage, setDisplayCopyMessage] = React.useState(false);

  const onCopy = React.useCallback(() => {
    setDisplayCopyMessage(true);
    setTimeout(() => setDisplayCopyMessage(false), 2000);
  }, []);

  return (
    <Container {...rest}>
      {displayCopyMessage ? (
        <CopyConfirmText>{t("common.copiedToClipboard")}</CopyConfirmText>
      ) : (
        <>
          <CopyTitle weight="bold">{title}</CopyTitle>
          <CopyText>{displayText || text}</CopyText>
        </>
      )}
      <CopyToClipboard text={text} onCopy={onCopy}>
        <CopyImage
          width="19"
          height="22"
          viewBox="0 0 19 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM13 4H6C4.9 4 4.01 4.9 4.01 6L4 20C4 21.1 4.89 22 5.99 22H17C18.1 22 19 21.1 19 20V10L13 4ZM6 20V6H12V11H17V20H6Z"
            fill={brandDetails.colors.textInputBackgroundOverlay}
          />
        </CopyImage>
      </CopyToClipboard>
    </Container>
  );
};
