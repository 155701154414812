import React from "react";

export const StartedWithout: React.FunctionComponent = () => {
  return (
    <div className="has-vertical-centered-content">
      <h3 className="title is-4 has-text-centered">Sorry!</h3>
      <p className="is-5 content has-text-centered">
        The game has started before you were setup
      </p>
    </div>
  );
};
