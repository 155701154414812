import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useAuth } from "../../data/auth";
import { BrandLogo } from "./BrandLogo";
import { useBrand } from "../../data/brand";
import { languageDetector } from "../../i18n";

export interface SideMenuProps {
  isOpen: boolean;
  logo?: string | null | undefined;
  onClosePress: () => void;
}

const MenuContent = styled(motion.nav)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto;
  z-index: 100;
`;
const Logo = styled(BrandLogo)`
  margin-bottom: 48px;
  max-width: 176px;
`;
const LinkText = styled.a`
  padding: 16px 0;

  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.backgroundOverlay};

  & a {
    color: ${(props) => props.theme.colors.backgroundOverlay};
  }
`;
const Languages = styled.div`
  margin-left: -8px;
`;
const LanguageButton = styled.button`
  border: 0;
  background: none;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
`;

const Overlay = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  background: none;
  border: 0;
`;

export const SideMenu: React.FunctionComponent<SideMenuProps> = ({
  isOpen,
  logo,
  onClosePress,
}) => {
  const { t } = useTranslation();
  const { isSignedIn, isAdmin, performSignOut } = useAuth();
  const {
    brandDetails: { allowSignUp },
  } = useBrand();

  const contentVariants = {
    open: { x: 0 },
    closed: { x: "-100%" },
  };
  const overlayVariants = {
    open: { opacity: 1, display: "block" },
    closed: { opacity: 0, display: "none" },
  };

  const animationProps = {
    initial: false,
    animate: isOpen ? "open" : "closed",
    transition: { ease: "easeOut", duration: 0.25 },
  };

  const history = useHistory();
  const makeLinkTo = React.useCallback(
    (path: string) => () => {
      history.push(path);

      onClosePress();
    },
    [history, onClosePress]
  );

  const makeLanguageChange = React.useCallback(
    (languageCode: string) => () => {
      languageDetector.cacheUserLanguage(languageCode);
      window.location.reload();
    },
    []
  );

  return (
    <>
      <Overlay {...animationProps} variants={overlayVariants} />

      <MenuContent {...animationProps} variants={contentVariants}>
        <Logo overrideSrc={logo} alt="" />
        {isSignedIn ? (
          <LinkText onClick={makeLinkTo("/")}>{t("menu.myAccount")}</LinkText>
        ) : (
          <>
            <LinkText onClick={makeLinkTo("/")}>{t("menu.home")}</LinkText>
            {allowSignUp && (
              <LinkText onClick={makeLinkTo("/signUp")}>
                {t("menu.signUp")}
              </LinkText>
            )}
            <LinkText onClick={makeLinkTo("/login")}>
              {t("menu.login")}
            </LinkText>
          </>
        )}
        {isAdmin && (
          <>
            <LinkText href="https://admin.poseparty.com/" target="_blank">
              {t("menu.admin")}
            </LinkText>
          </>
        )}
        {isSignedIn && (
          <LinkText onClick={performSignOut}>{t("menu.signOut")}</LinkText>
        )}

        <Languages>
          <LanguageButton onClick={makeLanguageChange("en")}>
            <span role="img" aria-label="English">
              🇬🇧
            </span>
          </LanguageButton>
          <LanguageButton onClick={makeLanguageChange("de")}>
            <span role="img" aria-label="Deutsch">
              🇩🇪
            </span>
          </LanguageButton>
          <LanguageButton onClick={makeLanguageChange("pt")}>
            <span role="img" aria-label="Portuguese">
              🇵🇹
            </span>
          </LanguageButton>
        </Languages>
      </MenuContent>

      <CloseButton
        onClick={onClosePress}
        {...animationProps}
        variants={overlayVariants}
      >
        <img src="/assets/crossWhite.svg" alt="Close" />
      </CloseButton>
    </>
  );
};
