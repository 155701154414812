import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Redirect, Link, useLocation } from "react-router-dom";
import { GameScreen, ScreenAreaRenderProps } from "../components/GameScreen";
import { useAuth } from "../../data/auth";
import { TextInput } from "../components/TextInput";
import { useFormInput } from "../../hooks/useFormInput";
import { Button } from "../components/Button";
import { useBrand } from "../../data/brand";
import { logger } from "../../logger";
import { GameHeader } from "../components/GameHeader";
import { Markdown } from "../components/Markdown";
import { useContinueParam } from "../../hooks/useContinueParam";

const Container = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto;
`;
const MarkdownContent = styled(Markdown)`
  margin: 16px 16px 0 16px;
`;
const FormContainer = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
`;
const FormTextInput = styled(TextInput)`
  margin-bottom: 16px;
`;
const SubmitContainer = styled.div`
  padding: 0 16px;
`;
const AuthButton = styled(Button)`
  margin-bottom: 8px;
`;
const AuthLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;
const AuthLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Login = () => {
  const { t } = useTranslation();
  const {
    brandDetails: { allowSignUp, landingPageContent },
  } = useBrand();
  const { isSignedIn, performSignIn } = useAuth();
  const location = useLocation();
  const continueParam = useContinueParam();

  const [email, handleEmailChange] = useFormInput();
  const [password, handlePasswordChange] = useFormInput();

  const handleFormSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const trimmedEmail = email.trim();
      if (trimmedEmail.length === 0 || password.length === 0) {
        alert(t("auth.formError"));
        return;
      }

      try {
        await performSignIn({ email: trimmedEmail, password });
      } catch (e) {
        logger.warn("Error performing auth", e);

        if (e.code === "auth/invalid-email") {
          alert(t("auth.invalidEmailError"));
        } else if (e.code === "auth/wrong-password") {
          alert(t("auth.incorrectPasswordError"));
        } else {
          alert(t("auth.authError"));
        }
      }
    },
    [email, password, performSignIn, t]
  );

  const renderHeader = React.useCallback(
    ({ toggleMenu }: ScreenAreaRenderProps) => {
      return (
        <GameHeader toggleMenu={toggleMenu} title={t("auth.loginTitle")} />
      );
    },
    [t]
  );

  const renderBody = React.useCallback(() => {
    return (
      <Container>
        {!!landingPageContent?.auth?.login && (
          <MarkdownContent source={landingPageContent.auth.login} />
        )}

        <form onSubmit={handleFormSubmit}>
          <FormContainer>
            <FormTextInput
              placeholder={t("auth.emailPlaceholder")}
              value={email}
              onChange={handleEmailChange}
            />
            <FormTextInput
              type="password"
              placeholder={t("auth.passwordPlaceholder")}
              value={password}
              onChange={handlePasswordChange}
            />
          </FormContainer>

          <SubmitContainer>
            <AuthButton type="submit">{t("auth.login")}</AuthButton>
            <AuthLinkContainer>
              {allowSignUp && (
                <>
                  <AuthLink to={`/signUp${location.search}`}>
                    {t("auth.signUp")}
                  </AuthLink>
                  &nbsp;|&nbsp;
                </>
              )}
              <AuthLink to={`/forgottenPassword${location.search}`}>
                {t("auth.forgottenPassword")}
              </AuthLink>
            </AuthLinkContainer>
          </SubmitContainer>
        </form>
      </Container>
    );
  }, [
    allowSignUp,
    email,
    handleEmailChange,
    handleFormSubmit,
    handlePasswordChange,
    landingPageContent,
    location.search,
    password,
    t,
  ]);

  if (isSignedIn) {
    return <Redirect to={continueParam || "/"} />;
  }

  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
