import { Tournament, TournamentProtected } from "@pose-party/types";
import { mapDate } from "@pose-party/data";
import firebase from "../firebase";

export const mapTournament = (
  id: string,
  values: firebase.firestore.DocumentData
): Tournament => {
  return {
    id,
    createdAt: mapDate(values.createdAt),
    name: values.name,
    logo: values.logo || undefined,
    logoUrl: values.logoUrl || undefined,
    hostId: values.hostId,
    hostEmail: values.hostEmail,
    heatCount: values.heatCount,
    topPlayers: values.topPlayers,
    heatPoses: values.heatPoses || [],
    heatGameIds: values.heatGameIds || [],
    finalPoses: values.finalPoses || [],
    finalGameId: values.finalGameId,
    additionalHosts: values.additionalHosts || [],
  };
};

/**
 * Maps a firebase document to a tournament
 */
export const mapQueryTournament = (
  data: firebase.firestore.QueryDocumentSnapshot
): Tournament => {
  return mapTournament(data.id, data.data());
};

/**
 * Maps a firestore document to a protected tournament
 * @param values
 */
export const mapTournamentProtected = (
  id: string,
  values: firebase.firestore.DocumentData
): TournamentProtected => {
  return {
    id,
    additionalHostCode: values.additionalHostCode,
  };
};
