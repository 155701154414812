import React from "react";
import { useHistory } from "react-router-dom";
import { useGameIdParam } from "./useGameIdParam";

export const useGameHostUI = () => {
  const gameId = useGameIdParam();
  const history = useHistory();

  const openHostGameUI = React.useCallback(() => {
    history.push(`/${gameId}/host`);
  }, [gameId, history]);

  const openScreenshareUI = React.useCallback(() => {
    window.open(
      `/${gameId}/host/screenshare`,
      `PosePartyScreenshare${gameId}`,
      "toolbar,resizable,width=1280,height=720"
    );
  }, [gameId]);

  return {
    openHostGameUI,
    openScreenshareUI,
  };
};
