import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { GameScreen } from "../../components/GameScreen";
import { BackButton } from "../../components/BackButton";
import { ShadowBottom } from "../../components/ShadowBottom";
import { RegularTitle } from "../../components/Title";
import {
  useTournamentData,
  useProtectedTournamentData,
} from "../../../data/tournament";
import { useGameList } from "../../../data/game";
import { UserDataRow } from "../../components/UserDataRow";
import { CopyContainer } from "../../components/CopyContainer";
import { Text } from "../../components/Text";
import { useBrand } from "../../../data/brand";

const Header = styled(ShadowBottom)`
  height: 72px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 12px;
`;

const TournamentTitle = styled(RegularTitle)`
  flex: 1;
  text-align: center;
  margin-left: -28px;
`;

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;
const CodeSection = styled.section`
  margin-top: 40px;
  padding: 0 16px;
`;
const CodeHintText = styled(Text)`
  text-align: center;
  margin-bottom: 32px;
`;
const CopyText = styled(CopyContainer)`
  margin-bottom: 16px;
`;
const GameSection = styled.section`
  margin-top: 40px;
  padding: 0 16px 16px 16px;
  background-color: ${(props) => props.theme.colors.background};
`;
const GameTitle = styled(RegularTitle)`
  margin-top: 40px;
`;

export const TournamentDetails: React.FunctionComponent = () => {
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { brandId, brandDetails } = useBrand();

  /**
   * Load the tournament data
   */
  const tournament = useTournamentData({ brandId, tournamentId });
  const tournamentProtected = useProtectedTournamentData({
    brandId,
    tournamentId,
  });
  const heatGames = useGameList({ brandId, gameIds: tournament?.heatGameIds });
  const finalGameId = React.useMemo(
    () => (tournament?.finalGameId ? [tournament?.finalGameId] : undefined),
    [tournament]
  );
  const finalGame = useGameList({ brandId, gameIds: finalGameId });

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return (
      <Header>
        <BackButton onClick={() => history.goBack()} />
        {!!tournament?.name && (
          <TournamentTitle>{tournament.name}</TournamentTitle>
        )}
      </Header>
    );
  }, [tournament, history]);

  const renderBody = React.useCallback(() => {
    if (!tournament?.name) {
      return (
        <LoadingContainer>
          <RegularTitle>{t("tournamentDetails.loading")}</RegularTitle>
        </LoadingContainer>
      );
    }

    const shortUrl = `${brandDetails.domain}/${tournamentId}`;
    const additionalHostShortUrl = tournamentProtected
      ? `${shortUrl}/inviteHost/${tournamentProtected.additionalHostCode}`
      : undefined;

    return (
      <Content>
        {!!additionalHostShortUrl && (
          <CodeSection>
            <CodeHintText>
              {t("tournamentDetails.additionalHostHint")}
            </CodeHintText>
            <CopyText
              title={t("tournamentDetails.url")}
              text={`https://${additionalHostShortUrl}`}
              displayText={additionalHostShortUrl}
            />
          </CodeSection>
        )}
        <GameSection>
          {!!heatGames && heatGames.length > 0 && (
            <>
              <GameTitle>{t("tournamentDetails.heatGames")}</GameTitle>

              {heatGames.map((game) => (
                <UserDataRow
                  key={game.id}
                  data={{ ...game, path: `/user/game/${game.id}` }}
                />
              ))}
            </>
          )}

          {!!finalGame && finalGame.length > 0 && (
            <>
              <GameTitle>{t("tournamentDetails.finalGame")}</GameTitle>
              <UserDataRow
                key={finalGame[0].id}
                data={{
                  ...finalGame[0],
                  path: `/user/game/${finalGame[0].id}`,
                }}
              />
            </>
          )}
        </GameSection>
      </Content>
    );
  }, [
    tournament,
    tournamentProtected,
    tournamentId,
    t,
    heatGames,
    finalGame,
    brandDetails.domain,
  ]);

  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
