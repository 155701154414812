import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { GameScreen } from "../../components/GameScreen";
import { GameHeader } from "../../components/GameHeader";
import { GameFooter } from "../../components/GameFooter";
import { Button, AccentButton } from "../../components/Button";
import { RegularTitle } from "../../components/Title";
import { TextInput } from "../../components/TextInput";
import { useFormInput } from "../../../hooks/useFormInput";
import { LoadingModal } from "../../components/LoadingModal";
import { newTournament } from "../../../data/tournament";
import { Text } from "../../components/Text";
import { PoseInput } from "../../components/PoseInput";
import { useBrand } from "../../../data/brand";
import { useGamePoseOptions } from "../../../hooks/useGamePoseOptions";
import { useAuth } from "../../../data/auth";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 32px 16px;
`;
const FieldTitle = styled(RegularTitle)`
  text-align: center;
  margin: 32px 0 8px 0;
`;
const FieldHint = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
`;
const PoseContainer = styled.div`
  margin-bottom: 16px;
`;

export const NewTournament: React.FunctionComponent = () => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    brandId,
    brandDetails: { gamePoseLimit, gamesHaveRandomPoses, allowTournaments },
  } = useBrand();
  const location = useLocation();
  const hostId = React.useMemo(
    () => new URLSearchParams(location.search).get("hostId") || undefined,
    [location.search]
  );

  /**
   * Handle the form data
   */
  const [tournamentName, onTournamentNameChange] = useFormInput();
  const [heatCount, onHeatCountChange] = useFormInput({ defaultValue: "5" });
  const [topPlayers, onTopPlayersChange] = useFormInput({ defaultValue: "1" });
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [
    heatPoses,
    _heatPoseTiming,
    heatPoseOptions,
    makeUpdateHeatPose,
    _makeUpdateHeatPoseTiming,
    makeDeleteHeatPose,
    handleAddHeatPose,
  ] = useGamePoseOptions();
  const [
    finalPoses,
    _finalPoseTiming,
    fianlPoseOptions,
    makeUpdateFinalPose,
    _makeUpdateFinalPoseTiming,
    makeDeleteFinalPose,
    handleAddFinalPose,
  ] = useGamePoseOptions();
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const heatPoseSelectOptions = React.useMemo(
    () =>
      heatPoseOptions.map((pose) => ({
        label: pose,
        value: pose,
      })),
    [heatPoseOptions]
  );
  const fianlPoseSelectOptions = React.useMemo(
    () =>
      fianlPoseOptions.map((pose) => ({
        label: pose,
        value: pose,
      })),
    [fianlPoseOptions]
  );

  /**
   * Handle submitting the form data
   */
  const [isCreating, setIsCreating] = React.useState(false);
  const handleCreate = React.useCallback(async () => {
    if (!tournamentName || heatPoses.length === 0) {
      alert(t("createTournament.invalidData"));
      return;
    }

    setIsCreating(true);

    try {
      const newTournamentId = await newTournament({
        brandId,
        hostId,
        name: tournamentName,
        heatCount: parseInt(heatCount, 10),
        topPlayers: parseInt(topPlayers, 10),
        heatPoses,
        finalPoses,
      });

      // Navigate to the tournament screen
      history.replace(`/user/tournament/${newTournamentId.data}`);

      setIsCreating(false);
    } catch (e) {
      alert(t("createTournament.createError", { details: e.toString() }));
      setIsCreating(false);
    }
  }, [
    tournamentName,
    heatPoses,
    t,
    brandId,
    hostId,
    heatCount,
    topPlayers,
    finalPoses,
    history,
  ]);

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return (
      <GameHeader goBack={history.goBack} title={t("createTournament.title")} />
    );
  }, [history.goBack, t]);

  const renderBody = React.useCallback(() => {
    return (
      <Content>
        <TextInput
          placeholder={t("createTournament.namePlaceholder")}
          value={tournamentName}
          onChange={onTournamentNameChange}
        />

        <FieldTitle>{t("createTournament.heatCountTitle")}</FieldTitle>
        <FieldHint>{t("createTournament.heatCountHint")}</FieldHint>
        <TextInput value={heatCount} onChange={onHeatCountChange} />

        <FieldTitle>{t("createTournament.topPlayersTitle")}</FieldTitle>
        <FieldHint>{t("createTournament.topPlayersHint")}</FieldHint>
        <TextInput value={topPlayers} onChange={onTopPlayersChange} />

        {!gamesHaveRandomPoses && (
          <>
            <FieldTitle>{t("createTournament.heatPosesTitle")}</FieldTitle>
            <FieldHint>{t("createTournament.poseHint")}</FieldHint>
            {heatPoses.map((pose, index) => (
              <PoseContainer key={pose}>
                <PoseInput
                  roundNumber={index + 1}
                  value={pose}
                  poseOptions={heatPoseSelectOptions}
                  onValueChange={makeUpdateHeatPose(index)}
                  onDeletePress={makeDeleteHeatPose(index)}
                />
              </PoseContainer>
            ))}
            {heatPoses.length < gamePoseLimit && (
              <AccentButton onClick={handleAddHeatPose}>
                {t("poseInput.addPose")}
              </AccentButton>
            )}

            <FieldTitle>{t("createTournament.finalPosesTitle")}</FieldTitle>
            <FieldHint>{t("createTournament.poseHint")}</FieldHint>
            {finalPoses.map((pose, index) => (
              <PoseContainer key={pose}>
                <PoseInput
                  roundNumber={index + 1}
                  value={pose}
                  poseOptions={fianlPoseSelectOptions}
                  onValueChange={makeUpdateFinalPose(index)}
                  onDeletePress={makeDeleteFinalPose(index)}
                />
              </PoseContainer>
            ))}
            {finalPoses.length < gamePoseLimit && (
              <AccentButton onClick={handleAddFinalPose}>
                {t("poseInput.addPose")}
              </AccentButton>
            )}
          </>
        )}
      </Content>
    );
  }, [
    fianlPoseSelectOptions,
    finalPoses,
    gamePoseLimit,
    gamesHaveRandomPoses,
    handleAddFinalPose,
    handleAddHeatPose,
    heatCount,
    heatPoseSelectOptions,
    heatPoses,
    makeDeleteFinalPose,
    makeDeleteHeatPose,
    makeUpdateFinalPose,
    makeUpdateHeatPose,
    onHeatCountChange,
    onTopPlayersChange,
    onTournamentNameChange,
    t,
    topPlayers,
    tournamentName,
  ]);

  const renderFooter = React.useCallback(() => {
    return (
      <GameFooter>
        <Button onClick={handleCreate}>{t("createTournament.submit")}</Button>
      </GameFooter>
    );
  }, [handleCreate, t]);

  if (!isAdmin && !allowTournaments) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <GameScreen
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
      {isCreating && <LoadingModal text={t("createTournament.creating")} />}
    </>
  );
};
