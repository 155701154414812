import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "./Text";
import { CheckboxInput } from "./CheckboxInput";

export interface GameAdvancedSettingsFormProps {
  stillSnapshots: boolean;
  onStillSnapshotsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultToUserFacingCamera: boolean;
  onDefaultToUserFacingCameraChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  allowSwapCamera: boolean;
  onAllowSwapCameraChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowSnapshotWebcam: boolean;
  onAllowSnapshotWebcamChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  allowSnapshotUpload: boolean;
  onAllowSnapshotUploadChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  enableCameraTimer: boolean;
  onEnableCameraTimerChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  hideRoundCountdown: boolean;
  onHideRoundCountdownChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  allowJoinLate: boolean;
  onAllowJoinLateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowSpectators: boolean;
  onAllowSpectatorsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLargeGame: boolean;
}

const Section = styled.section`
  margin-bottom: 32px;
`;
const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;
const CheckboxText = styled(Text)`
  margin-left: 16px;
`;

export const GameAdvancedSettingsForm: React.FunctionComponent<GameAdvancedSettingsFormProps> = ({
  stillSnapshots,
  onStillSnapshotsChange,
  defaultToUserFacingCamera,
  onDefaultToUserFacingCameraChange,
  allowSwapCamera,
  onAllowSwapCameraChange,
  allowSnapshotWebcam,
  onAllowSnapshotWebcamChange,
  allowSnapshotUpload,
  onAllowSnapshotUploadChange,
  enableCameraTimer,
  onEnableCameraTimerChange,
  hideRoundCountdown,
  onHideRoundCountdownChange,
  allowJoinLate,
  onAllowJoinLateChange,
  allowSpectators,
  onAllowSpectatorsChange,
  isLargeGame,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={stillSnapshots}
            onChange={onStillSnapshotsChange}
          />
          <CheckboxText>{t("gameDetails.stillSnapshotsHintText")}</CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={defaultToUserFacingCamera}
            onChange={onDefaultToUserFacingCameraChange}
          />
          <CheckboxText>
            {t("gameDetails.defaultToUserFacingCameraHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={allowSwapCamera}
            onChange={onAllowSwapCameraChange}
          />
          <CheckboxText>
            {t("gameDetails.allowSwapCameraHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={allowSnapshotWebcam}
            onChange={onAllowSnapshotWebcamChange}
          />
          <CheckboxText>
            {t("gameDetails.allowSnapshotWebcamHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={allowSnapshotUpload}
            onChange={onAllowSnapshotUploadChange}
          />
          <CheckboxText>
            {t("gameDetails.allowSnapshotUploadHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={enableCameraTimer}
            onChange={onEnableCameraTimerChange}
          />
          <CheckboxText>
            {t("gameDetails.enableCameraTimerHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      <Section>
        <CheckboxContainer>
          <CheckboxInput
            checked={hideRoundCountdown}
            onChange={onHideRoundCountdownChange}
          />
          <CheckboxText>
            {t("gameDetails.hideRoundCountdownHintText")}
          </CheckboxText>
        </CheckboxContainer>
      </Section>

      {!isLargeGame && (
        <>
          <Section>
            <CheckboxContainer>
              <CheckboxInput
                checked={allowJoinLate}
                onChange={onAllowJoinLateChange}
              />
              <CheckboxText>
                {t("gameDetails.allowJoinLateHintText")}
              </CheckboxText>
            </CheckboxContainer>
          </Section>

          <Section>
            <CheckboxContainer>
              <CheckboxInput
                checked={allowSpectators}
                onChange={onAllowSpectatorsChange}
              />
              <CheckboxText>
                {t("gameDetails.allowSpectatorsHintText")}
              </CheckboxText>
            </CheckboxContainer>
          </Section>
        </>
      )}
    </>
  );
};
