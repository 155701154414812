import React from "react";
import styled from "styled-components/macro";
import { RoundButton } from "./RoundButton";
import { ImageButton } from "./ImageButton";
import { useBrand } from "../../data/brand";
import { GoogleStorageImage } from "./GoogleStorageImage";

export interface SnapshotButtonProps {
  snapshotProgress: number | undefined;
  isUploading: boolean;
  hasSnapshot: boolean;
  onSwapCamera: () => void;
  onRejectSnapshot: () => void;
  onAcceptSnapshot: () => void;
  onTakeSnapshot: (() => void) | undefined;
}

const LeftButton = styled.div`
  width: 64px;
`;
const CenterButton = styled.div`
  width: 82px;
  margin: 0 32px;
`;
const RightButton = styled.div`
  width: 64px;
`;

const DisabledButton = styled(RoundButton)`
  border-color: transparent;
  opacity: 0;
`;

const DisabledImageButton = styled(ImageButton)`
  opacity: 0;
`;

export const SnapshotButtons: React.FunctionComponent<SnapshotButtonProps> = ({
  snapshotProgress,
  isUploading,
  hasSnapshot,
  onSwapCamera,
  onRejectSnapshot,
  onAcceptSnapshot,
  onTakeSnapshot,
}) => {
  const {
    brandDetails: {
      allowSwapCamera,
      gameButtonImageCapture,
      gameButtonImageAccept,
      gameButtonImageReject,
      gameButtonImageSwapCamera,
    },
  } = useBrand();

  const onRejectPress = isUploading ? undefined : onRejectSnapshot;
  const onAcceptPress = isUploading ? undefined : onAcceptSnapshot;

  if (hasSnapshot) {
    return (
      <>
        <LeftButton>
          {gameButtonImageReject ? (
            <ImageButton id="snapshotButton-reject" onClick={onRejectPress}>
              <GoogleStorageImage src={gameButtonImageReject} />
            </ImageButton>
          ) : (
            <RoundButton
              id="snapshotButton-reject"
              buttonColor="error"
              onClick={onRejectPress}
            >
              <img src="/assets/crossBlack.svg" alt="Reject snapshot" />
            </RoundButton>
          )}
        </LeftButton>

        {onTakeSnapshot ? (
          <CenterButton>
            {gameButtonImageCapture ? (
              <DisabledImageButton id="snapshotButton-capture" large disabled>
                <GoogleStorageImage src={gameButtonImageCapture} />
              </DisabledImageButton>
            ) : (
              <DisabledButton
                id="snapshotButton-capture"
                large
                buttonColor="neutral"
                disabled
              >
                <img src="/assets/takeSnapshotBlack.svg" alt="" />
              </DisabledButton>
            )}
          </CenterButton>
        ) : (
          <CenterButton />
        )}

        <RightButton>
          {gameButtonImageAccept ? (
            <ImageButton id="snapshotButton-accept" onClick={onAcceptPress}>
              {isUploading ? (
                <i className="fas fa-spinner fa-pulse is-light" />
              ) : (
                <GoogleStorageImage src={gameButtonImageAccept} />
              )}
            </ImageButton>
          ) : (
            <RoundButton
              id="snapshotButton-accept"
              buttonColor="accent"
              onClick={onAcceptPress}
            >
              {isUploading ? (
                <i className="fas fa-spinner fa-pulse is-light" />
              ) : (
                <img src="/assets/tickBlack.svg" alt="Accept snapshot" />
              )}
            </RoundButton>
          )}
        </RightButton>
      </>
    );
  } else {
    const buttonProps = {
      id: "snapshotButton-capture",
      large: true,
      disabled: snapshotProgress !== undefined,
      onMouseDown: onTakeSnapshot,
      onPointerDown: onTakeSnapshot,
    };

    let swapCameraButton = null;
    if (allowSwapCamera) {
      swapCameraButton = gameButtonImageSwapCamera ? (
        <ImageButton id="snapshotButton-swapCamera" onClick={onSwapCamera}>
          <GoogleStorageImage src={gameButtonImageSwapCamera} />
        </ImageButton>
      ) : (
        <RoundButton id="snapshotButton-swapCamera" onClick={onSwapCamera}>
          <img src="/assets/swapCamera.svg" alt="Swap camera" />
        </RoundButton>
      );
    }

    return (
      <>
        <LeftButton />

        {onTakeSnapshot && (
          <CenterButton>
            {gameButtonImageCapture ? (
              <ImageButton {...buttonProps}>
                {snapshotProgress !== undefined ? (
                  <i className="fas fa-spinner fa-pulse is-light" />
                ) : (
                  <GoogleStorageImage src={gameButtonImageCapture} />
                )}
              </ImageButton>
            ) : (
              <RoundButton {...buttonProps}>
                {snapshotProgress !== undefined ? (
                  <i className="fas fa-spinner fa-pulse is-light" />
                ) : (
                  <img
                    src="/assets/takeSnapshotWhite.svg"
                    alt="Take snapshot"
                  />
                )}
              </RoundButton>
            )}
          </CenterButton>
        )}

        <RightButton>{swapCameraButton}</RightButton>
      </>
    );
  }
};
