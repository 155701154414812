import React from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { GameRoundResults } from "./GameRoundResults";
import {
  useGameGroupIds,
  useGamePlayers,
  useGamePoses,
  useIsGameHost,
} from "../../../data/game";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { useAuth } from "../../../data/auth";
import { GalleryHeader } from "../GalleryHeader";
import { useBrand } from "../../../data/brand";
import { GoogleStorageImage } from "../../components/GoogleStorageImage";
import { Markdown } from "../../components/Markdown";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const BrandLink = styled(Link)`
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SmallLogo = styled(GoogleStorageImage)`
  width: 100%;
  max-width: 100px;
  max-height: 100px;
`;

const MarkdownContentTop = styled(Markdown)`
  margin: 32px 0;
`;
const MarkdownContentBottom = styled(Markdown)`
  margin-top: 32px;
`;

const ReportLink = styled.a`
  text-align: center;
  color: ${(props) => props.theme.colors.backgroundOverlay};
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const GameGallery = () => {
  const { t } = useTranslation();
  const { uid, isAdmin } = useAuth();
  const gameId = useGameIdParam();
  const {
    brandId,
    brandDetails: {
      name: brandName,
      logo: brandLogo,
      hideNamesInGalleries,
      landingPageContent,
    },
  } = useBrand();
  const isGameHost = useIsGameHost({ brandId, gameId, uid });

  const groupIds = useGameGroupIds({ brandId, gameId });

  const allGamePoses = useGamePoses({ brandId, gameId });
  const playedGamePoses = React.useMemo(
    () =>
      allGamePoses
        .filter((p) => p.played !== undefined)
        .sort((p1, p2) => {
          if (p1.played === undefined) {
            return -1;
          } else if (p2.played === undefined) {
            return 1;
          } else {
            return p1.played - p2.played;
          }
        }),
    [allGamePoses]
  );

  const hasLoaded = React.useMemo(
    () => playedGamePoses.length > 0 && groupIds !== undefined,
    [groupIds, playedGamePoses.length]
  );

  const gamePlayers = useGamePlayers({
    brandId,
    gameId: hasLoaded ? gameId : undefined,
  });

  // Games with multiple groups do not currently support galleries
  if (groupIds && groupIds.length > 1) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      {hasLoaded && groupIds !== undefined && (
        <>
          <GalleryHeader
            brandId={brandId}
            gameId={gameId}
            hideGameName={hideNamesInGalleries}
          />

          {landingPageContent?.gallery?.gridTop && (
            <MarkdownContentTop source={landingPageContent.gallery.gridTop} />
          )}

          {playedGamePoses.map((pose) => (
            <GameRoundResults
              key={pose.id}
              gameId={gameId}
              groupId={groupIds.length > 0 ? groupIds[0] : undefined}
              pose={pose}
              gamePlayers={gamePlayers}
              canDelete={isGameHost || isAdmin}
              hideNames={hideNamesInGalleries}
            />
          ))}

          {landingPageContent?.gallery?.gridBottom && (
            <MarkdownContentBottom
              source={landingPageContent.gallery.gridBottom}
            />
          )}

          <BrandLink to="/">
            <SmallLogo src={brandLogo || undefined} alt={brandName} />
          </BrandLink>

          <ReportLink href="mailto:report@poseparty.com">
            {t("gallery.report")}
          </ReportLink>
        </>
      )}
    </Container>
  );
};
