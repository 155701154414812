/* eslint-disable no-nested-ternary, global-require */
import React from "react";
import {
  GameSnapshot,
  GameStatus,
  GameSetupState,
  WebcamSnapshot,
} from "@pose-party/types";
import { useDebounce } from "use-debounce";
import { Redirect } from "react-router-dom";
import { GameContext } from "./GameContext";
import {
  useGameState,
  usePrimaryGameHost,
  useIsAdditionalGameHosts,
  useGameGalleryPin,
  useGameLogo,
  useGameName,
  useGamePose,
  useGameCurrentPlayer,
  useGamePlayers,
  useGameSpectatorCount,
  usePoseSpectatorScoredCount,
  useGamePoseSnapshots,
  GamePlayersParams,
  useGameSetupStateCounters,
  ensureAllPlayersAreInGroup,
} from "../../data/game";
import firebase from "../../data/firebase";
import { logger } from "../../logger";
import { useGalleryPinStorage } from "../useGalleryPinStorage";
import { GameData, GameProviderProps, RawGameData } from "./types";
import { useAuth } from "../../data/auth";
import { useBrand } from "../../data/brand";
import { useGameIdParam } from "../useGameIdParam";
import { FullScreenLoading } from "../../ui/components/FullScreenLoading";
import { useFinalGameData } from "../useFinalGameData";

const useValidGameData = (input: RawGameData): GameData | undefined => {
  return React.useMemo((): GameData | undefined => {
    const {
      state,
      gamePoseSnapshots,
      otherPlayerPoseSnapshots,
      ...rest
    } = input;
    if (state) {
      return {
        state,
        gamePoseSnapshots: gamePoseSnapshots || [],
        otherPlayerPoseSnapshots: otherPlayerPoseSnapshots || [],
        ...rest,
      };
    } else {
      return undefined;
    }
  }, [input]);
};

export const GameProvider: React.FunctionComponent<GameProviderProps> = React.memo(
  ({ children }) => {
    const { uid } = useAuth();
    const {
      brandId,
      brandDetails: { allowJoinLate, allowSpectators },
      hasLoadedBrandDetails,
      setGameDetails,
    } = useBrand();
    const gameId = useGameIdParam();

    /**
     * Game State
     */

    // Basic game state
    const gameState = useGameState({ brandId, gameId });

    // Check if we're the game host
    const primaryGameHostId = usePrimaryGameHost({ brandId, gameId });
    const isAdditionalHostUser = useIsAdditionalGameHosts({
      brandId,
      gameId,
      uid,
    });
    const isHost = React.useMemo(() => {
      const isHostUser = primaryGameHostId === uid || isAdditionalHostUser;
      if (isHostUser && gameState && gameState.status !== GameStatus.Setup) {
        return uid === gameState.mainHost;
      }

      return isHostUser;
    }, [gameState, isAdditionalHostUser, primaryGameHostId, uid]);

    // Set the game host id when it changes. This will ensure the game brand details match the host's
    // overriden brand details
    const [brandGameDetailsSet, setBrandGameDetailsSet] = React.useState(false);
    React.useEffect(() => {
      if (primaryGameHostId) {
        setGameDetails({ gameId, hostId: primaryGameHostId });
        setBrandGameDetailsSet(true);
      } else {
        setGameDetails(undefined);
      }

      return () => setGameDetails(undefined);
    }, [gameId, primaryGameHostId, setGameDetails]);

    // Check if we're the game host
    const galleryPin = useGameGalleryPin({ brandId, gameId });
    const [, storeGalleryPin] = useGalleryPinStorage(gameId);

    // Get the game logo
    const logo = useGameLogo({ brandId, gameId });

    // Get the game name
    const name = useGameName({ brandId, gameId });

    // Find the current game pose
    const currentPose = useGamePose({
      brandId,
      gameId: gameState ? gameId : undefined,
      poseId: gameState?.currentPoseId,
    });
    React.useEffect(() => {
      logger.info("Current pose updated", currentPose);
    }, [currentPose]);

    /**
     * Current Player
     */

    // Only when we have a valid game
    const currentPlayer = useGameCurrentPlayer({
      brandId,
      gameId: gameState ? gameId : undefined,
      uid,
    });

    /**
     * Locked Game Spectator
     */

    const isLockedGameSpectator =
      gameState !== undefined && !!gameState?.locked && currentPlayer === null;

    /**
     * Locked Game Spectator
     */

    const [isGameSpectator, setIsGameSpectator] = React.useState(false);

    // Check if we're a spectator
    const isSpectator = isLockedGameSpectator || isGameSpectator;
    React.useEffect(() => {
      // If we're a spectator, update the database so we can count them
      if (isSpectator) {
        try {
          firebase
            .database()
            .ref(`/brands/${brandId}/games/${gameId}/players/${uid}`)
            .set(null);
          firebase
            .database()
            .ref(`/brands/${brandId}/games/${gameId}/spectators/${uid}/date`)
            .set(Date.now());
        } catch (e) {
          // Ignore this error
        }
      }
    }, [brandId, isSpectator, gameId, uid]);

    const spectatorCount = useGameSpectatorCount({ brandId, gameId });

    const poseSpectatorScoredCount = usePoseSpectatorScoredCount({
      brandId,
      gameId,
      poseId: currentPose?.id,
    });

    /**
     * Players & Scores
     */

    // If we're spectating, then we just want to load poses from the default and only group
    const groupId = isSpectator ? "0" : currentPlayer?.groupId;

    // Filter out non-setup players when the game is in progress
    const gamePlayersParams: GamePlayersParams = {
      enable: false,
      brandId,
      gameId,
      groupId,
    };
    // Allow the players to load at any time if we're in a small game
    if (gameState && !gameState.large) {
      gamePlayersParams.enable = true;
      // Load the actual player list for small games. This allows all players to see the status of
      // each player and makes the game a bit more interactive
      gamePlayersParams.groupId = undefined;
      // Once we have done past the setup step, only show full setup players
      gamePlayersParams.onlySetup = gameState.status !== GameStatus.Setup;
    }
    // Allow the players to load after setup if we're in a large game
    if (gameState && gameState.large && gameState.status !== GameStatus.Setup) {
      gamePlayersParams.enable = true;
    }
    const gamePlayers = useGamePlayers(gamePlayersParams);

    // Get the total player count
    const setupStatusCounters = useGameSetupStateCounters({
      enabled: gameState?.status !== GameStatus.Setup,
      brandId,
      gameId,
    });
    const totalPlayerCount = setupStatusCounters
      ? setupStatusCounters[GameSetupState.Done]
      : 0;

    /**
     * Snapshots
     */

    const gamePoseSnapshots = useGamePoseSnapshots({
      brandId,
      gameId,
      groupId,
      poseId: gameState?.currentPoseId,
    });

    // Find the players snapshot
    const currentPlayerPoseSnapshot = React.useMemo(
      () => gamePoseSnapshots?.find((p) => p.uid === uid),
      [gamePoseSnapshots, uid]
    );
    React.useEffect(() => {
      logger.info(
        "Current player pose snapshot updated",
        currentPlayerPoseSnapshot
      );
    }, [currentPlayerPoseSnapshot]);

    // Find the other players snapshots, for scoring
    const otherPlayerPoseSnapshots = React.useMemo(
      () => gamePoseSnapshots?.filter((p) => p.uid !== uid),
      [gamePoseSnapshots, uid]
    );
    React.useEffect(() => {
      logger.info(
        "Other player pose snapshots updated",
        otherPlayerPoseSnapshots
      );
    }, [otherPlayerPoseSnapshots]);

    /**
     * Late for game if the game is not full, has started, and they're not setup
     * You also can't be late for a large game as otherwise you won't have been assigned a group
     * This is also only enabled for certain brands as a brand setting
     */

    const isLate =
      !isLockedGameSpectator &&
      !!gameState &&
      !gameState.large &&
      gameState.status !== GameStatus.Setup &&
      currentPlayer?.setupState !== GameSetupState.Done;

    // Work out the game object, but also avoid too many updates by debouncing
    const rawGameData: RawGameData = React.useMemo(
      () => ({
        currentPlayer,
        currentPlayerPoseSnapshot,
        currentPose,
        gamePoseSnapshots,
        isHost,
        isLate,
        isSpectator,
        logo,
        name,
        otherPlayerPoseSnapshots,
        players: gamePlayers,
        poseSpectatorScoredCount,
        primaryGameHostId,
        spectatorCount,
        totalPlayerCount,
        state: gameState,
      }),
      [
        currentPlayer,
        currentPlayerPoseSnapshot,
        currentPose,
        gamePlayers,
        gamePoseSnapshots,
        gameState,
        isHost,
        isLate,
        isSpectator,
        logo,
        name,
        otherPlayerPoseSnapshots,
        poseSpectatorScoredCount,
        primaryGameHostId,
        spectatorCount,
        totalPlayerCount,
      ]
    );
    const [debouncedRawGameData] = useDebounce(rawGameData, 250, {
      maxWait: 250,
    });
    const gameData = useValidGameData(debouncedRawGameData);
    // If we're going to delete the game data when it's finished, we want to hold onto a copy
    // locally so we can continue to show the final results
    const finalData = useFinalGameData(gameData);
    const data = gameData || finalData;

    // If the player is joining late or when the game is full, and the game allows for spectators
    // and not for late joiners, then automatically set them as a spectator
    React.useEffect(() => {
      if (
        brandGameDetailsSet &&
        hasLoadedBrandDetails &&
        !isGameSpectator &&
        allowSpectators &&
        !allowJoinLate &&
        isLate
      ) {
        setIsGameSpectator(true);
      }
    }, [
      allowJoinLate,
      allowSpectators,
      brandGameDetailsSet,
      hasLoadedBrandDetails,
      isGameSpectator,
      isLate,
    ]);

    /**
     * Methods
     */

    // Method to set the current player name
    const setPlayerName = React.useCallback(
      async (playerName: string) => {
        if (!uid || !gameState || isSpectator) {
          return;
        }

        return firebase
          .database()
          .ref(`/brands/${brandId}/games/${gameId}/players/${uid}/name`)
          .set(playerName);
      },
      [brandId, gameId, gameState, isSpectator, uid]
    );

    // Method to set the current player profile picture
    const setPlayerProfilePicture = React.useCallback(
      async (profilePicture: WebcamSnapshot) => {
        if (!uid || !gameState || isSpectator) {
          return;
        }

        const snapshotRef = firebase
          .storage()
          .ref(`/brands/${brandId}/profilePictures/${gameId}/${uid}`);
        const snapshotFramePromises = profilePicture.map(
          async (snapshotFrame, index): Promise<string> => {
            const snapshotFrameRef = snapshotRef.child(`${index}.jpg`);
            const uploadSnapshot = await snapshotFrameRef.putString(
              snapshotFrame,
              "data_url"
            );
            return uploadSnapshot.ref.toString();
          }
        );

        const uploadedSnapshotFrames = await Promise.all(snapshotFramePromises);

        await firebase
          .database()
          .ref(
            `/brands/${brandId}/games/${gameId}/players/${uid}/profilePicture`
          )
          .set(uploadedSnapshotFrames);

        return uploadedSnapshotFrames;
      },
      [brandId, gameId, gameState, isSpectator, uid]
    );

    // Method to set the current player setup state
    const setPlayerSetupState = React.useCallback(
      async (setupState: GameSetupState) => {
        if (
          !uid ||
          !gameState ||
          isSpectator ||
          currentPlayer?.setupState === setupState
        ) {
          return;
        }

        if (setupState === GameSetupState.Done && !!galleryPin) {
          storeGalleryPin(galleryPin);
        }

        const value = await firebase
          .database()
          .ref(`/brands/${brandId}/games/${gameId}/players/${uid}/setupState`)
          .set(setupState);

        if (allowJoinLate && isLate && setupState === GameSetupState.Done) {
          try {
            ensureAllPlayersAreInGroup({ brandId, gameId });
          } catch (e) {
            logger.warn(
              "Error ensuring all players were in group after a late start"
            );
          }
        }

        return value;
      },
      [
        uid,
        gameState,
        isSpectator,
        currentPlayer?.setupState,
        galleryPin,
        brandId,
        gameId,
        allowJoinLate,
        isLate,
        storeGalleryPin,
      ]
    );

    // Method to set the current player name
    const setPlayerSnapshot = React.useCallback(
      async (snapshot: WebcamSnapshot) => {
        if (!uid || !gameId || !currentPose || !currentPlayer?.groupId) {
          return;
        }

        const snapshotRef = firebase
          .storage()
          .ref(
            `/brands/${brandId}/snapshots/${gameId}/${currentPose.id}/${uid}`
          );
        const snapshotFramePromises = snapshot.map(
          async (snapshotFrame, index): Promise<string> => {
            const snapshotFrameRef = snapshotRef.child(`${index}.jpg`);
            const uploadSnapshot = await snapshotFrameRef.putString(
              snapshotFrame,
              "data_url"
            );
            return uploadSnapshot.ref.toString();
          }
        );

        const uploadedSnapshotFrames = await Promise.all(snapshotFramePromises);

        await firebase
          .database()
          .ref(
            `/brands/${brandId}/games/${gameId}/groups/${currentPlayer.groupId}/snapshots/${currentPose.id}`
          )
          .push({
            uid,
            poseId: currentPose.id,
            snapshot: uploadedSnapshotFrames,
          });

        // For games where the profile picture step is skipped, make the first captured pose the profile picture
        if (!currentPlayer.profilePicture) {
          setPlayerProfilePicture(snapshot);
        }
      },
      [
        brandId,
        currentPlayer,
        currentPose,
        gameId,
        setPlayerProfilePicture,
        uid,
      ]
    );

    // Method to set the current player name
    const setSnapshotVote = React.useCallback(
      async (snapshot: GameSnapshot, score: number) => {
        if (!uid || !gameId || !currentPose || !groupId) {
          return;
        }

        // Work out an id to avoid duplicate votes
        const id = `${uid}__${snapshot.uid}`;
        await firebase
          .database()
          .ref(
            `/brands/${brandId}/games/${gameId}/groups/${groupId}/votes/${currentPose.id}/${id}`
          )
          .set({
            uid,
            poseId: currentPose.id,
            playerId: snapshot.uid,
            score,
          });
      },
      [uid, gameId, currentPose, groupId, brandId]
    );

    // Method to set the current player name
    const setScordingState = React.useCallback(
      async (state: boolean) => {
        if (!uid || !currentPose) {
          return;
        }

        const databaseCollection = isSpectator ? "spectators" : "players";

        return firebase
          .database()
          .ref(
            `/brands/${brandId}/games/${gameId}/${databaseCollection}/${uid}/scored`
          )
          .set(state ? currentPose.id : null);
      },
      [brandId, currentPose, gameId, isSpectator, uid]
    );

    // Method to set ourselves as a game spectator
    const setAsSpectator = React.useCallback(async () => {
      if (allowSpectators && !gameState?.large && !isGameSpectator) {
        setIsGameSpectator(true);
      }
    }, [allowSpectators, gameState, isGameSpectator]);

    return (
      <GameContext.Provider
        value={{
          // @ts-ignore
          data,
          setPlayerName,
          setPlayerProfilePicture,
          setPlayerSetupState,
          setPlayerSnapshot,
          setSnapshotVote,
          setScordingState,
          setAsSpectator,
        }}
      >
        {!data && gameState === null && <Redirect to="/" />}
        {data && children}
        {!data && <FullScreenLoading />}
      </GameContext.Provider>
    );
  }
);
