import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useGameGroupIds, useGameSlideshow } from "../../data/game";
import { RegularTitle } from "../components/Title";
import { useGameIdParam } from "../../hooks/useGameIdParam";
import { GoogleStorageVideo } from "../components/GoogleStorageVideo";
import { AspectCard } from "../components/AspectCard";
import { TextInput } from "../components/TextInput";
import { Button } from "../components/Button";
import { useFormInput } from "../../hooks/useFormInput";
import { GalleryHeader } from "../Gallery/GalleryHeader";
import { useBrand } from "../../data/brand";
import { logger } from "../../logger";
import { Markdown } from "../components/Markdown";
import { api } from "../../data/api";
import { useAuth } from "../../data/auth";
import loadingBlack from "../../assets/loadingBlack.svg";

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${(props) => props.theme.colors.galleryBackground};
  color: ${(props) => props.theme.colors.galleryBackgroundOverlay};
  display: flex;
  flex-direction: column;
`;

const SnapshotContainer = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: auto;
`;

const MarkdownContentTop = styled(Markdown)`
  margin: 32px 0;
`;
const MarkdownContentBottom = styled(Markdown)`
  margin-top: 32px;
`;

const ShareForm = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: 32px auto;
`;
const ShareTitle = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 16px;
`;
const EmailTextInput = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};
`;
const ShareButton = styled(Button)`
  margin-top: 16px;
`;
const DownloadButtonContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 16px auto 0 auto;
`;
const DownloadButton = styled.a`
  border-color: transparent;
  font-family: "Nunito Sans";
  font-weight: 700;
  border-radius: 10px;
  height: 48px;
  min-height: 48px;
  display: block;
  padding: 12px;
  margin: 0 16px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.secondaryBrand};
  color: ${(props) => props.theme.colors.secondaryBrandOverlay};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.secondaryBrand};
    color: ${(props) => props.theme.colors.secondaryBrandOverlay};
    opacity: 0.7;
  }
`;

const GeneratingIcon = styled.img`
  margin-top: 32px;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
  align-self: center;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
const GeneratingTitle = styled(RegularTitle)`
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

export const GameSlideshow = () => {
  const { t } = useTranslation();
  const gameId = useGameIdParam();
  const {
    brandId,
    brandDetails: { landingPageContent, hideNamesInGalleries },
  } = useBrand();

  const groupIds = useGameGroupIds({ brandId, gameId });

  const googleStorageSlideshow = useGameSlideshow({ brandId, gameId });

  const [email, handleEmailChange, setEmail] = useFormInput();
  const { userDetails } = useAuth();
  React.useEffect(() => {
    if (userDetails?.email) {
      setEmail(userDetails.email);
    }
  }, [setEmail, userDetails]);

  const [isSharing, setIsSharing] = React.useState(false);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!email.trim()) {
        return;
      }

      setIsSharing(true);
      try {
        await api.post("shareSlideshow", {
          email: email.trim(),
          brandId,
          gameId,
        });

        setEmail("");

        alert(
          "Email sent! Check your email for the video and share from there"
        );
      } catch (e) {
        logger.warn("Error while sharing", e);
        alert(
          "Error while sharing. Please check your email address and try again."
        );
      }
      setIsSharing(false);
    },
    [brandId, email, gameId, setEmail]
  );

  // Game with multiple groups are not supported
  if (groupIds !== undefined && groupIds.length > 1) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <GalleryHeader
        brandId={brandId}
        gameId={gameId}
        hideGameName={hideNamesInGalleries}
      />

      {landingPageContent?.gallery?.shareTop && (
        <MarkdownContentTop source={landingPageContent.gallery.shareTop} />
      )}

      {googleStorageSlideshow ? (
        <>
          <SnapshotContainer>
            <AspectCard aspectRatio={1} style={{ borderRadius: "5%" }}>
              <GoogleStorageVideo
                src={googleStorageSlideshow}
                autoPlay
                controls={false}
                muted
                loop
              />
            </AspectCard>
          </SnapshotContainer>

          <DownloadButtonContainer>
            <DownloadButton
              href={`/download?gs=${googleStorageSlideshow}`}
              download="slideshow.mp4"
            >
              {t("slideshow.downloadVideo")}
            </DownloadButton>
          </DownloadButtonContainer>

          {landingPageContent?.gallery?.shareBottom && (
            <MarkdownContentBottom
              source={landingPageContent.gallery.shareBottom}
            />
          )}

          <ShareForm>
            <form onSubmit={handleSubmit}>
              <ShareTitle>{t("slideshow.formTitle")}</ShareTitle>
              <EmailTextInput
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder={t("slideshow.email")}
              />
              <ShareButton
                className={`button ${isSharing ? "is-loading" : ""}`}
                type="submit"
              >
                {t("slideshow.submit")}
              </ShareButton>
            </form>
          </ShareForm>
        </>
      ) : (
        <>
          <GeneratingIcon src={loadingBlack} alt="" />
          <GeneratingTitle>Generating slideshow...</GeneratingTitle>
        </>
      )}
    </Container>
  );
};
