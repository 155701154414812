import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { GameScreen } from "../../../components/GameScreen";
import { RegularTitle } from "../../../components/Title";
import { ScoreHint } from "../../../components/ScoreHint";

export interface ScoreHintInterstitialProps {
  duration: number;
}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(RegularTitle)`
  text-align: center;
  padding: 0 32px;
`;

const Hints = styled(motion.div)<{ animationStarted: boolean }>`
  position: relative;
  margin: 32px 0;
  width: 100%;
  height: 360px;
  opacity: ${(props) => (props.animationStarted ? 1 : 0)};
`;

const Hint = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScoreHintInterstitial: React.FunctionComponent<ScoreHintInterstitialProps> = React.memo(
  ({ duration }) => {
    const { t } = useTranslation();

    // We want a 0.25 second buffer at each end
    const hintBuffer = 0.25;
    const endBuffer = 1;
    const overallHintDuration = duration / 1000 - hintBuffer - endBuffer;
    // The duration of each hint
    const hintDuration = overallHintDuration / 3;

    const [animationStarted, setAnimationStarted] = React.useState(false);
    const onAnimationStart = React.useCallback(() => {
      setAnimationStarted(true);
    }, []);

    const renderBody = React.useCallback(() => {
      // The keyframes for the animation split into fifths
      const animationKeyframes = {
        scale: [0.9, 0.98, 1, 1.02, 1.1],
        opacity: [0, 1, 1, 1, 0],
      };

      return (
        <MainContainer id="gameScore">
          <Title>{t("scoreStartInterstitial.title")}</Title>

          <Hints animationStarted={animationStarted}>
            <Hint
              animate={animationKeyframes}
              onAnimationStart={onAnimationStart}
              transition={{
                duration: hintDuration,
                ease: "linear",
                delay: hintBuffer,
              }}
            >
              <ScoreHint type="dislike" />
            </Hint>

            <Hint
              animate={animationKeyframes}
              transition={{
                duration: hintDuration,
                ease: "linear",
                delay: hintBuffer + hintDuration,
              }}
            >
              <ScoreHint type="like" />
            </Hint>

            <Hint
              animate={animationKeyframes}
              transition={{
                duration: hintDuration,
                ease: "linear",
                delay: hintBuffer + hintDuration * 2,
              }}
            >
              <ScoreHint type="superlike" />
            </Hint>
          </Hints>
        </MainContainer>
      );
    }, [animationStarted, hintDuration, onAnimationStart, t]);

    return <GameScreen renderBody={renderBody} />;
  }
);
