import React from "react";
import { useTranslation } from "react-i18next";
// import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";
import { Text } from "../../components/Text";
import { RegularTitle } from "../../components/Title";

export const HostGameFinished: React.FunctionComponent = React.memo(() => {
  const { playerCount } = useHostGame();
  const { t } = useTranslation();

  return (
    <>
      <RegularTitle>{t("hostGameUI.finished.title")}</RegularTitle>
      {!!playerCount && (
        <Text>{t("hostGameUI.finished.playedCount", { playerCount })}</Text>
      )}
    </>
  );
});
