import React from "react";
import _ from "lodash";
import { useBrand } from "../data/brand";

interface PoseWithTiming {
  prompt: string;
  timing: number;
}

interface PoseWithOptionalTiming {
  prompt: string;
  timing: number | undefined;
}

export const useGamePoseOptions = (): [
  string[],
  number[],
  string[],
  (index: number) => (newValue: string | undefined) => void,
  (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  (index: number) => () => void,
  () => void,
  (poses: PoseWithOptionalTiming[]) => void
] => {
  const {
    brandDetails: {
      gamePoseOptions,
      gamesHaveRandomPoses,
      gamePoseLimit,
      timingPoseRoundLength,
    },
  } = useBrand();
  const timingPoseRoundLengthSeconds = timingPoseRoundLength / 1000;

  const shuffledGamePoseOptions = React.useMemo(
    () => _.shuffle(gamePoseOptions),
    [gamePoseOptions]
  );

  const [posesWithTiming, setPosesWithTiming] = React.useState<
    PoseWithTiming[]
  >(() => {
    const prompts = shuffledGamePoseOptions.slice(
      0,
      Math.min(
        gamesHaveRandomPoses ? gamePoseLimit : Math.min(gamePoseLimit, 3),
        shuffledGamePoseOptions.length
      )
    );
    const timings = prompts.map(() => timingPoseRoundLengthSeconds);
    return _.zipWith(prompts, timings, (prompt, timing) => ({
      prompt,
      timing,
    }));
  });
  const setInitialPoses = React.useCallback(
    (poses: PoseWithOptionalTiming[]) => {
      setPosesWithTiming(
        poses.map((p) => ({
          ...p,
          timing:
            typeof p.timing === "number"
              ? p.timing / 1000
              : timingPoseRoundLengthSeconds,
        }))
      );
    },
    [timingPoseRoundLengthSeconds]
  );

  const poses = React.useMemo(() => posesWithTiming.map((p) => p.prompt), [
    posesWithTiming,
  ]);
  const poseTiming = React.useMemo(() => posesWithTiming.map((p) => p.timing), [
    posesWithTiming,
  ]);

  const makeUpdatePose = React.useCallback(
    (index: number) => (newValue: string | undefined) => {
      setPosesWithTiming((prev) =>
        prev.map((previousValue, poseIndex) => {
          if (newValue && poseIndex === index) {
            return {
              ...previousValue,
              prompt: newValue,
            };
          } else {
            return previousValue;
          }
        })
      );
    },
    []
  );

  const makeUpdatePoseTiming = React.useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPosesWithTiming((prev) =>
        prev.map((previousValue, poseIndex) => {
          if (poseIndex === index) {
            let newTiming = 0;
            if (event.target.value.length !== 0) {
              newTiming =
                parseInt(event.target.value, 10) || previousValue.timing;
            }
            return {
              ...previousValue,
              timing: newTiming,
            };
          } else {
            return previousValue;
          }
        })
      );
    },
    []
  );

  const makeDeletePose = React.useCallback(
    (index: number) => () => {
      setPosesWithTiming((prev) =>
        prev.filter((_value, poseIndex) => poseIndex !== index)
      );
    },
    []
  );

  const handleAddPose = React.useCallback(() => {
    setPosesWithTiming((prev) => {
      // Pick the next default pose, looping through if we've run out of defaults
      const nextIndex = prev.length % shuffledGamePoseOptions.length;
      // Add the new pose
      return [
        ...prev,
        {
          prompt: shuffledGamePoseOptions[nextIndex],
          timing: timingPoseRoundLengthSeconds,
        },
      ];
    });
  }, [shuffledGamePoseOptions, timingPoseRoundLengthSeconds]);

  return [
    poses,
    poseTiming,
    shuffledGamePoseOptions,
    makeUpdatePose,
    makeUpdatePoseTiming,
    makeDeletePose,
    handleAddPose,
    setInitialPoses,
  ];
};
