import { api } from "../api";

/**
 * API call to create a new in person game
 */
export const newInPersonGame = async (params: {
  brandId: string;
  hostEmail: string;
}) => {
  return api.post("newInPersonGame", params);
};
