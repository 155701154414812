import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";

const CounterTable = styled.table`
  width: 100%;
  max-width: 500px;
  margin: 16px 0;
`;
const StatusRow = styled.tr`
  height: 30px;
  padding-bottom: 4px;
`;
const Count = styled.td`
  width: 50%;
  text-align: right;
`;

export const HostGamePoseCounter: React.FunctionComponent = React.memo(() => {
  const { playerCount, poseCompletedCount } = useHostGame();
  const { t } = useTranslation();

  const completed = poseCompletedCount || 0;
  const total = playerCount || 0;

  return (
    <CounterTable>
      <StatusRow>
        <td>{t("hostGameUI.playerCount")}</td>
        <Count>{playerCount || 0}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.playersCompleted")}</td>
        <Count>{completed}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.playersRemaining")}</td>
        <Count>{total - completed}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.playersPercentage")}</td>
        <Count>{total === 0 ? 0 : Math.ceil((completed / total) * 100)}%</Count>
      </StatusRow>
    </CounterTable>
  );
});
