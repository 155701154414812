import React from "react";
import styled from "styled-components/macro";
import { SideMenu } from "./SideMenu";
import { AppContainer } from "./AppContainer";
import { useBrand } from "../../data/brand";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";
import { MarkdownContentModal } from "./MarkdownContentModal";

export interface ScreenAreaRenderProps {
  toggleMenu: () => void;
}

export interface GameScreenProps {
  id?: string;
  logo?: string | null | undefined;
  helpContent?: string | undefined;
  renderHeader?: (props: ScreenAreaRenderProps) => React.ReactNode;
  renderBody: (props: ScreenAreaRenderProps) => React.ReactNode;
  renderFooter?: (props: ScreenAreaRenderProps) => React.ReactNode;
  renderButtons?: (props: ScreenAreaRenderProps) => React.ReactNode;
}

interface BackgroundImageProps {
  backgroundImageUrl: string | undefined;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div<BackgroundImageProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${(props) =>
    props.theme.settings.headerShadow
      ? "0px 2px 15px rgba(0, 0, 0, 0.2)"
      : undefined};
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.headerBackground}`
      : props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
`;

const BodyContainer = styled.div<BackgroundImageProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.background}`
      : props.theme.colors.background};
  color: ${(props) => props.theme.colors.backgroundOverlay};

  & a {
    color: ${(props) => props.theme.colors.backgroundOverlay};
  }
`;

const BaseFooterContainer = styled.div<BackgroundImageProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${(props) =>
    props.theme.settings.footerShadow
      ? "0px -2px 15px rgba(0, 0, 0, 0.2)"
      : undefined};
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.footerBackground}`
      : props.theme.colors.footerBackground};
  color: ${(props) => props.theme.colors.footerBackgroundOverlay};
`;
const FooterContainer = styled(BaseFooterContainer)`
  min-height: 80px;
`;
const EmptyFooterContainer = styled(BaseFooterContainer)`
  min-height: 59px;
`;

export const GameScreen: React.FunctionComponent<GameScreenProps> = ({
  id,
  logo,
  helpContent,
  renderHeader,
  renderBody,
  renderFooter,
  renderButtons,
}) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = React.useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);
  const areaProps = React.useMemo(() => ({ toggleMenu }), [toggleMenu]);

  const { brandDetails } = useBrand();
  const headerBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.headerBackgroundImage
  );
  const contentBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.contentBackgroundImage
  );
  const footerBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.footerBackgroundImage
  );

  const renderedHeader = renderHeader?.(areaProps);
  const renderedBody = renderBody(areaProps);
  const renderedFooter = renderFooter?.(areaProps);
  const renderedButtons = renderButtons?.(areaProps);

  return (
    <AppContainer>
      <Container id={id}>
        {!!renderedHeader && (
          <HeaderContainer backgroundImageUrl={headerBackgroundImageUrl}>
            {renderedHeader}
          </HeaderContainer>
        )}
        <BodyContainer backgroundImageUrl={contentBackgroundImageUrl}>
          {renderedBody}
        </BodyContainer>
        {!!renderedFooter && (
          <FooterContainer backgroundImageUrl={footerBackgroundImageUrl}>
            {renderedFooter}
          </FooterContainer>
        )}
        {!!renderedButtons && (
          <>
            {!renderedFooter && (
              <EmptyFooterContainer
                backgroundImageUrl={footerBackgroundImageUrl}
              />
            )}
            <div
              style={{
                position: "absolute",
                left: "0px",
                right: "0px",
                bottom: renderedFooter ? "42px" : "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "86px",
              }}
            >
              {renderedButtons}
            </div>
          </>
        )}
        <SideMenu isOpen={isMenuOpen} logo={logo} onClosePress={toggleMenu} />
      </Container>
      <MarkdownContentModal content={helpContent} />
    </AppContainer>
  );
};
