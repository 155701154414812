import React from "react";
import styled from "styled-components";
import { CheckboxInput } from "./CheckboxInput";
import { TermsText } from "./TermsText";

export interface TermsCheckboxProps
  extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
}

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & p {
    margin-left: 16px;
  }
`;

export const TermsCheckbox: React.FunctionComponent<TermsCheckboxProps> = (
  props
) => {
  return (
    <CheckboxLabel>
      <CheckboxInput className="termsCheckbox" {...props} />
      <TermsText />
    </CheckboxLabel>
  );
};
