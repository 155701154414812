import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { AuthLinks } from "./AuthLinks";
import { JoinGameForm } from "./JoinGameForm";
import { UserAccount } from "./UserAccount";
import { GameScreen, ScreenAreaRenderProps } from "../components/GameScreen";
import { GameHeader } from "../components/GameHeader";
import { Markdown } from "../components/Markdown";
import { MenuToggle } from "../components/MenuToggle";
import { BrandLogo } from "../components/BrandLogo";
import { useBrand } from "../../data/brand";
import { useAuth } from "../../data/auth";

const Container = styled.div`
  overflow-y: auto;
`;

const Hero = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const HeroLogo = styled(BrandLogo)`
  max-width: 60%;
  max-height: 120px;
`;

const ContentContainer = styled.div`
  padding: 25px 16px 0 16px;
  display: flex;
  flex-direction: column;
`;
const MarketingContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`;
const TopMarketingContainer = styled(MarketingContainer)`
  padding-top: 32px;
`;
const MiddleMarketingContainer = styled(MarketingContainer)`
  padding-top: 32px;
  padding-bottom: 0px;
`;
const BottomMarketingContainer = styled(MarketingContainer)``;

export const Home = () => {
  const { t } = useTranslation();
  const {
    brandDetails: { landingPageContent, showHomepageJoinGameForm },
  } = useBrand();
  const { isSignedIn, userDetails } = useAuth();
  const pageContent = isSignedIn
    ? landingPageContent?.userAccount
    : landingPageContent?.homepage;

  const renderHeader = React.useCallback(
    ({ toggleMenu }: ScreenAreaRenderProps) => {
      if (isSignedIn) {
        return (
          <GameHeader
            toggleMenu={toggleMenu}
            title={
              userDetails
                ? t("user.playerName", { playerName: userDetails.name })
                : undefined
            }
          />
        );
      } else {
        return (
          <Hero>
            <MenuToggle onClick={toggleMenu} />
            <HeroLogo />
          </Hero>
        );
      }
    },
    [isSignedIn, t, userDetails]
  );

  const renderBody = React.useCallback(() => {
    return (
      <Container>
        {!!pageContent?.top && (
          <TopMarketingContainer>
            <Markdown source={pageContent.top} />
          </TopMarketingContainer>
        )}

        {showHomepageJoinGameForm && (
          <ContentContainer>
            <JoinGameForm />
          </ContentContainer>
        )}

        {!!pageContent?.middle && (
          <MiddleMarketingContainer>
            <Markdown source={pageContent.middle} />
          </MiddleMarketingContainer>
        )}

        {isSignedIn ? <UserAccount /> : <AuthLinks />}

        {!!pageContent?.bottom && (
          <BottomMarketingContainer>
            <Markdown source={pageContent.bottom} />
          </BottomMarketingContainer>
        )}
      </Container>
    );
  }, [isSignedIn, pageContent, showHomepageJoinGameForm]);

  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
