import React from "react";
import { GameStatus } from "@pose-party/types";
import styled from "styled-components";
import { motion, AnimatePresence, Variants } from "framer-motion";
import { GameScreenshareGameDetails } from "./GameScreenshareGameDetails";
import { GameScreenshareSetup } from "./GameScreenshareSetup";
import { GameScreensharePose } from "./GameScreensharePose";
import { GameScreenshareScore } from "./GameScreenshareScore";
import { GameScreenshareResults } from "./GameScreenshareResults";
import { GameScreenshareFinalResults } from "./GameScreenshareFinalResults";
import { HostGameScreen } from "../../components/HostGameScreen";
import { HostGameProvider, useHostGame } from "../../../hooks/useHostGame";
import { WebcamStreamProvider } from "../../../data/webcam";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const GameDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;
const Content = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`;
const Slide = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const variants: Variants = {
  exit: {
    transition: {
      duration: 1,
      ease: "backInOut",
    },
    scale: 0.75,
    opacity: 0,
  },
  enter: {
    transition: {
      delay: 2,
      duration: 1,
      ease: "backInOut",
    },
    scale: 1,
    opacity: 1,
  },
};

export const HostGameScreenshareContent: React.FunctionComponent = React.memo(
  () => {
    const { state } = useHostGame();

    const renderBody = React.useCallback(() => {
      const slideProps = {
        variants,
        initial: "exit",
        animate: "enter",
        exit: "exit",
      };
      return (
        <Container>
          <GameDetails>
            <GameScreenshareGameDetails />
          </GameDetails>

          <Content>
            <AnimatePresence>
              {state.status === GameStatus.Setup && (
                <Slide key="setupSlide" {...slideProps}>
                  <GameScreenshareSetup />
                </Slide>
              )}

              {state.status === GameStatus.Pose && (
                <Slide key="poseSlide" {...slideProps}>
                  <GameScreensharePose />
                </Slide>
              )}

              {state.status === GameStatus.Score && (
                <Slide key="scoreSlide" {...slideProps}>
                  <GameScreenshareScore />
                </Slide>
              )}

              {state.status === GameStatus.Results && (
                <Slide key="resultsSlide" {...slideProps}>
                  <GameScreenshareResults />
                </Slide>
              )}

              {state.status === GameStatus.FinalResults && (
                <Slide key="finalResultsSlide" {...slideProps}>
                  <GameScreenshareFinalResults />
                </Slide>
              )}
            </AnimatePresence>
          </Content>
        </Container>
      );
    }, [state.status]);

    return <HostGameScreen renderBody={renderBody} />;
  }
);

export const HostGameScreenshareUI: React.FunctionComponent = React.memo(() => {
  return (
    <WebcamStreamProvider>
      <HostGameProvider>
        <HostGameScreenshareContent />
      </HostGameProvider>
    </WebcamStreamProvider>
  );
});
