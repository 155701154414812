import styled, { css } from "styled-components/macro";

export const headerBaseCss = css`
  font-family: "Nunito Sans";
  font-weight: 900;
`;

export const RegularTitle = styled.h4`
  ${headerBaseCss}
  font-size: 20px;
  line-height: 27px;
`;
export const LargeTitle = styled.h3`
  ${headerBaseCss}
  font-size: 22px;
  line-height: 30px;
`;
export const VeryLargeTitle = styled.h2`
  ${headerBaseCss}
  font-size: 38px;
  line-height: 51px;
`;
export const MassiveTitle = styled.h2`
  ${headerBaseCss}
  font-size: 54px;
  line-height: 71px;
`;
