import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { sanitizeGameId } from "@pose-party/data";
import { useFormInput } from "../../hooks/useFormInput";
import { TextInput } from "../components/TextInput";
import { Button } from "../components/Button";

const Form = styled.form`
  display: flex;
  height: 48px;
`;

const GameIdTextInput = styled(TextInput)`
  flex: 2;
  &:valid {
    text-transform: uppercase;
  }
`;

const JoinButton = styled(Button)`
  flex: 1;
  margin-left: 8px;
`;

export const JoinGameForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [gameid, onGameidChange] = useFormInput({
    inputTransform: (input) => input.toUpperCase(),
  });

  const hanleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const sanitized = sanitizeGameId(gameid);
      if (sanitized.length > 0) {
        history.push(`/${sanitized}`);
      }
    },
    [gameid, history]
  );

  return (
    <Form onSubmit={hanleSubmit}>
      <GameIdTextInput
        type="text"
        placeholder={t("home.joinForm.placeholder")}
        value={gameid}
        onChange={onGameidChange}
        autoCapitalize="characters"
        required
      />

      <JoinButton type="submit">{t("home.joinForm.submit")}</JoinButton>
    </Form>
  );
};
