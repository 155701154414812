import React from "react";
import { Trans } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Text } from "./Text";

export interface TermsTextProps {}

const PrivacyLink = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const TermsText: React.FunctionComponent<TermsTextProps> = () => {
  const location = useLocation();

  return (
    <Text>
      <Trans
        i18nKey="terms"
        components={{
          privacyLink: <PrivacyLink to={`${location.pathname}/privacy`} />,
        }}
      />
    </Text>
  );
};
