import React from "react";
import styled from "styled-components/macro";
import Color from "color";

export interface TextProps extends React.HTMLAttributes<HTMLInputElement> {}

export const TextInput = styled.input<TextProps>`
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 13px 0;
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.textInputBackground};
  border: 0;
  text-align: center;
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};

  &:active,
  &:focus {
    border: 0;
    outline: 0;
    box-shadow: 0 0 0 0.125em
      ${(props) =>
        Color(props.theme.colors.primaryBrand).alpha(0.25).toString()};
  }
`;
