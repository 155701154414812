import React from "react";

export const BrowserSupportedContext = React.createContext<{
  isBrowserSupported: boolean | undefined;
  ignoreBrowserUnsupported: () => void;
}>({
  isBrowserSupported: undefined,
  ignoreBrowserUnsupported: () => {
    throw new Error(
      "Must be called from inside an BrowserSupportedContext provider"
    );
  },
});
