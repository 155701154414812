import React from "react";
import _ from "lodash";
import { GameSnapshot, WebcamSnapshot } from "@pose-party/types";
import firebase from "../firebase";
import { logger } from "../../logger";
import { api } from "../api";

/**
 * Hook to get the snapshots of a given game and pose.
 *
 * `undefined`: Not yet loaded.
 * `GameSnapshot[]`: The game players. Might be empty
 */
export const useGamePoseSnapshots = ({
  brandId,
  gameId,
  poseId,
  groupId,
}: {
  brandId: string;
  gameId: string | undefined;
  poseId: string | undefined;
  groupId?: string | undefined;
}) => {
  const [gameSnapshots, setGameSnapshots] = React.useState<GameSnapshot[]>();

  React.useEffect(() => setGameSnapshots(undefined), [gameId, poseId]);

  React.useEffect(() => {
    if (!gameId || !poseId) {
      setGameSnapshots(undefined);
      return;
    }

    let path = `/brands/${brandId}/games/${gameId}`;
    if (groupId) {
      path += `/groups/${groupId}`;
    }
    path += `/snapshots/${poseId}`;

    const ref = firebase.database().ref(path);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      const rawValue = data.val();
      if (!rawValue) {
        return setGameSnapshots([]);
      }

      setGameSnapshots(
        _.chain(rawValue)
          .map(
            (snapshotValue, snapshotId): GameSnapshot => {
              return {
                id: snapshotId.toString(),
                poseId,
                uid: snapshotValue.uid ?? "",
                hidden: snapshotValue.hidden ?? false,
                snapshot: snapshotValue.snapshot || [],
                gif: snapshotValue.gif || undefined,
                mp4: snapshotValue.mp4 || undefined,
              };
            }
          )
          .sortBy((s) => s.uid)
          .value()
      );
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId, groupId, poseId]);

  React.useEffect(() => {
    logger.info("Game snapshots updated", gameSnapshots);
  }, [gameSnapshots]);

  return gameSnapshots;
};

/**
 * Marks a game snapshot as hidden
 */
export const hideGameSnapshot = async ({
  brandId,
  gameId,
  poseId,
  groupId,
  snapshotId,
}: {
  brandId: string;
  gameId: string;
  poseId: string;
  groupId: string;
  snapshotId: string;
}) => {
  const snapshotRef = firebase
    .database()
    .ref(
      `/brands/${brandId}/games/${gameId}/groups/${groupId}/snapshots/${poseId}/${snapshotId}`
    );
  await snapshotRef.child("hidden").set(true);
};

/**
 * Removes a given snapshot report
 */
export const removeSnapshotReport = async ({
  brandId,
  gameId,
  poseId,
  playerId,
}: {
  brandId: string;
  gameId: string;
  poseId: string;
  playerId: string;
}) => {
  const snapshotRef = firebase
    .database()
    .ref(
      `/brands/${brandId}/games/${gameId}/reportedSnapshots/${playerId}--${poseId}`
    );
  await snapshotRef.set(null);
};

/**
 * Reports an image
 */
export const reportSnapshot = (params: {
  brandId: string;
  gameId: string;
  groupId: string;
  poseId: string;
  uid: string;
  name: string;
  snapshotId: string;
  snapshot: WebcamSnapshot;
}) => {
  return api.post("reportSnapshot", params);
};
