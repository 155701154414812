import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StartDemoForm } from "./StartDemoForm";
import { NotFound } from "../NotFound";
import { useBrand } from "../../data/brand";
import { GameHeader } from "../components/GameHeader";
import { GameScreen } from "../components/GameScreen";
import { Markdown } from "../components/Markdown";

const Container = styled.div`
  overflow-y: auto;
`;

const MarketingContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`;
const TopMarketingContainer = styled(MarketingContainer)`
  padding-top: 32px;
`;
const BottomMarketingContainer = styled(MarketingContainer)``;
const StartDemoFormContainer = styled.div`
  padding: 32px 16px;
`;

export const InPersonStart = () => {
  const { t } = useTranslation();
  const {
    brandDetails: { allowInPersonGames, landingPageContent },
  } = useBrand();

  const renderHeader = React.useCallback(() => {
    return <GameHeader title={t("inPersonStart.title")} />;
  }, [t]);

  const renderBody = React.useCallback(() => {
    return (
      <Container>
        {!!landingPageContent?.startInPersonGame?.top && (
          <TopMarketingContainer>
            <Markdown source={landingPageContent.startInPersonGame.top} />
          </TopMarketingContainer>
        )}

        <StartDemoFormContainer>
          <StartDemoForm />
        </StartDemoFormContainer>

        {!!landingPageContent?.startInPersonGame?.bottom && (
          <BottomMarketingContainer>
            <Markdown source={landingPageContent.startInPersonGame.bottom} />
          </BottomMarketingContainer>
        )}
      </Container>
    );
  }, [landingPageContent]);

  // Show a 404 if demos are not enabled
  if (!allowInPersonGames) {
    return <NotFound />;
  }

  // Render the screen
  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
