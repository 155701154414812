import React from "react";
import { GamePlayer, GameSnapshot } from "@pose-party/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";
import { RegularTitle } from "../../components/Title";
import { AspectCard } from "../../components/AspectCard";
import { SnapshotDisplay } from "../../Game/SnapshotDisplay";
import { Text } from "../../components/Text";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { useBrand } from "../../../data/brand";
import {
  hideGamePlayer,
  useGamePlayers,
  useGamePoseSnapshots,
} from "../../../data/game";
import { Button } from "../../components/Button";

const Content = styled.section`
  margin: 32px 0;
`;
const Title = styled(RegularTitle)`
  margin-bottom: 16px;
`;
const Snapshots = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ReportedSnapshotContent = styled.div`
  width: 33%;
  padding: 16px;
`;
const Snapshot = styled(AspectCard)``;
const PlayerName = styled(Text)`
  margin-top: 8px;
  text-align: center;
`;
const KickButton = styled(Button)`
  margin-top: 8px;
`;

const SnapshotItem: React.FunctionComponent<{
  snapshot: GameSnapshot;
  player: GamePlayer | undefined;
  onKick: () => void;
}> = React.memo(({ snapshot, player, onKick }) => {
  const { t } = useTranslation();
  return (
    <ReportedSnapshotContent>
      <Snapshot aspectRatio={1}>
        <SnapshotDisplay snapshot={snapshot.snapshot} />
      </Snapshot>
      <PlayerName>{player?.name || "Unknown player"}</PlayerName>
      <KickButton onClick={onKick}>
        {t("hostGameUI.submittedSnapshots.kick")}
      </KickButton>
    </ReportedSnapshotContent>
  );
});

export const HostGameSubmittedSnapshots: React.FunctionComponent = React.memo(
  () => {
    const gameId = useGameIdParam();
    const { brandId } = useBrand();
    const { state } = useHostGame();
    const { t } = useTranslation();

    const snapshots =
      useGamePoseSnapshots({
        brandId,
        gameId,
        poseId: state.currentPoseId,
        groupId: "0", // Only used for normal games with a single group
      }) || [];
    const players = useGamePlayers({
      enable: !state.large,
      brandId,
      gameId,
      onlySetup: true,
    });

    const makeOnKick = React.useCallback(
      (player: GamePlayer) => async () => {
        const sure = window.confirm(
          `Are you sure you want to kick the player "${player.name}"?`
        );
        if (!sure) {
          return;
        }

        try {
          await hideGamePlayer({
            brandId,
            gameId,
            playerId: player.id,
            groupId: player.groupId,
          });
        } catch (e) {
          window.alert(
            `There was a problem when attempting to kick the player. ${e}`
          );
        }
      },
      [brandId, gameId]
    );

    return (
      <Content>
        <Title>{t("hostGameUI.submittedSnapshots.title")}</Title>

        {snapshots.length > 0 && (
          <Snapshots>
            {snapshots.map((snapshot) => {
              const player = players.find((p) => p.id === snapshot.uid);
              if (!player) {
                return null;
              }

              return (
                <SnapshotItem
                  key={snapshot.id}
                  snapshot={snapshot}
                  player={player}
                  onKick={makeOnKick(player)}
                />
              );
            })}
          </Snapshots>
        )}
      </Content>
    );
  }
);
