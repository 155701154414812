/* eslint-disable no-nested-ternary */

import { Results, WebcamSnapshot } from "@pose-party/types";

export const positionWithSuffix = (place: number): string => {
  const lastDigit = place % 10;
  const suffix =
    place === 11 || place === 12 || place === 13
      ? "th"
      : lastDigit === 1
      ? "st"
      : lastDigit === 2
      ? "nd"
      : lastDigit === 3
      ? "rd"
      : "th";

  return `${place}${suffix}`;
};

export interface ResultsPlayer {
  id: string;
  name: string;
  results: Results;
  snapshot: WebcamSnapshot | undefined;
  fake: boolean;
}
