import React from "react";

export const Kicked: React.FunctionComponent = () => {
  return (
    <div className="has-vertical-centered-content">
      <p className="is-5 content has-text-centered">
        You were removed from the game
      </p>
    </div>
  );
};
