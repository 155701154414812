import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Redirect, Link, useLocation } from "react-router-dom";
import { GameScreen, ScreenAreaRenderProps } from "../components/GameScreen";
import { useAuth } from "../../data/auth";
import { TextInput } from "../components/TextInput";
import { useFormInput } from "../../hooks/useFormInput";
import { Button } from "../components/Button";
import { useBrand } from "../../data/brand";
import { logger } from "../../logger";
import { GameHeader } from "../components/GameHeader";
import { TermsCheckbox } from "../components/TermsCheckbox";
import { useFormCheckbox } from "../../hooks/useFormCheckbox";
import { Markdown } from "../components/Markdown";
import { useContinueParam } from "../../hooks/useContinueParam";

const Container = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto;
`;
const MarkdownContent = styled(Markdown)`
  margin: 16px 16px 0 16px;
`;
const FormContainer = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
`;
const FormTextInput = styled(TextInput)`
  margin-bottom: 16px;
`;
const SubmitContainer = styled.div`
  padding: 0 16px;
`;
const AuthButton = styled(Button)`
  margin-bottom: 8px;
`;
const AuthLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;
const AuthLink = styled(Link)`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const SignUp = () => {
  const { t } = useTranslation();
  const {
    brandDetails: { allowSignUp, landingPageContent },
  } = useBrand();
  const { isSignedIn, performSignUp } = useAuth();
  const location = useLocation();
  const continueParam = useContinueParam();

  const [name, handleNameChange] = useFormInput();
  const [email, handleEmailChange] = useFormInput();
  const [password, handlePasswordChange] = useFormInput();
  const [termsCheckbox, handleTermsCheckboxChange] = useFormCheckbox();

  const handleFormSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const trimmedName = name.trim();
      const trimmedEmail = email.trim();
      if (
        trimmedName.length === 0 ||
        trimmedEmail.length === 0 ||
        password.length === 0
      ) {
        alert(t("auth.formError"));
        return;
      }

      try {
        await performSignUp({
          name: trimmedName,
          email: trimmedEmail,
          password,
        });
      } catch (e) {
        logger.warn("Error performing auth", e);

        if (e.code === "auth/email-already-in-use") {
          alert(t("auth.emailInUseError"));
        } else if (e.code === "auth/invalid-email") {
          alert(t("auth.invalidEmailError"));
        } else if (e.code === "auth/weak-password") {
          alert(t("auth.weakPasswordError"));
        } else {
          alert(t("auth.authError"));
        }
      }
    },
    [email, name, password, performSignUp, t]
  );

  const renderHeader = React.useCallback(
    ({ toggleMenu }: ScreenAreaRenderProps) => {
      return (
        <GameHeader toggleMenu={toggleMenu} title={t("auth.signUpTitle")} />
      );
    },
    [t]
  );

  const renderBody = React.useCallback(() => {
    return (
      <Container>
        {!!landingPageContent?.auth?.signUp && (
          <MarkdownContent source={landingPageContent.auth.signUp} />
        )}

        <form onSubmit={handleFormSubmit}>
          <FormContainer>
            <FormTextInput
              placeholder={t("auth.namePlaceholder")}
              value={name}
              onChange={handleNameChange}
            />
            <FormTextInput
              placeholder={t("auth.emailPlaceholder")}
              value={email}
              onChange={handleEmailChange}
            />
            <FormTextInput
              type="password"
              placeholder={t("auth.passwordPlaceholder")}
              value={password}
              onChange={handlePasswordChange}
            />
            <TermsCheckbox
              checked={termsCheckbox}
              onChange={handleTermsCheckboxChange}
            />
          </FormContainer>

          <SubmitContainer>
            <AuthButton type="submit" disabled={!termsCheckbox}>
              {t("auth.signUp")}
            </AuthButton>
            <AuthLinkContainer>
              {allowSignUp && (
                <>
                  <AuthLink to={`/login${location.search}`}>
                    {t("auth.login")}
                  </AuthLink>
                  &nbsp;|&nbsp;
                </>
              )}
              <AuthLink to={`/forgottenPassword${location.search}`}>
                {t("auth.forgottenPassword")}
              </AuthLink>
            </AuthLinkContainer>
          </SubmitContainer>
        </form>
      </Container>
    );
  }, [
    allowSignUp,
    email,
    handleEmailChange,
    handleFormSubmit,
    handleNameChange,
    handlePasswordChange,
    handleTermsCheckboxChange,
    landingPageContent,
    location.search,
    name,
    password,
    t,
    termsCheckbox,
  ]);

  if (isSignedIn) {
    return <Redirect to={continueParam || "/"} />;
  }

  if (!allowSignUp) {
    return <Redirect to="/login" />;
  }

  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
