import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { Text } from "../../components/Text";
import { TextInput } from "../../components/TextInput";
import { GalleryHeader } from "../GalleryHeader";
import { useBrand } from "../../../data/brand";

export interface GameGalleryPinEntryProps {
  brandId: string;
  gameId: string;
  value: string;
  onPinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface GameGalleryPinEntryStatic {
  shake(): void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

const HintText = styled(Text)`
  text-align: center;
  margin-bottom: 32px;
`;

const PinTextInput = styled(TextInput)`
  width: 250px;
  font-size: 54px;
  line-height: 71px;
  height: 71px;
  background-color: ${(props) => props.theme.colors.background};
`;

export const GameGalleryPinEntry = React.forwardRef<
  GameGalleryPinEntryStatic,
  GameGalleryPinEntryProps
>(({ brandId, gameId, value, onPinChange }, ref) => {
  const { t } = useTranslation();
  const {
    brandDetails: { hideNamesInGalleries },
  } = useBrand();

  const controls = useAnimation();

  React.useImperativeHandle(ref, () => ({
    shake: () => {
      controls.start("shake");
    },
  }));

  return (
    <Container>
      <GalleryHeader
        brandId={brandId}
        gameId={gameId}
        hideGameName={hideNamesInGalleries}
      />

      <Content>
        <HintText>{t("galleryPin.hint")}</HintText>
        <motion.div
          animate={controls}
          variants={{ shake: { x: [0, 20, -20, 20, -20, 20, 0] } }}
        >
          <PinTextInput
            type="tel"
            pattern="[0-9]{4}"
            autoFocus
            value={value}
            onChange={onPinChange}
          />
        </motion.div>
      </Content>
    </Container>
  );
});
