import React from "react";
import { motion, HTMLMotionProps } from "framer-motion";

export const BounceDiv: React.FunctionComponent<HTMLMotionProps<"div">> = (
  props
) => {
  return (
    <motion.div
      {...props}
      animate={{ scale: [1.05, 0.95] }}
      transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
    />
  );
};
