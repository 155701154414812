import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AccentButton } from "../components/Button";
import { TextInput } from "../components/TextInput";
import { useAuth } from "../../data/auth";
import { useFormInput } from "../../hooks/useFormInput";
import { newInPersonGame } from "../../data/game";
import { useBrand } from "../../data/brand";

const Form = styled.form``;

const EmailTextInput = styled(TextInput)``;

const StartButton = styled(AccentButton)`
  margin-top: 8px;
`;

export const StartDemoForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  const { brandId } = useBrand();
  const history = useHistory();

  // Manage the users email value
  const [email, onEmailChange, setEmail] = useFormInput({
    defaultValue: userDetails?.email,
  });
  const setEmailOnce = React.useRef(false);
  React.useEffect(() => {
    if (setEmailOnce.current || !userDetails?.email || email) {
      return;
    }

    setEmail(userDetails.email);
    setEmailOnce.current = true;
  }, [email, setEmail, userDetails]);

  // Handle the submit button
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) {
        return;
      }
      setLoading(true);

      const hostEmail = email.trim();
      if (!hostEmail) {
        alert(t("inPersonStart.errors.email"));
        return;
      }

      try {
        const { data: gameId } = await newInPersonGame({
          brandId,
          hostEmail,
        });
        history.push(`/${gameId}`);
      } catch (e) {
        alert(t("inPersonStart.errors.starting"));
      }

      setLoading(false);
    },
    [brandId, email, history, loading, t]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <EmailTextInput
        type="email"
        placeholder={t("inPersonStart.form.emailPlaceholder")}
        value={email}
        onChange={onEmailChange}
        required
      />

      <StartButton
        type="submit"
        disabled={email.length === 0}
        loading={loading}
      >
        {t("inPersonStart.form.submit")}
      </StartButton>
    </Form>
  );
};
