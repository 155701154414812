import React from "react";
import { GameState } from "@pose-party/types";
import firebase from "../firebase";
import { logger } from "../../logger";

export interface UseGameParams {
  brandId: string;
  gamePlayerLimit: number;
  gameId: string;
  uid: string | undefined;
}

/**
 * Load the primary game host user id. This is the host who created the game
 */
export const usePrimaryGameHost = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [primaryGameHost, setMainGameHost] = React.useState<
    string | null | undefined
  >();

  React.useEffect(() => {
    const mainRef = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/host`);
    mainRef.on("value", (data) => {
      setMainGameHost(data.val());
    });

    return () => {
      mainRef.off("value");
    };
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Primary hame host user id updated", primaryGameHost);
  }, [primaryGameHost]);

  return primaryGameHost;
};

/**
 * Say if the uid is one of those which has been added as an additional host
 */
export const useIsAdditionalGameHosts = ({
  brandId,
  gameId,
  uid,
}: {
  brandId: string;
  gameId: string;
  uid: string | undefined;
}) => {
  const [isAdditionalGameHost, setIsAdditionalGameHost] = React.useState(false);

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const additionalRef = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/additionalHosts/${uid}`);
    additionalRef.on("value", (data) => {
      setIsAdditionalGameHost(data.exists());
    });

    return () => {
      additionalRef.off("value");
    };
  }, [brandId, gameId, uid]);

  React.useEffect(() => {
    logger.info("Additional game host flag updated", isAdditionalGameHost);
  }, [isAdditionalGameHost]);

  return isAdditionalGameHost;
};

/**
 * Returns true if the current user is a game host
 */
export const useIsGameHost = ({
  brandId,
  gameId,
  uid,
}: {
  brandId: string;
  gameId: string;
  uid: string | undefined;
}) => {
  const primaryGameHostId = usePrimaryGameHost({ brandId, gameId });
  const isAdditionalHostUser = useIsAdditionalGameHosts({
    brandId,
    gameId,
    uid,
  });
  return primaryGameHostId === uid || isAdditionalHostUser;
};

/**
 * Gets the name of the game
 */
export const useGameName = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string | undefined => {
  const [gameName, setGameName] = React.useState<string>();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setGameName(data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/name`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game name updated", gameName);
  }, [gameName]);

  return gameName;
};

/**
 * Gets the large game status of the game
 */
export const useIsLargeGame = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): {
  isLargeGame: boolean | undefined;
  largeGroupSize: number | undefined;
} => {
  const [isLargeGame, setIsLargeGame] = React.useState<boolean>();
  const [largeGroupSize, setLargeGroupSize] = React.useState<number>();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) => {
      const { large, largeGroupSize: groupSize } = data.val();
      setIsLargeGame(!!large);
      setLargeGroupSize(large ? groupSize : undefined);
    };

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/state`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game is large updated", isLargeGame);
  }, [isLargeGame]);
  React.useEffect(() => {
    logger.info("Game large group size updated", largeGroupSize);
  }, [largeGroupSize]);

  return { isLargeGame, largeGroupSize };
};

/**
 * Gets the locked state of the game
 */
export const useGameLocked = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [locked, setLocked] = React.useState<boolean | undefined>();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setLocked(!!data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/state/locked`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game locked state updated", locked);
  }, [locked]);

  return locked;
};

/**
 * Gets the gallery PIN of the game
 */
export const useGameGalleryPin = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [gameName, setGameName] = React.useState<string | null | undefined>();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setGameName(data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/galleryPin`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game gallery PIN updated", gameName);
  }, [gameName]);

  return gameName;
};

/**
 * Gets the game player invite code. Must be a host to call
 */
export const useGamePlayerInviteCode = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [playerInviteCode, setPlayerInviteCode] = React.useState<
    string | null | undefined
  >();

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("gamesProtected")
      .doc(gameId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        if (data) {
          setPlayerInviteCode(data.inviteCode || false);
        } else {
          setPlayerInviteCode(null);
        }
      });

    return unsubscribe;
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game player invite code updated", playerInviteCode);
  }, [playerInviteCode]);

  return playerInviteCode;
};

/**
 * Gets the logo of the game
 */
export const useGameLogo = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string | null | undefined => {
  const [gameLogo, setGameLogo] = React.useState<string | null | undefined>();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setGameLogo(data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/logo`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game logo updated", gameLogo);
  }, [gameLogo]);

  return gameLogo;
};

/**
 * Gets the logo url of the game
 */
export const useGameLogoUrl = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string | null | undefined => {
  const [gameLogoUrl, setGameLogoUrl] = React.useState<
    string | null | undefined
  >();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setGameLogoUrl(data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/logoUrl`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game logo URL updated", gameLogoUrl);
  }, [gameLogoUrl]);

  return gameLogoUrl;
};

/**
 * Gets the video overlay url of the game
 */
export const useGameVideoOverlayUrl = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string | null | undefined => {
  const [gameLogoUrl, setGameLogoUrl] = React.useState<
    string | null | undefined
  >();

  React.useEffect(() => {
    const handler = (data: firebase.database.DataSnapshot) =>
      setGameLogoUrl(data.val());

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/videoOverlay`);
    ref.on("value", handler);

    return () => ref.off("value", handler);
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game video overlay URL updated", gameLogoUrl);
  }, [gameLogoUrl]);

  return gameLogoUrl;
};

/**
 * Hook to get the state of a given game.
 *
 * `undefined`: Not yet loaded.
 * `null`: Not a valid game.
 * `GameState`: The game state. Might be "finished".
 */
export const useGameState = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [gameState, setGameState] = React.useState<
    GameState | null | undefined
  >();

  React.useEffect(() => {
    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/state`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setGameState(
        data.val()
          ? {
              id: gameId,
              ...data.val(),
            }
          : null
      );
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game state updated", gameState);
  }, [gameState]);

  return gameState;
};

/**
 * Hook to get the number of spectators for a given game.
 *
 * `number`: The number of spectators for the given game.
 */
export const useGameSpectatorCount = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  const [gameSpectatorCount, setGameSpectatorCount] = React.useState(0);

  React.useEffect(() => {
    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/spectatorCounts/present`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setGameSpectatorCount(data.val() || 0);
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game spectator count updated", gameSpectatorCount);
  }, [gameSpectatorCount]);

  return gameSpectatorCount;
};

/**
 * Hook to get the number of spectators for a given game.
 *
 * `number`: The number of spectators for the given game.
 */
export const usePoseSpectatorScoredCount = ({
  brandId,
  gameId,
  poseId,
}: {
  brandId: string;
  gameId: string;
  poseId: string | undefined;
}) => {
  const [
    poseSpectatorScoredCount,
    setPoseSpectatorScoredCount,
  ] = React.useState(0);

  React.useEffect(() => {
    if (!poseId) {
      setPoseSpectatorScoredCount(0);
      return;
    }
    const ref = firebase
      .database()
      .ref(
        `/brands/${brandId}/games/${gameId}/spectatorCounts/poseScored/${poseId}`
      );

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setPoseSpectatorScoredCount(data.val() || 0);
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId, poseId]);

  React.useEffect(() => {
    logger.info(
      "Game pose spectator scored count updated",
      poseSpectatorScoredCount
    );
  }, [poseSpectatorScoredCount]);

  return poseSpectatorScoredCount;
};

/**
 * Returns an array of the group ids associated with a game
 */
export const useGameGroupIds = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string[] | undefined => {
  const [groupIds, setGroupIds] = React.useState<string[]>();

  React.useEffect(() => {
    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/groupIds`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setGroupIds(data.val() || []);
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game group ids updated", groupIds);
  }, [groupIds]);

  return groupIds;
};

/**
 * Returns the slideshow Google Storage path for a game
 */
export const useGameSlideshow = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): string | undefined => {
  const [slideshow, setSlideshow] = React.useState<string>();

  React.useEffect(() => {
    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/slideshow`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setSlideshow(data.val() || undefined);
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId]);

  React.useEffect(() => {
    logger.info("Game slideshow updated", slideshow);
  }, [slideshow]);

  return slideshow;
};
