/* eslint-disable react/jsx-wrap-multilines */

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  GamePlayer,
  WebcamStreamErrorCode,
  WebcamStreamState,
} from "@pose-party/types";
import { Button } from "../../../components/Button";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { Text } from "../../../components/Text";
import { useWebcamStream } from "../../../../data/webcam";
import { GameHeader } from "../../../components/GameHeader";
import { Markdown } from "../../../components/Markdown";
import { PlatformTabs } from "../../../components/PlatformTabs";

export interface WebcamSetupProps {
  currentPlayer: GamePlayer;
  onSkipPress: (() => void) | undefined;
  gameLogo: string | null | undefined;
}

const Container = styled.div`
  padding: 32px 16px;
  overflow: auto;
`;

const ContentText = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;

const HelpText = styled(Markdown)``;

const ContentButton = styled(Button)`
  margin-bottom: 16px;
`;

export const WebcamSetup: React.FunctionComponent<WebcamSetupProps> = React.memo(
  ({ currentPlayer, onSkipPress, gameLogo }) => {
    const { t } = useTranslation();
    const { stream, startStream } = useWebcamStream();

    /**
     * Handle the "allow" button press
     */
    const onAllowCameraPress = React.useCallback(() => {
      startStream();
    }, [startStream]);

    /**
     * Render the views
     */

    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameSetup.webcamSetup.title", {
              playerName: currentPlayer.name,
            })}
          />
        );
      },
      [currentPlayer.name, gameLogo, t]
    );

    const errorContent = React.useMemo(() => {
      if (stream.state !== WebcamStreamState.Error) {
        return null;
      }

      switch (stream.error.code) {
        case WebcamStreamErrorCode.Permission:
          return (
            <>
              <HelpText source={t("gameSetup.webcamErrors.permission.hint")} />
              <PlatformTabs
                iOS={
                  <HelpText
                    source={t("gameSetup.webcamErrors.permission.ios")}
                  />
                }
                android={
                  <HelpText
                    source={t("gameSetup.webcamErrors.permission.android")}
                  />
                }
                desktop={
                  <HelpText
                    source={t("gameSetup.webcamErrors.permission.desktop")}
                  />
                }
              />
            </>
          );
        case WebcamStreamErrorCode.InUse:
          return <HelpText source={t("gameSetup.webcamErrors.inUse")} />;
        case WebcamStreamErrorCode.NoCamera:
          return <HelpText source={t("gameSetup.webcamErrors.noCamera")} />;
        case WebcamStreamErrorCode.Unknown:
          return <HelpText source={t("gameSetup.webcamErrors.unknown")} />;
      }
    }, [stream, t]);

    const renderBody = React.useCallback(() => {
      return (
        <Container>
          <ContentText>{t("gameSetup.webcamSetup.prompt")}</ContentText>

          <ContentButton id="webcamSetup-allow" onClick={onAllowCameraPress}>
            {t("gameSetup.webcamSetup.button")}
          </ContentButton>

          {errorContent && onSkipPress && (
            <ContentButton id="webcamSetup-skip" onClick={onSkipPress}>
              {t("gameSetup.webcamSetup.skipButton")}
            </ContentButton>
          )}

          {errorContent}
        </Container>
      );
    }, [errorContent, onAllowCameraPress, onSkipPress, t]);

    return (
      <GameScreen
        id="gameSetup-webcamSetup"
        logo={gameLogo}
        renderHeader={renderHeader}
        renderBody={renderBody}
      />
    );
  }
);
