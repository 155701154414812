import React from "react";
import { useTranslation } from "react-i18next";
import { useBrand } from "../../../data/brand";
import { useHostGame } from "../../../hooks/useHostGame";
import { usePoseInterstitialTimer } from "../../../hooks/usePoseInterstitialTimer";
import { useRoundTimer } from "../../../hooks/useRoundTimer";

export interface HostGamePoseCountdownProps {
  onCountdownEnd: () => void;
}

export const HostGamePoseCountdown: React.FunctionComponent<HostGamePoseCountdownProps> = ({
  onCountdownEnd,
}) => {
  const {
    brandDetails: { timingRoundStartInterstitial, timingPoseRoundLength },
  } = useBrand();
  const { hostingWithHostUI, currentPose } = useHostGame();
  const { t } = useTranslation();

  /**
   * Timer for the interstitial screens
   */
  const interstitial = usePoseInterstitialTimer(timingRoundStartInterstitial);

  /**
   * Keep track of when we think the round ends
   */
  const { roundEnded, countdownDetails } = useRoundTimer({
    roundLength: currentPose?.timing || timingPoseRoundLength,
    // Only start when the interstitial screens are gone
    started: interstitial.state === "finished",
  });

  /**
   * Host should move to the next stage when the counter ends or all players are finished
   */
  const hasMovedNext = React.useRef(false);
  React.useEffect(() => {
    if (hostingWithHostUI && roundEnded && !hasMovedNext.current) {
      hasMovedNext.current = true;
      onCountdownEnd();
    }
  }, [hostingWithHostUI, onCountdownEnd, roundEnded]);

  const [currentSeconds, setCurrentSeconds] = React.useState(0);
  const requestRef = React.useRef<number>();
  const animate = React.useCallback(() => {
    if (!countdownDetails) {
      return;
    }

    // Work out how much time is left
    const roundTimeLeft = countdownDetails.end - Date.now();

    // Check if we're finished
    if (roundTimeLeft <= 0) {
      setCurrentSeconds(0);
      return;
    }

    // Set the number of seconds
    setCurrentSeconds(Math.ceil(roundTimeLeft / 1000));

    // Request another animation frame
    requestRef.current = requestAnimationFrame(animate);
  }, [countdownDetails]);
  React.useEffect(() => {
    if (!countdownDetails) {
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [animate, countdownDetails]);

  return countdownDetails ? (
    <p>{t("hostGameUI.controls.timeLeft", { time: currentSeconds })}</p>
  ) : null;
};
