import React from "react";
import "webrtc-adapter";
import { detect } from "detect-browser";
import {
  WebcamStreamState,
  WebcamStream,
  WebcamSnapshot,
  SNAPSHOT_WIDTH,
  SNAPSHOT_HEIGHT,
  SNAPSHOT_FRAMES,
  SNAPSHOT_FRAMES_PER_SECOND,
  WebcamStreamErrorCode,
  WebcamCaptureSnapshotParams,
  WebcamStartStreamOptions,
} from "@pose-party/types";
import { WebcamStreamContext } from "./WebcamStreamContext";
import { useBrand } from "../../brand";

export const WebcamStreamProvider: React.FunctionComponent = ({ children }) => {
  const {
    brandDetails: { defaultToUserFacingCamera },
  } = useBrand();

  // Store the state
  const [stream, setStream] = React.useState<WebcamStream>({
    state: WebcamStreamState.Inactive,
  });
  const streamStateRef = React.useRef(stream.state);
  React.useEffect(() => {
    streamStateRef.current = stream.state;
  }, [stream.state]);
  const streamStreamRef = React.useRef(
    stream.state === WebcamStreamState.Active ? stream.stream : undefined
  );
  React.useEffect(() => {
    streamStreamRef.current =
      stream.state === WebcamStreamState.Active ? stream.stream : undefined;
  }, [stream]);

  // Handle errors
  const handleError = React.useCallback((e: any) => {
    const details = e.message || e.toString();

    let code: WebcamStreamErrorCode;
    if (
      e.name === "NotFoundError" ||
      e.name === "DevicesNotFoundError" ||
      e.name === "OverconstrainedError" ||
      e.name === "ConstraintNotSatisfiedError"
    ) {
      // required track is missing  or constraints can not be satisfied by avb. devices
      code = WebcamStreamErrorCode.NoCamera;
    } else if (e.name === "NotReadableError" || e.name === "TrackStartError") {
      // webcam or mic are already in use
      code = WebcamStreamErrorCode.InUse;
    } else if (
      e.name === "NotAllowedError" ||
      e.name === "PermissionDeniedError"
    ) {
      // permission denied in browser
      code = WebcamStreamErrorCode.Permission;
    } else {
      // other errors
      code = WebcamStreamErrorCode.Unknown;
    }

    setStream({
      state: WebcamStreamState.Error,
      error: { code, details },
    });
  }, []);

  // If we want to use the front or rear camera. This is a ref because inside the `swapCamera`
  // function the `startStream` callback will not have the new value if we use state
  const requestFrontCamera = React.useRef(defaultToUserFacingCamera);

  const isMobile = React.useMemo(() => {
    const browser = detect();

    if (
      browser?.type === "browser" &&
      browser.os &&
      [
        "iOS",
        "Android OS",
        "BlackBerry OS",
        "Windows Mobile",
        "Amazon OS",
      ].includes(browser.os)
    ) {
      return true;
    }

    return false;
  }, []);

  // Start the stream
  const startStream = React.useCallback(
    async (options: WebcamStartStreamOptions = {}) => {
      try {
        if (
          !options.force &&
          streamStateRef.current === WebcamStreamState.Active
        ) {
          // Nothing to do if it's already active
          return;
        }

        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: requestFrontCamera.current ? "user" : "environment",
            width: SNAPSHOT_WIDTH,
            height: SNAPSHOT_HEIGHT,
          },
          audio: !!options.audio,
        });

        if (newStream.getVideoTracks().length === 0) {
          // TODO: Handle error
          handleError("No video track from the webcam");
          return;
        }

        const {
          width,
          height,
          facingMode,
        } = newStream.getVideoTracks()[0].getSettings();
        if (!width || !height) {
          // TODO: Handle error
          handleError("Unable to get size of webcam video");
          return;
        }

        // We assume a user facing camera if we're not on a mobile OS. If we are, then we use the value
        // provided to us when we started the stream to detect which camera is in use
        const userFacing = !isMobile || facingMode === "user";

        setStream({
          state: WebcamStreamState.Active,
          stream: newStream,
          width,
          height,
          userFacing,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, isMobile]
  );

  // Stop the stream
  const stopStream = React.useCallback(async () => {
    try {
      if (!streamStreamRef.current) {
        // Nothing to do if it's not active
        return;
      }

      streamStreamRef.current.getTracks().forEach((track) => track.stop());

      setStream({ state: WebcamStreamState.Inactive });
    } catch (e) {
      handleError(e);
    }
  }, [handleError]);

  // Always stop stream when we are unmounted
  React.useEffect(() => {
    return () => {
      stopStream();
    };
  }, [stopStream]);

  // Swap cameras and
  const swapCamera = React.useCallback(async () => {
    requestFrontCamera.current = !requestFrontCamera.current;
    await startStream({ force: true });
  }, [startStream]);

  // Handle the snapshot canvas and creating snapshots
  const snapshotCanvasRef = React.createRef<HTMLCanvasElement>();
  const createSnapshot = React.useCallback(
    async (
      videoEl: HTMLVideoElement,
      { stillMode, onCaptureFrame }: WebcamCaptureSnapshotParams
    ) => {
      if (
        !snapshotCanvasRef.current ||
        stream.state !== WebcamStreamState.Active
      ) {
        return;
      }

      const ctx = snapshotCanvasRef.current.getContext("2d");
      if (!ctx) {
        return;
      }

      // TODO: Take into account the input image size and offset by the correct amount

      ctx.save();
      if (stream.userFacing) {
        ctx.translate(SNAPSHOT_WIDTH, 0);
        ctx.scale(-1, 1);
      }

      if (stillMode) {
        ctx.drawImage(videoEl, 0, 0, SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        ctx.restore();
        return [snapshotCanvasRef.current.toDataURL("image/jpeg", 0.9)];
      }

      const frameData: WebcamSnapshot = [];
      const targetInterval = 1000 / SNAPSHOT_FRAMES_PER_SECOND;
      for (let i = 0; i < SNAPSHOT_FRAMES; i += 1) {
        const start = Date.now();

        onCaptureFrame((i + 1) / SNAPSHOT_FRAMES);
        ctx.drawImage(videoEl, 0, 0, SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        frameData.push(snapshotCanvasRef.current.toDataURL("image/jpeg", 0.9));

        // Wait for the next frame, taking into account how long capturing this frame took
        const snapshotTime = Date.now() - start;
        await new Promise((resolve) =>
          setTimeout(resolve, targetInterval - snapshotTime)
        );
      }
      ctx.restore();

      return frameData;
    },
    [snapshotCanvasRef, stream]
  );

  return (
    <WebcamStreamContext.Provider
      value={{
        stream,
        startStream,
        stopStream,
        swapCamera,
        createSnapshot,
      }}
    >
      {stream.state === WebcamStreamState.Active && (
        <canvas
          ref={snapshotCanvasRef}
          className="is-hidden"
          width={SNAPSHOT_WIDTH}
          height={SNAPSHOT_HEIGHT}
        />
      )}
      {children}
    </WebcamStreamContext.Provider>
  );
};
