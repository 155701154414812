import styled from "styled-components/macro";

export interface TextProps {
  subtle?: boolean;
  small?: boolean;
  weight?: "normal" | "bold" | "heavy";
}

export const Text = styled.p<TextProps>`
  font-family: "Nunito Sans";
  font-weight: ${(props) => {
    switch (props.weight) {
      case "heavy":
        return 900;
      case "bold":
        return 700;
      case "normal":
      default:
        return 400;
    }
  }};
  font-size: ${(props) => (props.small ? "14px" : "16px")};
  line-height: ${(props) => (props.small ? "19px" : "22px")};

  opacity: ${(props) => (props.subtle ? 0.8 : 1)};
`;
