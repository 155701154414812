import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { WebcamSnapshot } from "@pose-party/types";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { RegularTitle } from "../../../components/Title";
import { Text } from "../../../components/Text";
import { GameFooter } from "../../../components/GameFooter";
import { Button } from "../../../components/Button";
import { PlayerWaitingList } from "../../PlayerWaitingList";
import { SuperlikeLoadingIndicator } from "../../../components/SuperlikeLoadingIndicator";

export interface ScoringGamePlayers {
  id: string;
  name: string;
  profilePicture: WebcamSnapshot | undefined;
  finished: boolean;
  fake: boolean;
}

export interface ScoreWaitingProps {
  isLargeGame: boolean;
  gameLogo: string | null | undefined;
  gamePlayers: ScoringGamePlayers[];
  playerUid: string | undefined;
  spectatorCount: number;
  poseSpectatorScoredCount: number;
  renderHeader: (props: ScreenAreaRenderProps) => React.ReactNode;
  onHostShowResultsPress: (() => Promise<void>) | undefined;
  kickPlayer: ((playerId: string) => void) | undefined;
}

const Container = styled.div`
  margin-bottom: 16px;
  overflow-y: auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
`;
const Title = styled(RegularTitle)`
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
`;
const Count = styled(Text)`
  text-align: center;
  margin-top: 8px;
`;

export const ScoreWaiting: React.FunctionComponent<ScoreWaitingProps> = React.memo(
  ({
    isLargeGame,
    gameLogo,
    gamePlayers,
    playerUid,
    spectatorCount,
    poseSpectatorScoredCount,
    renderHeader,
    onHostShowResultsPress,
    kickPlayer,
  }) => {
    const { t } = useTranslation();

    const scoredCount = React.useMemo(
      () =>
        gamePlayers.reduce(
          (prev, player) => prev + (player.finished ? 1 : 0),
          0
        ),
      [gamePlayers]
    );

    const nextPress = React.useCallback(() => {
      if (!isLargeGame && scoredCount < gamePlayers.length) {
        const sure = window.confirm(t("gameScore.waiting.hostConfirm"));
        if (!sure) {
          return;
        }
      }

      onHostShowResultsPress?.();
    }, [
      isLargeGame,
      scoredCount,
      gamePlayers.length,
      onHostShowResultsPress,
      t,
    ]);

    const renderBody = React.useCallback(() => {
      return (
        <Container id="gameScore-waiting">
          <TextContainer>
            <Title>{t("gameScore.waiting.title")}</Title>
            {!isLargeGame && (
              <>
                <Count>
                  {t("gameScore.waiting.count", {
                    finished: scoredCount,
                    count: gamePlayers.length,
                  })}
                </Count>
                {spectatorCount > 0 && (
                  <Count>
                    {t("gameScore.waiting.spectatorCount", {
                      finished: poseSpectatorScoredCount,
                      count: spectatorCount,
                    })}
                  </Count>
                )}
              </>
            )}
          </TextContainer>

          {isLargeGame ? (
            <SuperlikeLoadingIndicator />
          ) : (
            <PlayerWaitingList
              players={gamePlayers}
              uid={playerUid}
              kickPlayer={kickPlayer}
            />
          )}
        </Container>
      );
    }, [
      t,
      isLargeGame,
      scoredCount,
      gamePlayers,
      spectatorCount,
      poseSpectatorScoredCount,
      playerUid,
      kickPlayer,
    ]);

    const renderFooter = React.useCallback(() => {
      if (!onHostShowResultsPress) {
        return null;
      }

      return (
        <GameFooter>
          <Button onClick={nextPress}>{t("gameScore.waiting.hostNext")}</Button>
        </GameFooter>
      );
    }, [nextPress, onHostShowResultsPress, t]);

    return (
      <GameScreen
        id="gameScore"
        logo={gameLogo}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
