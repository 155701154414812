import React from "react";
import { Redirect } from "react-router-dom";

export interface FinishedProps {
  gameId: string;
}

export const Finished: React.FunctionComponent<FinishedProps> = ({
  gameId,
}) => {
  return <Redirect to={`/gallery/${gameId}`} />;
};
