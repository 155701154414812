/* eslint-disable no-nested-ternary */
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { GamePlayer, GameSnapshot } from "@pose-party/types";

import { AspectCard } from "./AspectCard";
import { LargeTitle } from "./Title";
import { SnapshotDisplay } from "../Game/SnapshotDisplay";

export interface ResultsItemProps {
  player: GamePlayer;
  type: "pose" | "overall";
  isLargeGame: boolean;
  snapshot?: GameSnapshot;
  smallText?: boolean;
}

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;
const ResultsText = styled(LargeTitle)<{ small?: boolean }>`
  font-size: ${(props) => (props.small ? "20px" : "25px")};
  line-height: ${(props) => (props.small ? "27px" : "32px")};
`;
const PlayerDetails = styled(ResultsText)`
  margin-top: 22px;
`;
const GroupPosition = styled(ResultsText)`
  font-weight: 500;
`;
const Position = styled(ResultsText)`
  margin-bottom: 12px;
  font-weight: 500;
`;
const PositionNumber = styled.span`
  font-weight: 900;
`;
const Score = styled.span`
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const placeWithSuffix = (place: number): string => {
  const lastDigit = place % 10;
  const suffix =
    place === 11 || place === 12 || place === 13
      ? "th"
      : lastDigit === 1
      ? "st"
      : lastDigit === 2
      ? "nd"
      : lastDigit === 3
      ? "rd"
      : "th";

  return `${place}${suffix}`;
};

export const ResultsItem: React.FunctionComponent<ResultsItemProps> = React.memo(
  ({ player, type, isLargeGame, snapshot, smallText }) => {
    const results = type === "pose" ? player.poseResults : player.results;
    const groupResults =
      type === "pose" ? player.groupPoseResults : player.groupResults;

    if (!results) {
      return null;
    }

    const overallPosition = `${
      results.equalPosition ? "=" : ""
    }${placeWithSuffix(results.position)}`;

    const groupPosition = groupResults
      ? `${groupResults.equalPosition ? "=" : ""}${placeWithSuffix(
          groupResults.position
        )}`
      : undefined;

    return (
      <ListItem key={player.id}>
        <AspectCard aspectRatio={1} style={{ borderRadius: "5%" }}>
          <SnapshotDisplay
            snapshot={snapshot?.snapshot || player.profilePicture}
          />
        </AspectCard>

        <PlayerDetails small={smallText}>
          {player.name} <Score>{results.score}</Score>
        </PlayerDetails>

        {isLargeGame && !!groupPosition && (
          <GroupPosition small={smallText}>
            <Trans
              i18nKey="resultsItem.groupPosition"
              values={{
                position: groupPosition,
              }}
              components={[<PositionNumber>Dummy</PositionNumber>]}
            />
          </GroupPosition>
        )}

        <Position small={smallText}>
          <Trans
            i18nKey={
              isLargeGame
                ? "resultsItem.overallPositionLarge"
                : "resultsItem.overallPosition"
            }
            values={{
              position: overallPosition,
            }}
            components={[<PositionNumber>Dummy</PositionNumber>]}
          />
        </Position>
      </ListItem>
    );
  }
);
