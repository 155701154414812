import React from "react";
import styled from "styled-components";
import Color from "color";

export interface CheckboxInputProps
  extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
}

const CheckboxContainer = styled.div`
  display: inline-block;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.colors.secondaryBrandOverlay};
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-block;
  position: relative;
  top: 2px;
  width: 24px;
  height: 24px;
  background: ${(props) =>
    props.checked
      ? props.theme.colors.secondaryBrand
      : props.theme.colors.textInputBackground};
  border-radius: 4px;
  box-shadow: 0 0 0 0.125em
    ${(props) => Color(props.theme.colors.primaryBrand).alpha(0.25).toString()};
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const CheckboxInput: React.FunctionComponent<CheckboxInputProps> = ({
  className,
  id,
  checked,
  ...rest
}) => {
  return (
    <CheckboxContainer className={className} id={id}>
      <HiddenCheckbox checked={checked} {...rest} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
