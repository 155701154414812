import React from "react";
import styled from "styled-components";
import { GameStatus } from "@pose-party/types";
import { useTranslation } from "react-i18next";
import { HostGameControls } from "./HostGameControls";
import { HostGameSetupCounters } from "./HostGameSetupCounters";
import { HostGamePoseCounter } from "./HostGamePoseCounter";
import { HostGameScoreCounter } from "./HostGameScoreCounter";
import { HostGameSubmittedSnapshots } from "./HostGameSubmittedSnapshots";
import { HostGameFinished } from "./HostGameFinished";
import { HostGameReportedSnapshots } from "./HostGameReportedSnapshots";
import { HostGameScreen } from "../../components/HostGameScreen";
import { RegularTitle } from "../../components/Title";
import { HostGameProvider, useHostGame } from "../../../hooks/useHostGame";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useGameHostUI } from "../../../hooks/useGameHostUI";

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
`;
const GameName = styled(RegularTitle)``;
const Status = styled(Text)``;
const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;
const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 24px auto;
`;

export const HostGameContent: React.FunctionComponent = React.memo(() => {
  const { name, state, roundDetails } = useHostGame();
  const { t } = useTranslation();
  const { openScreenshareUI } = useGameHostUI();

  const stateText = React.useMemo(() => {
    switch (state.status) {
      case GameStatus.Setup:
        return t("hostGameUI.status.setup");
      case GameStatus.Pose:
        return t("hostGameUI.status.pose", roundDetails);
      case GameStatus.Score:
        return t("hostGameUI.status.score", roundDetails);
      case GameStatus.Results:
        return t("hostGameUI.status.results", roundDetails);
      case GameStatus.FinalResults:
      case GameStatus.Finished:
        return t("hostGameUI.status.finalResults");
    }
  }, [roundDetails, state.status, t]);

  const renderHeader = React.useCallback(() => {
    return (
      <HeaderContent>
        <div>
          <GameName>{name}</GameName>
          <Status>{stateText}</Status>
        </div>
        <Button onClick={openScreenshareUI} disableFullWidth>
          {t("hostGameUI.screenshareUI")}
        </Button>
      </HeaderContent>
    );
  }, [name, openScreenshareUI, stateText, t]);

  const renderBody = React.useCallback(() => {
    return (
      <BodyContainer>
        <BodyContent>
          <HostGameControls />

          {state.status === GameStatus.Setup && <HostGameSetupCounters />}
          {state.status === GameStatus.Pose && <HostGamePoseCounter />}
          {state.status === GameStatus.Score && (
            <>
              <HostGameScoreCounter />
              {!state.large && <HostGameSubmittedSnapshots />}
            </>
          )}
          {(state.status === GameStatus.FinalResults ||
            state.status === GameStatus.Finished) && <HostGameFinished />}

          <HostGameReportedSnapshots />
        </BodyContent>
      </BodyContainer>
    );
  }, [state.large, state.status]);

  return <HostGameScreen renderHeader={renderHeader} renderBody={renderBody} />;
});

export const HostGameUI: React.FunctionComponent = React.memo(() => {
  return (
    <HostGameProvider>
      <HostGameContent />
    </HostGameProvider>
  );
});
