import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Text } from "./Text";

const Row = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.background};
`;
const NameRowText = styled(Text)`
  flex: 1;
`;
const FixedRowText = styled(Text)`
  flex: 0;
  padding: 0 6px;
`;

export interface UserDataRowData {
  id: string | undefined;
  path: string;
  name: string | null;
  createdAt: Date | null;
}

export const UserDataRow = ({ data }: { data: UserDataRowData }) => {
  const { id, path, name, createdAt } = data;
  return (
    <Link to={path}>
      <Row>
        <NameRowText>{name}</NameRowText>
        {id && <FixedRowText subtle>{id}</FixedRowText>}
        <FixedRowText subtle style={{ textAlign: "right" }}>
          {createdAt?.toLocaleDateString()}
        </FixedRowText>
      </Row>
    </Link>
  );
};
