import React from "react";
import { v4 as uuid } from "uuid";
import { TournamentProtected } from "@pose-party/types";
import firebase from "../firebase";
import { logger } from "../../logger";
import { mapTournamentProtected } from "./utils";
import { api } from "../api";

/**
 * API call to create a new tournament
 */
export const newTournament = async (params: {
  brandId: string;
  hostId?: string;
  name: string;
  heatCount: number;
  topPlayers: number;
  heatPoses: string[];
  finalPoses: string[];
}) => {
  return api.post("newTournament", params);
};

/**
 * API call to delete a tournament
 */
export const deleteTournament = async (params: {
  brandId: string;
  tournamentId: string;
}) => {
  return api.post("deleteTournament", params);
};

/**
 * API call to delete a tournament
 */
export const acceptTournamentHostInvite = async (params: {
  brandId: string;
  tournamentId: string;
  additionalHostCode: string;
}) => {
  return api.post("acceptTournamentHostInvite", params);
};

/**
 * API call to update a tournament logo
 */
export const updateTournamentLogo = async ({
  brandId,
  tournamentId,
  file,
  logoUrl,
}: {
  brandId: string;
  tournamentId: string;
  file?: File;
  logoUrl?: string;
}) => {
  try {
    let fileRef: string | null = null;
    if (file) {
      const uploadedFile = await firebase
        .storage()
        .ref(`/brands/${brandId}/gameLogos`)
        .child(tournamentId)
        .child(uuid())
        .put(file);
      fileRef = uploadedFile.ref.toString();
    }

    await firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("tournaments")
      .doc(tournamentId)
      .set(
        {
          logo: fileRef || null,
          logoUrl: logoUrl || null,
        },
        { merge: true }
      );
    return true;
  } catch (e) {
    logger.warn("Error updating tournament logo", e);
    return false;
  }
};

/**
 * Hook to receive the protected tournament data. Must be a main host or admin to access.
 */
export const useProtectedTournamentData = ({
  brandId,
  tournamentId,
}: {
  brandId: string;
  tournamentId: string | undefined;
}) => {
  const [tournament, setTournament] = React.useState<TournamentProtected>();

  React.useEffect(() => {
    if (!tournamentId) {
      return;
    }

    const documentRef = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("tournamentsProtected")
      .doc(tournamentId);

    return documentRef.onSnapshot((data) => {
      const values = data.data();
      if (values) {
        setTournament(mapTournamentProtected(data.id, values));
      } else {
        setTournament(undefined);
      }
    });
  }, [brandId, tournamentId]);

  return tournament;
};
