import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { GamePlayer, GameSnapshot, GameSound } from "@pose-party/types";
import { GameHeader } from "../../../components/GameHeader";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { RegularTitle } from "../../../components/Title";
import { ResultsItem } from "../../../components/ResultsItem";
import { useGameSounds } from "../../../../hooks/useGameSounds";
import { SuperlikeLoadingIndicator } from "../../../components/SuperlikeLoadingIndicator";

export interface OwnResultsInterstitialProps {
  roundNumber: number;
  prompt: string;
  currentPlayerPoseSnapshot: GameSnapshot | undefined;
  player: GamePlayer;
  isLargeGame: boolean;
  gameLogo: string | null | undefined;
}

const MainContainer = styled.div`
  flex: 1;
  padding: 16px 32px;
  overflow-y: auto;
`;

const Title = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 32px;
`;

export const OwnResultsInterstitial: React.FunctionComponent<OwnResultsInterstitialProps> = React.memo(
  ({
    roundNumber,
    prompt,
    currentPlayerPoseSnapshot,
    player,
    isLargeGame,
    gameLogo,
  }) => {
    const { t } = useTranslation();
    const { playGameSound } = useGameSounds();

    React.useEffect(() => {
      const ownPosition = isLargeGame
        ? player.groupPoseResults?.position
        : player.poseResults?.position;
      if (ownPosition === 1) {
        playGameSound(GameSound.RoundWinner);
      }
    }, [
      playGameSound,
      isLargeGame,
      player.groupPoseResults?.position,
      player.poseResults?.position,
    ]);

    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader logo={gameLogo} toggleMenu={toggleMenu} title={prompt} />
        );
      },
      [prompt, gameLogo]
    );

    const renderBody = React.useCallback(() => {
      if (currentPlayerPoseSnapshot) {
        return (
          <MainContainer>
            <Title>
              {t("ownResultsInterstitial.roundNumber", { number: roundNumber })}
            </Title>

            <ResultsItem
              player={player}
              type="pose"
              isLargeGame={isLargeGame}
              snapshot={currentPlayerPoseSnapshot}
            />
          </MainContainer>
        );
      } else {
        return (
          <MainContainer>
            <Title>{t("ownResultsInterstitial.waiting")}</Title>

            <SuperlikeLoadingIndicator />
          </MainContainer>
        );
      }
    }, [currentPlayerPoseSnapshot, isLargeGame, player, roundNumber, t]);

    return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
  }
);
