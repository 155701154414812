import React from "react";
import styled from "styled-components/macro";
import { GameScreen } from "../../../components/GameScreen";
import { MassiveTitle } from "../../../components/Title";
import { Text } from "../../../components/Text";

export interface PoseCountdownInterstitialProps {
  prompt: string;
  count: number;
}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CountText = styled(Text)`
  position: absolute;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  font-size: 644px;
  line-height: 878px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const Prompt = styled(MassiveTitle)`
  z-index: 2;
  text-align: center;
`;

export const PoseCountdownInterstitial: React.FunctionComponent<PoseCountdownInterstitialProps> = React.memo(
  ({ prompt, count }) => {
    const renderBody = React.useCallback(() => {
      return (
        <MainContainer id="gamePose">
          <CountText>{count}</CountText>
          <Prompt>{prompt}</Prompt>
        </MainContainer>
      );
    }, [count, prompt]);

    return <GameScreen renderBody={renderBody} />;
  }
);
