import React from "react";
import _ from "lodash";
import { GameListGame } from "@pose-party/types";
import { mapGameListGame } from "@pose-party/data";
import firebase from "../firebase";

export const usePlayerGames = ({
  brandId,
  uid,
}: {
  brandId: string;
  uid: string | undefined;
}) => {
  const [gamesList, setGamesList] = React.useState<GameListGame[]>();

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const gamesRef = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("games")
      .where("host", "==", uid)
      .where("deleted", "!=", true);

    return gamesRef.onSnapshot((data) => {
      const raw = data.docs.map(mapGameListGame);
      setGamesList(_.sortBy(raw, (g) => g.createdAt).reverse());
    });
  }, [brandId, uid]);

  return gamesList;
};

export const usePlayerGame = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string | undefined;
}) => {
  const [game, setGame] = React.useState<GameListGame>();

  React.useEffect(() => {
    if (!gameId) {
      return;
    }

    const gamesRef = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("games")
      .doc(gameId);

    return gamesRef.onSnapshot((data) => {
      const raw = mapGameListGame(data);
      setGame(raw);
    });
  }, [brandId, gameId]);

  return game;
};

export const useGameList = ({
  brandId,
  gameIds,
}: {
  brandId: string;
  gameIds: string[] | undefined;
}) => {
  const gameIdGroups = React.useMemo(() => _.chunk(gameIds, 10), [gameIds]);
  const [gameLists, setGameLists] = React.useState<{
    [index: number]: GameListGame[];
  }>([]);
  const gamesList = React.useMemo(() => {
    if (gameIdGroups.length === Object.keys(gameLists).length) {
      return _.chain(gameLists)
        .flatMap((g) => g)
        .sortBy((g) => g.name)
        .value();
    } else {
      return undefined;
    }
  }, [gameLists, gameIdGroups.length]);

  React.useEffect(() => {
    if (gameIdGroups.length === 0) {
      return;
    }

    const unsubscribes = gameIdGroups.map((ids, index) => {
      const gamesRef = firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .collection("games")
        .where("tournamentId", "!=", false)
        .where("gameId", "in", ids);

      return gamesRef.onSnapshot((data) => {
        setGameLists((prev) => ({
          ...prev,
          [index]: data.docs.map(mapGameListGame),
        }));
      });
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [brandId, gameIdGroups]);

  return gamesList;
};
