import React from "react";
import styled from "styled-components";
import { useBrand } from "../../data/brand";

const Button = styled.button`
  position: absolute;
  top: 21px;
  left: 8px;
  background: none;
  border: 0;
  z-index: 10;
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;

export const MenuToggle: React.FunctionComponent<React.HTMLAttributes<
  HTMLButtonElement
>> = (props) => {
  const { brandDetails } = useBrand();

  return (
    <Button {...props}>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
          fill={brandDetails.colors.headerBackgroundOverlay}
        />
      </svg>
    </Button>
  );
};
