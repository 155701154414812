import React from "react";
import {
  loadBrandDetails,
  useUserBrandDetails,
  useGameBrandDetails,
} from "@pose-party/data";
import { BrandDetails } from "@pose-party/types";
import { BrandContext } from "./BrandContext";
import { sanitizeBrandId } from "./utils";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";

export const BrandProvider: React.FunctionComponent = ({ children }) => {
  /**
   * Use the brand ID based on the hostname
   */
  const brandId = React.useMemo(
    () => sanitizeBrandId(window.location.hostname),
    []
  );

  /**
   * Load the brand details
   */
  const [baseBrandDetails, setBrandDetails] = React.useState<BrandDetails>();
  React.useEffect(() => {
    const snapshotUnsubscribe = loadBrandDetails(
      { brandId },
      (newBrandDetails) => {
        // Redirect away if there are no brand details to load
        if (!newBrandDetails) {
          window.location.assign("https://poseparty.com");
          return;
        }

        setBrandDetails(newBrandDetails);
      }
    );

    return snapshotUnsubscribe;
  }, [brandId]);

  /**
   * Load the user specific brand settings, with the overrides and then use this as the brand
   * details we use for most of the site. This allows any brand setting to be overriden for a
   * specific user.
   */
  const [uid, setCurrentUid] = React.useState<string>();
  const userBrandDetailsPartial = useUserBrandDetails({ brandId, uid });

  /**
   * Load the game specific brand settings. These will be updated when the game id is set and will
   * mirror the primary host's overriden brand settings. These should be used when using brand
   * settings inside a specific game or gallery.
   */
  const [gameDetails, setGameDetails] = React.useState<{
    gameId: string;
    hostId: string;
  }>();
  const gameHostBrandDetailsPartial = useUserBrandDetails({
    brandId,
    uid: gameDetails?.hostId,
  });
  const gameBrandDetailsPartial = useGameBrandDetails({
    brandId,
    gameId: gameDetails?.gameId,
  });

  /**
   * Combine all the sets of the brand details we've loaded into a single brand details object
   */
  const brandDetailsInternal = React.useMemo((): BrandDetails | undefined => {
    if (!baseBrandDetails) {
      return undefined;
    }

    // Use the game host brand settings as the user setting, if there is some. IF not, use the signed in user ones
    const userBrandDetails =
      gameHostBrandDetailsPartial || userBrandDetailsPartial;

    return {
      ...baseBrandDetails,
      ...userBrandDetails,
      ...gameBrandDetailsPartial,
    };
  }, [
    baseBrandDetails,
    gameBrandDetailsPartial,
    gameHostBrandDetailsPartial,
    userBrandDetailsPartial,
  ]);
  // Whenever this used by a child view, it will never be undefined. This keeps TS happy
  const brandDetails = brandDetailsInternal as BrandDetails;

  const hasLoadedBrandDetails = React.useMemo(() => {
    // We've not loaded if there is a uid set but we have not loaded a user brand settings yet
    if (uid && userBrandDetailsPartial === undefined) {
      return false;
    }

    // We've not loaded if there is some game details set, but we have not loaded both of the game brand details yet
    if (
      gameDetails &&
      (gameHostBrandDetailsPartial === undefined ||
        gameBrandDetailsPartial === undefined)
    ) {
      return false;
    }

    // Otherwise, we must be loaded
    return true;
  }, [
    gameBrandDetailsPartial,
    gameDetails,
    gameHostBrandDetailsPartial,
    uid,
    userBrandDetailsPartial,
  ]);

  /**
   * Change the page title when the brand name changes
   */
  React.useEffect(() => {
    if (!brandDetailsInternal) {
      return;
    }

    document.title = brandDetailsInternal.name;
  }, [brandDetailsInternal]);

  /**
   * Set the favicon based on the brand icon property
   */
  const brandIconUrl = useGoogleStorageUrl(brandDetailsInternal?.icon);
  React.useEffect(() => {
    const icon = brandIconUrl || "/icon192.png";

    const links = document.getElementsByClassName("favicon");
    for (let i = 0; i < links.length; i += 1) {
      const link = links.item(i);
      if (link) {
        /// @ts-ignore
        link.href = icon;
      }
    }
  }, [brandIconUrl]);

  /**
   * Return the hook values via context
   */
  return (
    <BrandContext.Provider
      value={{
        brandId,
        brandDetails,
        hasLoadedBrandDetails,
        setCurrentUid,
        setGameDetails,
      }}
    >
      {!!brandDetails && children}
    </BrandContext.Provider>
  );
};
