import React from "react";
import sortBy from "lodash/sortBy";
import { GamePose } from "@pose-party/types";
import firebase, { useFBDatabaseCollection } from "../firebase";
import { logger } from "../../logger";

/**
 * Internal method to get the next unplayed pose id
 */
export const getNextUnplayedPoseId = async (
  gameRef: firebase.database.Reference
): Promise<string | undefined> => {
  const firstPose = await gameRef
    .child(`poses`)
    .orderByChild("order")
    .once("value");
  if (firstPose.numChildren() === 0) {
    throw new Error("There is no pose to start the game");
  }
  // Start the pose
  let poseId: string | undefined;
  firstPose.forEach((pose) => {
    if (!poseId && pose.key && !pose.child("played").exists()) {
      poseId = pose.key;
    }
  });

  return poseId;
};

/**
 * Hook to get the poses of a given game.
 *
 * `undefined`: Not yet loaded.
 * `GamePose[]`: The game poses. Might be empty
 */
export const useGamePoses = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string | undefined;
}) => {
  const gamePoses = useFBDatabaseCollection<GamePose>(
    gameId ? `/brands/${brandId}/games/${gameId}/poses` : undefined,
    React.useCallback(
      (id, data) => ({
        id,
        prompt: data.child("prompt").val() || "",
        played: Number.parseInt(data.child("played").val(), 10) || undefined,
        timing: Number.parseInt(data.child("timing").val(), 10) || undefined,
        order: data.child("order").val() ?? Number.MAX_VALUE,
      }),
      []
    )
  );
  const shortedGamePoses = React.useMemo(() => sortBy(gamePoses, "order"), [
    gamePoses,
  ]);

  React.useEffect(() => {
    logger.info("Game poses updated", shortedGamePoses);
  }, [shortedGamePoses]);

  return shortedGamePoses;
};

/**
 * Hook to get a single pose
 *
 * `undefined`: Not yet loaded.
 * `null`: No pose.
 * `GamePose`: The game pose.
 */
export const useGamePose = ({
  brandId,
  gameId,
  poseId,
}: {
  brandId: string;
  gameId: string | undefined;
  poseId: string | undefined;
}): GamePose | null | undefined => {
  const [gamePose, setGamePose] = React.useState<GamePose | null | undefined>();

  React.useEffect(() => {
    setGamePose(undefined);

    if (!gameId || !poseId) {
      return;
    }

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/poses/${poseId}`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      setGamePose(
        data.val()
          ? {
              id: poseId,
              prompt: data.child("prompt").val() || "",
              played:
                Number.parseInt(data.child("played").val(), 10) || undefined,
              timing:
                Number.parseInt(data.child("timing").val(), 10) || undefined,
              order: data.child("order").val() ?? Number.MAX_VALUE,
            }
          : null
      );
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId, poseId]);

  return gamePose;
};
