import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import { Text } from "./Text";

export interface MarkdownProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  source?: string;
  linkTarget?: string;
}

export const Markdown: React.FunctionComponent<MarkdownProps> = ({
  source,
  linkTarget,
  className,
  ...rest
}) => {
  return (
    <Text className={`content ${className}`} {...rest}>
      <ReactMarkdown
        source={source}
        escapeHtml={false}
        linkTarget={linkTarget}
      />
    </Text>
  );
};
