import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "../../../components/Button";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { TermsCheckbox } from "../../../components/TermsCheckbox";
import { Text } from "../../../components/Text";
import { TextInput } from "../../../components/TextInput";
import { useAuth } from "../../../../data/auth";
import { useFormInput } from "../../../../hooks/useFormInput";
import { GameHeader } from "../../../components/GameHeader";
import { useFormCheckbox } from "../../../../hooks/useFormCheckbox";
import { useBrand } from "../../../../data/brand";

export interface PlayerNameSetupProps {
  gameLogo: string | null | undefined;
  onSetPlayerName: (name: string) => void;
}

const NameForm = styled.form`
  padding: 32px 16px;
`;
const FormTitle = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;
const FormTextInput = styled(TextInput)`
  margin-bottom: 16px;
`;

export const PlayerNameSetup: React.FunctionComponent<PlayerNameSetupProps> = React.memo(
  ({ gameLogo, onSetPlayerName }) => {
    const {
      brandDetails: { maxPlayerNameLength },
    } = useBrand();
    const { userDetails, updateName, acceptTerms } = useAuth();
    const [playerName, onPlayerNameChange, setPlayerName] = useFormInput({
      defaultValue: userDetails?.name,
    });
    const { t } = useTranslation();

    React.useEffect(() => {
      if (userDetails?.name) {
        setPlayerName(userDetails?.name);
      }
    }, [setPlayerName, userDetails]);

    /**
     * Handle the terms checkbox
     */

    const shouldDisplayTermsCheckbox = !userDetails?.acceptedTerms;
    const [termsCheckbox, handleTermsCheckboxChange] = useFormCheckbox();

    /**
     * Handle submitting the player name
     */
    const handlePlayerNameSubmit = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const trimmedPlayerName = playerName.trim();
        if (trimmedPlayerName.length > 0) {
          onSetPlayerName(trimmedPlayerName);

          try {
            updateName(trimmedPlayerName);

            if (shouldDisplayTermsCheckbox) {
              acceptTerms();
            }
          } catch (e) {
            // Ignore
          }
        }
      },
      [
        acceptTerms,
        onSetPlayerName,
        playerName,
        shouldDisplayTermsCheckbox,
        updateName,
      ]
    );

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameSetup.title")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      return (
        <NameForm onSubmit={handlePlayerNameSubmit}>
          <FormTitle weight="bold">{t("gameSetup.nameSetup.title")}</FormTitle>
          <FormTextInput
            id="playerName-nameInput"
            type="text"
            placeholder={t("gameSetup.nameSetup.prompt")}
            value={playerName}
            maxLength={maxPlayerNameLength}
            onChange={onPlayerNameChange}
          />

          {shouldDisplayTermsCheckbox && (
            <TermsCheckbox
              checked={termsCheckbox}
              onChange={handleTermsCheckboxChange}
            />
          )}

          <Button
            id="playerName-continue"
            type="submit"
            disabled={
              !playerName || (shouldDisplayTermsCheckbox && !termsCheckbox)
            }
          >
            {t("common.continue")}
          </Button>
        </NameForm>
      );
    }, [
      handlePlayerNameSubmit,
      handleTermsCheckboxChange,
      maxPlayerNameLength,
      onPlayerNameChange,
      playerName,
      shouldDisplayTermsCheckbox,
      t,
      termsCheckbox,
    ]);

    return (
      <GameScreen
        id="gameSetup-playerName"
        logo={gameLogo}
        renderHeader={renderHeader}
        renderBody={renderBody}
      />
    );
  }
);
