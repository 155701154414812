import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGameBrandDetails } from "@pose-party/data";
import { BrandDetails } from "@pose-party/types";
import { useHistory, useParams } from "react-router-dom";
import { GameScreen } from "../../components/GameScreen";
import { GameHeader } from "../../components/GameHeader";
import { AccentButton } from "../../components/Button";
import { RegularTitle } from "../../components/Title";
import { CopyContainer } from "../../components/CopyContainer";
import { GameAdvancedSettingsForm } from "../../components/GameAdvancedSettingsForm";
import {
  useGameGalleryPin,
  setGameLock,
  removeGameLock,
  setGamePin,
  removeGamePin,
  useGamePlayerInviteCode,
  useGameLogo,
  usePlayerGame,
  useGameLocked,
  updateGameBrandDetailsOverride,
} from "../../../data/game";
import { Text } from "../../components/Text";
import { useBrand } from "../../../data/brand";
import { useFormCheckbox } from "../../../hooks/useFormCheckbox";

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 32px 16px;
  position: relative;
`;
const Section = styled.section`
  margin-bottom: 32px;
`;
const HintText = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;
const PinHintText = styled(HintText)`
  margin-top: 32px;
`;
const CopyText = styled(CopyContainer)`
  margin-bottom: 16px;
`;

const getBooleanValue = (
  key: keyof BrandDetails,
  brandDetails: BrandDetails,
  gameBrandDetails: Partial<BrandDetails> | undefined
): boolean => {
  if (typeof gameBrandDetails?.[key] === "boolean") {
    return !!gameBrandDetails?.[key];
  } else if (typeof brandDetails[key] === "boolean") {
    return !!brandDetails[key];
  } else {
    return false;
  }
};

const useAdvancedGameDetailsSetting = ({
  key,
  brandId,
  gameId,
  brandDetails,
  gameBrandDetails,
}: {
  key: keyof BrandDetails;
  brandId: string;
  gameId: string;
  brandDetails: BrandDetails;
  gameBrandDetails: Partial<BrandDetails> | undefined;
}): [boolean, (event: React.ChangeEvent<HTMLInputElement>) => void] => {
  const [value, onValueChangeInternal, setValue] = useFormCheckbox(
    getBooleanValue(key, brandDetails, gameBrandDetails)
  );
  React.useEffect(() => {
    setValue(getBooleanValue(key, brandDetails, gameBrandDetails));
  }, [brandDetails, gameBrandDetails, key, setValue]);
  const onValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateGameBrandDetailsOverride({
        brandId,
        gameId,
        key,
        // Only set the game brand override it does not equal the brand option
        // Otherwise we remove it from the overrides as it's not overriding anything
        value:
          event.target.checked !== brandDetails[key]
            ? event.target.checked
            : undefined,
      });

      // Update the checkbox valueitself
      onValueChangeInternal(event);
    },
    [brandDetails, brandId, gameId, key, onValueChangeInternal]
  );

  return [value, onValueChange];
};

export const EditGameAdvanced: React.FunctionComponent = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { brandId, brandDetails } = useBrand();
  const {
    allowProtectedGalleries,
    deleteGamesWhenFinished,
    allowLockedGames,
  } = brandDetails;

  /**
   * Load the game data
   */
  const game = usePlayerGame({ brandId, gameId });
  const gameLogo = useGameLogo({ brandId, gameId });
  const isGameLocked = useGameLocked({ brandId, gameId });
  const gameGalleryPin = useGameGalleryPin({ brandId, gameId });
  const playerInviteCode = useGamePlayerInviteCode({ brandId, gameId });
  const gameBrandDetails = useGameBrandDetails({ brandId, gameId });

  /**
   * Handle the still image mode
   */
  const [
    stillSnapshots,
    onStillSnapshotsChange,
  ] = useAdvancedGameDetailsSetting({
    key: "stillSnapshots",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle the default camera
   */
  const [
    defaultToUserFacingCamera,
    onDefaultToUserFacingCameraChange,
  ] = useAdvancedGameDetailsSetting({
    key: "defaultToUserFacingCamera",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle allowing camera swapping
   */
  const [
    allowSwapCamera,
    onAllowSwapCameraChange,
  ] = useAdvancedGameDetailsSetting({
    key: "allowSwapCamera",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle allowing camera swapping
   */
  const [
    allowSnapshotWebcam,
    onAllowSnapshotWebcamChange,
  ] = useAdvancedGameDetailsSetting({
    key: "allowSnapshotWebcam",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle allowing camera swapping
   */
  const [
    allowSnapshotUpload,
    onAllowSnapshotUploadChange,
  ] = useAdvancedGameDetailsSetting({
    key: "allowSnapshotUpload",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle enabling self timer
   */
  const [
    enableCameraTimer,
    onEnableCameraTimerChange,
  ] = useAdvancedGameDetailsSetting({
    key: "enableCameraTimer",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle hiding the round countdown timer
   */
  const [
    hideRoundCountdown,
    onHideRoundCountdownChange,
  ] = useAdvancedGameDetailsSetting({
    key: "hideRoundCountdown",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle the allow join late setting
   */
  const [allowJoinLate, onAllowJoinLateChange] = useAdvancedGameDetailsSetting({
    key: "allowJoinLate",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Handle the allow spectators setting
   */
  const [
    allowSpectators,
    onAllowSpectatorsChange,
  ] = useAdvancedGameDetailsSetting({
    key: "allowSpectators",
    brandId,
    gameId,
    brandDetails,
    gameBrandDetails,
  });

  /**
   * Methods to handle the button presses
   */
  const handleBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  /**
   * Method
   */
  const [isModifyingLock, setIsModifyingLock] = React.useState(false);
  const handleEnableLock = React.useCallback(async () => {
    setIsModifyingLock(true);

    try {
      setGameLock({ brandId, gameId });
    } catch (e) {
      alert(t("gameDetails.errorSettingGameLock"));
    }

    setIsModifyingLock(false);
  }, [brandId, gameId, t]);
  const handleDisableLock = React.useCallback(async () => {
    setIsModifyingLock(true);

    try {
      removeGameLock({ brandId, gameId });
    } catch (e) {
      alert(t("gameDetails.errorRemovingGameLock"));
    }

    setIsModifyingLock(false);
  }, [brandId, gameId, t]);

  const [isModifyingPin, setIsModifyingPin] = React.useState(false);
  const handleEnablePin = React.useCallback(async () => {
    setIsModifyingPin(true);

    try {
      setGamePin({ brandId, gameId });
    } catch (e) {
      alert(t("gameDetails.errorSettingGamePin"));
    }

    setIsModifyingPin(false);
  }, [brandId, gameId, t]);
  const handleDisablePin = React.useCallback(async () => {
    setIsModifyingPin(true);

    try {
      removeGamePin({ brandId, gameId });
    } catch (e) {
      alert(t("gameDetails.errorRemovingGamePin"));
    }

    setIsModifyingPin(false);
  }, [brandId, gameId, t]);

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return (
      <GameHeader
        logo={gameLogo}
        goBack={handleBack}
        title={game?.name || ""}
      />
    );
  }, [game, gameLogo, handleBack]);

  const renderBody = React.useCallback(() => {
    if (!game?.name || gameBrandDetails === undefined) {
      return (
        <LoadingContainer>
          <RegularTitle>{t("gameDetails.loading")}</RegularTitle>
        </LoadingContainer>
      );
    }

    const shortUrl = `${brandDetails.domain}/${gameId}`;
    const playerInviteShortUrl =
      typeof playerInviteCode === "string"
        ? `${shortUrl}/invitePlayer/${playerInviteCode}`
        : undefined;

    return (
      <Content>
        {!!isGameLocked && (
          <Section>
            <HintText>
              {game.tournamentId
                ? t("gameDetails.tournamentLockedInviteHint")
                : t("gameDetails.lockedInviteHint")}
            </HintText>
            <CopyText
              title={t("gameDetails.url")}
              text={`https://${playerInviteShortUrl}`}
              displayText={playerInviteShortUrl}
            />
            {!game.tournamentId && (
              <AccentButton
                className={isModifyingLock ? "is-loading" : ""}
                onClick={handleDisableLock}
              >
                {t("gameDetails.disableGameLock")}
              </AccentButton>
            )}
          </Section>
        )}

        {!isGameLocked && allowLockedGames && !!playerInviteShortUrl && (
          <Section>
            <HintText>{t("gameDetails.canLockInviteHint")}</HintText>
            <AccentButton
              className={isModifyingLock ? "is-loading" : ""}
              onClick={handleEnableLock}
            >
              {t("gameDetails.enableGameLock")}
            </AccentButton>
          </Section>
        )}

        {allowProtectedGalleries && !deleteGamesWhenFinished && (
          <Section>
            <PinHintText>{t("gameDetails.galleryPinHintText")}</PinHintText>
            {!!gameGalleryPin && (
              <CopyText
                title={t("gameDetails.galleryPin")}
                text={gameGalleryPin}
              />
            )}
            {gameGalleryPin ? (
              <AccentButton
                className={isModifyingPin ? "is-loading" : ""}
                onClick={handleDisablePin}
              >
                {t("gameDetails.disableGalleryPin")}
              </AccentButton>
            ) : (
              <AccentButton
                className={isModifyingPin ? "is-loading" : ""}
                onClick={handleEnablePin}
              >
                {t("gameDetails.enableGalleryPin")}
              </AccentButton>
            )}
          </Section>
        )}

        {brandDetails.allowHostPerGameSettings && (
          <GameAdvancedSettingsForm
            stillSnapshots={stillSnapshots}
            onStillSnapshotsChange={onStillSnapshotsChange}
            defaultToUserFacingCamera={defaultToUserFacingCamera}
            onDefaultToUserFacingCameraChange={
              onDefaultToUserFacingCameraChange
            }
            allowSwapCamera={allowSwapCamera}
            onAllowSwapCameraChange={onAllowSwapCameraChange}
            allowSnapshotWebcam={allowSnapshotWebcam}
            onAllowSnapshotWebcamChange={onAllowSnapshotWebcamChange}
            allowSnapshotUpload={allowSnapshotUpload}
            onAllowSnapshotUploadChange={onAllowSnapshotUploadChange}
            enableCameraTimer={enableCameraTimer}
            onEnableCameraTimerChange={onEnableCameraTimerChange}
            hideRoundCountdown={hideRoundCountdown}
            onHideRoundCountdownChange={onHideRoundCountdownChange}
            allowJoinLate={allowJoinLate}
            onAllowJoinLateChange={onAllowJoinLateChange}
            allowSpectators={allowSpectators}
            onAllowSpectatorsChange={onAllowSpectatorsChange}
            isLargeGame={!!game?.large}
          />
        )}
      </Content>
    );
  }, [
    allowJoinLate,
    allowLockedGames,
    allowProtectedGalleries,
    allowSnapshotUpload,
    allowSnapshotWebcam,
    allowSpectators,
    allowSwapCamera,
    brandDetails.allowHostPerGameSettings,
    brandDetails.domain,
    defaultToUserFacingCamera,
    deleteGamesWhenFinished,
    enableCameraTimer,
    game,
    gameBrandDetails,
    gameGalleryPin,
    gameId,
    handleDisableLock,
    handleDisablePin,
    handleEnableLock,
    handleEnablePin,
    hideRoundCountdown,
    isGameLocked,
    isModifyingLock,
    isModifyingPin,
    onAllowJoinLateChange,
    onAllowSnapshotUploadChange,
    onAllowSnapshotWebcamChange,
    onAllowSpectatorsChange,
    onAllowSwapCameraChange,
    onDefaultToUserFacingCameraChange,
    onEnableCameraTimerChange,
    onHideRoundCountdownChange,
    onStillSnapshotsChange,
    playerInviteCode,
    stillSnapshots,
    t,
  ]);

  return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
};
