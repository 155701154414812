import React from "react";
import { useTranslation } from "react-i18next";
import { WebcamSnapshot } from "@pose-party/types";
import styled from "styled-components";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { SnapshotButtons } from "../../../components/SnapshotButtons";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import {
  WebcamDisplay,
  WebcamDisplayStatic,
} from "../../../components/WebcamDisplay";
import { useAuth } from "../../../../data/auth";
import { AspectCard } from "../../../components/AspectCard";
import { GameHeader } from "../../../components/GameHeader";
import { SnapshotImagePickerButton } from "../../../components/SnapshotImagePickerButton";

export interface PlayerProfilePictureSetupProps {
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  stillMode: boolean;
  enableCameraTimer: boolean;
  enableUploadMode: boolean;
  onSetPlayerProfilePicture: (
    profilePicture: WebcamSnapshot
  ) => Promise<WebcamSnapshot | undefined>;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 32px 42px 32px;
`;

export const PlayerProfilePictureSetup: React.FunctionComponent<PlayerProfilePictureSetupProps> = React.memo(
  ({
    gameLogo,
    helpContent,
    stillMode,
    enableCameraTimer,
    enableUploadMode,
    onSetPlayerProfilePicture,
  }) => {
    const { updateProfilePicture } = useAuth();
    const webcamRef = React.createRef<WebcamDisplayStatic>();
    const [playerProfilePicture, onPlayerProfilePictureChange] = React.useState<
      WebcamSnapshot
    >();
    const { t } = useTranslation();

    /**
     * Handle the snapshot button
     */
    const [snapshotProgress, setSnapshotProgress] = React.useState<number>();
    const onTakeSnapshot = React.useCallback(async () => {
      if (snapshotProgress !== undefined || !webcamRef.current) {
        return;
      }

      setSnapshotProgress(0);
      const snapshot = await webcamRef.current.createSnapshot({
        stillMode,
        countdown: enableCameraTimer,
        onCaptureFrame: (progress) => {
          setSnapshotProgress(progress);
        },
      });
      onPlayerProfilePictureChange(snapshot);
      setSnapshotProgress(undefined);
    }, [enableCameraTimer, snapshotProgress, stillMode, webcamRef]);
    const onSwapCamera = React.useCallback(() => {
      webcamRef.current?.swapCamera();
    }, [webcamRef]);

    /**
     * Handle picking snapshots from the device
     */
    const onSnapshotPicked = React.useCallback((snapshot: WebcamSnapshot) => {
      onPlayerProfilePictureChange(snapshot);
      setSnapshotProgress(undefined);
    }, []);

    /**
     * Handle accepting and rejecting the snapshot
     */
    const onRejectSnapshot = React.useCallback(() => {
      onPlayerProfilePictureChange(undefined);
    }, []);
    const [uploadingSnapshot, setUploadingSnapshot] = React.useState(false);
    const onAcceptSnapshot = React.useCallback(async () => {
      if (!playerProfilePicture || uploadingSnapshot) {
        return;
      }

      try {
        setUploadingSnapshot(true);
        const setProfilePicture = await onSetPlayerProfilePicture(
          playerProfilePicture
        );
        if (!setProfilePicture) {
          return;
        }

        updateProfilePicture(setProfilePicture);
      } catch (e) {
        // Ignore
      } finally {
        setUploadingSnapshot(false);
      }
    }, [
      onSetPlayerProfilePicture,
      playerProfilePicture,
      updateProfilePicture,
      uploadingSnapshot,
    ]);

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameSetup.profilePictureSetup.title")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      return (
        <>
          <Container>
            <AspectCard aspectRatio={1}>
              {playerProfilePicture && (
                <SnapshotDisplay snapshot={playerProfilePicture} />
              )}
              {!playerProfilePicture && enableUploadMode && (
                <SnapshotImagePickerButton
                  onSnapshotPicked={onSnapshotPicked}
                />
              )}
              {!playerProfilePicture && !enableUploadMode && (
                <WebcamDisplay ref={webcamRef} />
              )}
            </AspectCard>
          </Container>
        </>
      );
    }, [enableUploadMode, onSnapshotPicked, playerProfilePicture, webcamRef]);

    const renderButtons = React.useCallback(() => {
      return (
        <SnapshotButtons
          snapshotProgress={snapshotProgress}
          isUploading={uploadingSnapshot}
          hasSnapshot={!!playerProfilePicture}
          onSwapCamera={onSwapCamera}
          onRejectSnapshot={onRejectSnapshot}
          onAcceptSnapshot={onAcceptSnapshot}
          onTakeSnapshot={enableUploadMode ? undefined : onTakeSnapshot}
        />
      );
    }, [
      snapshotProgress,
      uploadingSnapshot,
      playerProfilePicture,
      onSwapCamera,
      onRejectSnapshot,
      onAcceptSnapshot,
      enableUploadMode,
      onTakeSnapshot,
    ]);

    return (
      <GameScreen
        id="gameSetup-profilePicture"
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderButtons={renderButtons}
      />
    );
  }
);
