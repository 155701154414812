import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GameScreen } from "./GameScreen";
import { LargeTitle } from "./Title";

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled(LargeTitle)`
  text-align: center;
`;

export const FullScreenLoading = () => {
  const { t } = useTranslation();

  const renderBody = React.useCallback(() => {
    return (
      <Container>
        <LoadingText>{t("common.loading")}</LoadingText>
      </Container>
    );
  }, [t]);

  return <GameScreen renderBody={renderBody} />;
};
