import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AccentButton, Button } from "../../../components/Button";
import { Markdown } from "../../../components/Markdown";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { GameHeader } from "../../../components/GameHeader";
import { Text } from "../../../components/Text";
import { useBrand } from "../../../../data/brand";
import { useAuth } from "../../../../data/auth";
import { useGameHostUI } from "../../../../hooks/useGameHostUI";
import { GameQrCode } from "../../../components/GameQrCode";

export interface GameLandingPageProps {
  gameId: string;
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  gameName: string | null | undefined;
  content:
    | {
        top?: string;
        bottom?: string;
      }
    | undefined;
  isLargeGame: boolean;
  isHost: boolean;
  isDemo: boolean;
  isInPerson: boolean;
  onStart: () => void;
  onSpectate: () => void;
}

const Container = styled.div`
  padding: 0 16px 16px 16px;
  overflow: auto;
`;

const MarkdownContent = styled(Markdown)`
  margin-top: 16px;
`;

const StartButtonContainer = styled.div`
  margin-top: 16px;
`;

const AuthTitle = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;
const AuthButton = styled(Button)`
  margin-top: 8px;
`;
const LoginInLink = styled(Link)`
  display: block;
  padding: 8px 0;
  text-align: center;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
const LinkButton = styled.button`
  text-decoration: underline;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  margin-top: 8px;
  text-align: center;
  display: block;
  width: 100%;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    text-decoration: none;
  }
`;

export const GameLandingPage: React.FunctionComponent<GameLandingPageProps> = React.memo(
  ({
    gameId,
    gameLogo,
    helpContent,
    gameName,
    content,
    isLargeGame,
    isHost,
    isDemo,
    isInPerson,
    onStart,
    onSpectate,
  }) => {
    const { t } = useTranslation();
    const { isSignedIn } = useAuth();
    const {
      brandDetails: {
        allPlayersMustSignIn,
        allowSignUp,
        allowSpectators,
        allowHostGameUI,
      },
    } = useBrand();
    const location = useLocation();
    const history = useHistory();
    const { openHostGameUI, openScreenshareUI } = useGameHostUI();

    /**
     * Handle the auth
     */

    const requiresAuth = React.useMemo(() => {
      return allPlayersMustSignIn && !isSignedIn;
    }, [allPlayersMustSignIn, isSignedIn]);

    const handleHostAuth = React.useCallback(() => {
      history.push(`/signUp?continue=${location.pathname}`);
    }, [history, location.pathname]);

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            title={gameName || undefined}
            toggleMenu={toggleMenu}
          />
        );
      },
      [gameLogo, gameName]
    );

    const renderBody = React.useCallback(() => {
      return (
        <Container>
          {!!content?.top && (
            <MarkdownContent
              source={content.top.replace(/\{\{gameName\}\}/g, gameName || "")}
            />
          )}

          {isInPerson && <GameQrCode gameId={gameId} />}

          <StartButtonContainer>
            {requiresAuth ? (
              <>
                <AuthTitle weight="bold">
                  {t("gameSetup.landing.authTitle")}
                </AuthTitle>
                {allowSignUp && (
                  <AuthButton onClick={handleHostAuth}>
                    {t("gameSetup.landing.authSignUp")}
                  </AuthButton>
                )}
                <LoginInLink to={`/login?continue=${location.pathname}`}>
                  {t("gameSetup.landing.authLogin")}
                </LoginInLink>
              </>
            ) : (
              <>
                <AccentButton
                  type="button"
                  onClick={onStart}
                  id="landingPage-startGame"
                >
                  {t("gameSetup.landing.start")}
                </AccentButton>
                {allowSpectators && !isLargeGame && (
                  <LinkButton onClick={onSpectate}>
                    {t("gameSetup.landing.spectate")}
                  </LinkButton>
                )}
                {allowHostGameUI && isHost && !isDemo && (
                  <>
                    <LinkButton onClick={openScreenshareUI}>
                      {t("gameSetup.landing.screenshareUI")}
                    </LinkButton>
                    <LinkButton onClick={openHostGameUI}>
                      {t("gameSetup.landing.hostGameUI")}
                    </LinkButton>
                  </>
                )}
              </>
            )}
          </StartButtonContainer>

          {!!content?.bottom && (
            <MarkdownContent
              source={content.bottom.replace(
                /\{\{gameName\}\}/g,
                gameName || ""
              )}
            />
          )}
        </Container>
      );
    }, [
      allowHostGameUI,
      allowSignUp,
      allowSpectators,
      content,
      gameId,
      gameName,
      handleHostAuth,
      isDemo,
      isHost,
      isInPerson,
      isLargeGame,
      location.pathname,
      onSpectate,
      onStart,
      openHostGameUI,
      openScreenshareUI,
      requiresAuth,
      t,
    ]);

    return (
      <GameScreen
        id="gameSetup-landingPage"
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
      />
    );
  }
);
