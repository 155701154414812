import React from "react";
import styled from "styled-components/macro";
import { GameSound } from "@pose-party/types";
import { LargeTitle, RegularTitle } from "./Title";
import { MenuToggle } from "./MenuToggle";
import { useGameSounds } from "../../hooks/useGameSounds";
import { useInIFrame } from "../../hooks/useInIFrame";
import { BrandLogo } from "./BrandLogo";
import { useBrand } from "../../data/brand";
import { BackButton } from "./BackButton";
import { Text } from "./Text";
import { useGame } from "../../hooks/useGame";

export interface HeaderCountdownDetails {
  start: number;
  end: number;
  playClick?: boolean;
}
export interface GameHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  logo?: string | null | undefined;
  title?: string;
  subtitle?: string;
  toggleMenu?: () => void;
  goBack?: () => void;
  countdownDetails?: HeaderCountdownDetails;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 62px;
`;

const HeaderLogo = styled(BrandLogo)`
  height: 58px;
  max-width: 70%;
  margin: 8px auto 0 auto;
`;

const HeaderTitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
`;

const HeaderTitle = styled(LargeTitle)`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
  margin: 14px 0;
`;

const HeaderSubtitle = styled(Text)`
  flex: 1;
  text-align: center;
  vertical-align: center;
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
  margin-bottom: 12px;
`;

const CounterText = styled(RegularTitle)`
  position: absolute;
  right: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
`;

const HeaderCountdown = styled.div`
  height: 4px;
  background-color: ${(props) => props.theme.colors.primaryBrand};
  width: 100%;
`;

export const GameHeader: React.FunctionComponent<GameHeaderProps> = ({
  logo,
  title,
  subtitle,
  toggleMenu,
  goBack,
  countdownDetails,
  ...rest
}) => {
  const inIFrame = useInIFrame();
  const { playGameSound } = useGameSounds();
  const {
    brandDetails: { showLogoInHeader },
  } = useBrand();
  const { data: gameData } = useGame();
  const [currentProgress, setCurrentProgress] = React.useState(0);
  const [currentSeconds, setCurrentSeconds] = React.useState(0);
  const requestRef = React.useRef<number>();
  const animate = React.useCallback(() => {
    if (!countdownDetails) {
      return;
    }

    // Work out how much time is left
    const roundTimeLeft = countdownDetails.end - Date.now();

    // Check if we're finished
    if (roundTimeLeft <= 0) {
      setCurrentProgress(0);
      setCurrentSeconds(0);
      return;
    }

    // Set the number of seconds
    setCurrentSeconds(Math.ceil(roundTimeLeft / 1000));

    // Work out the percentage that we're through the given time
    const length = countdownDetails.end - countdownDetails.start;
    setCurrentProgress(roundTimeLeft / length);

    // Request another animation frame
    requestRef.current = requestAnimationFrame(animate);
  }, [countdownDetails]);
  React.useEffect(() => {
    if (!countdownDetails) {
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [animate, countdownDetails]);

  /**
   * Play the countdown click when there is 5 seconds left on the timer
   */
  const soundLoop = React.useRef<() => void>();
  React.useEffect(() => {
    if (
      countdownDetails?.playClick &&
      currentSeconds > 0 &&
      currentSeconds <= 5
    ) {
      if (!soundLoop.current) {
        soundLoop.current = playGameSound(GameSound.Countdown);
      }
    } else if (soundLoop.current) {
      soundLoop.current?.();
      soundLoop.current = undefined;
    }
  }, [countdownDetails, playGameSound, currentSeconds]);
  // Stop the sound when we unmount
  React.useEffect(() => {
    return () => {
      soundLoop.current?.();
      soundLoop.current = undefined;
    };
  }, []);

  return (
    <>
      {!inIFrame && !gameData?.state?.inPerson && !!toggleMenu && (
        <MenuToggle onClick={toggleMenu} />
      )}
      {!!goBack && <BackButton onClick={goBack} />}
      <Header {...rest}>
        {showLogoInHeader && <HeaderLogo overrideSrc={logo} />}

        <HeaderTitleContainer>
          {!!title && <HeaderTitle>{title}</HeaderTitle>}
          {!!currentSeconds && <CounterText>{currentSeconds}s</CounterText>}
        </HeaderTitleContainer>
        {!!subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
        <HeaderCountdown style={{ width: `${currentProgress * 100}%` }} />
      </Header>
    </>
  );
};
