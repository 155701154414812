import React from "react";
import _ from "lodash";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GamePlayer } from "@pose-party/types";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { Button } from "../../../components/Button";
import { TextInput } from "../../../components/TextInput";
import { ResultsItem } from "../../../components/ResultsItem";
import { useFormInput } from "../../../../hooks/useFormInput";
import { logger } from "../../../../logger";
import { GameHeader } from "../../../components/GameHeader";
import { useBrand } from "../../../../data/brand";
import { useInIFrame } from "../../../../hooks/useInIFrame";
import { api } from "../../../../data/api";

export interface FinalResultsListProps {
  brandId: string;
  gameId: string;
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  isLargeGame: boolean;
  isInPerson: boolean;
  gamePlayers: GamePlayer[];
  hasEmail: boolean;
}

const Container = styled.div`
  padding: 16px 32px;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: 12px;
`;

export const FooterForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
`;
const EmailTextInput = styled(TextInput)`
  flex: 1;
  margin-right: 12px;
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};
`;
const SendEmailButton = styled(Button)``;

const ViewGalleryContainer = styled.div``;
const ViewGalleryButton = styled(Button)``;

export const FinalResultsList: React.FunctionComponent<FinalResultsListProps> = React.memo(
  ({
    brandId,
    gameId,
    gameLogo,
    helpContent,
    isLargeGame,
    isInPerson,
    gamePlayers,
    hasEmail,
  }) => {
    const { t } = useTranslation();
    const inIFrame = useInIFrame();
    const history = useHistory();
    const {
      brandDetails: { deleteGamesWhenFinished },
    } = useBrand();

    /**
     * Handle the email form
     */
    const [email, handleEmailChange] = useFormInput();
    const [isSharing, setIsSharing] = React.useState(false);
    const handleSubmit = React.useCallback(
      async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!email.trim()) {
          return;
        }

        setIsSharing(true);
        try {
          await api.post("shareGalleryEmail", {
            email: email.trim(),
            brandId,
            gameId,
          });

          alert("Email sent! Check your email for the gallery URL");
        } catch (e) {
          logger.warn("Error while sharing", e);
          alert(
            "Error while sharing. Please check your email address and try again."
          );
        }
        setIsSharing(false);
      },
      [brandId, email, gameId]
    );

    /**
     * Handle viewing the gallery
     */
    const handleViewGallery = React.useCallback(() => {
      // If we're in an iframe, then we want to open in a new tab. Otherwise, navigate within the same tab
      if (inIFrame) {
        window.open(`/gallery/${gameId}`, "_blank");
      } else {
        history.push(`/gallery/${gameId}`);
      }
    }, [inIFrame, gameId, history]);

    /**
     * Handle viewing the slideshow
     */
    const handleViewSlideshow = React.useCallback(() => {
      // If we're in an iframe, then we want to open in a new tab. Otherwise, navigate within the same tab
      if (inIFrame) {
        window.open(`/slideshow/${gameId}`, "_blank");
      } else {
        history.push(`/slideshow/${gameId}`);
      }
    }, [inIFrame, gameId, history]);

    /**
     * Render the main list
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameFinalResults.title")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      const sortedPlayers = _.sortBy(
        gamePlayers,
        (p) => p.groupResults?.position
      );
      return (
        <Container>
          {sortedPlayers.map((player) => (
            <ResultsItem
              key={player.id}
              player={player}
              type="overall"
              isLargeGame={isLargeGame}
              smallText
            />
          ))}
        </Container>
      );
    }, [gamePlayers, isLargeGame]);

    const renderFooter = React.useCallback(() => {
      if (isLargeGame || deleteGamesWhenFinished) {
        return null;
      }

      return (
        <Footer>
          {!hasEmail && !isInPerson && (
            <FooterForm onSubmit={handleSubmit}>
              <EmailTextInput
                type="email"
                placeholder={t("gameFinalResults.emailPlaceholder")}
                value={email}
                onChange={handleEmailChange}
              />
              <SendEmailButton
                type="submit"
                disableFullWidth
                className={isSharing ? "is-loading" : undefined}
              >
                {t("gameFinalResults.send")}
              </SendEmailButton>
            </FooterForm>
          )}

          <ViewGalleryContainer>
            {isInPerson ? (
              <ViewGalleryButton onClick={handleViewSlideshow}>
                {t("gameFinalResults.viewSlideshow")}
              </ViewGalleryButton>
            ) : (
              <ViewGalleryButton onClick={handleViewGallery}>
                {t("gameFinalResults.viewGallery")}
              </ViewGalleryButton>
            )}
          </ViewGalleryContainer>
        </Footer>
      );
    }, [
      isLargeGame,
      deleteGamesWhenFinished,
      hasEmail,
      isInPerson,
      handleSubmit,
      t,
      email,
      handleEmailChange,
      isSharing,
      handleViewSlideshow,
      handleViewGallery,
    ]);

    return (
      <GameScreen
        id="gameFinalResults"
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
