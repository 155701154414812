import React from "react";
import { Route, Switch } from "react-router-dom";
import { GameGalleryAccessState } from "@pose-party/types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { GameGallery } from "./GameGallery";
import {
  GameGalleryPinEntry,
  GameGalleryPinEntryStatic,
} from "./GameGalleryPinEntry";
import { GameGallerySingle } from "./GameGallerySingle";
import { useGameIdParam } from "../../hooks/useGameIdParam";
import { useGameGalleryPin, usePrimaryGameHost } from "../../data/game";
import { useGalleryPinStorage } from "../../hooks/useGalleryPinStorage";
import { useFormInput } from "../../hooks/useFormInput";
import { useBrand } from "../../data/brand";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";
import { LargeTitle } from "../components/Title";

interface BackgroundImageProps {
  backgroundImageUrl: string | undefined;
}

const Container = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.galleryBackground}`
      : props.theme.colors.galleryBackground};
  color: ${(props) => props.theme.colors.galleryBackgroundOverlay};
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled(LargeTitle)`
  text-align: center;
`;

export const GalleryIndex = () => {
  const { t } = useTranslation();
  const path = "/gallery";
  const gameId = useGameIdParam();
  const { brandId, brandDetails, setGameDetails } = useBrand();
  const galleryBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.galleryBackgroundImage
  );

  /**
   * Game host id. Used to make sure the game brand details are correct for the host user overrides
   */

  const primaryGameHost = usePrimaryGameHost({ brandId, gameId });
  React.useEffect(() => {
    if (primaryGameHost) {
      setGameDetails({ gameId, hostId: primaryGameHost });
    } else {
      setGameDetails(undefined);
    }

    return () => setGameDetails(undefined);
  }, [gameId, primaryGameHost, setGameDetails]);

  /**
   * Gallery PIN entry
   */

  // Hook to get and set the gallery PIN so it can can be remembered on this device
  const [getSavedGalleryPin, storeGalleryPin] = useGalleryPinStorage(gameId);

  const [
    enteredGalleryPin,
    handleEnteredPinChange,
    setEnteredGalleryPin,
  ] = useFormInput({ defaultValue: getSavedGalleryPin() || undefined });
  const gameGalleryPin = useGameGalleryPin({ brandId, gameId });
  const accessState = React.useMemo(() => {
    if (gameGalleryPin === undefined) {
      return GameGalleryAccessState.Loading;
    } else if (gameGalleryPin === null) {
      return GameGalleryAccessState.Granted;
    } else if (gameGalleryPin === enteredGalleryPin) {
      return GameGalleryAccessState.Granted;
    } else {
      return GameGalleryAccessState.PinRequired;
    }
  }, [gameGalleryPin, enteredGalleryPin]);

  // Reset the PIN text input if it's not correct and store it if it's the correct PIN
  const pinEntryRef = React.createRef<GameGalleryPinEntryStatic>();
  React.useEffect(() => {
    if (
      !!gameGalleryPin &&
      enteredGalleryPin.length === gameGalleryPin.length
    ) {
      if (enteredGalleryPin === gameGalleryPin) {
        storeGalleryPin(enteredGalleryPin);
      } else {
        setEnteredGalleryPin("");
        pinEntryRef.current?.shake();
      }
    }
  }, [
    gameGalleryPin,
    enteredGalleryPin,
    setEnteredGalleryPin,
    storeGalleryPin,
    pinEntryRef,
  ]);

  const content = React.useMemo(() => {
    switch (accessState) {
      case GameGalleryAccessState.Loading:
        return (
          <LoadingContainer>
            <LoadingText>{t("common.loading")}</LoadingText>
          </LoadingContainer>
        );
      case GameGalleryAccessState.PinRequired:
        return (
          <GameGalleryPinEntry
            ref={pinEntryRef}
            brandId={brandId}
            gameId={gameId}
            value={enteredGalleryPin}
            onPinChange={handleEnteredPinChange}
          />
        );
      case GameGalleryAccessState.Granted:
        return (
          <Switch>
            <Route path={`${path}/:gameId/:poseId/:uid`}>
              <GameGallerySingle />
            </Route>
            <Route path={`${path}/:gameId`}>
              <GameGallery />
            </Route>
          </Switch>
        );
    }
  }, [
    accessState,
    brandId,
    enteredGalleryPin,
    gameId,
    handleEnteredPinChange,
    pinEntryRef,
    t,
  ]);

  return (
    <Container backgroundImageUrl={galleryBackgroundImageUrl}>
      {content}
    </Container>
  );
};
