import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DeleteContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s transform, 0.25s box-shadow;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
`;

const DeleteImage = styled.img`
  width: 8px;
  height: 8px;
`;

export const SmallDeleteIcon: React.FunctionComponent<React.HTMLAttributes<
  HTMLDivElement
>> = (props) => {
  const { t } = useTranslation();
  return (
    <DeleteContainer {...props}>
      <DeleteImage src="/assets/crossBlack.svg" alt={t("common.kick")} />
    </DeleteContainer>
  );
};
