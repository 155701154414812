import React from "react";
import { detect } from "detect-browser";
import { BrowserSupportedContext } from "./BrowserSupportedContext";

const UNSUPPORTED_NAMES = [
  "crios",
  "firefox",
  "fxios",
  "ios-webview",
  "ie",
  "opera-mini",
  "facebook",
  "instagram",
];

const STORAGE_KEY = "poseParty-ignoreUnsupported";

export const BrowserSupportedProvider: React.FunctionComponent = ({
  children,
}) => {
  const browser = detect();
  const isSupported = React.useMemo(() => {
    if (!browser) {
      return undefined;
    }

    if (UNSUPPORTED_NAMES.includes(browser.name)) {
      return false;
    }

    return true;
  }, [browser]);

  const [ignored, setIgnored] = React.useState(
    window.localStorage.getItem(STORAGE_KEY) === "true"
  );
  const ignoreBrowserUnsupported = React.useCallback(() => {
    window.localStorage.setItem(STORAGE_KEY, "true");
    setIgnored(true);
  }, []);

  /**
   * Return the hook values via context
   */
  return (
    <BrowserSupportedContext.Provider
      value={{
        isBrowserSupported: isSupported === false ? ignored : isSupported,
        ignoreBrowserUnsupported,
      }}
    >
      {children}
    </BrowserSupportedContext.Provider>
  );
};
