import React from "react";
import styled from "styled-components/macro";
import { GameSound } from "@pose-party/types";
import { useTranslation } from "react-i18next";
import { GameScreen } from "../../../components/GameScreen";
import { MassiveTitle } from "../../../components/Title";
import { Text } from "../../../components/Text";
import { useGameSounds } from "../../../../hooks/useGameSounds";

export interface FinalResultsInterstitialProps {}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(MassiveTitle)`
  text-align: center;
`;

const HintText = styled(Text)`
  text-align: center;
  padding: 4px 32px;
`;

export const FinalResultsInterstitial: React.FunctionComponent<FinalResultsInterstitialProps> = React.memo(
  () => {
    const { t } = useTranslation();
    const { playGameSound } = useGameSounds();

    React.useEffect(() => {
      playGameSound(GameSound.DrumRoll);
    }, [playGameSound]);

    const renderBody = React.useCallback(() => {
      return (
        <MainContainer id="gameFinalResults">
          <Title>{t("finalResultsInterstitial.title")}</Title>
          <HintText>{t("finalResultsInterstitial.subtitle")}</HintText>
        </MainContainer>
      );
    }, [t]);

    return <GameScreen renderBody={renderBody} />;
  }
);
