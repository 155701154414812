import { GameSetupState } from "@pose-party/types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHostGame } from "../../../hooks/useHostGame";

const CounterTable = styled.table`
  width: 100%;
  max-width: 500px;
  margin: 16px 0;
`;
const StatusRow = styled.tr`
  height: 30px;
  padding-bottom: 4px;
`;
const MainStatusRow = styled(StatusRow)`
  font-weight: 700;
`;
const Count = styled.td`
  width: 50%;
  text-align: right;
`;

export const HostGameSetupCounters: React.FunctionComponent = React.memo(() => {
  const { setupStatusCounters, spectatorCount } = useHostGame();
  const { t } = useTranslation();

  if (!setupStatusCounters) {
    return null;
  }

  return (
    <CounterTable>
      <StatusRow>
        <td>{t("hostGameUI.setupCounters.unsupported")}</td>
        <Count>{setupStatusCounters[GameSetupState.Unsupported]}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.setupCounters.landingPage")}</td>
        <Count>{setupStatusCounters[GameSetupState.LandingPage]}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.setupCounters.playerName")}</td>
        <Count>{setupStatusCounters[GameSetupState.PlayerName]}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.setupCounters.webcamPermissions")}</td>
        <Count>{setupStatusCounters[GameSetupState.WebcamPermissions]}</Count>
      </StatusRow>
      <StatusRow>
        <td>{t("hostGameUI.setupCounters.profilePicture")}</td>
        <Count>{setupStatusCounters[GameSetupState.ProfilePicture]}</Count>
      </StatusRow>
      <MainStatusRow>
        <td>{t("hostGameUI.setupCounters.done")}</td>
        <Count>{setupStatusCounters[GameSetupState.Done]}</Count>
      </MainStatusRow>
      {spectatorCount > 0 && (
        <MainStatusRow>
          <td>{t("hostGameUI.setupCounters.spectators")}</td>
          <Count>{spectatorCount}</Count>
        </MainStatusRow>
      )}
    </CounterTable>
  );
});
