import React from "react";
import styled from "styled-components/macro";
import { WebcamSnapshot } from "@pose-party/types";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import {
  WebcamDisplay,
  WebcamDisplayStatic,
} from "../../../components/WebcamDisplay";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { ShadowBottom } from "../../../components/ShadowBottom";
import { SnapshotButtons } from "../../../components/SnapshotButtons";
import { AspectCard } from "../../../components/AspectCard";
import { SnapshotImagePickerButton } from "../../../components/SnapshotImagePickerButton";

export interface PoseProps {
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  stillMode: boolean;
  enableCameraTimer: boolean;
  enableUploadMode: boolean;
  onSnapshotTaken: (snapshot: WebcamSnapshot) => Promise<void>;
  renderHeader: (props: ScreenAreaRenderProps) => React.ReactNode;
}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 32px 42px 32px;
`;

export const CapturePose: React.FunctionComponent<PoseProps> = React.memo(
  ({
    gameLogo,
    helpContent,
    stillMode,
    enableCameraTimer,
    enableUploadMode,
    onSnapshotTaken,
    renderHeader,
  }) => {
    const webcamRef = React.createRef<WebcamDisplayStatic>();

    const [snapshotProgress, setSnapshotProgress] = React.useState<number>();
    const [lastSnapshot, setLastSnapshot] = React.useState<WebcamSnapshot>();
    const onTakeSnapshot = React.useCallback(async () => {
      if (snapshotProgress !== undefined || !webcamRef.current) {
        return;
      }

      setSnapshotProgress(0);
      const snapshot = await webcamRef.current.createSnapshot({
        stillMode,
        countdown: enableCameraTimer,
        onCaptureFrame: (progress) => {
          setSnapshotProgress(progress);
        },
      });
      if (snapshot) {
        setLastSnapshot(snapshot);
      } else {
        // TODO: Handle the error case
      }
      setSnapshotProgress(undefined);
    }, [enableCameraTimer, snapshotProgress, stillMode, webcamRef]);

    const onSnapshotPicked = React.useCallback((snapshot: WebcamSnapshot) => {
      setLastSnapshot(snapshot);
    }, []);

    const onSwapCamera = React.useCallback(() => {
      webcamRef.current?.swapCamera();
    }, [webcamRef]);

    const onRejectSnapshot = React.useCallback(() => {
      setLastSnapshot(undefined);
    }, []);

    const [uploadingSnapshot, setUploadingSnapshot] = React.useState(false);
    const onAcceptSnapshot = React.useCallback(async () => {
      if (!lastSnapshot || uploadingSnapshot) {
        return;
      }

      setUploadingSnapshot(true);
      try {
        await onSnapshotTaken(lastSnapshot);
      } catch (e) {
        // Ignore
      }
      setUploadingSnapshot(false);
    }, [lastSnapshot, onSnapshotTaken, uploadingSnapshot]);

    const renderBody = React.useCallback(() => {
      return (
        <ShadowBottom style={{ flex: 1 }}>
          <MainContainer>
            <AspectCard style={{ maxHeight: "100%" }} aspectRatio={1}>
              {lastSnapshot && <SnapshotDisplay snapshot={lastSnapshot} />}
              {!lastSnapshot && enableUploadMode && (
                <SnapshotImagePickerButton
                  onSnapshotPicked={onSnapshotPicked}
                />
              )}
              {!lastSnapshot && !enableUploadMode && (
                <WebcamDisplay ref={webcamRef} />
              )}
            </AspectCard>
          </MainContainer>
        </ShadowBottom>
      );
    }, [enableUploadMode, lastSnapshot, onSnapshotPicked, webcamRef]);

    const renderButtons = React.useCallback(() => {
      return (
        <SnapshotButtons
          snapshotProgress={snapshotProgress}
          isUploading={uploadingSnapshot}
          hasSnapshot={!!lastSnapshot}
          onSwapCamera={onSwapCamera}
          onRejectSnapshot={onRejectSnapshot}
          onAcceptSnapshot={onAcceptSnapshot}
          onTakeSnapshot={enableUploadMode ? undefined : onTakeSnapshot}
        />
      );
    }, [
      snapshotProgress,
      uploadingSnapshot,
      lastSnapshot,
      onSwapCamera,
      onRejectSnapshot,
      onAcceptSnapshot,
      enableUploadMode,
      onTakeSnapshot,
    ]);

    return (
      <GameScreen
        id="gamePose"
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderButtons={renderButtons}
      />
    );
  }
);
