import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  GameSnapshot,
  GamePlayer,
  DISLIKE_SCORE,
  LIKE_SCORE,
  SUPERLIKE_SCORE,
} from "@pose-party/types";
import {
  SnapshotCard,
  SnapshotCardDirection,
  SnapshotCardStatic,
} from "./SnapshotCard";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { RoundButton } from "../../../components/RoundButton";
import { useBrand } from "../../../../data/brand";
import { GoogleStorageImage } from "../../../components/GoogleStorageImage";
import { ImageButton } from "../../../components/ImageButton";
import dislikeBlack from "../../../../assets/dislikeBlack.svg";
import likeBlack from "../../../../assets/likeBlack.svg";
import superlikeBlack from "../../../../assets/superlikeBlack.svg";
import { reportSnapshot } from "../../../../data/game";
import { useGameIdParam } from "../../../../hooks/useGameIdParam";

export interface SnapshotScoringProps {
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  groupId: string | undefined;
  poseId: string | undefined;
  gamePlayers: GamePlayer[] | undefined;
  otherPlayerPoseSnapshots: GameSnapshot[];
  isSpectator: boolean;
  onSnapshotVote: (snapshot: GameSnapshot, score: number) => void;
  onScoringFinished: () => void;
  renderHeader: (props: ScreenAreaRenderProps) => React.ReactNode;
}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px 42px 32px;
`;

const ReportButton = styled.button`
  text-decoration: underline;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  margin-top: 50px;
  opacity: 0.5;

  &:hover {
    text-decoration: none;
  }
`;

export const SnapshotScoring: React.FunctionComponent<SnapshotScoringProps> = React.memo(
  ({
    gameLogo,
    helpContent,
    groupId,
    poseId,
    gamePlayers,
    otherPlayerPoseSnapshots,
    isSpectator,
    onSnapshotVote,
    onScoringFinished,
    renderHeader,
  }) => {
    const { t } = useTranslation();
    const {
      brandId,
      brandDetails: { gameButtonDislike, gameButtonLike, gameButtonSuperLike },
    } = useBrand();
    const gameId = useGameIdParam();
    const snapshotCardRef = React.createRef<SnapshotCardStatic>();
    const [activeIndex, setActiveIndex] = React.useState(0);

    const currentSnapshot = React.useMemo(
      () =>
        otherPlayerPoseSnapshots &&
        otherPlayerPoseSnapshots.length > activeIndex
          ? otherPlayerPoseSnapshots[activeIndex]
          : undefined,
      [activeIndex, otherPlayerPoseSnapshots]
    );
    const currentSnapshotPlayerId = React.useMemo(() => currentSnapshot?.uid, [
      currentSnapshot,
    ]);
    const currentSnapshotPlayerName = React.useMemo(
      () => gamePlayers?.find((p) => p.id === currentSnapshotPlayerId)?.name,
      [currentSnapshotPlayerId, gamePlayers]
    );

    /**
     * Methods to handle voting
     */
    const handleVote = React.useCallback(
      (score: number) => {
        if (!currentSnapshot) {
          return;
        }

        onSnapshotVote(currentSnapshot, score);

        const nextIndex = activeIndex + 1;
        if (nextIndex < otherPlayerPoseSnapshots.length) {
          setActiveIndex(nextIndex);
        } else {
          onScoringFinished();
        }
      },
      [
        activeIndex,
        currentSnapshot,
        onScoringFinished,
        onSnapshotVote,
        otherPlayerPoseSnapshots.length,
      ]
    );
    const makeHandleButton = React.useCallback(
      (direction: SnapshotCardDirection) => () => {
        snapshotCardRef.current?.animateInDirection(direction);
      },
      [snapshotCardRef]
    );
    const onCardGesture = React.useCallback(
      (direction: SnapshotCardDirection) => {
        switch (direction) {
          case SnapshotCardDirection.Left:
            return handleVote(DISLIKE_SCORE);
          case SnapshotCardDirection.Right:
            return handleVote(LIKE_SCORE);
          case SnapshotCardDirection.Up:
            return handleVote(SUPERLIKE_SCORE);
        }
      },
      [handleVote]
    );

    /**
     * Handle reporting images
     */

    const onReport = React.useCallback(() => {
      if (
        !groupId ||
        !poseId ||
        !currentSnapshot ||
        !currentSnapshotPlayerId ||
        !currentSnapshotPlayerName
      ) {
        return;
      }

      const sure = window.confirm(t("gameScore.scoring.reportConfirm"));
      if (!sure) {
        return;
      }

      try {
        reportSnapshot({
          brandId,
          gameId,
          groupId,
          poseId,
          snapshotId: currentSnapshot.id,
          snapshot: currentSnapshot.snapshot,
          uid: currentSnapshotPlayerId,
          name: currentSnapshotPlayerName,
        });
      } catch (e) {
        // Ignore
      }

      window.alert(t("gameScore.scoring.reportThanks"));

      handleVote(DISLIKE_SCORE);
    }, [
      brandId,
      currentSnapshot,
      currentSnapshotPlayerId,
      currentSnapshotPlayerName,
      gameId,
      groupId,
      handleVote,
      poseId,
      t,
    ]);

    /**
     * Render the views
     */

    const renderBody = React.useCallback(() => {
      if (!currentSnapshot) {
        return null;
      }

      return (
        <MainContainer id="gameScore-doScoring">
          <SnapshotCard
            ref={snapshotCardRef}
            key={currentSnapshot.id}
            snapshot={currentSnapshot.snapshot}
            playerName={currentSnapshotPlayerName}
            onCardGesture={onCardGesture}
          />
        </MainContainer>
      );
    }, [
      currentSnapshot,
      currentSnapshotPlayerName,
      onCardGesture,
      snapshotCardRef,
    ]);

    const renderButtons = React.useCallback(() => {
      if (!currentSnapshot) {
        return null;
      }

      return (
        <>
          {gameButtonDislike ? (
            <ImageButton
              id="gameScore-dislike"
              onClick={makeHandleButton(SnapshotCardDirection.Left)}
            >
              <GoogleStorageImage src={gameButtonDislike} />
            </ImageButton>
          ) : (
            <RoundButton
              id="gameScore-dislike"
              buttonColor="error"
              onClick={makeHandleButton(SnapshotCardDirection.Left)}
            >
              <img src={dislikeBlack} alt={t("gameScore.scoring.dislike")} />
            </RoundButton>
          )}

          {gameButtonSuperLike ? (
            <ImageButton
              id="gameScore-superlike"
              large
              style={{ margin: "0 32px" }}
              onClick={makeHandleButton(SnapshotCardDirection.Up)}
            >
              <GoogleStorageImage src={gameButtonSuperLike} />
            </ImageButton>
          ) : (
            <RoundButton
              id="gameScore-superlike"
              large
              buttonColor="neutral"
              style={{ margin: "0 32px" }}
              onClick={makeHandleButton(SnapshotCardDirection.Up)}
            >
              <img src={superlikeBlack} alt={t("gameScore.scoring.like")} />
            </RoundButton>
          )}

          {gameButtonLike ? (
            <ImageButton
              id="gameScore-like"
              onClick={makeHandleButton(SnapshotCardDirection.Right)}
            >
              <GoogleStorageImage src={gameButtonLike} />
            </ImageButton>
          ) : (
            <RoundButton
              id="gameScore-like"
              buttonColor="accent"
              onClick={makeHandleButton(SnapshotCardDirection.Right)}
            >
              <img src={likeBlack} alt={t("gameScore.scoring.superlike")} />
            </RoundButton>
          )}
        </>
      );
    }, [
      currentSnapshot,
      gameButtonDislike,
      gameButtonLike,
      gameButtonSuperLike,
      makeHandleButton,
      t,
    ]);

    const renderFooter = React.useCallback(() => {
      if (isSpectator) {
        return null;
      }

      return (
        <ReportButton onClick={onReport}>
          {t("gameScore.scoring.report")}
        </ReportButton>
      );
    }, [isSpectator, onReport, t]);

    return (
      <GameScreen
        id="gameScore"
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderButtons={renderButtons}
        renderFooter={renderFooter}
      />
    );
  }
);
