/* eslint-disable class-methods-use-this */
import winston from "winston";
import Transport from "winston-transport";
import * as Sentry from "@sentry/browser";

class SentryTransport extends Transport {
  log(info: any, next: () => void) {
    setImmediate(() => {
      if (!info.level || !info.message) {
        return;
      }

      let level = Sentry.Severity.Log;
      switch (info.level) {
        case "error":
          level = Sentry.Severity.Error;
          break;
        case "warn":
          level = Sentry.Severity.Warning;
          break;
        case "data":
        case "info":
          level = Sentry.Severity.Info;
          break;
        case "debug":
        case "verbose":
        case "silly":
          level = Sentry.Severity.Debug;
          break;
      }
      Sentry.addBreadcrumb({
        message: info.message,
        level,
      });
    });

    // Perform the writing to the remote service
    next();
  }
}

export const logger = winston.createLogger({
  level: "info",
  format: winston.format.json(),
  transports: [new SentryTransport({})],
});

if (process.env.NODE_ENV !== "production") {
  logger.add(
    new winston.transports.Console({
      format: winston.format.combine(
        winston.format.colorize(),
        winston.format.align(),
        winston.format.simple()
      ),
    })
  );
}
