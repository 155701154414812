import React from "react";

export const useFormImageFileInput = (): [
  File | undefined,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
  (file: File | undefined) => void
] => {
  const [file, setFile] = React.useState<File>();

  const handleFileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (event.target.files?.length !== 1) {
        return;
      }

      const eventFile = event.target.files[0];
      if (!eventFile.type.startsWith("image/")) {
        alert("Please only choose images");
        // eslint-disable-next-line no-param-reassign
        event.target.value = "";
        return;
      }

      setFile(event.target.files[0]);
    },
    []
  );

  return [file, handleFileChange, setFile];
};
