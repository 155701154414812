import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "./Button";
import { BrandLogo } from "./BrandLogo";
import { Markdown } from "./Markdown";
import { Modal } from "./Modal";

const Logo = styled(BrandLogo)`
  max-height: 150px;
  max-width: 60%;
  margin-bottom: 12px;
  align-self: center;
`;
const TermsText = styled.div`
  text-align: center;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.backgroundOverlay};

  & p {
    margin: 12px 0;
  }

  & a {
    color: ${(props) => props.theme.colors.backgroundOverlay};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
const CloseButton = styled(Button)`
  margin-top: 12px;
`;

export const TermsModal: React.FunctionComponent = React.memo(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const goBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Modal>
      <Logo />
      <TermsText>
        <Markdown source={t("termsModal.terms")} linkTarget="_blank" />
      </TermsText>
      <CloseButton onClick={goBack}>{t("termsModal.closeButton")}</CloseButton>
    </Modal>
  );
});
