import React from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useBrand } from "../../../data/brand";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { LargeTitle } from "../../components/Title";

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Instructions = styled(LargeTitle)`
  margin-bottom: 32px;
  max-width: 400px;
  text-align: center;
`;
const GameQRCode = styled(QRCode)`
  margin-bottom: 32px;
  padding: 12px;
  background-color: white;
`;
const GameURL = styled(LargeTitle)``;

export const GameScreenshareSetup: React.FunctionComponent = React.memo(() => {
  const {
    brandDetails: { domain },
  } = useBrand();
  const gameId = useGameIdParam();
  const { t } = useTranslation();

  const shortGameURL = `${domain}/${gameId}`;
  const fullGameURL = `https://${shortGameURL}`;

  return (
    <Content>
      <Instructions>
        {t("hostGameScreenshareUI.setup.instructions")}
      </Instructions>
      <GameQRCode value={fullGameURL} size={250} />
      <GameURL>{shortGameURL}</GameURL>
    </Content>
  );
});
