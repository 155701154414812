import React from "react";
import isEqual from "lodash/isEqual";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { setDataFirebase } from "@pose-party/data";
import { environment } from "../environment";

firebase.initializeApp(environment.firebase);
setDataFirebase(firebase);

export const useFBDatabaseCollection = <T extends { id: string }>(
  path: string | undefined,
  transform: (id: string, data: firebase.database.DataSnapshot) => T
): T[] | undefined => {
  const [data, setData] = React.useState<T[]>();

  React.useEffect(() => {
    setData(undefined);

    if (!path) {
      return;
    }

    const ref = firebase.database().ref(path);

    const handleDataChange = (snapshot: firebase.database.DataSnapshot) => {
      setData((prev) => {
        const id = snapshot.key;
        if (!id) {
          return prev;
        }

        const previousElement = prev?.find((g) => g.id === id);
        const newData = transform(id, snapshot);

        if (isEqual(previousElement, newData)) {
          return prev;
        } else {
          return [
            ...(prev?.filter((g) => g.id !== id) ?? []),
            transform(id, snapshot),
          ];
        }
      });
    };

    const handleDataRemove = (snapshot: firebase.database.DataSnapshot) => {
      setData((prev) => {
        const id = snapshot.key;
        if (!id) {
          return prev;
        }

        return prev?.filter((g) => g.id !== id);
      });
    };

    ref.on("child_added", handleDataChange);
    ref.on("child_changed", handleDataChange);
    ref.on("child_removed", handleDataRemove);

    return () => {
      ref.off("child_added", handleDataChange);
      ref.off("child_changed", handleDataChange);
      ref.off("child_removed", handleDataRemove);
    };
  }, [path, transform]);

  return data;
};

export default firebase;
