import React from "react";
import _ from "lodash";
import { GamePlayer, GameSetupState } from "@pose-party/types";
import firebase, { useFBDatabaseCollection } from "../firebase";
import { logger } from "../../logger";

const mapGamePlayer = (
  id: string,
  data: firebase.database.DataSnapshot
): GamePlayer => ({
  id,
  name: data.child("name").val() ?? "",
  profilePicture: data.child("profilePicture").val() ?? "",
  setupState: data.child("setupState").val() || undefined,
  groupId: data.child("groupId").val() || undefined,
  hidden: !!data.child("hidden").val(),
  scored: data.child("scored").val() || undefined,
  groupPoseResults: data.child("groupPoseResults").val() || undefined,
  groupResults: data.child("groupResults").val() || undefined,
  poseResults: data.child("poseResults").val() || undefined,
  results: data.child("results").val() || undefined,
  fake: !!data.child("fake").val(),
});

/**
 * Hook to get the current player of a given game and uid.
 *
 * `undefined`: Not yet loaded.
 * `null`: No player data.
 * `GamePlayer[]`: The game players. Might be empty
 */
export const useGameCurrentPlayer = ({
  brandId,
  gameId,
  uid,
}: {
  brandId: string;
  gameId: string | undefined;
  uid: string | undefined;
}) => {
  const [currentPlayer, setCurrentPlayer] = React.useState<
    GamePlayer | null | undefined
  >();

  React.useEffect(() => {
    if (!gameId || !uid) {
      setCurrentPlayer(undefined);
      return;
    }

    const ref = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}/players/${uid}`);

    const handleUpdateValue = (data: firebase.database.DataSnapshot) => {
      const rawValue = data.val();
      if (!rawValue) {
        return setCurrentPlayer(null);
      }

      setCurrentPlayer(mapGamePlayer(uid, data));
    };

    ref.on("value", handleUpdateValue);

    return () => {
      ref.off("value", handleUpdateValue);
    };
  }, [brandId, gameId, uid]);

  React.useEffect(() => {
    logger.info("Current player updated", currentPlayer);
  }, [currentPlayer]);

  return currentPlayer;
};
export interface GamePlayersParams {
  enable?: boolean;
  brandId: string;
  gameId: string | undefined;
  groupId?: string | undefined;
  onlySetup?: boolean;
}

/**
 * Hook to get the players of a given game.
 *
 * `undefined`: Not yet loaded.
 * `GamePlayer[]`: The game players. Might be empty
 */
export const useGamePlayers = ({
  enable = true,
  brandId,
  gameId,
  groupId,
  onlySetup,
}: GamePlayersParams): GamePlayer[] => {
  let path: string | undefined;
  if (enable && gameId) {
    path = `/brands/${brandId}/games/${gameId}`;
    if (groupId) {
      path += `/groups/${groupId}`;
    }
    path += "/players";
  }

  const rawGamePlayers = useFBDatabaseCollection<GamePlayer>(
    path,
    mapGamePlayer
  );

  const gamePlayers = React.useMemo(() => {
    return (
      _.chain(rawGamePlayers)
        // Only show setup players if the flag is set
        .filter((player) => {
          if (player.hidden) {
            return false;
          } else if (player.setupState === GameSetupState.Unsupported) {
            return false;
          } else if (!onlySetup) {
            return true;
          } else {
            return player.setupState === GameSetupState.Done;
          }
        })
        // Sort by their id so the sorting is stable
        .sortBy("id")
        // Return the value
        .value()
    );
  }, [onlySetup, rawGamePlayers]);

  React.useEffect(() => {
    logger.info("Game players updated", gamePlayers);
  }, [gamePlayers]);

  return gamePlayers;
};

/**
 * Marks a given player in the game as hidden
 */
export const hideGamePlayer = async ({
  brandId,
  gameId,
  playerId,
  groupId,
}: {
  brandId: string;
  gameId: string;
  playerId: string;
  groupId: string | undefined;
}) => {
  const playerRef = firebase
    .database()
    .ref(`/brands/${brandId}/games/${gameId}/players/${playerId}`);
  await playerRef.child("hidden").set(true);

  if (groupId) {
    const playerGroupRef = firebase
      .database()
      .ref(
        `/brands/${brandId}/games/${gameId}/groups/${groupId}/players/${playerId}`
      );
    await playerGroupRef.child("hidden").set(true);
  }
};
