import React from "react";
import { GameScores } from "@pose-party/types";
import { useFBDatabaseCollection } from "../firebase";
import { logger } from "../../logger";

/**
 * Hook to get the scores of a given pose in the game
 *
 * `undefined`: Not yet loaded.
 * `GameScores[]`: The game votes. Might be empty
 */
export const useGamePoseScores = ({
  brandId,
  gameId,
  poseId,
  groupId,
}: {
  brandId: string;
  gameId: string | undefined;
  poseId: string | undefined;
  groupId: string | undefined;
}) => {
  let path: string | undefined;
  if (!!gameId && !!poseId) {
    path = `/brands/${brandId}/games/${gameId}`;
    if (groupId) {
      path += `/groups/${groupId}`;
    }
    path += `/scores/${poseId}`;
  }

  const gamePoseScores = useFBDatabaseCollection<GameScores>(
    path,
    React.useCallback(
      (id, data) => ({
        id,
        playerId: id,
        score: data.val() || 0,
      }),
      []
    )
  );

  React.useEffect(() => {
    logger.info("Game pose scores updated", gamePoseScores);
  }, [gamePoseScores]);

  return gamePoseScores;
};

/**
 * Hook to get the results of a given game
 *
 * `undefined`: Not yet loaded.
 * `GameScores[]`: The game votes. Might be empty
 */
// TODO: Add in support for groups
export const useGameResultsScores = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string | undefined;
}) => {
  const path = gameId
    ? `/brands/${brandId}/games/${gameId}/results`
    : undefined;
  const gameVotes = useFBDatabaseCollection<GameScores>(
    path,
    React.useCallback(
      (id, data) => ({
        id,
        playerId: id,
        score: data.val() || 0,
      }),
      []
    )
  );

  React.useEffect(() => {
    logger.info("Game pose scores updated", gameVotes);
  }, [gameVotes]);

  return gameVotes;
};
