import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useBrand } from "../../../data/brand";
import { useWebcamStream } from "../../../data/webcam";
import { useHostGame } from "../../../hooks/useHostGame";
import { AspectCard } from "../../components/AspectCard";
import { BrandLogo } from "../../components/BrandLogo";
import { AccentButton, Button } from "../../components/Button";
import { LargeTitle, VeryLargeTitle } from "../../components/Title";
import { WebcamDisplay } from "../../components/WebcamDisplay";

const Logo = styled(BrandLogo)`
  max-height: 150px;
  margin-bottom: 16px;
`;
const SmallLogo = styled(Logo)`
  max-height: 90px;
`;
const GameName = styled(VeryLargeTitle)`
  text-align: center;
  margin: 0 32px 8px 32px;
`;
const PlayerCount = styled(LargeTitle)<{ hasCount: boolean }>`
  text-align: center;
  opacity: ${(props) => (props.hasCount ? 1 : 0)};
  color: ${(props) => props.theme.colors.primaryBrand};
`;
const WebcamContainer = styled(AspectCard)`
  width: 400px;
  margin-bottom: 16px;
  background-color: black;
`;
const WebcamSetup = styled.div`
  dislay: flex;
  width: 100%;
  max-width: 400px;
  margin-top: 32px;
  align-items: center;

  & p {
    text-align: center;
    margin-bottom: 8px;
  }
`;
const WebcamButtons = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
`;
const ForbidWebcam = styled(Button)`
  margin: 0 8px;
`;
const AllowWebcam = styled(AccentButton)`
  margin: 0 8px;
`;

export const GameScreenshareGameDetails = () => {
  const { startStream, stopStream } = useWebcamStream();
  const { name, logo, playerCount } = useHostGame();
  const {
    brandDetails: { allowWebcamInScreenshareUI },
  } = useBrand();
  const { t } = useTranslation();

  const [webcamEnabled, setWebcamEnabled] = React.useState<boolean>();
  React.useEffect(() => {
    if (webcamEnabled) {
      startStream({ audio: true });
    } else {
      stopStream();
    }
  }, [startStream, stopStream, webcamEnabled]);

  const forbidWebcam = React.useCallback(() => {
    setWebcamEnabled(false);
  }, []);

  const allowWebcam = React.useCallback(() => {
    setWebcamEnabled(true);
  }, []);

  if (allowWebcamInScreenshareUI && webcamEnabled === undefined) {
    return (
      <WebcamSetup>
        <p>{t("hostGameScreenshareUI.webcamSetup.hint")}</p>
        <WebcamButtons>
          <ForbidWebcam onClick={forbidWebcam}>
            {t("hostGameScreenshareUI.webcamSetup.forbid")}
          </ForbidWebcam>
          <AllowWebcam onClick={allowWebcam}>
            {t("hostGameScreenshareUI.webcamSetup.allow")}
          </AllowWebcam>
        </WebcamButtons>
      </WebcamSetup>
    );
  }

  return (
    <>
      {webcamEnabled ? (
        <SmallLogo overrideSrc={logo} />
      ) : (
        <Logo overrideSrc={logo} />
      )}

      {webcamEnabled && (
        <WebcamContainer>
          <WebcamDisplay enableAudio />
        </WebcamContainer>
      )}

      <GameName>{name}</GameName>
      <PlayerCount hasCount={!!playerCount && playerCount > 1}>
        {t("hostGameScreenshareUI.playerCount", { playerCount })}
      </PlayerCount>
    </>
  );
};
