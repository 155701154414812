import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { GamePlayer, GameSound } from "@pose-party/types";
import { GameHeader } from "../../../components/GameHeader";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { RegularTitle } from "../../../components/Title";
import { ResultsItem } from "../../../components/ResultsItem";
import { useGameSounds } from "../../../../hooks/useGameSounds";

export interface OwnResultsProps {
  player: GamePlayer;
  isLargeGame: boolean;
  gameLogo: string | null | undefined;
}

const MainContainer = styled.div`
  flex: 1;
  padding: 16px 32px;
  overflow-y: auto;
`;

const Title = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 32px;
`;

export const OwnResults: React.FunctionComponent<OwnResultsProps> = React.memo(
  ({ player, isLargeGame, gameLogo }) => {
    const { t } = useTranslation();
    const { playGameSound } = useGameSounds();

    React.useEffect(() => {
      const ownPosition = isLargeGame
        ? player.groupPoseResults?.position
        : player.poseResults?.position;
      if (ownPosition === 1) {
        playGameSound(GameSound.RoundWinner);
      }
    }, [
      playGameSound,
      isLargeGame,
      player.groupPoseResults?.position,
      player.poseResults?.position,
    ]);

    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameFinalResults.title")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      return (
        <MainContainer>
          <Title>{t("gameFinalResults.ownResultsTitle")}</Title>

          <ResultsItem
            player={player}
            type="overall"
            isLargeGame={isLargeGame}
          />
        </MainContainer>
      );
    }, [isLargeGame, player, t]);

    return <GameScreen renderHeader={renderHeader} renderBody={renderBody} />;
  }
);
