import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import "./index.css";
import "./i18n";
import * as Sentry from "@sentry/browser";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { environment } from "./environment";

if (environment.googleTagManager.enabled) {
  TagManager.initialize({
    gtmId: environment.googleTagManager.gtmId,
  });
}

Sentry.init({
  dsn:
    "https://30ad89982b254c09b20af70841eb32cd@o390935.ingest.sentry.io/5236247",
  environment: environment.sentry.environment,
  release: environment.sentry.release,
  enabled: process.env.NODE_ENV === "production",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
