import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { GameListGameStatus } from "@pose-party/types";
import { FileInput } from "../../components/FileInput";
import { GameScreen } from "../../components/GameScreen";
import { GameHeader } from "../../components/GameHeader";
import { GameFooter } from "../../components/GameFooter";
import { Button, AccentButton } from "../../components/Button";
import { RegularTitle } from "../../components/Title";
import { CopyContainer } from "../../components/CopyContainer";
import {
  deleteGame,
  removeGameBranding,
  updateGameBranding,
  useGameLocked,
  useGameLogo,
  useGameLogoUrl,
  useGamePlayerInviteCode,
  useGameVideoOverlayUrl,
  usePlayerGame,
} from "../../../data/game";
import { Text } from "../../components/Text";
import { useBrand } from "../../../data/brand";
import { useFormImageFileInput } from "../../../hooks/useFormImageFileInput";
import { useFormInput } from "../../../hooks/useFormInput";
import { TextInput } from "../../components/TextInput";
import { useGameHostUI } from "../../../hooks/useGameHostUI";
import { GoogleStorageImage } from "../../components/GoogleStorageImage";
import { AspectCard } from "../../components/AspectCard";

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 32px 16px;
  position: relative;
`;
const HintTitle = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 8px;
`;
const HintText = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`;
const BrandingHintText = styled(HintText)`
  margin-top: 32px;
`;
const BrandingFileInput = styled(FileInput)`
  margin-bottom: 16px;
`;
const BrandingTextInput = styled(TextInput)`
  margin-bottom: 16px;
`;
const CopyText = styled(CopyContainer)`
  margin-bottom: 16px;
`;
const BrandingButton = styled(AccentButton)`
  margin-bottom: 16px;
`;
const ExampleGameLogo = styled(GoogleStorageImage)`
  width: 100%;
  margin-bottom: 16px;
`;
const VideoOverlayExampleContainer = styled.div`
  margin-bottom: 16px;
  position: relative;
`;
const VideoOverlayExample = styled(GoogleStorageImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const LockedGame = styled.div`
  margin-top: 32px;
`;
const EditButtons = styled.div`
  margin-top: 24px;
`;
const EditButton = styled(AccentButton)`
  margin-top: 8px;
`;
const DeleteButton = styled(AccentButton)`
  margin-top: 32px;
`;
const StackedGameFooter = styled(GameFooter)`
  flex-direction: column;
  padding: 8px;
`;
const GameFooterInnerRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;
const StartLaterButton = styled(Button)`
  margin: 0 4px;
`;
const StartButton = styled(Button)`
  margin: 0 4px;
`;
const TextButton = styled.button`
  text-decoration: underline;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    text-decoration: none;
  }
`;

export const GameDetails: React.FunctionComponent = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { brandId, brandDetails } = useBrand();
  const {
    allowGameBranding,
    showHomepageJoinGameForm,
    deleteGamesWhenFinished,
    allowHostGameUI,
  } = brandDetails;
  const [deleting, setDeleting] = React.useState(false);
  const gameLogoUrl = useGameLogoUrl({ brandId, gameId });
  const gameOverlayUrl = useGameVideoOverlayUrl({ brandId, gameId });
  const videoOverlayUrl = gameOverlayUrl || brandDetails.videoOverlay;
  const { openHostGameUI, openScreenshareUI } = useGameHostUI();

  /**
   * Load the game data
   */
  const game = usePlayerGame({ brandId, gameId });
  const gameLogo = useGameLogo({ brandId, gameId });
  const isGameLocked = useGameLocked({ brandId, gameId });
  const playerInviteCode = useGamePlayerInviteCode({ brandId, gameId });

  /**
   * Methods to handle the button presses
   */
  const handleEdit = React.useCallback(() => {
    history.push(`/user/game/${gameId}/edit`);
  }, [gameId, history]);
  const handleEditAdvanced = React.useCallback(() => {
    history.push(`/user/game/${gameId}/advanced`);
  }, [gameId, history]);
  const handleDelete = React.useCallback(async () => {
    const sure = window.confirm(t("gameDetails.deleteConfirm"));
    if (!sure) {
      return;
    }

    setDeleting(true);
    await deleteGame({ gameId, brandId });
    setDeleting(false);
    history.replace("/");
  }, [brandId, gameId, history, t]);
  const handleBack = React.useCallback(() => {
    history.push("/");
  }, [history]);
  const handleStart = React.useCallback(() => {
    history.push(`/${gameId}`);
  }, [gameId, history]);
  const handleGallery = React.useCallback(() => {
    history.push(`/gallery/${gameId}`);
  }, [gameId, history]);

  /**
   * Handle the game branding form
   */
  const [logoFile, handleLogoFileChange, setLogoFile] = useFormImageFileInput();
  const [
    overlayFile,
    handleOverlayFileChange,
    setOverlayFile,
  ] = useFormImageFileInput();
  const [
    formGameLogoUrl,
    handleFormGameLogoUrlChange,
    setGameGalleryUrl,
  ] = useFormInput({ defaultValue: gameLogoUrl || undefined });
  React.useEffect(() => {
    setGameGalleryUrl(gameLogoUrl || "");
  }, [setGameGalleryUrl, gameLogoUrl]);

  const [isUpdatingBranding, setIsUpdatingBranding] = React.useState(false);
  const handleBrandingSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsUpdatingBranding(true);

      try {
        await updateGameBranding({
          brandId,
          gameId,
          logoFile,
          overlayFile,
          logoUrl: formGameLogoUrl,
        });
      } catch (e) {
        alert(t("gameDetails.updateBrandingError"));
        return;
      }

      setLogoFile(undefined);
      setOverlayFile(undefined);

      setIsUpdatingBranding(false);
    },
    [
      brandId,
      formGameLogoUrl,
      gameId,
      logoFile,
      overlayFile,
      setLogoFile,
      setOverlayFile,
      t,
    ]
  );

  const [isRemovingBranding, setIsRemovingBranding] = React.useState(false);
  const onRemoveBrandingPress = React.useCallback(async () => {
    setIsRemovingBranding(true);

    try {
      await removeGameBranding({ brandId, gameId });
    } catch (e) {
      alert(t("gameDetails.updateBrandingError"));
      return;
    }

    setIsRemovingBranding(false);
  }, [brandId, gameId, t]);

  /**
   * Render the views
   */
  const renderHeader = React.useCallback(() => {
    return (
      <GameHeader
        logo={gameLogo}
        goBack={handleBack}
        title={game?.name || ""}
      />
    );
  }, [game, gameLogo, handleBack]);

  const renderBody = React.useCallback(() => {
    if (!game?.name) {
      return (
        <LoadingContainer>
          <RegularTitle>{t("gameDetails.loading")}</RegularTitle>
        </LoadingContainer>
      );
    }

    const shortUrl = `${brandDetails.domain}/${gameId}`;
    const fullUrl = `https://${shortUrl}`;
    const playerInviteShortUrl =
      typeof playerInviteCode === "string"
        ? `${shortUrl}/invitePlayer/${playerInviteCode}`
        : undefined;

    return (
      <Content>
        <HintTitle>{t("gameDetails.hintTitle")}</HintTitle>
        <HintText>{t("gameDetails.hintText")}</HintText>
        <CopyText
          title={t("gameDetails.url")}
          text={fullUrl}
          displayText={shortUrl}
        />
        {showHomepageJoinGameForm && (
          <CopyText title={t("gameDetails.code")} text={gameId} />
        )}

        {!!isGameLocked && (
          <LockedGame>
            <HintText>
              {game.tournamentId
                ? t("gameDetails.tournamentLockedInviteHint")
                : t("gameDetails.lockedInviteHint")}
            </HintText>
            <CopyText
              title={t("gameDetails.url")}
              text={`https://${playerInviteShortUrl}`}
              displayText={playerInviteShortUrl}
            />
          </LockedGame>
        )}

        {game?.status === GameListGameStatus.Setup && (
          <EditButtons>
            <EditButton onClick={handleEdit}>
              {t("gameDetails.edit")}
            </EditButton>
            <EditButton onClick={handleEditAdvanced}>
              {t("gameDetails.editAdvanced")}
            </EditButton>
          </EditButtons>
        )}

        <DeleteButton
          onClick={handleDelete}
          className={deleting ? "is-loading" : ""}
        >
          {t("gameDetails.delete")}
        </DeleteButton>

        {!!allowGameBranding && (
          <form onSubmit={handleBrandingSubmit}>
            <BrandingHintText>
              {t("gameDetails.brandingHintText")}
            </BrandingHintText>

            <BrandingFileInput
              id="logo"
              title={t("gameDetails.brandingLogo")}
              value={logoFile}
              onChange={handleLogoFileChange}
              style={{ marginBottom: "16px" }}
            />

            <BrandingTextInput
              placeholder={t("gameDetails.brandingLogoUrlPlaceholder")}
              value={formGameLogoUrl}
              onChange={handleFormGameLogoUrlChange}
            />

            {gameLogo && <ExampleGameLogo src={gameLogo} />}

            <BrandingFileInput
              id="overlay"
              title={t("gameDetails.brandingOverlay")}
              value={overlayFile}
              onChange={handleOverlayFileChange}
              style={{ marginBottom: "16px" }}
            />

            {videoOverlayUrl && (
              <VideoOverlayExampleContainer>
                <AspectCard aspectRatio={1}>
                  <GoogleStorageImage src="gs://pose-party.appspot.com/content/poseparty_pose_peacetocamera_4_480x480.jpg" />
                  <VideoOverlayExample src={videoOverlayUrl} />
                </AspectCard>
              </VideoOverlayExampleContainer>
            )}

            <BrandingButton
              type="submit"
              className={isUpdatingBranding ? "is-loading" : ""}
            >
              {t("gameDetails.updateGameBranding")}
            </BrandingButton>
            <BrandingButton
              type="button"
              className={isRemovingBranding ? "is-loading" : ""}
              onClick={onRemoveBrandingPress}
            >
              {t("gameDetails.removeGameBranding")}
            </BrandingButton>
          </form>
        )}
      </Content>
    );
  }, [
    allowGameBranding,
    brandDetails.domain,
    deleting,
    formGameLogoUrl,
    game,
    gameId,
    gameLogo,
    handleBrandingSubmit,
    handleDelete,
    handleEdit,
    handleEditAdvanced,
    handleFormGameLogoUrlChange,
    handleLogoFileChange,
    handleOverlayFileChange,
    isGameLocked,
    isRemovingBranding,
    isUpdatingBranding,
    logoFile,
    onRemoveBrandingPress,
    overlayFile,
    playerInviteCode,
    showHomepageJoinGameForm,
    t,
    videoOverlayUrl,
  ]);

  const renderFooter = React.useCallback(() => {
    if (!game?.name) {
      return null;
    }

    if (
      game?.status === GameListGameStatus.Finished &&
      !game?.large &&
      !deleteGamesWhenFinished
    ) {
      return (
        <GameFooter>
          <Button onClick={handleGallery}>{t("gameDetails.gallery")}</Button>
        </GameFooter>
      );
    }

    if (game?.status !== GameListGameStatus.Finished) {
      const startLaterButton = (
        <StartLaterButton onClick={handleBack}>
          {t("gameDetails.playLater")}
        </StartLaterButton>
      );

      const screenshareUIButton = (
        <TextButton onClick={openScreenshareUI}>
          {t("gameDetails.screenshareUI")}
        </TextButton>
      );

      if (game.large) {
        return (
          <StackedGameFooter>
            <GameFooterInnerRow>
              {startLaterButton}
              <StartButton onClick={openHostGameUI}>
                {t("gameDetails.host")}
              </StartButton>
            </GameFooterInnerRow>
            {screenshareUIButton}
          </StackedGameFooter>
        );
      } else {
        const hostGameUIButton = allowHostGameUI ? (
          <TextButton onClick={openHostGameUI}>
            {t("gameDetails.hostGameUI")}
          </TextButton>
        ) : null;

        const startButton = (
          <StartButton onClick={handleStart}>
            {t("gameDetails.play")}
          </StartButton>
        );

        return (
          <StackedGameFooter>
            <GameFooterInnerRow>
              {startLaterButton}
              {startButton}
            </GameFooterInnerRow>
            {hostGameUIButton}
            {screenshareUIButton}
          </StackedGameFooter>
        );
      }
    }

    return null;
  }, [
    allowHostGameUI,
    deleteGamesWhenFinished,
    game,
    handleBack,
    handleGallery,
    handleStart,
    openHostGameUI,
    openScreenshareUI,
    t,
  ]);

  return (
    <GameScreen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderFooter={renderFooter}
    />
  );
};
