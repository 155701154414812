import React from "react";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";
import styled from "styled-components";
import { GamePlayer, GameSetupState } from "@pose-party/types";
import { Button } from "../../../components/Button";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { AspectCard } from "../../../components/AspectCard";
import { GameFooter } from "../../../components/GameFooter";
import { Text } from "../../../components/Text";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import { SmallDeleteIcon } from "../../../components/SmallDeleteIcon";
import { GameHeader } from "../../../components/GameHeader";
import loadingWhite from "../../../../assets/loadingWhite.svg";
import { GameQrCode } from "../../../components/GameQrCode";

export interface SetupWaitingProps {
  isHost: boolean;
  gameId: string;
  isInPerson: boolean;
  gameLogo: string | null | undefined;
  gamePlayers: GamePlayer[];
  currentPlayerUid: string | undefined;
  spectatorCount: number;
  onHostStartPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

const Container = styled.div`
  flex: 1;
  padding: 32px;
  overflow-y: auto;
`;

const Count = styled(Text)`
  margin-bottom: 32px;
  text-align: center;
`;

const LoadingPlayerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.secondaryBrand};

  & img {
    animation: spin 2s linear infinite;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const PlayerStateText = styled(Text)`
  color: white;
  font-size: 14px;
  line-height: 16px;
  margin: 0 16px;
  text-align: center;
  margin-top: 8px;
`;

export const SetupWaiting: React.FunctionComponent<SetupWaitingProps> = React.memo(
  ({
    isHost,
    gameId,
    isInPerson,
    gameLogo,
    gamePlayers,
    currentPlayerUid,
    spectatorCount,
    onHostStartPress,
    kickPlayer,
  }) => {
    const { t } = useTranslation();

    /**
     * Construct the player list
     */
    const playerList = React.useMemo(() => sortBy(gamePlayers, (p) => p.id), [
      gamePlayers,
    ]);
    const count = React.useMemo(
      () =>
        playerList.reduce(
          (prev, player) =>
            player.setupState === GameSetupState.Done ? prev + 1 : prev,
          0
        ),
      [playerList]
    );
    const of = playerList.length;

    /**
     * Handle the host next button
     */

    const handleHostNext = React.useCallback(() => {
      if (count !== of) {
        const sure = window.confirm(t("gameSetup.waiting.hostConfirm"));
        if (!sure) {
          return;
        }
      }

      onHostStartPress();
    }, [count, of, onHostStartPress, t]);

    /**
     * Handle the kick player button
     */
    const makeKickPlayerClick = React.useCallback(
      (playerId: string) => () => {
        kickPlayer(playerId);
      },
      [kickPlayer]
    );

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameSetup.waiting.title")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      const playerCount =
        count === of
          ? t("gameSetup.waiting.allSetupCount", { count })
          : t("gameSetup.waiting.count", { count, of });
      const spectatorText =
        spectatorCount > 0
          ? t("gameSetup.waiting.spectatorCount", {
              count: spectatorCount,
            })
          : undefined;

      return (
        <Container>
          {isInPerson && <GameQrCode gameId={gameId} />}

          <Count>
            {playerCount}
            {spectatorText ? ` (${spectatorText})` : ""}
          </Count>

          {(!isInPerson || isHost) && (
            <div className="columns is-multiline is-mobile">
              {playerList.map((player) => (
                <div
                  key={player.id}
                  className="column is-one-third"
                  style={{ position: "relative" }}
                >
                  <AspectCard aspectRatio={1} style={{ borderRadius: "5%" }}>
                    {player.profilePicture ? (
                      <SnapshotDisplay snapshot={player.profilePicture} />
                    ) : (
                      <LoadingPlayerContainer>
                        {player.setupState !== GameSetupState.Done && (
                          <img
                            src={loadingWhite}
                            alt={t("gameSetup.waiting.playerSettingUp")}
                            style={{ width: "35px", height: "35px" }}
                          />
                        )}
                        {isHost && (
                          <PlayerStateText>
                            {startCase(player.setupState || undefined)}
                          </PlayerStateText>
                        )}
                      </LoadingPlayerContainer>
                    )}
                  </AspectCard>
                  <Text
                    weight="bold"
                    style={{ textAlign: "center", marginTop: 8 }}
                  >
                    {player.name}
                  </Text>

                  {isHost && !player.fake && player.id !== currentPlayerUid && (
                    <SmallDeleteIcon onClick={makeKickPlayerClick(player.id)} />
                  )}
                </div>
              ))}
            </div>
          )}
        </Container>
      );
    }, [
      count,
      of,
      t,
      spectatorCount,
      isInPerson,
      gameId,
      playerList,
      isHost,
      currentPlayerUid,
      makeKickPlayerClick,
    ]);

    const renderFooter = React.useCallback(() => {
      if (isHost) {
        return (
          <GameFooter>
            <Button onClick={handleHostNext}>
              {t("gameSetup.waiting.hostStart")}
            </Button>
          </GameFooter>
        );
      }
    }, [handleHostNext, isHost, t]);

    return (
      <GameScreen
        id="gameSetup-waiting"
        logo={gameLogo}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
