import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { OptionsType } from "react-select";
import { Text } from "./Text";
import { SelectInput } from "./SelectInput";
import { useBrand } from "../../data/brand";

export interface PoseInputProps {
  roundNumber?: number;
  value: string | undefined;
  onValueChange: (value: string | undefined) => void;
  poseOptions: OptionsType<{ label: string; value: string }>;
  onDeletePress?: () => void;
}

const RoundText = styled(Text)`
  margin-bottom: 4px;
`;
const RoundRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RoundInputContainer = styled.div`
  flex: 1;
`;
const DeleteButton = styled.button`
  background: none;
  border: 0;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  padding: 0;

  &:active {
    opacity: 0.5;
  }

  &:focus {
    border-color: transparent;
    outline: 0;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

export const PoseInput: React.FunctionComponent<PoseInputProps> = ({
  roundNumber,
  value,
  onValueChange,
  poseOptions,
  onDeletePress,
}) => {
  const { t } = useTranslation();
  const { brandDetails } = useBrand();

  return (
    <>
      {roundNumber !== undefined && (
        <RoundText weight="bold">
          {t("poseInput.poseNumber", { count: roundNumber })}
        </RoundText>
      )}
      <RoundRow>
        <RoundInputContainer>
          <SelectInput
            options={poseOptions}
            value={value ? { label: value, value } : undefined}
            onChange={(changedValue: any) => {
              if (typeof changedValue === "object") {
                onValueChange(changedValue.value);
              } else {
                onValueChange(undefined);
              }
            }}
          />
        </RoundInputContainer>
        {!!onDeletePress && (
          <DeleteButton onClick={onDeletePress}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z"
                fill={brandDetails.colors.backgroundOverlay}
              />
            </svg>
          </DeleteButton>
        )}
      </RoundRow>
    </>
  );
};
