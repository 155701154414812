import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  LargeTitle,
  VeryLargeTitle,
  MassiveTitle,
} from "../../components/Title";
import { useHostGame } from "../../../hooks/useHostGame";
import { useBrand } from "../../../data/brand";
import { usePoseInterstitialTimer } from "../../../hooks/usePoseInterstitialTimer";
import { useRoundTimer } from "../../../hooks/useRoundTimer";

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(VeryLargeTitle)`
  text-align: center;
  margin-bottom: 8px;
`;
const HintText = styled(LargeTitle)`
  text-align: center;
  margin-bottom: 80px;
  max-width: 500px;
  color: ${(props) => props.theme.colors.primaryBrand};
`;
const CountdownText = styled(MassiveTitle)`
  text-align: center;
  margin-bottom: 70px;
  max-width: 500px;
  color: ${(props) => props.theme.colors.primaryBrand};
`;
const PoseText = styled(MassiveTitle)`
  text-align: center;
`;
const PoseContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GameScreensharePose: React.FunctionComponent = React.memo(() => {
  const {
    roundDetails: { roundNumber },
    currentPose,
  } = useHostGame();
  const { t } = useTranslation();

  const {
    brandDetails: { timingRoundStartInterstitial, timingPoseRoundLength },
  } = useBrand();

  /**
   * Timer for the interstitial screens
   */
  const interstitial = usePoseInterstitialTimer(timingRoundStartInterstitial);

  /**
   * Keep track of when we think the round ends
   */
  const requestRef = React.useRef<number>();
  const { countdownDetails } = useRoundTimer({
    roundLength: currentPose?.timing || timingPoseRoundLength,
    // Only start when the interstitial screens are gone
    started: interstitial.state === "finished",
  });
  const [countdownText, setCountdownText] = React.useState<string>();
  const animate = React.useCallback(() => {
    if (!countdownDetails) {
      return;
    }

    // Work out how much time is left
    const roundTimeLeft = countdownDetails.end - Date.now();

    // Check if we're finished
    if (roundTimeLeft <= 0) {
      setCountdownText("0s");
      return;
    }

    const roundTimeLeftSeconds = Math.ceil(
      (countdownDetails.end - Date.now()) / 1000
    );
    let text = "";
    if (roundTimeLeftSeconds >= 60) {
      const minutes = Math.floor(roundTimeLeftSeconds / 60);
      text += `${minutes}m `;
    }

    const seconds = Math.floor(roundTimeLeftSeconds % 60);
    text += `${seconds}s`;

    setCountdownText(text);

    // Request another animation frame
    requestRef.current = requestAnimationFrame(animate);
  }, [countdownDetails]);
  React.useEffect(() => {
    if (!countdownDetails) {
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [animate, countdownDetails]);

  return (
    <Content>
      <Title>
        {t("roundStartInterstitial.roundNumber", { number: roundNumber })}
      </Title>
      {countdownText ? (
        <CountdownText>{countdownText}</CountdownText>
      ) : (
        <HintText>{t("roundStartInterstitial.hint")}</HintText>
      )}

      <PoseContainer key={`bounce-${currentPose?.prompt}`}>
        <PoseText>{currentPose?.prompt}</PoseText>
      </PoseContainer>
    </Content>
  );
});
