import React from "react";
import CreatableSelect, { Props } from "react-select/creatable";
import { ThemeContext } from "styled-components";

export const SelectInput: React.FunctionComponent<Props<
  {
    label: string;
    value: string;
  },
  false
>> = ({ ...rest }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <CreatableSelect
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: "48px",
          maxWidth: "100%",
          width: "100%",
          borderRadius: "10px",
          backgroundColor: theme.colors.textInputBackground,
          border: 0,
          boxShadow: state.isFocused ? `0 0 0 0.125em rgba(0,0,0,0.5)` : "none",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "transparent",
        }),
        input: (provided) => ({
          ...provided,
          fontFamily: "Nunito Sans",
          fontSize: "16px",
          color: theme.colors.textInputBackgroundOverlay,
        }),
      }}
      {...rest}
    />
  );
};
