import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { loadTopPoseResults, PlayerPositionDetails } from "../../../data/game";
import { LargeTitle, VeryLargeTitle } from "../../components/Title";
import { AspectCard } from "../../components/AspectCard";
import { ResultsSlider } from "../../components/ResultsSlider";
import { useHostGame } from "../../../hooks/useHostGame";
import { useBrand } from "../../../data/brand";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { SnapshotDisplay } from "../../Game/SnapshotDisplay";
import { positionWithSuffix } from "../../Game/GameStateView/Results/utils";

const Title = styled(VeryLargeTitle)`
  text-align: center;
  margin: 32px 0 8px 0;
`;
const HintText = styled(LargeTitle)`
  text-align: center;
  color: ${(props) => props.theme.colors.primaryBrand};
`;
const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SnapshotCard = styled(AspectCard)`
  width: 350px;
  height: 350px;
`;
const ResultName = styled(LargeTitle)`
  margin-top: 16px;
`;
const ResultPosition = styled(LargeTitle)`
  margin-top: 8px;
`;
const ResultScore = styled(LargeTitle)`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

export const GameScreenshareResults: React.FunctionComponent = React.memo(
  () => {
    const { brandId } = useBrand();
    const gameId = useGameIdParam();
    const {
      roundDetails: { roundNumber },
      currentPose,
    } = useHostGame();
    const { t } = useTranslation();

    const [positionDetails, setPositionDetails] = React.useState<
      PlayerPositionDetails[]
    >();

    React.useEffect(() => {
      loadTopPoseResults({
        brandId,
        gameId,
        poseId: currentPose?.id || "0",
        numberOfPositions: 5,
      }).then((newPositionDetails) => {
        setPositionDetails(newPositionDetails);
      });
    }, [brandId, currentPose, gameId]);

    const results = React.useMemo(() => {
      if (!positionDetails) {
        return [];
      }

      return positionDetails.map(
        ({ snapshot, name, score, position, equalPosition }) => {
          return (
            <Result>
              <SnapshotCard aspectRatio={1}>
                <SnapshotDisplay snapshot={snapshot} />
              </SnapshotCard>
              <ResultName>{name}</ResultName>
              <ResultPosition>
                {equalPosition ? "=" : ""}
                {positionWithSuffix(position)}
              </ResultPosition>
              <ResultScore>{score}</ResultScore>
            </Result>
          );
        }
      );
    }, [positionDetails]);

    const Header = (
      <>
        <Title>
          {t("hostGameScreenshareUI.results.title", { number: roundNumber })}
        </Title>
        <HintText>{currentPose?.prompt}</HintText>
      </>
    );

    return <ResultsSlider header={Header} results={results} />;
  }
);
