import React from "react";

export interface FormCheckboxParams {
  defaultValue?: boolean;
}

export const useFormCheckbox = (
  defaultValue?: boolean
): [
  boolean,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
  (value: boolean) => void
] => {
  const [value, setValue] = React.useState(defaultValue || false);

  const handleValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
    },
    []
  );

  return [value, handleValueChange, setValue];
};
