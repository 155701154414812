import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { GameFooter } from "../../../components/GameFooter";
import { GameHeader } from "../../../components/GameHeader";
import { SuperlikeLoadingIndicator } from "../../../components/SuperlikeLoadingIndicator";
import { Text } from "../../../components/Text";

export interface SetupWaitingLargeProps {
  isHost: boolean;
  gameLogo: string | null | undefined;
  onHostStartPress: () => Promise<void>;
}

const Container = styled.div`
  flex: 1;
  padding: 32px;
`;

const Title = styled(Text)`
  margin-bottom: 32px;
  text-align: center;
`;

export const SetupWaitingLarge: React.FunctionComponent<SetupWaitingLargeProps> = React.memo(
  ({ isHost, gameLogo, onHostStartPress }) => {
    const { t } = useTranslation();

    /**
     * Handle the host next button
     */

    const handleHostNext = React.useCallback(() => {
      onHostStartPress();
    }, [onHostStartPress]);

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={t("gameSetup.waiting.largeTitle")}
          />
        );
      },
      [gameLogo, t]
    );

    const renderBody = React.useCallback(() => {
      return (
        <Container>
          <Title>{t("gameSetup.waiting.largeHint")}</Title>
          <SuperlikeLoadingIndicator />
        </Container>
      );
    }, [t]);

    const renderFooter = React.useCallback(() => {
      if (isHost) {
        return (
          <GameFooter>
            <Button onClick={handleHostNext}>
              {t("gameSetup.waiting.hostStart")}
            </Button>
          </GameFooter>
        );
      }
    }, [handleHostNext, isHost, t]);

    return (
      <GameScreen
        id="gameSetup-waiting"
        logo={gameLogo}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
