import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { GameScreen } from "../../../components/GameScreen";
import { MassiveTitle } from "../../../components/Title";
import { Text } from "../../../components/Text";

export interface RoundEndInterstitialProps {
  roundNumber: number;
}

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(MassiveTitle)`
  text-align: center;
`;

const HintText = styled(Text)`
  text-align: center;
  padding: 4px 32px;
`;

export const RoundEndInterstitial: React.FunctionComponent<RoundEndInterstitialProps> = React.memo(
  ({ roundNumber }) => {
    const { t } = useTranslation();

    const renderBody = React.useCallback(() => {
      return (
        <MainContainer>
          <Title>
            {t("roundEndInterstitial.roundNumber", { number: roundNumber })}
          </Title>
          <HintText>{t("roundEndInterstitial.subtitle")}</HintText>
        </MainContainer>
      );
    }, [roundNumber, t]);

    return <GameScreen renderBody={renderBody} />;
  }
);
