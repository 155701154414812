import React from "react";
import { UserRole, WebcamSnapshot } from "@pose-party/types";
import _ from "lodash";
import firebase from "../firebase";
import { AuthContext } from "./AuthContext";
import { logger } from "../../logger";
import { useBrand } from "../brand";
import { api } from "../api";

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const {
    brandId,
    brandDetails: {
      allowSignUp,
      allowedSignUpEmailDomains,
      allPlayersAreHosts,
      emailTemplateUserWelcome,
    },
    setCurrentUid,
  } = useBrand();

  /**
   * For holding the state
   */
  const [loaded, setLoaded] = React.useState(false);
  const [uid, setUid] = React.useState<string>();
  const [isAnonymous, setIsAnonymous] = React.useState(false);
  const [name, setName] = React.useState(undefined);
  const [email, setEmail] = React.useState(undefined);
  const [profilePicture, setProfilePicture] = React.useState(undefined);
  const [isSignedInAsAdmin, setIsSignedInAsAdmin] = React.useState(false);
  const [isSignedInAsHost, setIsSignedInAsHost] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  /**
   * Let the brand provider know about the uid when we load it
   */
  React.useEffect(() => {
    setCurrentUid(uid);
  }, [setCurrentUid, uid]);

  /**
   * Combine the user details
   * */
  const userDetails = React.useMemo(() => {
    if (!uid) {
      return undefined;
    }

    let role = UserRole.User;
    if (isSignedInAsAdmin) {
      role = UserRole.Admin;
    } else if (isSignedInAsHost) {
      role = UserRole.Host;
    }

    const d = {
      name,
      email,
      profilePicture,
      role,
      acceptedTerms,
    };
    logger.info("User details updated", d);
    return d;
  }, [
    acceptedTerms,
    email,
    isSignedInAsAdmin,
    isSignedInAsHost,
    name,
    profilePicture,
    uid,
  ]);

  /**
   * Work out if the user is signed in fully
   */
  const isSignedIn = React.useMemo(() => !!uid && !isAnonymous, [
    isAnonymous,
    uid,
  ]);

  /**
   * Work out if the user roles
   */
  const isHost = React.useMemo(
    () => !!userDetails && userDetails.role >= UserRole.Host,
    [userDetails]
  );
  const isAdmin = React.useMemo(
    () => !!userDetails && userDetails.role >= UserRole.Admin,
    [userDetails]
  );

  /**
   * Subscrube to authentication updates to listen for changes to the uid and admin flag
   * */
  React.useEffect(() => {
    const authStateChangeUnsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (user) => {
        if (user?.uid) {
          setUid(user.uid);
          if (user.isAnonymous) {
            setIsAnonymous(true);
            setIsSignedInAsAdmin(false);
          } else {
            setIsAnonymous(false);
            const token = await user.getIdTokenResult();
            setIsSignedInAsAdmin(!!token.claims.admin);
          }
        } else {
          setUid(undefined);
          setIsSignedInAsAdmin(false);
          await firebase.auth().signInAnonymously();
        }

        setLoaded(true);
      });

    return authStateChangeUnsubscribe;
  }, []);

  /**
   * Subscrube to the Firestore user object whenever the uid changes
   * */
  React.useEffect(() => {
    if (uid) {
      const snapshotUnsubscribe = firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          setName(doc.get("name"));
          setEmail(doc.get("email"));
          setProfilePicture(doc.get("profilePicture"));
          setIsSignedInAsHost(allPlayersAreHosts || !!doc.get("host"));
          setAcceptedTerms(!!doc.get("acceptedTerms"));
        });

      return snapshotUnsubscribe;
    }
  }, [allPlayersAreHosts, brandId, uid]);

  /**
   * Update the players name
   * */
  const updateName = React.useCallback(
    async (newName: string) => {
      if (!uid) {
        return;
      }

      await firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .collection("users")
        .doc(uid)
        .set({ name: newName }, { merge: true });
    },
    [brandId, uid]
  );

  /**
   * Update the players profile picture
   * */
  const updateProfilePicture = React.useCallback(
    async (newProfilePicture: WebcamSnapshot) => {
      if (!uid) {
        return;
      }

      await firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .collection("users")
        .doc(uid)
        .set({ profilePicture: newProfilePicture }, { merge: true });
    },
    [brandId, uid]
  );

  /**
   * Update the players terms acceptence
   * */
  const acceptTerms = React.useCallback(async () => {
    if (!uid) {
      return;
    }

    await firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("users")
      .doc(uid)
      .set({ acceptedTerms: Date.now() }, { merge: true });
  }, [brandId, uid]);

  /**
   * Update the players email address
   * */
  const updateEmail = React.useCallback(
    async (newEmail: string) => {
      if (!uid) {
        return;
      }

      await firebase
        .firestore()
        .collection("brands")
        .doc(brandId)
        .collection("users")
        .doc(uid)
        .set({ email: newEmail }, { merge: true });
    },
    [brandId, uid]
  );

  /**
   * Method to perform a sign in
   */
  const performSignIn = React.useCallback(
    async ({
      email: signInEmail,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(signInEmail, password);

      return !!result.user;
    },
    []
  );

  /**
   * Method to perform a sign up
   */
  const performSignUp = React.useCallback(
    async ({
      name: signUpName,
      email: signUpEmail,
      password,
    }: {
      name: string;
      email: string;
      password: string;
    }) => {
      if (!allowSignUp) {
        return false;
      }

      if (allowedSignUpEmailDomains.length > 0) {
        const emailDomain = _.last(signUpEmail.split("@"))?.toLowerCase();
        if (!emailDomain || !allowedSignUpEmailDomains.includes(emailDomain)) {
          alert("You cannot sign up with this email address");
          return false;
        }
      }

      const result = await firebase
        .auth()
        .currentUser?.linkWithCredential(
          firebase.auth.EmailAuthProvider.credential(signUpEmail, password)
        );

      const success = !!result?.user;
      setIsAnonymous(result?.user?.isAnonymous ?? true);

      if (success) {
        await updateName(signUpName);
        await updateEmail(signUpEmail);
        await acceptTerms();

        if (emailTemplateUserWelcome) {
          api
            .post("userWelcomeEmail", {
              brandId,
              email: signUpEmail,
            })
            .catch((e) => {
              logger.error("Unable to send welcome email", e);
            });
        }
      }

      return success;
    },
    [
      allowSignUp,
      allowedSignUpEmailDomains,
      updateName,
      updateEmail,
      acceptTerms,
      emailTemplateUserWelcome,
      brandId,
    ]
  );

  /**
   * Method to perform a sign out
   */
  const performPasswordReset = React.useCallback(
    async ({
      email: resetEmail,
      continueUrl,
    }: {
      email: string;
      continueUrl?: string;
    }) => {
      return firebase
        .auth()
        .sendPasswordResetEmail(
          resetEmail,
          continueUrl ? { url: continueUrl } : undefined
        );
    },
    []
  );

  /**
   * Method to perform a sign out
   */
  const performSignOut = React.useCallback(async () => {
    await firebase.auth().signOut();
  }, []);

  /**
   * Return the hook values via context
   */
  return (
    <AuthContext.Provider
      value={{
        uid,
        isAnonymous,
        isSignedIn,
        isHost,
        isAdmin,
        userDetails,
        performSignIn,
        performSignUp,
        updateName,
        updateProfilePicture,
        acceptTerms,
        updateEmail,
        performPasswordReset,
        performSignOut,
      }}
    >
      {loaded && children}
    </AuthContext.Provider>
  );
};
