import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { AccentButton } from "../components/Button";
import { TextInput } from "../components/TextInput";
import { useAuth } from "../../data/auth";
import { useFormInput } from "../../hooks/useFormInput";
import { newDemoGame } from "../../data/game";
import { useBrand } from "../../data/brand";

const Form = styled.form``;

const EmailTextInput = styled(TextInput)``;

const StartButton = styled(AccentButton)`
  margin-top: 8px;
`;

export const StartDemoForm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  const {
    brandId,
    brandDetails: { requireEmailForDemoGames },
  } = useBrand();
  const history = useHistory();
  const { alertHostUid } = useParams<{ alertHostUid?: string }>();

  // Manage the users email value
  const [email, onEmailChange, setEmail] = useFormInput({
    defaultValue: userDetails?.email,
  });
  const setEmailOnce = React.useRef(false);
  React.useEffect(() => {
    if (setEmailOnce.current || !userDetails?.email || email) {
      return;
    }

    setEmail(userDetails.email);
    setEmailOnce.current = true;
  }, [email, setEmail, userDetails]);

  // Handle the submit button
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) {
        return;
      }
      setLoading(true);

      const hostEmail = email.trim();
      if (requireEmailForDemoGames && !hostEmail) {
        alert(t("demoStart.errors.email"));
        return;
      }

      try {
        const { data: gameId } = await newDemoGame({
          brandId,
          hostEmail,
          alertHostUid,
        });
        history.push(`/${gameId}`);
      } catch (e) {
        alert(t("demoStart.errors.starting"));
      }

      setLoading(false);
    },
    [
      alertHostUid,
      brandId,
      email,
      history,
      loading,
      requireEmailForDemoGames,
      t,
    ]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <EmailTextInput
        type="email"
        placeholder={t(
          requireEmailForDemoGames
            ? "demoStart.form.emailPlaceholder"
            : "demoStart.form.emailPlaceholderOptional"
        )}
        value={email}
        onChange={onEmailChange}
        required={requireEmailForDemoGames}
      />

      <StartButton
        type="submit"
        disabled={requireEmailForDemoGames && email.length === 0}
        loading={loading}
      >
        {t("demoStart.form.submit")}
      </StartButton>
    </Form>
  );
};
