/* eslint-disable no-param-reassign */

import axios from "axios";
import { environment } from "../environment";
import firebase from "./firebase";

export const api = axios.create({
  baseURL: environment.apiBaseURL,
});

api.interceptors.request.use(async (config) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
