import React from "react";
import { GameSound } from "@pose-party/types";

export const GameSoundsContext = React.createContext<{
  playGameSound: (sound: GameSound) => () => void;
}>({
  playGameSound: () => {
    throw new Error("Must be used inside a GameSoundProvider");
  },
});
