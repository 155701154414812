import React from "react";
import styled from "styled-components/macro";

export interface ImageButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  large?: boolean;
}

export const ImageButton = styled.div<ImageButtonProps>`
  width: ${(props) => (props.large ? "82px" : "64px")};
  height: ${(props) => (props.large ? "82px" : "64px")};
  background: transparent;
  outline: 0;
  border: 0;
  font-size: 2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    opacity: 0.7;
  }

  &:focus {
    outline: 0;
  }

  & img {
    width: 100%;
    height: 100%;
  }
`;
