import React from "react";
import QRCode from "react-qr-code";
import styled from "styled-components/macro";
import { useBrand } from "../../data/brand";

export interface GameQrCodeProps {
  gameId: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const GameQrCode: React.FC<GameQrCodeProps> = ({ gameId }) => {
  const {
    brandDetails: { domain },
  } = useBrand();

  const gamePath = `${domain}/${gameId}`;
  const gameUrl = `https://${gamePath}`;

  return (
    <Container>
      <QRCode value={gameUrl} size={225} level="H" />
    </Container>
  );
};
