import React from "react";
import styled from "styled-components";
import { useBrand } from "../../data/brand";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";

export interface HostGameScreenProps {
  id?: string;
  renderHeader?: () => React.ReactNode;
  renderBody: () => React.ReactNode;
}

interface BackgroundImageProps {
  backgroundImageUrl: string | undefined;
}

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div<BackgroundImageProps>`
  flex: 0;
  width: 100%;
  box-shadow: ${(props) =>
    props.theme.settings.headerShadow
      ? "0px 2px 15px rgba(0, 0, 0, 0.2)"
      : undefined};
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.headerBackground}`
      : props.theme.colors.headerBackground};
  color: ${(props) => props.theme.colors.headerBackgroundOverlay};
  z-index: 1;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 12px auto;
`;

const BodyContainer = styled.div<BackgroundImageProps>`
  flex: 1;
  overflow: hidden;
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.background}`
      : props.theme.colors.background};
  color: ${(props) => props.theme.colors.backgroundOverlay};

  & a {
    color: ${(props) => props.theme.colors.backgroundOverlay};
  }
`;

export const HostGameScreen: React.FunctionComponent<HostGameScreenProps> = ({
  id,
  renderHeader,
  renderBody,
}) => {
  const { brandDetails } = useBrand();
  const headerBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.headerBackgroundImage
  );
  const contentBackgroundImageUrl = useGoogleStorageUrl(
    brandDetails.contentBackgroundImage
  );

  const renderedHeader = renderHeader?.();
  const renderedBody = renderBody();

  return (
    <Container id={id}>
      {!!renderedHeader && (
        <HeaderContainer backgroundImageUrl={headerBackgroundImageUrl}>
          <HeaderContent>{renderedHeader}</HeaderContent>
        </HeaderContainer>
      )}

      <BodyContainer backgroundImageUrl={contentBackgroundImageUrl}>
        {renderedBody}
      </BodyContainer>
    </Container>
  );
};
