import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { AuthProvider } from "./data/auth";
import { BrandProvider } from "./data/brand";
import { BrowserSupportedProvider } from "./data/browserSupported";
import { Navigation } from "./ui/Navigation";

interface State {
  hasError: boolean;
  eventId: string | undefined;
}

export default class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false,
      eventId: undefined,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;

    if (hasError) {
      return (
        <div className="container has-centered-content">
          <h1 className="title">Sorry. There was an error</h1>
          <p className="content">
            The game has crashed. Please report the issue so we can fix the
            issue and then refresh the game
          </p>
          <button
            type="button"
            className="button is-danger"
            onClick={() => Sentry.showReportDialog({ eventId })}
          >
            Report feedback
          </button>
        </div>
      );
    }
    return (
      <React.Suspense fallback={<div>Loading... </div>}>
        <Router>
          <BrandProvider>
            <BrowserSupportedProvider>
              <AuthProvider>
                <Navigation />
              </AuthProvider>
            </BrowserSupportedProvider>
          </BrandProvider>
        </Router>
      </React.Suspense>
    );
  }
}
