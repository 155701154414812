import React from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

/**
 * Hook to get the contnue parameter from the query string
 */
export const useContinueParam = (): string | undefined => {
  const location = useLocation();

  const continueParam = React.useMemo(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof params.continue === "string") {
      return params.continue;
    } else {
      return undefined;
    }
  }, [location.search]);

  return continueParam;
};
