import React from "react";
import { useTimer } from "react-timer-hook";

const POSE_COUNTDOWN_INTERSTITIAL = 3 * 1000; // 3 seconds

type PoseInterstitialState =
  | { state: "finished" }
  | { state: "poseCountdown"; count: number }
  | { state: "roundStart" };

export const usePoseInterstitialTimer = (
  roundInterstitialLength: number
): PoseInterstitialState => {
  const { seconds } = useTimer({
    expiryTimestamp:
      Date.now() + roundInterstitialLength + POSE_COUNTDOWN_INTERSTITIAL,
  });

  const state: PoseInterstitialState = React.useMemo(() => {
    switch (seconds) {
      case 0:
        return {
          state: "finished",
        };
      case 1:
      case 2:
      case 3:
        return {
          state: "poseCountdown",
          count: seconds,
        };
      default:
        return {
          state: "roundStart",
        };
    }
  }, [seconds]);

  return state;
};
