import React from "react";
import { useTimer } from "react-timer-hook";
import { ScoreHintInterstitial } from "./ScoreHintInterstitial";
import { SnapshotScoring } from "./SnapshotScoring";
import { ScoreWaiting } from "./ScoreWaiting";
import { GameHeader } from "../../../components/GameHeader";
import { ScreenAreaRenderProps } from "../../../components/GameScreen";
import { useBrand } from "../../../../data/brand";
import { useGame } from "../../../../hooks/useGame";

export interface ScoreProps {
  onHostShowResultsPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

const useScoreHintInterstitialTimer = (): [boolean, number] => {
  const {
    brandDetails: { timingScoreHintInterstitial },
  } = useBrand();

  const { seconds } = useTimer({
    expiryTimestamp: Date.now() + timingScoreHintInterstitial,
  });

  const state = React.useMemo(() => seconds > 0, [seconds]);
  return [state, timingScoreHintInterstitial];
};

export const Score: React.FunctionComponent<ScoreProps> = React.memo(
  ({ onHostShowResultsPress, kickPlayer }) => {
    const {
      brandDetails: { demoHelpContent },
    } = useBrand();
    const {
      data: {
        state,
        logo: gameLogo,
        currentPlayer,
        currentPose,
        players: gamePlayers,
        spectatorCount,
        poseSpectatorScoredCount,
        otherPlayerPoseSnapshots,
        isHost,
        isSpectator,
      },
      setSnapshotVote,
      setScordingState,
    } = useGame();

    /**
     * Keep track of our own current scoring state using a combination of the Frebase and local data
     */
    const [hasVoted, setHasVoted] = React.useState(false);
    React.useEffect(() => {
      if (
        !currentPose ||
        !currentPlayer ||
        otherPlayerPoseSnapshots === undefined
      ) {
        // Not yet loaded
      } else if (isSpectator) {
        // The value for spectators is set manually, not from the server
      } else if (currentPlayer.scored === currentPose.id) {
        // Already voted
        setHasVoted(true);
      } else if (otherPlayerPoseSnapshots.length === 0) {
        // Nothing to vote on
        setScordingState(true);
      }
    }, [
      currentPose,
      currentPlayer,
      isSpectator,
      setScordingState,
      otherPlayerPoseSnapshots,
    ]);

    /**
     * Keep track of other players scoring state
     */
    const playerList = React.useMemo(() => {
      return (gamePlayers || []).map((player) => {
        return {
          id: player.id,
          name: player.name,
          profilePicture: player.profilePicture,
          finished:
            !!currentPose && (player.scored === currentPose.id || player.fake),
          fake: player.fake,
        };
      });
    }, [currentPose, gamePlayers]);

    /**
     * Callback for when scoring is finished
     */
    const onScoringFinished = React.useCallback(() => {
      setScordingState(true);

      if (isSpectator) {
        setHasVoted(true);
      }
    }, [isSpectator, setScordingState]);

    /**
     * Run a timer for the state of the hint interstitial
     */
    const [showHint, interstitialDuration] = useScoreHintInterstitialTimer();

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        if (!currentPose) {
          return null;
        }

        return (
          <GameHeader
            logo={gameLogo}
            title={currentPose.prompt.toUpperCase()}
            toggleMenu={toggleMenu}
          />
        );
      },
      [currentPose, gameLogo]
    );

    if (!currentPose || otherPlayerPoseSnapshots === undefined) {
      // Waiting for data to load
      return null;
    } else if (showHint) {
      // Hint interstitial
      return <ScoreHintInterstitial duration={interstitialDuration} />;
    } else if (hasVoted) {
      // Has voted. Waiting for other players
      return (
        <ScoreWaiting
          isLargeGame={!!state.large}
          gameLogo={gameLogo}
          gamePlayers={playerList}
          playerUid={currentPlayer?.id}
          renderHeader={renderHeader}
          spectatorCount={spectatorCount}
          poseSpectatorScoredCount={poseSpectatorScoredCount}
          onHostShowResultsPress={isHost ? onHostShowResultsPress : undefined}
          kickPlayer={isHost ? kickPlayer : undefined}
        />
      );
    } else {
      // Scoring other players
      return (
        <SnapshotScoring
          gameLogo={gameLogo}
          helpContent={
            state.demo && currentPose.order === 0
              ? demoHelpContent?.preScore
              : undefined
          }
          groupId={currentPlayer?.groupId}
          poseId={currentPose.id}
          gamePlayers={gamePlayers}
          otherPlayerPoseSnapshots={otherPlayerPoseSnapshots}
          isSpectator={isSpectator}
          onSnapshotVote={setSnapshotVote}
          onScoringFinished={onScoringFinished}
          renderHeader={renderHeader}
        />
      );
    }
  }
);
