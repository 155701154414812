import React from "react";
import {
  WebcamCaptureSnapshotParams,
  WebcamStream,
  WebcamStartStreamOptions,
  WebcamStreamState,
  WebcamSnapshot,
} from "@pose-party/types";

export const WebcamStreamContext = React.createContext<{
  stream: WebcamStream;
  startStream: (options?: WebcamStartStreamOptions) => void;
  stopStream: () => void;
  swapCamera: () => void;
  createSnapshot: (
    videoEl: HTMLVideoElement,
    params: WebcamCaptureSnapshotParams
  ) => Promise<WebcamSnapshot | undefined>;
}>({
  stream: { state: WebcamStreamState.Inactive },
  startStream: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  stopStream: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  swapCamera: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
  createSnapshot: () => {
    throw new Error("Must be used inside a WebcamStreamProvider");
  },
});
