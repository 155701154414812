import React from "react";
import {
  WebcamCaptureSnapshotParams,
  WebcamStreamState,
  WebcamSnapshot,
} from "@pose-party/types";
import { useWebcamStream } from "../../data/webcam";

export interface WebcamDisplayProps {
  enableAudio?: boolean;
}

export interface WebcamDisplayStatic {
  swapCamera(): void;
  createSnapshot(
    params: WebcamCaptureSnapshotParams
  ): Promise<WebcamSnapshot | undefined>;
}

const COUNTDOWN_TIME = 3; // seconds

export const WebcamDisplay = React.memo(
  React.forwardRef<WebcamDisplayStatic, WebcamDisplayProps>(
    ({ enableAudio }: React.PropsWithChildren<WebcamDisplayProps>, ownRef) => {
      const videoRef = React.createRef<HTMLVideoElement>();
      const { stream, createSnapshot, swapCamera } = useWebcamStream();
      const [countdown, setCountdown] = React.useState<number>();

      // Attach the video stream whenever it or the video ref changes
      React.useEffect(() => {
        if (!videoRef.current) {
          return;
        }

        if (stream.state === WebcamStreamState.Active) {
          if (videoRef.current.srcObject !== stream.stream) {
            videoRef.current.srcObject = stream.stream;
          }
        } else {
          videoRef.current.srcObject = null;
        }
      }, [stream, videoRef]);

      // Imperative method for capturing a snapshot
      React.useImperativeHandle(ownRef, () => ({
        swapCamera,
        createSnapshot: async (params: WebcamCaptureSnapshotParams) => {
          if (!videoRef.current) {
            return undefined;
          }
          const videoEl = videoRef.current;

          if (
            params.countdown &&
            stream.state === WebcamStreamState.Active &&
            stream.userFacing
          ) {
            let countdownTimeToSet = COUNTDOWN_TIME;
            while (countdownTimeToSet > 0) {
              setCountdown(countdownTimeToSet);
              await new Promise((resolve) => setTimeout(resolve, 1000));
              countdownTimeToSet -= 1;
            }
            setCountdown(undefined);
          }

          return createSnapshot(videoEl, params);
        },
      }));

      const isUserFacing =
        stream.state === WebcamStreamState.Active && stream.userFacing;

      // Render everything
      return (
        <div
          className={`aspectContainer webcamPreviewContainer${
            isUserFacing ? " userFacing" : ""
          }`}
        >
          <video
            ref={videoRef}
            autoPlay
            muted={!enableAudio}
            playsInline
            controls={false}
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "cover",
            }}
          />

          {typeof countdown === "number" && (
            <div className="countdownOverlay">
              <p>{countdown}</p>
            </div>
          )}
        </div>
      );
    }
  )
);
