import React from "react";
import styled from "styled-components/macro";
import { Text } from "./Text";

export interface FileInputProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  title: string;
  value: File | undefined;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: transparent;
  font-family: "Nunito Sans";
  font-weight: 700;
  border-radius: 10px;
  padding: 0 16px;
  height: 48px;
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};
`;

const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const Title = styled(Text)`
  margin-right: 8px;
`;
const Label = styled.label`
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const FileInput: React.FunctionComponent<FileInputProps> = ({
  title,
  style,
  id,
  value,
  ...rest
}) => {
  return (
    <Container style={style}>
      <HiddenInput type="file" id={id} name={id} {...rest} />
      <Title weight="bold">{title}</Title>
      <Label htmlFor={id}>{value ? value.name : "Choose a file"}</Label>
    </Container>
  );
};
