import React from "react";
import styled from "styled-components/macro";
import { useBrand } from "../../data/brand";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";

interface BackgroundImageProps {
  backgroundImageUrl: string | undefined;
}

const OuterContainer = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.backgroundImageUrl
      ? `url(${props.backgroundImageUrl}) ${props.theme.colors.desktopBackground}`
      : props.theme.colors.desktopBackground};
`;
const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  max-width: 440px;
  position: relative;
  overflow: hidden;
`;

export const AppContainer: React.FunctionComponent = ({ children }) => {
  const {
    brandDetails: { desktopBackgroundImage },
  } = useBrand();
  const desktopBackgroundImageUrl = useGoogleStorageUrl(desktopBackgroundImage);

  return (
    <OuterContainer backgroundImageUrl={desktopBackgroundImageUrl}>
      <Container>{children}</Container>
    </OuterContainer>
  );
};
