import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { WebcamSnapshot } from "@pose-party/types";
import { useGameGroupIds, useGamePoseSnapshots } from "../../../data/game";
import { RegularTitle } from "../../components/Title";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { GoogleStorageVideo } from "../../components/GoogleStorageVideo";
import { AspectCard } from "../../components/AspectCard";
import { SnapshotDisplay } from "../../Game/SnapshotDisplay";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { useFormInput } from "../../../hooks/useFormInput";
import { GalleryHeader } from "../GalleryHeader";
import { useBrand } from "../../../data/brand";
import { logger } from "../../../logger";
import { Markdown } from "../../components/Markdown";
import { useAuth } from "../../../data/auth";
import { api } from "../../../data/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const AllLink = styled(Link)`
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props.theme.colors.primaryBrand};
  margin-bottom: 32px;
  text-align: center;
`;

const SnapshotContainer = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: auto;
`;

const MarkdownContentTop = styled(Markdown)`
  margin: 32px 0;
`;
const MarkdownContentBottom = styled(Markdown)`
  margin-top: 32px;
`;

const ShareForm = styled.div`
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: 32px auto;
`;
const ShareTitle = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 16px;
`;
const EmailTextInput = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.textInputBackground};
  color: ${(props) => props.theme.colors.textInputBackgroundOverlay};
`;
const ShareButton = styled(Button)`
  margin-top: 16px;
`;
const DownloadButtonContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 16px auto 0 auto;
`;
const DownloadButton = styled.a`
  border-color: transparent;
  font-family: "Nunito Sans";
  font-weight: 700;
  border-radius: 10px;
  height: 48px;
  min-height: 48px;
  display: block;
  padding: 12px;
  margin: 0 16px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.secondaryBrand};
  color: ${(props) => props.theme.colors.secondaryBrandOverlay};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.secondaryBrand};
    color: ${(props) => props.theme.colors.secondaryBrandOverlay};
    opacity: 0.7;
  }
`;

const SanpshotView = ({
  snapshot,
}: {
  snapshot: string | WebcamSnapshot | null | undefined;
}) => {
  if (typeof snapshot === "string") {
    return (
      <GoogleStorageVideo src={snapshot} autoPlay controls={false} muted loop />
    );
  } else if (Array.isArray(snapshot)) {
    return <SnapshotDisplay snapshot={snapshot} />;
  } else {
    return null;
  }
};

export const GameGallerySingle = () => {
  const { t } = useTranslation();
  const gameId = useGameIdParam();
  const { poseId, uid } = useParams<{ poseId: string; uid: string }>();
  const {
    brandId,
    brandDetails: { landingPageContent, hideNamesInGalleries },
  } = useBrand();

  const groupIds = useGameGroupIds({ brandId, gameId });

  const snapshots = useGamePoseSnapshots({
    brandId,
    gameId,
    poseId,
    groupId: groupIds && groupIds.length === 1 ? groupIds[0] : undefined,
  });

  const hasLoaded = React.useMemo(() => snapshots !== undefined, [snapshots]);

  const snapshot = React.useMemo(() => snapshots?.find((s) => s.uid === uid), [
    snapshots,
    uid,
  ]);

  const [email, handleEmailChange, setEmail] = useFormInput();
  const { userDetails } = useAuth();
  React.useEffect(() => {
    if (userDetails?.email) {
      setEmail(userDetails.email);
    }
  }, [setEmail, userDetails]);

  const [isSharing, setIsSharing] = React.useState(false);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!email.trim()) {
        return;
      }

      setIsSharing(true);
      try {
        await api.post("shareEmail", {
          email: email.trim(),
          brandId,
          gameId,
          poseId,
          uid,
        });

        setEmail("");

        alert(
          "Email sent! Check your email for the video and share from there"
        );
      } catch (e) {
        logger.warn("Error while sharing", e);
        alert(
          "Error while sharing. Please check your email address and try again."
        );
      }
      setIsSharing(false);
    },
    [email, brandId, gameId, poseId, uid, setEmail]
  );

  // Game with multiple groups are not supported
  if (groupIds !== undefined && groupIds.length > 1) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      {hasLoaded && (
        <>
          <GalleryHeader
            brandId={brandId}
            gameId={gameId}
            hideGameName={hideNamesInGalleries}
          />

          {!!snapshot && (
            <>
              {landingPageContent?.gallery?.shareTop && (
                <MarkdownContentTop
                  source={landingPageContent.gallery.shareTop}
                />
              )}

              <SnapshotContainer>
                <AspectCard aspectRatio={1} style={{ borderRadius: "5%" }}>
                  <SanpshotView snapshot={snapshot.mp4 || snapshot.snapshot} />
                </AspectCard>
              </SnapshotContainer>

              {!!snapshot.mp4 && (
                <DownloadButtonContainer>
                  <DownloadButton
                    href={`/download?gs=${snapshot.mp4}`}
                    download="snapshot.mp4"
                  >
                    {t("gallery.share.downloadVideo")}
                  </DownloadButton>
                </DownloadButtonContainer>
              )}

              {landingPageContent?.gallery?.shareBottom && (
                <MarkdownContentBottom
                  source={landingPageContent.gallery.shareBottom}
                />
              )}

              <ShareForm>
                <form onSubmit={handleSubmit}>
                  <ShareTitle>{t("gallery.share.title")}</ShareTitle>
                  <EmailTextInput
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={t("gallery.share.email")}
                  />
                  <ShareButton
                    className={`button ${isSharing ? "is-loading" : ""}`}
                    type="submit"
                  >
                    {t("gallery.share.submit")}
                  </ShareButton>
                </form>
              </ShareForm>

              <AllLink to={`/gallery/${gameId}`}>{t("gallery.all")}</AllLink>
            </>
          )}
        </>
      )}
    </Container>
  );
};
