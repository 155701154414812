import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  DISLIKE_SCORE_STRING,
  LIKE_SCORE_STRING,
  SUPERLIKE_SCORE_STRING,
} from "@pose-party/types";
import { useBrand } from "../../data/brand";
import { LargeTitle, VeryLargeTitle } from "./Title";
import notGreat from "../../assets/notGreat.svg";
import like from "../../assets/like.svg";
import superLike from "../../assets/superLike.svg";
import { GoogleStorageImage } from "./GoogleStorageImage";

export interface ScoreHintProps extends React.HTMLAttributes<HTMLDivElement> {
  type: "dislike" | "like" | "superlike";
}

const Hint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  max-width: 180px;
  max-height: 180px;
`;
const GSImage = styled(GoogleStorageImage)`
  max-width: 180px;
  max-height: 180px;
`;
const HintText = styled(LargeTitle)`
  text-align: center;
  font-weight: 600;
  padding: 24px 0 8px 0;
`;
const ScoreText = styled(VeryLargeTitle)`
  text-align: center;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

export const ScoreHint: React.FunctionComponent<ScoreHintProps> = React.memo(
  ({ type, ...rest }) => {
    const {
      brandDetails: {
        gameScoringDislike,
        gameScoringLike,
        gameScoringSuperLike,
      },
    } = useBrand();
    const { t } = useTranslation();

    let googleStorageUrl: string | undefined;
    let imageUrl: string | undefined;
    if (type === "dislike") {
      if (gameScoringDislike) {
        googleStorageUrl = gameScoringDislike;
      } else {
        imageUrl = notGreat;
      }
    } else if (type === "like") {
      if (gameScoringLike) {
        googleStorageUrl = gameScoringLike;
      } else {
        imageUrl = like;
      }
    } else if (gameScoringSuperLike) {
      googleStorageUrl = gameScoringSuperLike;
    } else {
      imageUrl = superLike;
    }

    let hintText: string;
    if (type === "dislike") {
      hintText = t("scoreStartInterstitial.notGreatHint");
    } else if (type === "like") {
      hintText = t("scoreStartInterstitial.likeHint");
    } else {
      hintText = t("scoreStartInterstitial.superLikeHint");
    }

    let score: string;
    if (type === "dislike") {
      score = DISLIKE_SCORE_STRING;
    } else if (type === "like") {
      score = LIKE_SCORE_STRING;
    } else {
      score = SUPERLIKE_SCORE_STRING;
    }

    return (
      <Hint {...rest}>
        {googleStorageUrl ? (
          <GSImage src={googleStorageUrl} />
        ) : (
          <Image src={imageUrl} alt="" />
        )}
        <HintText>{hintText}</HintText>
        <ScoreText>
          {t("scoreStartInterstitial.pointsHint", { score })}
        </ScoreText>
      </Hint>
    );
  }
);
