import React from "react";
import styled from "styled-components";
import { useBrand } from "../../data/brand";

const Button = styled.button`
  position: absolute;
  top: 20px;
  left: 8px;
  background: none;
  border: 0;
  z-index: 10;
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
`;

export const BackButton: React.FunctionComponent<React.HTMLAttributes<
  HTMLButtonElement
>> = (props) => {
  const { brandDetails } = useBrand();

  return (
    <Button {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
          fill={brandDetails.colors.headerBackgroundOverlay}
        />
      </svg>
    </Button>
  );
};
