import React from "react";
import styled from "styled-components/macro";

export interface AspectCardProps extends React.HTMLAttributes<HTMLDivElement> {
  aspectRatio?: number;
}

interface ContainerProps {
  aspectRatio: number;
}
const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: ${(props) => `${(1 / props.aspectRatio) * 100}%`};
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  & img {
    display: flex;
    width: 100%;
    height: 100%;
  }

  & video {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
`;

export const AspectCard: React.FunctionComponent<AspectCardProps> = ({
  aspectRatio,
  ...props
}) => {
  return <Container aspectRatio={aspectRatio ?? 1} {...props} />;
};
