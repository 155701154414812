import React from "react";
import styled from "styled-components/macro";
import { GameScreen, ScreenAreaRenderProps } from "./components/GameScreen";
import { MenuToggle } from "./components/MenuToggle";
import { RegularTitle } from "./components/Title";
import { Text } from "./components/Text";

const Container = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.background};
  overflow-y: auto;
`;
const HeaderContainer = styled.div`
  padding: 70px 16px 32px 16px;
  display: flex;
  flex-direction: column;
`;
const Title = styled(RegularTitle)`
  text-align: center;
  margin-bottom: 8px;
`;
const HintText = styled(Text)`
  text-align: center;
`;

export const NotFound: React.FunctionComponent = () => {
  const renderBody = React.useCallback(
    ({ toggleMenu }: ScreenAreaRenderProps) => {
      return (
        <Container>
          <MenuToggle onClick={toggleMenu} />

          <HeaderContainer>
            <Title>Page not found</Title>

            <HintText>Sorry. That page was not found.</HintText>
          </HeaderContainer>
        </Container>
      );
    },
    []
  );

  return <GameScreen renderBody={renderBody} />;
};
