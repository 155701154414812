import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GameListGameStatus } from "@pose-party/types";
import { Text } from "../components/Text";
import { Button } from "../components/Button";
import { useAuth } from "../../data/auth";
import { RegularTitle } from "../components/Title";
import { usePlayerGames } from "../../data/game";
import { UserDataRow } from "../components/UserDataRow";
import { useTounramentsByHost } from "../../data/tournament";
import { useBrand } from "../../data/brand";

const Container = styled.div`
  padding: 32px 16px 16px 16px;
`;

const CreateButton = styled(Button)`
  margin-bottom: 16px;
`;

const GameTitle = styled(RegularTitle)`
  margin-top: 40px;
`;

export const UserAccount = () => {
  const { t } = useTranslation();
  const { uid, userDetails, isHost } = useAuth();
  const {
    brandId,
    brandDetails: {
      showHomepageJoinGameForm,
      hostGameLimit,
      allowTournaments,
      hideFinishedGamesInAccount,
    },
  } = useBrand();
  const history = useHistory();
  const games = usePlayerGames({ brandId, uid });
  const tournaments = useTounramentsByHost({ brandId, uid });
  const nonTournamentGames = React.useMemo(
    () => games?.filter((game) => !game.tournamentId),
    [games]
  );
  const upcomingGames = React.useMemo(
    () =>
      nonTournamentGames?.filter(
        (game) => game.status !== GameListGameStatus.Finished
      ),
    [nonTournamentGames]
  );
  const finishedGames = React.useMemo(
    () =>
      hideFinishedGamesInAccount
        ? undefined
        : nonTournamentGames?.filter(
            (game) => game.status === GameListGameStatus.Finished
          ),
    [hideFinishedGamesInAccount, nonTournamentGames]
  );

  const handleCreateGame = React.useCallback(() => {
    history.push("/user/create");
  }, [history]);

  const handleCreateTournament = React.useCallback(() => {
    history.push("/user/tournament/create");
  }, [history]);

  return (
    <Container>
      {isHost && !!games && !!userDetails && games.length < hostGameLimit && (
        <>
          <CreateButton onClick={handleCreateGame}>
            {t("user.createGame")}
          </CreateButton>
          {allowTournaments && (
            <CreateButton onClick={handleCreateTournament}>
              {t("user.createTournament")}
            </CreateButton>
          )}
        </>
      )}

      <GameTitle>{t("user.upcomingGames")}</GameTitle>
      {!!upcomingGames && upcomingGames.length > 0 ? (
        upcomingGames.map((game) => (
          <UserDataRow
            key={game.id}
            data={{
              name: game.name,
              id: showHomepageJoinGameForm ? game.id : undefined,
              createdAt: game.createdAt,
              path: `/user/game/${game.id}`,
            }}
          />
        ))
      ) : (
        <Text>No games</Text>
      )}

      {!!finishedGames && finishedGames.length > 0 && (
        <>
          <GameTitle>{t("user.finishedGames")}</GameTitle>

          {finishedGames.map((game) => (
            <UserDataRow
              key={game.id}
              data={{
                name: game.name,
                id: showHomepageJoinGameForm ? game.id : undefined,
                createdAt: game.createdAt,
                path: `/user/game/${game.id}`,
              }}
            />
          ))}
        </>
      )}

      {!!tournaments && tournaments.length > 0 && (
        <>
          <GameTitle>{t("user.tournaments")}</GameTitle>

          {tournaments.map((tournament) => (
            <UserDataRow
              key={tournament.id}
              data={{
                name: tournament.name,
                id: showHomepageJoinGameForm ? tournament.id : undefined,
                createdAt: tournament.createdAt,
                path: `/user/tournament/${tournament.id}`,
              }}
            />
          ))}
        </>
      )}
    </Container>
  );
};
