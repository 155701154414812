import React from "react";
import { GameSetupState, GameStatus } from "@pose-party/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";
import { useAuth } from "../../../data/auth";
import { useBrand } from "../../../data/brand";
import { AccentButton, Button } from "../../components/Button";
import { useGameIdParam } from "../../../hooks/useGameIdParam";
import { useHostActions } from "../../../hooks/useHostActions";
import { useHostGame } from "../../../hooks/useHostGame";
import { HostGamePoseCountdown } from "./HostGamePoseCountdown";
import { Modal } from "../../components/Modal";
import { hostAddPose } from "../../../data/game";
import { PoseInput } from "../../components/PoseInput";
import { Text } from "../../components/Text";

const StateChangebutton = styled(Button)``;
const ExtraStateChangebutton = styled(Button)`
  margin-top: 8px;
  font-weight: 500;
`;

const ExtendHint = styled(Text)`
  margin-bottom: 8px;
`;
const ExtendStart = styled(AccentButton)`
  margin-top: 8px;
`;
const ExtendClose = styled(Button)`
  margin-top: 8px;
`;

export const HostGameControls: React.FunctionComponent = React.memo(() => {
  const {
    state,
    isHost,
    roundDetails,
    setupStatusCounters,
    playerCount,
    poseCompletedCount,
    scoreCompletedCount,
    hostingWithHostUI,
  } = useHostGame();
  const { uid } = useAuth();
  const {
    brandId,
    brandDetails: {
      allowEndingGameEarly,
      allowAddingNewRoundsInGame,
      gamePoseOptions,
    },
  } = useBrand();
  const gameId = useGameIdParam();
  const { t } = useTranslation();

  const [performingAction, actions] = useHostActions({
    brandId,
    gameId,
    uid,
    groupSize: state.largeGroupSize,
    hostPlaying: false,
    isHost,
  });

  const totalSetupPlayers = React.useMemo(() => {
    return _.reduce(setupStatusCounters, (prev, value) => prev + value, 0);
  }, [setupStatusCounters]);
  const hasPlayersLeftToSetup =
    setupStatusCounters?.[GameSetupState.Done] !== totalSetupPlayers;
  const startGame = React.useCallback(() => {
    if (hasPlayersLeftToSetup) {
      const sure = window.confirm(
        "There are players still setting up. Are you sure you want to start the game?"
      );
      if (!sure) {
        return;
      }
    }

    actions.startGame();
  }, [actions, hasPlayersLeftToSetup]);

  const hasPlayersLeftToPose = playerCount !== poseCompletedCount;
  const makeStartScoring = React.useCallback(
    (skipConfirm) => () => {
      if (!skipConfirm && hasPlayersLeftToPose) {
        const sure = window.confirm(
          "There are players still posing. Are you sure you want to start scoring?"
        );
        if (!sure) {
          return;
        }
      }

      actions.startScoring();
    },
    [actions, hasPlayersLeftToPose]
  );
  React.useEffect(() => {
    if (
      state.status === GameStatus.Pose &&
      !hasPlayersLeftToPose &&
      hostingWithHostUI
    ) {
      makeStartScoring(true)();
    }
  }, [hasPlayersLeftToPose, hostingWithHostUI, makeStartScoring, state.status]);

  const hasPlayersLeftToScore = playerCount !== scoreCompletedCount;
  const showResults = React.useCallback(() => {
    if (hasPlayersLeftToScore) {
      const sure = window.confirm(
        "There are players still scoring. Are you sure you want to show results?"
      );
      if (!sure) {
        return;
      }
    }

    actions.showResults();
  }, [actions, hasPlayersLeftToScore]);

  const endGameEarly = React.useCallback(() => {
    const sure = window.confirm(
      "There are rounds still left to play. Are you sure you want to end the game and show the final results?"
    );
    if (!sure) {
      return;
    }

    actions.showFinalResults();
  }, [actions]);

  const [showExtendModal, setShowExtendModal] = React.useState(false);
  const poseSelectOptions = React.useMemo(
    () =>
      gamePoseOptions.map((pose) => ({
        label: pose,
        value: pose,
      })),
    [gamePoseOptions]
  );
  const [extendPose, setExtendPose] = React.useState<string | undefined>(
    gamePoseOptions[0]
  );
  const onHostExtendPress = React.useCallback(() => {
    setShowExtendModal(true);
  }, []);
  const onHostCloseExtendPress = React.useCallback(() => {
    setShowExtendModal(false);
  }, []);
  const onHostExtendStartPress = React.useCallback(async () => {
    if (!extendPose) {
      return;
    }

    await hostAddPose({
      brandId,
      gameId,
      prompt: extendPose,
      order: parseInt(roundDetails.roundNumber, 10),
    });

    setShowExtendModal(false);

    actions.startNextPose();
  }, [actions, brandId, extendPose, gameId, roundDetails.roundNumber]);

  const stateChangeButton = React.useMemo(() => {
    switch (state.status) {
      case GameStatus.Setup: {
        const completed = setupStatusCounters?.[GameSetupState.Done] || 0;
        const total = totalSetupPlayers || 0;
        const percentage =
          total === 0 ? 0 : Math.ceil((completed / total) * 100);
        return (
          <StateChangebutton loading={performingAction} onClick={startGame}>
            {t("hostGameUI.controls.startGame", { percentage })}
          </StateChangebutton>
        );
      }
      case GameStatus.Pose: {
        const completed = poseCompletedCount || 0;
        const total = playerCount || 0;
        const percentage =
          total === 0 ? 0 : Math.ceil((completed / total) * 100);
        return (
          <>
            <StateChangebutton
              loading={performingAction}
              onClick={makeStartScoring(false)}
            >
              {t("hostGameUI.controls.startScore", { percentage })}
            </StateChangebutton>
            <HostGamePoseCountdown onCountdownEnd={makeStartScoring(true)} />
          </>
        );
      }
      case GameStatus.Score: {
        const completed = scoreCompletedCount || 0;
        const total = playerCount || 0;
        const percentage =
          total === 0 ? 0 : Math.ceil((completed / total) * 100);
        return (
          <StateChangebutton loading={performingAction} onClick={showResults}>
            {t("hostGameUI.controls.showResults", { percentage })}
          </StateChangebutton>
        );
      }
      case GameStatus.Results:
        if (roundDetails.roundNumber !== roundDetails.roundCount) {
          // Still more rounds to go
          return (
            <>
              <StateChangebutton
                loading={performingAction}
                onClick={actions.startNextPose}
              >
                {t("hostGameUI.controls.startNextRound")}
              </StateChangebutton>
              {allowEndingGameEarly && (
                <ExtraStateChangebutton
                  loading={performingAction}
                  onClick={endGameEarly}
                >
                  {t("hostGameUI.controls.endGameEarly")}
                </ExtraStateChangebutton>
              )}
            </>
          );
        } else {
          // We've played the final round
          return (
            <>
              <StateChangebutton
                loading={performingAction}
                onClick={actions.showFinalResults}
              >
                {t("hostGameUI.controls.showFinalResults")}
              </StateChangebutton>
              {allowAddingNewRoundsInGame && (
                <ExtraStateChangebutton
                  loading={performingAction}
                  onClick={onHostExtendPress}
                >
                  {t("hostGameUI.controls.extendGame")}
                </ExtraStateChangebutton>
              )}
            </>
          );
        }
      case GameStatus.FinalResults:
      case GameStatus.Finished:
        return null;
    }
  }, [
    actions.showFinalResults,
    actions.startNextPose,
    allowAddingNewRoundsInGame,
    allowEndingGameEarly,
    endGameEarly,
    onHostExtendPress,
    performingAction,
    playerCount,
    poseCompletedCount,
    roundDetails.roundCount,
    roundDetails.roundNumber,
    scoreCompletedCount,
    setupStatusCounters,
    showResults,
    startGame,
    makeStartScoring,
    state.status,
    t,
    totalSetupPlayers,
  ]);

  return (
    <>
      {stateChangeButton}
      {showExtendModal && (
        <Modal>
          <ExtendHint>{t("gameResults.extendHint")}</ExtendHint>
          <PoseInput
            value={extendPose}
            onValueChange={setExtendPose}
            poseOptions={poseSelectOptions}
          />
          <ExtendStart onClick={onHostExtendStartPress}>
            {t("gameResults.extendStart")}
          </ExtendStart>
          <ExtendClose onClick={onHostCloseExtendPress}>
            {t("gameResults.extendClose")}
          </ExtendClose>
        </Modal>
      )}
    </>
  );
});
