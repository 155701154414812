import React from "react";
import { useTimer } from "react-timer-hook";
import { FinalResultsInterstitial } from "./FinalResultsInterstitial";
import { FinalResultsList } from "./FinalResultsList";
import { useBrand } from "../../../../data/brand";
import { useGameIdParam } from "../../../../hooks/useGameIdParam";
import { useGame } from "../../../../hooks/useGame";
import { useAuth } from "../../../../data/auth";
import { OwnResults } from "./OwnResults";

export interface FinalResultsProps {}

enum FinalResultsInterstitialState {
  List,
  Own,
  Title,
}
const useFinalResultsInterstitialTimer = (): FinalResultsInterstitialState => {
  const { seconds } = useTimer({
    expiryTimestamp: Date.now() + 10000, // 10 seconds
  });

  const state: FinalResultsInterstitialState = React.useMemo(() => {
    if (seconds === 0) {
      return FinalResultsInterstitialState.List;
    } else if (seconds <= 5) {
      // 5 seconds
      return FinalResultsInterstitialState.Own;
    } else {
      return FinalResultsInterstitialState.Title;
    }
  }, [seconds]);

  return state;
};

export const FinalResults: React.FunctionComponent<FinalResultsProps> = React.memo(
  () => {
    const { userDetails } = useAuth();
    const {
      brandId,
      brandDetails: { demoHelpContent },
    } = useBrand();
    const gameId = useGameIdParam();
    const {
      data: { logo: gameLogo, currentPlayer, players: gamePlayers, state },
    } = useGame();
    const isLargeGame = !!state.large;
    const isInPerson = !!state.inPerson;

    const interstitialState = useFinalResultsInterstitialTimer();

    if (interstitialState === FinalResultsInterstitialState.Title) {
      return <FinalResultsInterstitial />;
    } else if (
      interstitialState === FinalResultsInterstitialState.Own &&
      currentPlayer
    ) {
      return (
        <OwnResults
          player={currentPlayer}
          isLargeGame={isLargeGame}
          gameLogo={gameLogo}
        />
      );
    } else if (gamePlayers) {
      return (
        <FinalResultsList
          brandId={brandId}
          gameId={gameId}
          helpContent={
            state.demo ? demoHelpContent?.preFinalResults : undefined
          }
          isLargeGame={isLargeGame}
          isInPerson={isInPerson}
          gamePlayers={gamePlayers}
          gameLogo={gameLogo}
          hasEmail={!!userDetails?.email}
        />
      );
    } else {
      return null;
    }
  }
);
