import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  GamePlayer,
  GamePose,
  GameSnapshot,
  WebcamSnapshot,
} from "@pose-party/types";
import _ from "lodash";
import {
  GameScreen,
  ScreenAreaRenderProps,
} from "../../../components/GameScreen";
import { Text } from "../../../components/Text";
import { AspectCard } from "../../../components/AspectCard";
import { SnapshotDisplay } from "../../SnapshotDisplay";
import { RegularTitle } from "../../../components/Title";
import { GameFooter } from "../../../components/GameFooter";
import { AccentButton, Button } from "../../../components/Button";
import firebase from "../../../../data/firebase";
import { getNextUnplayedPoseId, hostAddPose } from "../../../../data/game";
import { ResultsPlayer, positionWithSuffix } from "./utils";
import { SmallDeleteIcon } from "../../../components/SmallDeleteIcon";
import { GameHeader } from "../../../components/GameHeader";
import { Modal } from "../../../components/Modal";
import { useBrand } from "../../../../data/brand";
import { PoseInput } from "../../../components/PoseInput";

export interface ResultsGridProps {
  brandId: string;
  gameId: string;
  gameLogo: string | null | undefined;
  helpContent: string | undefined;
  isLargeGame: boolean;
  currentPose: GamePose;
  currentPlayer: GamePlayer | null | undefined;
  currentPlayerPoseSnapshot: GameSnapshot | undefined;
  gamePlayers: GamePlayer[];
  otherPlayerPoseSnapshots: GameSnapshot[];
  isHost: boolean;
  allowEndingGameEarly: boolean;
  allowAddingNewRoundsInGame: boolean;
  uid: string | undefined;
  onHostNextPosePress: () => Promise<void>;
  onHostFinalResultsPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const RoundLeaderboardTitle = styled(RegularTitle)`
  margin: 16px 0 4px 0;
  text-align: center;
`;

const RoundLeaderboardSubtitle = styled(Text)`
  margin-bottom: 16px;
  text-align: center;
`;

const ResultsContainer = styled.div`
  flex: 1;
  padding: 16px 32px 16px 32px;
`;

const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Position = styled(Text)`
  margin-bottom: 8px;
`;
const PlayerName = styled(Text)`
  margin-top: 8px;
`;
const Score = styled(RegularTitle)`
  color: ${(props) => props.theme.colors.primaryBrand};
  line-height: 27px;
`;

const ExtendHint = styled(Text)`
  margin-bottom: 8px;
`;
const ExtendStart = styled(AccentButton)`
  margin-top: 8px;
`;
const ExtendClose = styled(Button)`
  margin-top: 8px;
`;

export const ResultsGrid: React.FunctionComponent<ResultsGridProps> = React.memo(
  ({
    brandId,
    gameId,
    gameLogo,
    helpContent,
    isLargeGame,
    currentPose,
    currentPlayer,
    currentPlayerPoseSnapshot,
    gamePlayers,
    otherPlayerPoseSnapshots,
    isHost,
    allowEndingGameEarly,
    allowAddingNewRoundsInGame,
    uid,
    onHostNextPosePress,
    onHostFinalResultsPress,
    kickPlayer,
  }) => {
    const { t } = useTranslation();
    const {
      brandDetails: { gamePoseOptions },
    } = useBrand();

    const onHostEndEarlyPress = React.useCallback(() => {
      const sure = window.confirm(t("gameResults.hostEndEarlyConfirm"));
      if (!sure) {
        return;
      }

      onHostFinalResultsPress();
    }, [onHostFinalResultsPress, t]);

    const [showExtendModal, setShowExtendModal] = React.useState(false);
    const poseSelectOptions = React.useMemo(
      () =>
        gamePoseOptions.map((pose) => ({
          label: pose,
          value: pose,
        })),
      [gamePoseOptions]
    );
    const [extendPose, setExtendPose] = React.useState<string | undefined>(
      gamePoseOptions[0]
    );
    const onHostExtendPress = React.useCallback(() => {
      setShowExtendModal(true);
    }, []);
    const onHostCloseExtendPress = React.useCallback(() => {
      setShowExtendModal(false);
    }, []);
    const onHostExtendStartPress = React.useCallback(async () => {
      if (!extendPose) {
        return;
      }

      await hostAddPose({
        brandId,
        gameId,
        prompt: extendPose,
        order: currentPose.order + 1,
      });

      setShowExtendModal(false);

      onHostNextPosePress();
    }, [brandId, currentPose.order, extendPose, gameId, onHostNextPosePress]);

    /**
     * Work out the list of players to display
     */
    const playerList: ResultsPlayer[] = React.useMemo(() => {
      if (!otherPlayerPoseSnapshots) {
        return [];
      }

      return _.chain(gamePlayers)
        .map((p) => {
          let snapshot: WebcamSnapshot | undefined;
          if (
            p.id === currentPlayer?.id &&
            !!currentPlayerPoseSnapshot?.snapshot
          ) {
            snapshot = currentPlayerPoseSnapshot.snapshot;
          } else {
            snapshot = otherPlayerPoseSnapshots.find(
              (s) => s.uid === p.id && !s.hidden
            )?.snapshot;
          }

          return { ...p, snapshot };
        })
        .filter((p) => !!p.snapshot)
        .filter((p) => !!p.groupPoseResults)
        .sortBy((p) => p.groupPoseResults?.position)
        .map((p) => {
          if (!p.groupPoseResults) {
            return null;
          }

          return {
            id: p.id,
            name: p.name,
            snapshot: p.snapshot,
            fake: p.fake,
            results: p.groupPoseResults,
          };
        })
        .compact()
        .value();
    }, [
      currentPlayer,
      currentPlayerPoseSnapshot,
      gamePlayers,
      otherPlayerPoseSnapshots,
    ]);

    /**
     * For host, work out if this is the last round
     */
    const [isLastRound, setIsLastRound] = React.useState(false);
    React.useEffect(() => {
      const gameRef = firebase
        .database()
        .ref(`/brands/${brandId}/games/${gameId}`);
      getNextUnplayedPoseId(gameRef).then((poseId) => {
        setIsLastRound(!poseId);
      });
    }, [brandId, gameId]);

    /**
     * Render the main list
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        return (
          <GameHeader
            logo={gameLogo}
            toggleMenu={toggleMenu}
            title={currentPose.prompt.toUpperCase()}
          />
        );
      },
      [currentPose.prompt, gameLogo]
    );

    const renderBody = React.useCallback(() => {
      return (
        <Container>
          <RoundLeaderboardTitle>
            {t(isLargeGame ? "gameResults.largeTitle" : "gameResults.title", {
              count: currentPose.order + 1,
            })}
          </RoundLeaderboardTitle>
          <RoundLeaderboardSubtitle>
            {t("gameResults.subtitle")}
          </RoundLeaderboardSubtitle>

          <ResultsContainer>
            <div className="columns is-multiline is-mobile">
              {playerList.map((player) => (
                <GridItem key={player.id} className="column is-one-third">
                  <Position subtle>
                    {player.results.equalPosition ? "=" : ""}
                    {positionWithSuffix(player.results.position)}
                  </Position>

                  <AspectCard aspectRatio={1} style={{ borderRadius: "5%" }}>
                    {!!player.snapshot && (
                      <SnapshotDisplay snapshot={player.snapshot} />
                    )}
                  </AspectCard>

                  <PlayerName weight="bold">{player.name}</PlayerName>

                  <Score>{player.results.score}</Score>

                  {isHost && player.id !== uid && !player.fake && (
                    <SmallDeleteIcon
                      onClick={() => kickPlayer(player.id)}
                      style={{ top: "28px" }}
                    />
                  )}
                </GridItem>
              ))}
            </div>
          </ResultsContainer>
          {showExtendModal && (
            <Modal>
              <ExtendHint>{t("gameResults.extendHint")}</ExtendHint>
              <PoseInput
                value={extendPose}
                onValueChange={setExtendPose}
                poseOptions={poseSelectOptions}
              />
              <ExtendStart onClick={onHostExtendStartPress}>
                {t("gameResults.extendStart")}
              </ExtendStart>
              <ExtendClose onClick={onHostCloseExtendPress}>
                {t("gameResults.extendClose")}
              </ExtendClose>
            </Modal>
          )}
        </Container>
      );
    }, [
      t,
      isLargeGame,
      currentPose.order,
      playerList,
      showExtendModal,
      extendPose,
      poseSelectOptions,
      onHostExtendStartPress,
      onHostCloseExtendPress,
      isHost,
      uid,
      kickPlayer,
    ]);

    /**
     * Render the footer for the host
     */
    const renderFooter = React.useCallback(() => {
      if (!isHost) {
        return null;
      }

      const nextRoundButton = (
        <Button onClick={onHostNextPosePress}>
          {t("gameResults.hostNext")}
        </Button>
      );

      let content = null;
      if (isLastRound) {
        content = (
          <>
            <Button onClick={onHostFinalResultsPress}>
              {t("gameResults.hostEnd")}
            </Button>
            {allowAddingNewRoundsInGame && (
              <Button
                style={{ marginLeft: 8, fontWeight: 400 }}
                onClick={onHostExtendPress}
              >
                {t("gameResults.extendGame")}
              </Button>
            )}
          </>
        );
      } else {
        content = (
          <>
            {nextRoundButton}
            {allowEndingGameEarly && (
              <Button
                style={{ marginLeft: 8, fontWeight: 400 }}
                onClick={onHostEndEarlyPress}
              >
                {t("gameResults.hostEndEarly")}
              </Button>
            )}
          </>
        );
      }

      return <GameFooter>{content}</GameFooter>;
    }, [
      allowAddingNewRoundsInGame,
      allowEndingGameEarly,
      isHost,
      isLastRound,
      onHostEndEarlyPress,
      onHostExtendPress,
      onHostFinalResultsPress,
      onHostNextPosePress,
      t,
    ]);

    return (
      <GameScreen
        logo={gameLogo}
        helpContent={helpContent}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
      />
    );
  }
);
