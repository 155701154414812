import React from "react";
import { GameSetupState, WebcamStreamState } from "@pose-party/types";
import { GameLandingPage } from "./GameLandingPage";
import { PlayerNameSetup } from "./PlayerNameSetup";
import { WebcamSetup } from "./WebcamSetup";
import { PlayerProfilePictureSetup } from "./PlayerProfilePictureSetup";
import { SetupWaiting } from "./SetupWaiting";
import { SetupWaitingLarge } from "./SetupWaitingLarge";
import { useWebcamStream } from "../../../../data/webcam";
import { useBrand } from "../../../../data/brand";
import { useBrowserSupported } from "../../../../data/browserSupported";
import { useAuth } from "../../../../data/auth";
import { useGame } from "../../../../hooks/useGame";

export interface GameSetupProps {
  onHostStartPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

export const GameSetup = React.memo(
  ({ onHostStartPress, kickPlayer }: GameSetupProps) => {
    const { isSignedIn } = useAuth();
    const {
      data: {
        currentPlayer,
        players,
        isHost,
        isSpectator,
        logo: gameLogo,
        name: gameName,
        spectatorCount,
        state,
      },
      setPlayerName,
      setPlayerProfilePicture,
      setPlayerSetupState,
      setAsSpectator,
    } = useGame();

    const { stream } = useWebcamStream();
    const {
      brandDetails: {
        allPlayersMustSignIn,
        landingPageContent,
        demoHelpContent,
        stillSnapshots,
        enableCameraTimer,
        allowSnapshotWebcam,
        allowSnapshotUpload,
      },
    } = useBrand();

    /**
     * Keep track of the browser supported state
     */
    const { isBrowserSupported } = useBrowserSupported();

    /**
     * Keep track of the landing page status
     */
    const [viewedLandingPage, setViewedLandingPage] = React.useState(false);
    const onGameStart = React.useCallback(() => {
      setViewedLandingPage(true);
    }, []);

    /**
     * Webcam setup
     */
    const [webcamSetupSkipped, setWebcamSetupSkipped] = React.useState(false);
    const onSkipWebcamPress = React.useCallback(() => {
      setWebcamSetupSkipped(true);
    }, []);
    const isWebcamSetup = React.useMemo(() => {
      if (allowSnapshotWebcam) {
        return stream.state === WebcamStreamState.Active || webcamSetupSkipped;
      } else {
        return true;
      }
    }, [allowSnapshotWebcam, stream.state, webcamSetupSkipped]);

    const gameLandingPageContent = React.useMemo(() => {
      if (
        state.demo &&
        (landingPageContent?.gameDemo?.top ||
          landingPageContent?.gameDemo?.bottom)
      ) {
        return landingPageContent.gameDemo;
      } else if (
        state.inPerson &&
        (landingPageContent?.gameInPerson?.top ||
          landingPageContent?.gameInPerson?.bottom)
      ) {
        return landingPageContent.gameInPerson;
      } else if (isHost) {
        return landingPageContent?.gameHost;
      } else {
        return landingPageContent?.gamePlayer;
      }
    }, [
      isHost,
      landingPageContent?.gameDemo,
      landingPageContent?.gameInPerson,
      landingPageContent?.gameHost,
      landingPageContent?.gamePlayer,
      state.demo,
      state.inPerson,
    ]);
    const hasLandingPageContent =
      gameLandingPageContent &&
      (gameLandingPageContent.top || gameLandingPageContent.bottom);

    /**
     * Manage and update the state of the player so the host can see who has setup
     */
    const setupState = React.useMemo((): GameSetupState => {
      if (isSpectator) {
        return GameSetupState.Done;
      } else if (isBrowserSupported === false) {
        return GameSetupState.Unsupported;
      } else if (
        (!viewedLandingPage && hasLandingPageContent && !isSpectator) ||
        (!isSignedIn && allPlayersMustSignIn)
      ) {
        return GameSetupState.LandingPage;
      } else if (!currentPlayer?.name && !isSpectator) {
        return GameSetupState.PlayerName;
      } else if (currentPlayer && !isWebcamSetup) {
        return GameSetupState.WebcamPermissions;
      } else if (
        !currentPlayer?.profilePicture &&
        !isSpectator &&
        !state.inPerson
      ) {
        return GameSetupState.ProfilePicture;
      } else {
        return GameSetupState.Done;
      }
    }, [
      allPlayersMustSignIn,
      currentPlayer,
      hasLandingPageContent,
      isBrowserSupported,
      isSignedIn,
      isSpectator,
      isWebcamSetup,
      state.inPerson,
      viewedLandingPage,
    ]);
    React.useEffect(() => {
      setPlayerSetupState(setupState);
    }, [setPlayerSetupState, setupState]);

    /**
     * Render the views
     */
    switch (setupState) {
      case GameSetupState.Unsupported:
      case GameSetupState.LandingPage:
        return (
          <GameLandingPage
            gameId={state.id}
            gameLogo={gameLogo}
            helpContent={state.demo ? demoHelpContent?.preSetup : undefined}
            gameName={gameName}
            content={gameLandingPageContent}
            isLargeGame={!!state.large}
            isHost={isHost}
            isDemo={!!state.demo}
            isInPerson={!!state.inPerson}
            onStart={onGameStart}
            onSpectate={setAsSpectator}
          />
        );
      case GameSetupState.PlayerName:
        return (
          <PlayerNameSetup
            gameLogo={gameLogo}
            onSetPlayerName={setPlayerName}
          />
        );
      case GameSetupState.WebcamPermissions:
        return currentPlayer ? (
          <WebcamSetup
            gameLogo={gameLogo}
            currentPlayer={currentPlayer}
            onSkipPress={allowSnapshotUpload ? onSkipWebcamPress : undefined}
          />
        ) : null;
      case GameSetupState.ProfilePicture:
        return (
          <PlayerProfilePictureSetup
            gameLogo={gameLogo}
            helpContent={
              state.demo ? demoHelpContent?.preProfilePhoto : undefined
            }
            stillMode={stillSnapshots}
            enableCameraTimer={enableCameraTimer}
            enableUploadMode={
              allowSnapshotUpload && stream.state !== WebcamStreamState.Active
            }
            onSetPlayerProfilePicture={setPlayerProfilePicture}
          />
        );
      case GameSetupState.Done:
        if (state.large) {
          return (
            <SetupWaitingLarge
              gameLogo={gameLogo}
              isHost={isHost}
              onHostStartPress={onHostStartPress}
            />
          );
        } else {
          return (
            <SetupWaiting
              gameId={state.id}
              gameLogo={gameLogo}
              isHost={isHost}
              isInPerson={!!state.inPerson}
              gamePlayers={players}
              currentPlayerUid={currentPlayer?.id}
              spectatorCount={spectatorCount}
              onHostStartPress={onHostStartPress}
              kickPlayer={kickPlayer}
            />
          );
        }
    }
  }
);
