import React from "react";
import { useTimer } from "react-timer-hook";
import { RoundEndInterstitial } from "./RoundEndInterstitial";
import { OwnResultsInterstitial } from "./OwnResultsInterstitial";
import { ResultsGrid } from "./ResultsGrid";
import { useBrand } from "../../../../data/brand";
import { useGame } from "../../../../hooks/useGame";
import { useGameIdParam } from "../../../../hooks/useGameIdParam";

export interface ResultsProps {
  onHostNextPosePress: () => Promise<void>;
  onHostFinalResultsPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

type ResultsInterstitialState =
  | { state: "finished" }
  | { state: "ownResults" }
  | { state: "roundEnd" };

const useResultsInterstitialTimer = (): ResultsInterstitialState => {
  const {
    brandDetails: {
      timingRoundEndInterstitial,
      timingResultsOwnScoreInterstitial,
    },
  } = useBrand();

  const { seconds } = useTimer({
    expiryTimestamp:
      Date.now() +
      timingRoundEndInterstitial +
      timingResultsOwnScoreInterstitial,
  });

  const state: ResultsInterstitialState = React.useMemo(() => {
    if (seconds >= timingResultsOwnScoreInterstitial / 1000) {
      return { state: "roundEnd" };
    } else if (seconds > 0) {
      return { state: "ownResults" };
    } else {
      return { state: "finished" };
    }
  }, [seconds, timingResultsOwnScoreInterstitial]);

  return state;
};

export const Results: React.FunctionComponent<ResultsProps> = React.memo(
  ({ onHostNextPosePress, onHostFinalResultsPress, kickPlayer }) => {
    const {
      brandId,
      brandDetails: {
        allowEndingGameEarly,
        allowAddingNewRoundsInGame,
        demoHelpContent,
      },
    } = useBrand();
    const gameId = useGameIdParam();
    const {
      data: {
        state,
        logo: gameLogo,
        currentPose,
        players: gamePlayers,
        currentPlayer,
        currentPlayerPoseSnapshot,
        otherPlayerPoseSnapshots,
        isHost,
        isSpectator,
      },
    } = useGame();

    /**
     * Start a timer for the interstitial
     */
    const interstitial = useResultsInterstitialTimer();

    if (!currentPose || (!isSpectator && !currentPlayer)) {
      return null;
    } else if (interstitial.state === "roundEnd") {
      return <RoundEndInterstitial roundNumber={currentPose.order + 1} />;
    } else if (
      interstitial.state === "ownResults" &&
      !!currentPlayer &&
      !!currentPlayerPoseSnapshot
    ) {
      return (
        <OwnResultsInterstitial
          roundNumber={currentPose.order + 1}
          prompt={currentPose.prompt.toUpperCase()}
          player={currentPlayer}
          isLargeGame={!!state.large}
          currentPlayerPoseSnapshot={currentPlayerPoseSnapshot}
          gameLogo={gameLogo}
        />
      );
    } else {
      return (
        <ResultsGrid
          brandId={brandId}
          gameId={gameId}
          gameLogo={gameLogo}
          helpContent={
            state.demo && currentPose.order === 0
              ? demoHelpContent?.preResults
              : undefined
          }
          isLargeGame={!!state.large}
          currentPose={currentPose}
          currentPlayer={currentPlayer}
          currentPlayerPoseSnapshot={currentPlayerPoseSnapshot}
          gamePlayers={gamePlayers}
          otherPlayerPoseSnapshots={otherPlayerPoseSnapshots}
          isHost={isHost}
          allowEndingGameEarly={allowEndingGameEarly}
          allowAddingNewRoundsInGame={!state.demo && allowAddingNewRoundsInGame}
          uid={currentPlayer?.id}
          onHostNextPosePress={onHostNextPosePress}
          onHostFinalResultsPress={onHostFinalResultsPress}
          kickPlayer={kickPlayer}
        />
      );
    }
  }
);
