import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Markdown } from "./Markdown";
import { Modal } from "./Modal";

export interface MarkdownContentModalProps {
  content: string | undefined;
}

export const MarkdownContentModal: React.FunctionComponent<MarkdownContentModalProps> = React.memo(
  ({ content }) => {
    const { t } = useTranslation();
    const [displayed, setDisplayed] = React.useState(!!content);

    const onClose = React.useCallback(() => setDisplayed(false), []);

    if (!displayed || !content) {
      return null;
    }

    return (
      <Modal animated onClose={onClose}>
        <Markdown source={content} />
        <Button onClick={onClose}>{t("common.continue")}</Button>
      </Modal>
    );
  }
);
