import React from "react";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GameStatus } from "@pose-party/types";
import { GameStateView } from "./GameStateView";
import { FullScreenLoading } from "../components/FullScreenLoading";
import { hideGamePlayer } from "../../data/game";
import { useAuth } from "../../data/auth";
import { useGame } from "../../hooks/useGame";
import { useHostActions } from "../../hooks/useHostActions";
import { useGameIdParam } from "../../hooks/useGameIdParam";
import { LoadingModal } from "../components/LoadingModal";
import { useBrand } from "../../data/brand";

const UserSelectDisable = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
`;

export const Game: React.FunctionComponent = React.memo(() => {
  const { t } = useTranslation();
  const { uid } = useAuth();
  const { brandId, hasLoadedBrandDetails } = useBrand();
  const gameId = useGameIdParam();
  const { data } = useGame();

  const [performingHostNext, hostActions] = useHostActions({
    brandId,
    gameId,
    uid,
    groupSize: data.state.largeGroupSize,
    hostPlaying: true,
    isHost: data.isHost,
  });

  const kickPlayer = React.useCallback(
    (playerId: string) => {
      const player = data.players?.find((p) => p.id === playerId);

      const confirmMessage = player?.name
        ? t("common.kickConfirmName", { playerName: player.name })
        : t("common.kickConfirmUnknown");
      const sure = window.confirm(confirmMessage);
      if (!sure) {
        return;
      }

      hideGamePlayer({ brandId, gameId, playerId, groupId: player?.groupId });
    },
    [brandId, data.players, gameId, t]
  );

  /**
   * Delay showing the game slightly to ensure we have the host brand settings correctly
   */
  const [showGame, setShowGame] = React.useState(false);
  React.useEffect(() => {
    const handle = setTimeout(() => setShowGame(true), 500);
    return () => clearTimeout(handle);
  }, []);

  return showGame && hasLoadedBrandDetails ? (
    <UserSelectDisable id="posePartyGame">
      <GameStateView hostActions={hostActions} kickPlayer={kickPlayer} />
      {performingHostNext && <LoadingModal text={t("hostNext")} />}
      {data.state.status !== GameStatus.Setup &&
        data.state.status !== GameStatus.Finished &&
        data.state.status !== GameStatus.FinalResults && (
          <Prompt message="Are you sure you want to leave the party?" />
        )}
    </UserSelectDisable>
  ) : (
    <FullScreenLoading />
  );
});
