import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { useBrand } from "../../data/brand";
import { AccentButton } from "../components/Button";

const LinksContainer = styled.div`
  padding: 16px 16px 32px 16px;
  display: flex;
  flex-direction: column;
`;
const AuthButton = styled(AccentButton)`
  margin-top: 8px;
`;
const LoginInLink = styled(Link)`
  padding: 8px 0;
  text-align: center;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const Spacer = styled.div`
  height: 32px;
`;

export const AuthLinks = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    brandDetails: { allowSignUp },
  } = useBrand();

  const handleHostAuth = React.useCallback(() => {
    history.push("/signup");
  }, [history]);

  if (!allowSignUp) {
    return <Spacer />;
  }

  return (
    <LinksContainer>
      <AuthButton onClick={handleHostAuth}>{t("home.authSignUp")}</AuthButton>
      <LoginInLink to="/login">{t("home.authSignIn")}</LoginInLink>
    </LinksContainer>
  );
};
