import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  useGameName,
  useGameCurrentPlayer,
  useGameLogo,
  useGameLogoUrl,
  acceptGamePlayerInvite,
} from "../../data/game";
import { GameScreen } from "../components/GameScreen";
import { ShadowBottom } from "../components/ShadowBottom";
import { RegularTitle } from "../components/Title";
import { Text } from "../components/Text";
import { BackButton } from "../components/BackButton";
import { Button } from "../components/Button";
import { useAuth } from "../../data/auth";
import { useBrand } from "../../data/brand";
import { BrandLogo } from "../components/BrandLogo";

const Content = styled.div`
  overflow-y: auto;
`;
const TopCard = styled(ShadowBottom)`
  padding: 24px;
  align-items: center;
`;
const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;
const Logo = styled(BrandLogo)`
  margin: 40px 0;
  max-width: 300px;
  max-height: 200px;
`;
const GameName = styled(RegularTitle)``;
const GameCode = styled(Text)`
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const Instructions = styled.div`
  margin: 32px 16px;
`;
const Hint = styled(Text)`
  margin-bottom: 16px;
`;

export const GameInvite = () => {
  const { t } = useTranslation();
  const { gameId, playerInviteCode } = useParams<{
    gameId: string;
    playerInviteCode: string;
  }>();
  const history = useHistory();
  const { uid } = useAuth();
  const { brandId, brandDetails } = useBrand();

  const gamePath = `${brandDetails.domain}/${gameId}`;
  const gameURL = `https://${gamePath}`;

  const gameName = useGameName({ brandId, gameId });
  const gameLogo = useGameLogo({ brandId, gameId });
  const gameLogoUrl = useGameLogoUrl({ brandId, gameId });
  const gamePlayers = useGameCurrentPlayer({ brandId, gameId, uid });
  const isCurrentPlayer = !!gamePlayers;

  const gameLogoView = React.useMemo(
    () => <Logo overrideSrc={gameLogo} alt="" />,
    [gameLogo]
  );

  const [isAccepting, setIsAccepting] = React.useState(false);
  const onAcceptPress = React.useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();

      if (isAccepting) {
        return;
      }

      setIsAccepting(true);

      try {
        await acceptGamePlayerInvite({
          brandId,
          gameId,
          playerInviteCode,
        });

        setIsAccepting(false);
      } catch (e) {
        alert(t("gameInvite.acceptError", { details: e.toString() }));
        setIsAccepting(false);
      }
    },
    [brandId, playerInviteCode, isAccepting, t, gameId]
  );

  const renderBody = React.useCallback(() => {
    return (
      <Content>
        <TopCard>
          <BackButtonContainer>
            <BackButton onClick={() => history.replace(`/${gameId}`)} />
          </BackButtonContainer>
          {gameLogoUrl ? (
            <a href={gameLogoUrl}>{gameLogoView}</a>
          ) : (
            gameLogoView
          )}
          {!!gameName && <GameName>{gameName}</GameName>}
          <a href={gameURL}>
            <GameCode weight="bold">{gamePath}</GameCode>
          </a>
        </TopCard>

        {isCurrentPlayer ? (
          <Instructions>
            <Hint>{t("gameInvite.isCurrentPlayer")}</Hint>
          </Instructions>
        ) : (
          <Instructions>
            <Hint>{t("gameInvite.hint")}</Hint>
            <Button loading={isAccepting} onClick={onAcceptPress}>
              {t("gameInvite.button")}
            </Button>
          </Instructions>
        )}
      </Content>
    );
  }, [
    gameLogoUrl,
    gameLogoView,
    gameName,
    gameURL,
    gamePath,
    isCurrentPlayer,
    t,
    isAccepting,
    onAcceptPress,
    history,
    gameId,
  ]);

  return <GameScreen renderBody={renderBody} />;
};
