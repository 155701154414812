import React, { ImgHTMLAttributes } from "react";
import { useGoogleStorageUrl } from "../../hooks/useGoogleStorageUrl";

export type GoogleStorageImageProps = ImgHTMLAttributes<HTMLImageElement>;

export const GoogleStorageImage: React.FunctionComponent<GoogleStorageImageProps> = ({
  src,
  ...rest
}) => {
  const internalSrc = useGoogleStorageUrl(src);
  return internalSrc ? <img src={internalSrc} alt="" {...rest} /> : null;
};
