import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

export interface ModalProps {
  animated?: boolean;
  onClose?: () => void;
}

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.backgroundOverlay};
  border-radius: 8px;
  width: 100%;
  max-width: min(calc(100vw - 40px), 343px);
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const Modal: React.FunctionComponent<ModalProps> = React.memo(
  ({ animated, onClose, children }) => {
    const content = (
      <div className="modal is-active">
        <div className="modal-background" />
        <Container className="modal-content">{children}</Container>
        {onClose && (
          <button
            className="modal-close is-large"
            type="button"
            aria-label="close"
            onClick={onClose}
          />
        )}
      </div>
    );

    if (animated) {
      return (
        <motion.div
          variants={{
            visible: {
              opacity: 1,
              transition: {
                delay: 0.5,
              },
            },
            hidden: { opacity: 0 },
          }}
          initial="hidden"
          animate="visible"
        >
          {content}
        </motion.div>
      );
    } else {
      return content;
    }
  }
);
