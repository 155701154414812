import { GameState, GameStatus } from "@pose-party/types";
import React from "react";
import { useBrand } from "../data/brand";

export const useFinalGameData = <
  GameData extends { state: GameState } | undefined
>(
  data: GameData
) => {
  const {
    brandDetails: { deleteGamesWhenFinished },
  } = useBrand();

  const [finalData, setFinalData] = React.useState<GameData>();
  React.useEffect(() => {
    if (!deleteGamesWhenFinished) {
      return;
    }

    if (
      data?.state.status === GameStatus.FinalResults ||
      data?.state.status === GameStatus.Finished
    ) {
      setFinalData(data);
    }
  }, [data, deleteGamesWhenFinished]);

  return finalData;
};
