import React from "react";
import { WebcamSnapshot, SNAPSHOT_FRAMES_PER_SECOND } from "@pose-party/types";
import { GoogleStorageImage } from "../components/GoogleStorageImage";

export interface SnapshotDisplayProps {
  snapshot: WebcamSnapshot | undefined;
  animated?: boolean;
}

export const SnapshotDisplay: React.FunctionComponent<SnapshotDisplayProps> = React.memo(
  ({ snapshot = [], animated }) => {
    const [counter, setCounter] = React.useState({
      current: 0,
      direction: 1,
    });
    React.useEffect(() => {
      const interval = setInterval(() => {
        if (animated === false || snapshot.length <= 1) {
          return;
        }

        setCounter((prev) => {
          const nextCurrentDirection = prev.current + prev.direction;
          if (
            nextCurrentDirection >= 0 &&
            nextCurrentDirection < snapshot.length
          ) {
            // This direction is fine, set the new value
            return {
              current: nextCurrentDirection,
              direction: prev.direction,
            };
          } else if (nextCurrentDirection < 0) {
            // We reached the bottom, count up
            return {
              current: 1,
              direction: 1,
            };
          } else {
            // We reached the top, count down
            return {
              current: snapshot.length - 2,
              direction: -1,
            };
          }
        });
      }, 1000 / SNAPSHOT_FRAMES_PER_SECOND);
      return () => clearInterval(interval);
    }, [animated, snapshot.length]);

    if (snapshot.length > 0) {
      return (
        <>
          {snapshot.map((snapshotFrame, index) => (
            <GoogleStorageImage
              key={snapshotFrame}
              src={snapshotFrame}
              className="snpshotDisplayFrame"
              style={{
                width: "100%",
                height: "100%",
                display: index !== counter.current ? "none" : undefined,
              }}
            />
          ))}
        </>
      );
    } else {
      return null;
    }
  }
);
