import React from "react";
import { useBrand } from "../../data/brand";
import {
  GoogleStorageImage,
  GoogleStorageImageProps,
} from "./GoogleStorageImage";

export interface BrandLogoProps extends Omit<GoogleStorageImageProps, "src"> {
  overrideSrc?: string | null | undefined;
}

export const BrandLogo: React.FunctionComponent<BrandLogoProps> = ({
  overrideSrc,
  alt,
  ...rest
}) => {
  const { brandDetails } = useBrand();

  const src = overrideSrc || brandDetails.logo;
  if (src) {
    return (
      <GoogleStorageImage src={src} alt={alt || brandDetails.name} {...rest} />
    );
  } else {
    return null;
  }
};
