import React from "react";
import { RegularTitle } from "./Title";

export interface LoadingModalProps {
  text: string;
}

export const LoadingModal: React.FunctionComponent<LoadingModalProps> = ({
  text,
}) => {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <RegularTitle
          style={{ color: "white", textAlign: "center", overflow: "none" }}
        >
          {text}
        </RegularTitle>
      </div>
    </div>
  );
};
