import React from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  BrandDetails,
  GamePlayer,
  GameSetupState,
  GameState,
  GameStatus,
  ReportedSnapshot,
  WebcamSnapshot,
} from "@pose-party/types";
import { getNextUnplayedPoseId } from "./pose";
import firebase, { useFBDatabaseCollection } from "../firebase";
import { api } from "../api";
import { logger } from "../../logger";
import { groupPlayers } from "./utils";

/**
 * Calculate scores
 */
export const calculateScores = (params: {
  brandId: string;
  gameId: string;
  poseId?: string;
}) => {
  return api.post("calculateScores", params);
};

/**
 * Accept an invite to a locked game
 */
export const acceptGamePlayerInvite = (params: {
  brandId: string;
  gameId: string;
  playerInviteCode: string;
}) => {
  return api.post("acceptGamePlayerInvite", params);
};

/**
 * Internal helper to group players at the start of the game
 */
const doGroupPlayers = async ({
  gameRef,
  groupSize,
}: {
  gameRef: firebase.database.Reference;
  groupSize: number | undefined;
}) => {
  // Get the player list with only those who have completed setup
  const playersRef = gameRef.child("players");
  const playersSnapshot = await playersRef.once("value");
  const playersData: Record<
    string,
    Omit<GamePlayer, "id">
  > = playersSnapshot.val();
  const players: GamePlayer[] = _.chain(playersData)
    .map(
      (data, id): GamePlayer => ({
        id,
        ...data,
      })
    )
    .filter((player) => player.setupState === GameSetupState.Done)
    .value();

  // Work out the groups
  const playersGrouped = groupPlayers(players, groupSize || players.length);

  // Create the group
  const groupIds = [];
  for (let i = 0; i < playersGrouped.length; i += 1) {
    const groupId = i.toString();
    groupIds.push(groupId);

    const playerGroup = playersGrouped[i];
    const playerIds = _.map(playerGroup, (p) => p.id);

    const groupRef = gameRef.child("groups").child(groupId);
    await groupRef.child("players").set(
      _.keyBy(
        playerGroup.map((player) => _.omit(player, ["scored", "setupState"])),
        (p) => p.id
      )
    );

    // Set the group id on the players
    for (let j = 0; j < playerIds.length; j += 1) {
      playersRef.child(playerIds[j]).child("groupId").set(groupId);
    }
  }

  // Set the array of group ids
  // TODO: Don't do it this way when we have preset groups
  await gameRef.child("groupIds").set(groupIds);
};

/**
 * Method for a host to add a pose to the game
 */
export const hostAddPose = async ({
  brandId,
  gameId,
  prompt,
  order,
}: {
  brandId: string;
  gameId: string;
  prompt: string;
  order: number;
}) => {
  // Create the game reference
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);
  await gameRef.child(`poses`).child(order.toString()).set({
    prompt,
    order,
  });
};

/**
 * Internal helper method to start the given pose of the given game id
 */
const startPose = async (
  gameRef: firebase.database.Reference,
  poseId: string
) => {
  await gameRef.child("state/status").set(GameStatus.Pose);
  await gameRef.child("state/currentPoseId").set(poseId);
  await gameRef.child(`poses/${poseId}/played`).set(Date.now());
};

/**
 * Methods to move to the next step of the game
 */
// Setting up - Start the game
export const hostStartGame = async ({
  brandId,
  gameId,
  uid,
  groupSize,
  hostPlaying,
}: {
  brandId: string;
  gameId: string;
  uid: string;
  groupSize: number | undefined;
  hostPlaying: boolean;
}): Promise<void> => {
  // Create the game reference
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);

  // Move players into a group and set the group id on all of the players
  await doGroupPlayers({ gameRef, groupSize });

  // Get the first unplayed pose
  const poseId = await getNextUnplayedPoseId(gameRef);
  if (!poseId) {
    throw new Error("There is no pose to start the game");
  }
  // Set the started at time
  await gameRef.child("startedAt").set(Date.now());
  // Set this user as the main host. This avoid multiple hosts automatically advancing the game
  await gameRef.child("state/mainHost").set(hostPlaying ? uid : "hostUI");
  // Start the pose
  await startPose(gameRef, poseId);
};

// Posing - Move to scoring
export const hostStartScoring = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
  uid: string;
}): Promise<void> => {
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);
  await gameRef.child("state/status").set(GameStatus.Score);
};

// Scoring - Move to Results
export const hostShowResults = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
  uid: string;
}): Promise<void> => {
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);

  const currentStateData = await gameRef.child("state").once("value");
  if (!currentStateData.exists()) {
    throw new Error("The given `gameId` is not valid");
  }
  const currentState: GameState = currentStateData.val();

  await calculateScores({
    brandId,
    gameId,
    poseId: currentState.currentPoseId,
  });

  // For in person games with a single round, skip the results stage and go directly to final results
  // Otherwise, go to the round results stage
  await gameRef
    .child("state/status")
    .set(currentState.inPerson ? GameStatus.FinalResults : GameStatus.Results);
};

// Results - Move to next pose
export const hostStartNextPose = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
  uid: string;
}): Promise<void> => {
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);

  const poseId = await getNextUnplayedPoseId(gameRef);
  if (!poseId) {
    throw new Error("The given game does not have a next pose to move to");
  }

  await startPose(gameRef, poseId);
};

// Results - Move to final results
export const hostShowFinalResults = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
  uid: string;
}): Promise<void> => {
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);
  await gameRef.child("finishedAt").set(Date.now());
  await gameRef.child("state/status").set(GameStatus.FinalResults);
};

export const hostActions = {
  startGame: hostStartGame,
  startScoring: hostStartScoring,
  showResults: hostShowResults,
  startNextPose: hostStartNextPose,
  showFinalResults: hostShowFinalResults,
};
export type HostActions = Record<keyof typeof hostActions, () => Promise<void>>;

/**
 * API call to create a new game
 */
export const newGame = async (params: {
  brandId: string;
  gameId?: string;
  hostId?: string;
  name: string;
  largeGroupSize?: number;
  poses: string[];
  poseTimings: number[];
  gameBrandOverrides?: Partial<BrandDetails>;
}) => {
  return api.post("newGame", params);
};

/**
 * API call to create a edit game
 */
export const editGame = async (params: {
  brandId: string;
  gameId: string;
  name: string;
  largeGroupSize?: number;
  poses: string[];
  poseTimings: number[];
}) => {
  return api.post("editGame", params);
};

/**
 * API call to delete a new game
 */
export const deleteGame = async (params: {
  brandId: string;
  gameId: string;
}) => {
  return api.post("deleteGame", params);
};

/**
 * API call to ensure all players are in groups
 */
export const ensureAllPlayersAreInGroup = async (params: {
  brandId: string;
  gameId: string;
}) => {
  return api.post("ensureAllPlayersAreInGroup", params);
};

/**
 * API call to regenerate the snapshots for a game
 */
export const regenerateGameSnapshots = async (params: {
  brandId: string;
  gameId: string;
}) => {
  return api.post("regenerateGameSnapshots", params);
};

/**
 * Set a lock on a given game
 */
export const setGameLock = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  await firebase
    .database()
    .ref(`/brands/${brandId}/games/${gameId}/state/locked`)
    .set(true);
};

/**
 * Remove the lock for the given game
 */
export const removeGameLock = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  await firebase
    .database()
    .ref(`/brands/${brandId}/games/${gameId}/state/locked`)
    .set(null);
};

/**
 * Set a PIN for the given game
 */
const GAME_PIN_LENGTH = 4;
const GAME_PIN_CHARACTERS = "0123456789";
export const setGamePin = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  // Create the PIN
  let pin = "";
  for (let i = 0; i < GAME_PIN_LENGTH; i += 1) {
    pin += GAME_PIN_CHARACTERS.charAt(
      Math.floor(Math.random() * GAME_PIN_CHARACTERS.length)
    );
  }

  // Create the game reference
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);

  // Set the PIN
  await gameRef.child("galleryPin").set(pin);
};

/**
 * Remove the PIN for the given game
 */
export const removeGamePin = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}) => {
  // Create the game reference
  const gameRef = firebase.database().ref(`/brands/${brandId}/games/${gameId}`);

  // Remove the PIN
  await gameRef.child("galleryPin").set(null);
};

/**
 * Updates the game with the given logo and overlay
 */
export const updateGameBranding = async ({
  brandId,
  gameId,
  logoFile,
  overlayFile,
  logoUrl,
}: {
  brandId: string;
  gameId: string;
  logoFile?: File;
  overlayFile?: File;
  logoUrl: string;
}): Promise<boolean> => {
  try {
    let logoFileRef: string | null = null;
    if (logoFile) {
      const uploadedLogoFile = await firebase
        .storage()
        .ref(`/brands/${brandId}/gameLogos`)
        .child(gameId)
        .child(uuid())
        .put(logoFile);
      logoFileRef = uploadedLogoFile.ref.toString();
    }

    let overlayFileRef: string | null = null;
    if (overlayFile) {
      const uploadedOverlayFile = await firebase
        .storage()
        .ref(`/brands/${brandId}/gameOverlays`)
        .child(gameId)
        .child(uuid())
        .put(overlayFile);
      overlayFileRef = uploadedOverlayFile.ref.toString();
    }

    const gameRef = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}`);
    if (logoFileRef) {
      await gameRef.child("logo").set(logoFileRef);
    }
    if (overlayFileRef) {
      await gameRef.child("videoOverlay").set(overlayFileRef);
    }
    await gameRef.child("logoUrl").set(logoUrl);
    return true;
  } catch (e) {
    logger.warn("Error updating game branding", e);
    return false;
  }
};

/**
 * Removes the custom game branding
 */
export const removeGameBranding = async ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): Promise<boolean> => {
  try {
    const gameRef = firebase
      .database()
      .ref(`/brands/${brandId}/games/${gameId}`);
    await gameRef.child("logo").set(null);
    await gameRef.child("videoOverlay").set(null);
    return true;
  } catch (e) {
    logger.warn("Error remove game branding", e);
    return false;
  }
};

/**
 * Sets a subset of the game branding overrides
 */
export const updateGameBrandDetailsOverride = async <
  Key extends keyof BrandDetails
>({
  brandId,
  gameId,
  key,
  value,
}: {
  brandId: string;
  gameId: string;
  key: Key;
  value: BrandDetails[Key] | undefined;
}): Promise<boolean> => {
  try {
    const gameBrandDetailsDoc = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("gameBrandDetails")
      .doc(gameId);

    if (value === undefined || value === null) {
      // If we're set to undefined or null, then remove the key completed
      await gameBrandDetailsDoc.set(
        { [key]: firebase.firestore.FieldValue.delete() },
        { merge: true }
      );
    } else {
      // Otherwise, set the value provided
      await gameBrandDetailsDoc.set({ [key]: value }, { merge: true });
    }
    return true;
  } catch (e) {
    logger.warn("Error remove game branding", e);
    return false;
  }
};

/**
 * Sets a subset of the game branding overrides
 */
export const updateGameBrandDetailsOverrides = async ({
  brandId,
  gameId,
  values,
}: {
  brandId: string;
  gameId: string;
  values: Partial<BrandDetails>;
}): Promise<boolean> => {
  try {
    const gameBrandDetailsDoc = firebase
      .firestore()
      .collection("brands")
      .doc(brandId)
      .collection("gameBrandDetails")
      .doc(gameId);

    await gameBrandDetailsDoc.set(values, { merge: true });
    return true;
  } catch (e) {
    logger.warn("Error remove game branding", e);
    return false;
  }
};

/**
 * Subscrbe to changes in the setup game state counters
 */
export const useGameSetupStateCounters = ({
  enabled,
  brandId,
  gameId,
}: {
  enabled: boolean;
  brandId: string;
  gameId: string;
}): { [status in GameSetupState]: number } | undefined => {
  const values = useFBDatabaseCollection(
    enabled ? `/brands/${brandId}/games/${gameId}/setupState` : undefined,
    React.useCallback(
      (id, data) => ({ id, value: data.child("value").val() }),
      []
    )
  );

  const gameSetupStateCounters = React.useMemo(() => {
    if (!enabled) {
      return undefined;
    }

    const counts =
      values !== undefined ? _.countBy(values, (data) => data.value) : {};

    return {
      [GameSetupState.Unsupported]: counts[GameSetupState.Unsupported] || 0,
      [GameSetupState.LandingPage]: counts[GameSetupState.LandingPage] || 0,
      [GameSetupState.PlayerName]: counts[GameSetupState.PlayerName] || 0,
      [GameSetupState.WebcamPermissions]:
        counts[GameSetupState.WebcamPermissions] || 0,
      [GameSetupState.ProfilePicture]:
        counts[GameSetupState.ProfilePicture] || 0,
      [GameSetupState.Done]: counts[GameSetupState.Done] || 0,
    };
  }, [enabled, values]);

  return gameSetupStateCounters;
};

/**
 * Subscrbe to changes in the pose game state count
 */
export const useGamePoseStateCounter = ({
  enabled,
  brandId,
  gameId,
  poseId,
}: {
  enabled: boolean;
  brandId: string;
  gameId: string;
  poseId: string | undefined;
}): number | undefined => {
  const completedPlayerIds = useFBDatabaseCollection(
    enabled && poseId
      ? `/brands/${brandId}/games/${gameId}/poseState/${poseId}`
      : undefined,
    React.useCallback((id) => ({ id }), [])
  );

  return completedPlayerIds?.length;
};

/**
 * Subscrbe to changes in the pose game state count
 */
export const useGameScoreStateCounter = ({
  enabled,
  brandId,
  gameId,
  poseId,
}: {
  enabled: boolean;
  brandId: string;
  gameId: string;
  poseId: string | undefined;
}): number | undefined => {
  const completedPlayerIds = useFBDatabaseCollection(
    enabled && poseId
      ? `/brands/${brandId}/games/${gameId}/scoreState/${poseId}`
      : undefined,
    React.useCallback((id) => ({ id }), [])
  );

  return completedPlayerIds?.length;
};

/**
 * Subscrbe to changes in the reported snapshot collection
 */
export const useReportedSnapshots = ({
  brandId,
  gameId,
}: {
  brandId: string;
  gameId: string;
}): ReportedSnapshot[] | undefined => {
  return useFBDatabaseCollection(
    `/brands/${brandId}/games/${gameId}/reportedSnapshots`,
    React.useCallback(
      (id, data) => ({
        id,
        groupId: data.child("groupId").val(),
        poseId: data.child("poseId").val(),
        snapshotId: data.child("snapshotId").val(),
        snapshot: data.child("snapshot").val(),
        uid: data.child("uid").val(),
        name: data.child("name").val(),
        reportUid: data.child("reportUid").val(),
      }),
      []
    )
  );
};

/**
 * Load the top X pose results
 */
export interface PlayerPositionDetails {
  uid: string;
  name: string;
  position: number;
  equalPosition: boolean;
  score: number;
  snapshot: WebcamSnapshot;
}
export const loadTopPoseResults = async ({
  brandId,
  gameId,
  poseId,
  numberOfPositions,
}: {
  brandId: string;
  gameId: string;
  poseId: string;
  numberOfPositions: number;
}): Promise<PlayerPositionDetails[]> => {
  const playersRef = firebase
    .database()
    .ref(`/brands/${brandId}/games/${gameId}/players`);

  const playersQuery = playersRef
    .orderByChild("poseResults/position")
    .startAt(1)
    .endAt(numberOfPositions);

  const players = await playersQuery.once("value");

  const groupSnapshots: {
    [groupId: string]: firebase.database.DataSnapshot;
  } = {};

  const promises: Promise<PlayerPositionDetails | null>[] = [];
  players.forEach((player) => {
    promises.push(
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve) => {
        const uid = player.key;
        const groupId = player.child("groupId").val() || "";
        const name = player.child("name").val() || "";
        const position = player.child("poseResults/position").val();
        const equalPosition =
          player.child("poseResults/equalPosition").val() || false;
        const score = player.child("poseResults/score").val() || false;

        let snapshots = groupSnapshots[groupId];
        if (!snapshots) {
          snapshots = await firebase
            .database()
            .ref(
              `/brands/${brandId}/games/${gameId}/groups/${groupId}/snapshots/${poseId}`
            )
            .once("value");
        }

        let playerSnapshot: WebcamSnapshot | undefined;
        snapshots.forEach((snapshot) => {
          if (snapshot.child("uid").val() === uid) {
            playerSnapshot = snapshot.child("snapshot").val();
          }
        });

        if (
          !uid ||
          !name ||
          typeof position !== "number" ||
          typeof score !== "number" ||
          !playerSnapshot
        ) {
          resolve(null);
        } else {
          resolve({
            uid,
            name,
            position,
            equalPosition,
            score,
            snapshot: playerSnapshot,
          });
        }
      })
    );
  });

  const playerDetails = await Promise.all(promises);
  return _.chain(playerDetails).compact().sortBy("position").reverse().value();
};

/**
 * Load the top X player details from the final results
 */
export const loadTopResults = async ({
  brandId,
  gameId,
  numberOfPositions,
}: {
  brandId: string;
  gameId: string;
  numberOfPositions: number;
}): Promise<PlayerPositionDetails[]> => {
  const playersRef = firebase
    .database()
    .ref(`/brands/${brandId}/games/${gameId}/players`);

  const playersQuery = playersRef
    .orderByChild("results/position")
    .startAt(1)
    .endAt(numberOfPositions);

  const players = await playersQuery.once("value");

  const playerDetails: PlayerPositionDetails[] = [];
  players.forEach((player) => {
    const uid = player.key;
    const name = player.child("name").val() || "";
    const position = player.child("results/position").val();
    const equalPosition = player.child("results/equalPosition").val() || false;
    const score = player.child("results/score").val() || false;
    const profilePicture = player.child("profilePicture").val();

    if (
      !uid ||
      !name ||
      typeof position !== "number" ||
      typeof score !== "number" ||
      !profilePicture
    ) {
      return;
    }

    playerDetails.push({
      uid,
      name,
      position,
      equalPosition,
      score,
      snapshot: profilePicture,
    });
  });

  return _.chain(playerDetails).compact().sortBy("position").reverse().value();
};
