import React from "react";
import { Game } from "./Game";
import { WebcamStreamProvider } from "../../data/webcam";
import { GameProvider } from "../../hooks/useGame";
import { GameSoundsProvider } from "../../hooks/useGameSounds";

export const GameIndex: React.FunctionComponent = React.memo(() => {
  return (
    <WebcamStreamProvider>
      <GameSoundsProvider>
        <GameProvider>
          <Game />
        </GameProvider>
      </GameSoundsProvider>
    </WebcamStreamProvider>
  );
});
