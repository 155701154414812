import React from "react";
import { useTranslation } from "react-i18next";
import { WebcamStreamState } from "@pose-party/types";
import { GameHeader } from "../../../components/GameHeader";
import { RoundStartInterstitial } from "./RoundStartInterstitial";
import { PoseCountdownInterstitial } from "./PoseCountdownInterstitial";
import { CapturePose } from "./CapturePose";
import { PoseWaiting } from "./PoseWaiting";
import { useRoundTimer } from "../../../../hooks/useRoundTimer";
import { ScreenAreaRenderProps } from "../../../components/GameScreen";
import { useBrand } from "../../../../data/brand";
import { useGame } from "../../../../hooks/useGame";
import { usePoseInterstitialTimer } from "../../../../hooks/usePoseInterstitialTimer";
import { useWebcamStream } from "../../../../data/webcam";

export interface PoseProps {
  onHostStartScoringPress: () => Promise<void>;
  kickPlayer: (playerId: string) => void;
}

export const Pose: React.FunctionComponent<PoseProps> = React.memo(
  ({ onHostStartScoringPress, kickPlayer }) => {
    const { t } = useTranslation();
    const {
      brandDetails: {
        timingRoundStartInterstitial,
        stillSnapshots,
        enableCameraTimer,
        timingPoseRoundLength,
        hideRoundCountdown,
        demoHelpContent,
        allowSnapshotUpload,
      },
    } = useBrand();
    const {
      data: {
        state,
        currentPose,
        currentPlayerPoseSnapshot,
        otherPlayerPoseSnapshots,
        players,
        isHost,
        isSpectator,
        logo: gameLogo,
      },
      setPlayerSnapshot,
    } = useGame();
    const { stream } = useWebcamStream();

    /**
     * Manipulate the player list to decide when they are finished
     */
    const playerList = players.map((player) => {
      const { id, profilePicture } = player;
      // Finished if it's the current player or we have a snapshot for the given player
      const finished =
        (currentPlayerPoseSnapshot &&
          player.id === currentPlayerPoseSnapshot.uid) ||
        !!otherPlayerPoseSnapshots?.find(
          (snapshot) => snapshot.uid === player.id
        );

      return {
        id,
        profilePicture,
        finished,
      };
    });
    const allFinished = React.useMemo(
      () => playerList.reduce((prev, player) => prev && player.finished, true),
      [playerList]
    );

    /**
     * Timer for the interstitial screens
     */
    const interstitial = usePoseInterstitialTimer(timingRoundStartInterstitial);

    /**
     * Keep track of when we think the round ends
     */
    const { roundEnded, countdownDetails } = useRoundTimer({
      roundLength: currentPose?.timing || timingPoseRoundLength,
      // Only start when the interstitial screens are gone
      started: interstitial.state === "finished",
    });

    /**
     * Host should move to the next stage when the counter ends or all players are finished
     */
    const hasMovedNext = React.useRef(false);
    React.useEffect(() => {
      if (isHost && (allFinished || roundEnded) && !hasMovedNext.current) {
        hasMovedNext.current = true;
        onHostStartScoringPress();
      }
    }, [allFinished, isHost, onHostStartScoringPress, roundEnded]);

    /**
     * Allow the host to manually move next, if needed
     */
    const onHostNext = React.useCallback(() => {
      if (!isHost) {
        return;
      }

      if (!allFinished && !roundEnded) {
        const sure = window.confirm(t("gamePose.waiting.hostConfirm"));
        if (!sure) {
          return;
        }
      }

      onHostStartScoringPress();
    }, [allFinished, isHost, onHostStartScoringPress, roundEnded, t]);

    /**
     * Render the views
     */
    const renderHeader = React.useCallback(
      ({ toggleMenu }: ScreenAreaRenderProps) => {
        if (!currentPose) {
          return;
        }

        return (
          <>
            <GameHeader
              logo={gameLogo}
              title={currentPose.prompt.toUpperCase()}
              toggleMenu={toggleMenu}
              countdownDetails={
                countdownDetails && !hideRoundCountdown
                  ? {
                      ...countdownDetails,
                      playClick: !currentPlayerPoseSnapshot,
                    }
                  : undefined
              }
            />
          </>
        );
      },
      [
        countdownDetails,
        currentPlayerPoseSnapshot,
        currentPose,
        gameLogo,
        hideRoundCountdown,
      ]
    );

    if (!currentPose) {
      // Not yet loaded
      return null;
    } else if (interstitial.state === "roundStart") {
      // Round start interstitial
      return <RoundStartInterstitial roundNumber={currentPose.order + 1} />;
    } else if (interstitial.state === "poseCountdown") {
      // Pose countdown
      return (
        <PoseCountdownInterstitial
          prompt={currentPose.prompt}
          count={interstitial.count}
        />
      );
    } else if (!isSpectator && !currentPlayerPoseSnapshot) {
      // Player snapshot
      return (
        <CapturePose
          gameLogo={gameLogo}
          helpContent={
            state.demo && currentPose.order === 0
              ? demoHelpContent?.prePose
              : undefined
          }
          stillMode={stillSnapshots}
          enableCameraTimer={enableCameraTimer}
          enableUploadMode={
            allowSnapshotUpload && stream.state !== WebcamStreamState.Active
          }
          onSnapshotTaken={setPlayerSnapshot}
          renderHeader={renderHeader}
        />
      );
    } else {
      // Player waiting
      return (
        <PoseWaiting
          isDemoGame={!!state.demo}
          isLargeGame={!!state.large}
          gameLogo={gameLogo}
          currentPlayerPoseSnapshot={currentPlayerPoseSnapshot}
          gamePlayers={players}
          otherPlayerPoseSnapshots={otherPlayerPoseSnapshots || []}
          isSpectator={isSpectator}
          renderHeader={renderHeader}
          kickPlayer={isHost ? kickPlayer : undefined}
          onHostNext={isHost ? onHostNext : undefined}
        />
      );
    }
  }
);
