import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useStopwatch, useTimer } from "react-timer-hook";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { VeryLargeTitle } from "../../components/Title";
import { ScoreHint } from "../../components/ScoreHint";

const SECONDS_PER_HINT = 5;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(VeryLargeTitle)`
  text-align: center;
  margin: 32px 0;
`;

const Hints = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 120px;
  position: relative;
`;
const Hint = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const variants: Variants = {
  initial: {
    transition: {
      ease: "backInOut",
    },
    scale: 0.75,
    opacity: 0,
  },
  enter: {
    transition: {
      duration: 0.75,
      delay: 0.25,
      ease: "backInOut",
    },
    scale: 1,
    opacity: 1,
  },
  exit: {
    transition: {
      duration: 0.5,
      ease: "backInOut",
    },
    scale: 1.1,
    opacity: 0,
  },
};

export const GameScreenshareScore: React.FunctionComponent = React.memo(() => {
  const { t } = useTranslation();

  const stopwatch = useStopwatch({
    autoStart: false,
  });
  useTimer({
    expiryTimestamp: Date.now() + 2000,
    onExpire: React.useCallback(() => {
      stopwatch.start();
    }, [stopwatch]),
  });
  const slide = React.useMemo(() => {
    const count = stopwatch.seconds % (3 * SECONDS_PER_HINT);
    if (count < SECONDS_PER_HINT) {
      return 0;
    } else if (count < 2 * SECONDS_PER_HINT) {
      return 1;
    } else {
      return 2;
    }
  }, [stopwatch.seconds]);

  const slideProps = {
    variants,
    initial: "initial",
    animate: "enter",
    exit: "exit",
  };

  return (
    <Content>
      <Title>{t("scoreStartInterstitial.title")}</Title>

      <Hints>
        <AnimatePresence>
          {slide === 0 && (
            <Hint key="dislike" {...slideProps}>
              <ScoreHint type="dislike" />
            </Hint>
          )}

          {slide === 1 && (
            <Hint key="like" {...slideProps}>
              <ScoreHint type="like" />
            </Hint>
          )}

          {slide === 2 && (
            <Hint key="superlike" {...slideProps}>
              <ScoreHint type="superlike" />
            </Hint>
          )}
        </AnimatePresence>
      </Hints>
    </Content>
  );
});
